import React from 'react';
import { Pressable, View } from 'react-native';
import { StyleService, Text, useStyleSheet } from '@ui-kitten/components';
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome';
import { TextIcon } from 'components/layout';
import * as helper from 'utility/Helper';

export const Claim = (props) => {

    /*
        general:
        - isFirstItem
        - isLastItem
        - setActiveModal
        - setClaimDetails
        - setModalVisible
        claimDetails (props):
        - claimId
        - claimImages [claimDocumentId, filePath, createdat]
        - createdAt
        - createdAtFull
        - createdBy
        - details
        - updatedAt
        - updatedAtFull
    */

    const styles = useStyleSheet(themedStyles);
    const claimDetails = props.claimDetails;

    const showModal = () => {
        props.setClaimDetails(claimDetails);
        props.setActiveModal('ClaimDetails');
        props.setModalVisible(true);
    }

    return (
        <Pressable style={helper.isWeb() ? {cursor: "pointer"} : {}} onPress={() => showModal()}>
            <View style={helper.getContainerStyles(props, styles)}>
                <View style={styles.dataView}>
                    <View style={styles.titleView}>
                        <Text style={styles.title} category="s2">Claim Details</Text>
                        {props.claimImages.length > 0 &&
                            <FontAwesomeIcon style={styles.imageIcon} icon={['fal', 'image']} size={styles.imageIcon.size} color={styles.imageIcon.color} />
                        }
                    </View>
                    <TextIcon iconName="calendar-alt" iconWeight="far" iconSize={styles.icon.size} iconColor={styles.icon.color} text={'Submitted on '+props.createdAt} textCategory="c2" textAppearance="hint" />
                    <Text style={styles.details} appearance="hint" category="p1">{props.details}</Text>
                </View>
                <View style={styles.actionView}>
                    <Text status="primary" category="p2">View</Text>
                </View>
            </View>
        </Pressable>
    )

}

const themedStyles = StyleService.create({
    container: {
        borderBottomColor: "border-basic-color-3",
        borderBottomWidth: 1,
        flexDirection: "row",
        flex: 1,
        paddingVertical: 5,
        minHeight: 60,
    },
    firstItem: {
        paddingTop: 2,
    },
    lastItem: {
        borderBottomWidth: 0,
        paddingBottom: 0,
    },
    imageIcon: {
        color: "color-primary-500",
        size: 14,
        marginLeft: 5,
    },
    dataView: {

    },
    titleView: {
        flexDirection: "row",
        alignItems: "center",
        marginBottom: 3,
    },
    details: {
        marginTop: 8
    },
    actionView: {
        position: "absolute",
        right: 5,
        top: "50%",
        marginTop: -8
    },
    forwardIcon: {
        color: "color-primary-500",
        size: 20,
    },
    icon: {
        size: 14,
        color: "color-basic-600"
    }
});
