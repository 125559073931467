import React, { useContext, useState, useEffect } from 'react';
import { useFocusEffect } from "@react-navigation/native";
import { Dimensions, ScrollView, View } from 'react-native';
import { SafeAreaView } from 'react-native-safe-area-context';
import { Button, Divider, Layout, List, ListItem, StyleService, Text, useStyleSheet } from '@ui-kitten/components';
import { AuthContext } from 'components/context';
import { EmptyDataSet, Loader } from 'components/utility';
import { CustomModal, Header, Section } from 'components/layout';
import { FindALoadFilters } from 'screens/find-a-load/modals';
import { LoadSummary } from 'components/loads';
import LocationTrackingDisclosure from 'location/modals/LocationTrackingDisclosure';
import * as helper from 'utility/Helper';
import * as api from 'api/SleekTechnologiesApi';
import * as Constants from 'config/Constants';
import moment from 'moment';

export default function FindALoad({ route, navigation }) {

    const styles = useStyleSheet(themedStyles);
    const { signOut } = useContext(AuthContext);
    const [loads, setLoads] = useState([]);
    const [isLoading, setLoading] = useState(false);
    const [isRefreshing, setRefreshing] = useState(false);
    const [recordCount, setRecordCount] = useState(null);
    const [modalVisible, setModalVisible] = useState(false);
    const [locationTrackingDisclosureModalVisible, setLocationTrackingDisclosureModalVisible] = useState(false);
    const [lastLoadTime, setLastLoadTime] = useState(Date.now()/1000);

    const pickupDateFrom = moment().format("MM/DD/YYYY");
    const pickupDateTo = moment().add(30, 'days').format("MM/DD/YYYY");
    const defaultLoadFilters = {
        loadType: "all",
        origin: "all",
        originRadius: 100,
        destination: "all",
        destinationRadius: 100,
        pickupDateFrom: pickupDateFrom,
        pickupDateTo: pickupDateTo,
        offset: 0
    };
    const defaultLoadFilterDisplayValues = {
        loadType: Constants.LOAD_TYPE_VALUES,
        origin: '',
        originRadius: 100,
        destination: '',
        destinationRadius: 100,
        pickupDateFrom: pickupDateFrom,
        pickupDateTo: pickupDateTo,
        offset: 0,
    };
    const [loadFilters, setLoadFilters] = useState(defaultLoadFilters);
    const [loadFilterDisplayValues, setLoadFilterDisplayValues] = useState(defaultLoadFilterDisplayValues);

    const breadcrumbs = [
        {
            label: 'Dashboard',
            path: '/dashboard'
        },
        {
            label: 'Find A Load',
            path: ''
        }
    ]
    const windowWidth = Dimensions.get('window').width;
    const windowHeight = Dimensions.get('window').height;
    const bodyHeight = windowHeight - 140;

    const getAvailableLoads = async () => {
        setLoading(true);
        const viewedLocationTrackingDisclosure = await helper.getSecureStoreItem('viewedLocationTrackingDisclosure');
        api.getAvailableLoads(loadFilters)
            .then(function(response) {
                if(helper.hasValue(response.data.msg) && response.data.msg == "Token Not Found or Expired") {
                    signOut();
                }
                if(response.data.result == "success") {
                    setLoads(loads => {
                        return [...loads, ...response.data.availableLoads]
                    });
                    setRecordCount(response.data.recordCount);
                    setLastLoadTime(Date.now()/1000);
                    setLoading(false);
                    setRefreshing(false);
                    if(!helper.isWeb() && viewedLocationTrackingDisclosure != 'yes') {
                        helper.setSecureStoreItem('viewedLocationTrackingDisclosure', 'yes');
                        setLocationTrackingDisclosureModalVisible(true);
                    }
                } else if(response.data.result == "error") {
                    throw {
                        type: "custom",
                        message: response.data.msg
                    }
                }
            })
            .catch(function(error){
                setLoading(false);
                helper.handleCatchError(error);
            });
    }

    useEffect(() => {
        getAvailableLoads();
    }, [loadFilters]);

    useFocusEffect(
        React.useCallback(() => {
            if((Date.now()/1000) - 900 > lastLoadTime || route.params?.refreshLoads) {
                navigation.reset({index: 0, routes: [{ name: 'FindALoad' }]});
            }
        }, [lastLoadTime])
    );

    const renderLoadSummary = ({ item, index }) => {
        return (
            <ListItem
                style={styles.listItem}
                children={
                    <LoadSummary
                        {...item}
                        loadSummaryType="available"
                    />
                }
            />
        )
    }

    const renderListFooter = () => {
        if(isLoading && !isRefreshing) {
            return (
                <View style={styles.loader}>
                    <Loader loadingText="Getting Available Loads"/>
                </View>
            )
        } else {
            return null;
        }
    }

    const renderListEmptyComponent = () => {
        if(recordCount == 0 && !isLoading) {
            return (
                <EmptyDataSet primaryMessage="No Loads Found" secondaryMessage="Use Filters to Expand your Search" />
            )
        } else {
            return null;
        }
    }

    const handleLoadMore = () => {
        if(loads.length < recordCount && !isLoading) {
            setLoadFilters({
                ...loadFilters,
                offset: loads.length
            });
        }
    }

    const refreshData = () => {
        setRefreshing(true);
        setLoads([]);
        setRecordCount(null);
        setLoadFilters({
            ...loadFilters,
            offset: 0
        });
    }

    const getPageActions = () => {
        if(helper.isMobileWeb()) {
            return (
                <Button appearance="outline" size="small" onPress={() => setModalVisible(true)}>Filter</Button>
            )
        } else {
            return null;
        }
    }

    const renderLoadList = () => {
        return (
            <List
                style={styles.listView}
                contentContainerStyle={[styles.listContainer, { justifyContent: loads.length > 0 ? "flex-start" : "center"}]}
                data={loads}
                renderItem={renderLoadSummary}
                ItemSeparatorComponent={Divider}
                keyExtractor={(item, index) => index.toString()}
                ListFooterComponent={renderListFooter}
                ListEmptyComponent={renderListEmptyComponent}
                onEndReached={handleLoadMore}
                onEndReachedThreshold={Constants.END_OF_LIST_THRESHOLD}
                onRefresh={() => refreshData()}
                refreshing={isRefreshing}
            />
        )
    }

    const renderLoadFilters = () => {
        return (
            <FindALoadFilters
                setModalVisible={setModalVisible}
                setLoading={setLoading}
                setLoads={setLoads}
                defaultLoadFilters={defaultLoadFilters}
                defaultLoadFilterDisplayValues={defaultLoadFilterDisplayValues}
                loadFilterDisplayValues={loadFilterDisplayValues}
                loadFilters={loadFilters}
                setLoadFilters={setLoadFilters}
                setLoadFilterDisplayValues={setLoadFilterDisplayValues}
            />
        )
    }

    const renderLocationTrackingDisclosure = () => {
        return (
            <LocationTrackingDisclosure setModalVisible={setLocationTrackingDisclosureModalVisible} />
        )
    }

    return (
        helper.isWeb() ? (
            <View style={styles.webView}>
                <Header breadcrumbs={breadcrumbs} title="Find A Load" subtitle={helper.isMobileWeb() && recordCount != null ? recordCount + ' Matching Loads' : ''} pageActions={getPageActions()} />
                <Layout style={styles.layout} level="3">
                    {helper.isDesktopWeb() &&
                        <ScrollView>
                            <View style={styles.main}>
                                <View style={styles.columnOne}>
                                    <Section title="Filters" noBodyPadding={false} renderBody={renderLoadFilters()} />
                                </View>
                                <View style={styles.columnTwo}>
                                    <Section title="Available Loads" subtitle={recordCount != null ? recordCount + ' Matching Loads' : ''} noBodyPadding={loads.length > 0 ? true : false} bodyHeight={bodyHeight} renderBody={renderLoadList()} />
                                </View>
                            </View>
                        </ScrollView>
                    }
                    {helper.isMobileWeb() &&
                        <>
                            {renderLoadList()}
                        </>
                    }
                </Layout>
                {helper.isMobileWeb() &&
                    <CustomModal title="Filter Loads" modalVisible={modalVisible} setModalVisible={setModalVisible}>
                        {renderLoadFilters()}
                    </CustomModal>
                }
            </View>
        ) : (
            <SafeAreaView style={styles.safeAreaView} edges={['top', 'left', 'right']}>
                <Header activeScreen="FindALoad" subtitle="Tip: Pull Down to Refresh" setModalVisible={setModalVisible} />
                <Layout style={styles.layout} level="3">
                    {renderLoadList()}
                </Layout>
                <CustomModal title="Filter Loads" modalVisible={modalVisible} setModalVisible={setModalVisible}>
                    {renderLoadFilters()}
                </CustomModal>
                <CustomModal title="Sleek Fleet" modalVisible={locationTrackingDisclosureModalVisible} setModalVisible={setLocationTrackingDisclosureModalVisible}>
                    {renderLocationTrackingDisclosure()}
                </CustomModal>
            </SafeAreaView>
        )
    )

}

const themedStyles = StyleService.create({
    // global styles
    layout: {
        flex: 1,
        marginTop: helper.isDesktopWeb() ? 5 : 0
    },
    loader: {
        marginTop: 10,
        paddingVertical: 20,
    },
    listContainer: {
        flexGrow: 1
    },
    listView: {
        borderBottomLeftRadius: helper.isDesktopWeb() ? 6 : 0,
        borderBottomRightRadius: helper.isDesktopWeb() ? 6 : 0
    },
    listItem: {
        paddingHorizontal: 0,
        paddingVertical: 0,
    },
    // native specific styles
    safeAreaView: {
        backgroundColor: "safe-area-view-background",
        flex: 1,
    },
    // web specific styles
    webView: {
        flex: 1,
    },
    main: {
        flexDirection: "row",
    },
    columnOne: {
        flex: 3,
    },
    columnTwo: {
        flex: 6
    }
});
