import React, { useContext, useState, useEffect } from 'react';
import { Image, View } from 'react-native';
import { Button, Divider, Layout, StyleService, Text, useStyleSheet } from '@ui-kitten/components';
import { AuthContext } from 'components/context';
import { InputField } from 'components/forms';
import { Loader, UploadPreview } from 'components/utility';
import { Formik, Field } from 'formik';
import { Rating } from "react-native-rating-element";
import * as yup from 'yup';
import * as api from 'api/SleekTechnologiesApi';
import * as helper from 'utility/Helper';

export default function SubmitLocationReview(props) {

    const styles = useStyleSheet(themedStyles);
    const { signOut } = useContext(AuthContext);
    const [isLoading, setLoading] = useState(false);
    const [uploads, setUploads] = useState([]);
    const [uploadMethod, setUploadMethod] = useState(null);
    const [userRating, setUserRating] = useState(0);
    const loadDetails = props.loadDetails;
    const locationId = props.locationId;
    const validationSchema = yup.object().shape({
        rating: yup
            .number()
            .required('Rating is required'),
    });

    const pickImage = async () => {

        let result = await helper.pickImage();

        if(result) {
            let newUploads = uploads;
            newUploads.push(result);
            setUploads([...newUploads]);
            setUploadMethod('imagePickerLibrary');
        }

    };

    const takePhoto = async () => {

        let result = await helper.takePhoto();

        if(result) {
            let newUploads = uploads;
            newUploads.push(result);
            setUploads([...newUploads]);
            setUploadMethod('imagePickerCamera');
        }
    };

    const UploadsList = () => {
        return (
            uploads.map((item, index) => {
                let uploadNumber = index + 1;
                item.uploadMethod = uploadMethod;
                item.uploadTitle = 'File upload ' + uploadNumber + ' of ' + uploads.length;
                item.index = index;

                return (
                    <UploadPreview key={index} removeUpload={removeUpload} {...item} />
                )
            })
        )
    }

    const resetUploads = () => {
        let newUploads = [];
        setUploads([...newUploads]);
        setUploadMethod(null);
    }

    const addUpload = () => {

        switch(uploadMethod) {
            case 'imagePickerLibrary':
                pickImage();
                break;
            case 'imagePickerCamera':
                takePhoto();
                break;
        }
    }

    const removeUpload = (index) => {

        let newUploads = uploads;
        newUploads.splice(index, 1);
        setUploads([...newUploads]);

    }

    const submitLocationReview = (data) => {

        setLoading(true);

        let uploadedImages = [];
        let uploadedImage;

        uploads.forEach(upload => {
            uploadedImage = 'data:image/jpeg;base64,'+upload.base64;
            uploadedImages.push(uploadedImage);
        });

        api.submitLocationReview(data.locationId, userRating, data.review, uploadedImages)
            .then(function(response){
                if(helper.hasValue(response.data.msg) && response.data.msg == "Token Not Found or Expired") {
                    signOut();
                }
                setLoading(false);
                if(response.data.result == "success") {
                    props.setModalVisible(false);
                    props.setModalError(null);
                    helper.showFlashMessage(response.data.result, response.data.msg);
                    props.getLoadDetails(loadDetails.sleekFleetLoadId);
                } else if(response.data.result == "error") {
                    throw {
                        type: "custom",
                        message: response.data.msg
                    }
                }
            })
            .catch(function(error){
                setLoading(false);
                helper.handleCatchError(error, true, props.setModalError);
            });
    }

    if(isLoading) {
        return (
            <Loader loadingText="Submitting Location Review" />
        )
    } else {
        return (
            <View style={styles.container}>

                <Formik
                    validationSchema={validationSchema}
                    initialValues={{
                        locationId: locationId,
                        rating: userRating,
                        review: "",
                        uploadedFiles: uploads,
                    }}
                    onSubmit={(values) => submitLocationReview(values)}
                >
                    {({ handleSubmit, isValid, values }) => (
                        <>
                            <View style={[styles.instructions, styles.firstItem]}>
                                <Text category="s2">Location Rating</Text>
                                <Text category="p1" appearance="hint">How would you rate your overall experience at this location?</Text>
                            </View>
                            <View style={styles.ratingView}>
                                <Rating
                                    rated={userRating}
                                    totalCount={5}
                                    size={40}
                                    ratingColor={styles.starIconFilled.color}
                                    ratingBackgroundColor={styles.starIcon.color}
                                    icon="star"
                                    onIconTap={rating => setUserRating(rating)}
                                    marginBetweenRatingIcon={3}
                                    direction="row"
                                />
                            </View>
                            <Divider />
                            <View style={styles.uploadsView}>
                                <View style={styles.instructions}>
                                    <Text category="s2">Location Photos (Optional)</Text>
                                    <Text category="p1" appearance="hint">Upload one or more photos of this location to help other drivers:</Text>
                                </View>
                                {uploads.length == 0 &&
                                    <>
                                        <View style={styles.buttonWrapper}>
                                            <View style={styles.buttonView}>
                                                <Button style={[styles.button, styles.buttonLeft]} appearance="outline" size="small" onPress={pickImage}>Choose From Library</Button>
                                            </View>
                                            <View style={styles.buttonView}>
                                                <Button style={[styles.button, styles.buttonRight]} appearance="outline" size="small" onPress={takePhoto}>Take Photo</Button>
                                            </View>
                                        </View>
                                    </>
                                }
                                {uploads.length > 0 &&
                                    <>
                                        <View style={styles.uploadsView}>
                                            <UploadsList />
                                        </View>
                                        <View style={styles.buttonWrapper}>
                                            <View style={styles.buttonView}>
                                                <Button style={[styles.button, styles.buttonLeft]} appearance="outline" size="small" onPress={addUpload}>Add Another</Button>
                                            </View>
                                            <View style={styles.buttonView}>
                                                <Button style={[styles.button, styles.buttonRight]} appearance="outline" size="small" onPress={resetUploads}>Cancel</Button>
                                            </View>
                                        </View>
                                    </>
                                }
                            </View>
                            <Divider />
                            <View style={styles.instructions}>
                                <Text category="s2">Location Review (Optional)</Text>
                                <Text category="p1" appearance="hint">How would you describe your experience at this location?</Text>
                            </View>
                            <Field
                                component={InputField}
                                name="review"
                                placeholder="Enter details here..."
                                multiline={true}
                                textStyle={{ minHeight: 150 }}
                            />
                            <Button onPress={handleSubmit} disabled={!isValid}>Submit Location Review</Button>
                        </>
                    )}
                </Formik>
            </View>
        )
    }
}

const themedStyles = StyleService.create({
    container: {
        // styles here...
    },
    uploadsView: {
        paddingBottom: 10,
    },
    instructions: {
        paddingBottom: 15,
        marginTop: 15
    },
    firstItem: {
        marginTop: 5
    },
    buttonWrapper: {
        flexDirection: "row",
    },
    buttonView: {
        flex: 1,
    },
    button: {
        marginBottom: 10,
    },
    buttonLeft: {
        marginRight: 5,
    },
    buttonRight: {
        marginLeft: 5
    },
    ratingView: {
        marginTop: 0,
        marginBottom: 20,
    },
    starIcon: {
        color: "color-basic-500"
    },
    starIconFilled: {
        color: "color-primary-500"
    }
});
