import React, { useContext, useState, useEffect } from 'react';
import { Image, Pressable, ScrollView, View } from 'react-native';
import { SafeAreaView } from 'react-native-safe-area-context';
import { Button, Divider, Layout, StyleService, Text, useStyleSheet } from '@ui-kitten/components';
import { AppDataContext, AuthContext } from 'components/context';
import { Header } from 'components/layout';
import { Loader, UploadPreview } from 'components/utility';
import { InputField, SelectField, ToggleField } from 'components/forms';
import { Formik, Field } from 'formik';
import Collapsible from 'react-native-collapsible';
import * as yup from 'yup';
import * as api from 'api/SleekTechnologiesApi';
import * as helper from 'utility/Helper';
import * as Constants from 'config/Constants';
import getEnvVars from 'root/environment';

export default function MyProfileEdit({ navigation }) {

    const styles = useStyleSheet(themedStyles);
    const { sleekApiUrl } = getEnvVars();
    const { appData, setAppData } = useContext(AppDataContext);
    const { signOut } = useContext(AuthContext);
    const userData = appData.carrierUserData;
    const [isLoading, setLoading] = useState(false);

    const [userImageCollapsed, setUserImageCollapsed] = useState(true);
    const [userImageUploads, setUserImageUploads] = useState([]);

    const [driverLicenseCollapsed, setDriverLicenseCollapsed] = useState(true);
    const [driverLicenseImageUploads, setDriverLicenseImageUploads] = useState([]);

    const [driverLicensePlateCollapsed, setDriverLicensePlateCollapsed] = useState(true);
    const [driverLicensePlateImageUploads, setDriverLicensePlateImageUploads] = useState([]);

    const stateProvinceOptions = Constants.STATE_PROVICE_OPTIONS;
    const trailerTypeOptions = Constants.TRAILER_TYPE_OPTIONS;
    const trailerLengthOptions = Constants.TRAILER_LENGTH_OPTIONS;
    const trailerWidthOptions = Constants.TRAILER_WIDTH_OPTIONS;
    const trailerHeightOptions = Constants.TRAILER_HEIGHT_OPTIONS;
    const trailerDoorTypeOptions = Constants.TRAILER_DOOR_TYPE_OPTIONS;
    const trailerLengthValues = Constants.TRAILER_LENGTH_VALUES;
    const trailerWidthValues = Constants.TRAILER_WIDTH_VALUES;
    const trailerHeightValues = Constants.TRAILER_HEIGHT_VALUES;

    const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/

    yup.addMethod(yup.string, "phoneLength", function (errorMessage) {
        return this.test(`test-phone-length`, errorMessage, function (value) {
            const { path, createError } = this;

            return (
                ((value && value.replace(/-/g, "").length === 10) || typeof value == 'undefined') ||
                createError({ path, message: errorMessage })
            );
        });
    });

    const validationSchema = yup.object().shape({
        firstName: yup
            .string()
            .required('First Name is Required'),
        lastName: yup
            .string()
            .required('Last Name is Required'),
        email: yup
            .string()
            .email()
            .nullable()
            .test('oneOfRequired', 'Email or Phone is Required', function(item){return(this.parent.email || this.parent.phone)}),
        phone: yup
            .string()
            .matches(phoneRegExp, 'Phone number is not valid')
            .phoneLength('Phone number must be 10 digits')
            .nullable()
            .test('oneOfRequired', 'Email or Phone is Required', function(item){return(this.parent.email || this.parent.phone)}),
        passwordConfirmation: yup
            .string()
            .oneOf([yup.ref('password'), null], 'Passwords must match')
            .when('password', {is: value => value && value != '', then: yup.string().required('Please confirm your password')}),
        maxWeightOfLoadsUnformatted: yup
            .number()
            .typeError('Max weight must be a number'),
    });

    const breadcrumbs = [
        {
            label: 'Dashboard',
            path: '/dashboard'
        },
        {
            label: 'My Profile',
            path: '/my-profile'
        },
        {
            label: 'Edit Profile',
            path: ''
        }
    ]

    const toggleCollapse = (type) => {

        if(type == 'userImage') {
            setUserImageCollapsed(!userImageCollapsed);
        } else if(type == 'driverLicense') {
            setDriverLicenseCollapsed(!driverLicenseCollapsed);
        } else if(type == 'driverLicensePlate') {
            setDriverLicensePlateCollapsed(!driverLicensePlateCollapsed);
        }

    };

    const pickImage = (uploadType) => async () => {

        let result = await helper.pickImage();

        if(result) {
            if(uploadType == 'userImage') {
                let newUploads = userImageUploads;
                newUploads.push(result);
                setUserImageUploads([...newUploads]);
            } else if(uploadType == 'driverLicense') {
                let newUploads = driverLicenseImageUploads;
                newUploads.push(result);
                setDriverLicenseImageUploads([...newUploads]);
            } else if(uploadType == 'driverLicensePlate') {
                let newUploads = driverLicensePlateImageUploads;
                newUploads.push(result);
                setDriverLicensePlateImageUploads([...newUploads]);
            }
        }

    };

    const takePhoto = (uploadType) => async () => {

        let result = await helper.takePhoto();

        if(result) {
            if(uploadType == 'userImage') {
                let newUploads = userImageUploads;
                newUploads.push(result);
                setUserImageUploads([...newUploads]);
            } else if(uploadType == 'driverLicense') {
                let newUploads = driverLicenseImageUploads;
                newUploads.push(result);
                setDriverLicenseImageUploads([...newUploads]);
            } else if(uploadType == 'driverLicensePlate') {
                let newUploads = driverLicensePlateImageUploads;
                newUploads.push(result);
                setDriverLicensePlateImageUploads([...newUploads]);
            }
        }
    };

    const UploadsList = (props) => {
        return (
            props.uploads.map((item, index) => {
                let uploadNumber = index + 1;
                item.uploadMethod = "imagePicker";
                item.uploadTitle = props.title;
                item.index = index;

                return (
                    <UploadPreview key={index} removeUpload={props.removeUpload} {...item} />
                )
            })
        )
    }

    const resetUploads = (uploadType) => () => {
        let newUploads = [];
        if(uploadType == 'userImage') {
            setUserImageUploads([...newUploads]);
        } else if(uploadType == 'driverLicense') {
            setDriverLicenseImageUploads([...newUploads]);
        } else if(uploadType == 'driverLicensePlate') {
            setDriverLicensePlateImageUploads([...newUploads]);
        }
    }

    const removeUserImageUpload = (index) => {
        let newUploads = userImageUploads;
        newUploads.splice(index, 1);
        setUserImageUploads([...newUploads]);
    }

    const removeDriverLicenseUpload = (index) => {
        let newUploads = driverLicenseImageUploads;
        newUploads.splice(index, 1);
        setDriverLicenseImageUploads([...newUploads]);
    }

    const removeDriverLicensePlateUpload = (index) => {
        let newUploads = driverLicensePlateImageUploads;
        newUploads.splice(index, 1);
        setDriverLicensePlateImageUploads([...newUploads]);
    }

    const userSettings = {
        firstName: userData.firstName,
        lastName: userData.lastName,
        password: '',
        passwordConfirmation: '',
        email: userData.email,
        phone: userData.phone,
        doNotDisturb: (userData.doNotDisturb == 1 ? true : false),
        receiveSleekFleetSMSNotifications: (userData.receiveSleekFleetSMSNotifications == 'Yes' ? true : false),
        receiveNewLoadSMSNotifications: (userData.receiveNewLoadSMSNotifications == 'Yes' ? true : false),
        receiveActiveLoadSMSNotifications: (userData.receiveActiveLoadSMSNotifications == 'Yes' ? true : false),
        receiveSleekFleetEmailNotifications: (userData.receiveSleekFleetEmailNotifications == 'Yes' ? true : false),
        receiveNewLoadEmailNotifications: (userData.receiveNewLoadEmailNotifications == 'Yes' ? true : false),
        receiveActiveLoadEmailNotifications: (userData.receiveActiveLoadEmailNotifications == 'Yes' ? true : false),
        driverLicenseNumber: (userData.driverLicenseNumber == null ? '' : userData.driverLicenseNumber),
        driverLicenseState: (userData.driverLicenseState == null ? '' : userData.driverLicenseState),
        truckType: (userData.truckType == null ? '' : userData.truckType),
        trailerLength: (userData.trailerLength == null ? '' : userData.trailerLength),
        trailerLengthOther: (userData.trailerLength == null || trailerLengthValues.includes(userData.trailerLength) ? '' : userData.trailerLength),
        trailerWidth: (userData.trailerWidth == null ? '' : userData.trailerWidth),
        trailerWidthOther: (userData.trailerWidth == null || trailerWidthValues.includes(userData.trailerWidth) ? '' : userData.trailerWidth),
        trailerHeight: (userData.trailerHeight == null ? '' : userData.trailerHeight),
        trailerHeightOther: (userData.trailerHeight == null || trailerHeightValues.includes(userData.trailerHeight) ? '' : userData.trailerHeight),
        doorType: (userData.doorType == null ? '' : userData.doorType),
        maxWeightOfLoadsUnformatted: (userData.maxWeightOfLoadsUnformatted == null ? '' : userData.maxWeightOfLoadsUnformatted),
        userImage: '',                               //Possibly set to display the current one -- here and under render section
        driverLicenseImage: '',                      //Possibly set to display the current one -- here and under render section
        driverLicensePlateImage: '',                 //Possibly set to display the current one -- here and under render section
    };

    const saveCarrierUserData = (data) => {

        setLoading(true);

        //Make Data Updates
        if(data.phone != '') {
            let submittedPhone = data.phone.replace(/-/g, "");
            data.phone = submittedPhone.substr(0,3)+'-'+submittedPhone.substr(3,3)+'-'+submittedPhone.substr(6,4);
        }
        if(data.trailerLength == 'Other') {
            data.trailerLength = data.trailerLengthOther;
        }
        if(data.trailerWidth == 'Other') {
            data.trailerWidth = data.trailerWidthOther;
        }
        if(data.trailerHeight == 'Other') {
            data.trailerHeight = data.trailerHeightOther;
        }

        //Add Images to Data, if provided
        data.userImage = (typeof userImageUploads[0] !== 'undefined' ? userImageUploads[0].base64 : '');
        data.driverLicenseImage = (typeof driverLicenseImageUploads[0] !== 'undefined' ? driverLicenseImageUploads[0].base64 : '');
        data.driverLicensePlateImage = (typeof driverLicensePlateImageUploads[0] !== 'undefined' ? driverLicensePlateImageUploads[0].base64 : '');

        //Update Carrier Data
        api.updateCarrierUserData(data)
            .then(function(response){
                if(helper.hasValue(response.data.msg) && response.data.msg == "Token Not Found or Expired") {
                    signOut();
                }
                if(response.data.result == "success") {
                    helper.showFlashMessage(response.data.result, response.data.msg);
                    //Refresh Application Data
                    api.getApplicationData()
                        .then(function(response){
                            if(response.data.result == "success") {
                                setAppData({...response.data});
                                navigation.reset({index: 0, routes: [{ name: 'MyProfile' }]});
                            } else if(response.data.result == "error") {
                                throw {
                                    type: "custom",
                                    message: response.data.msg
                                }
                            }
                        })
                        .catch(function(error){
                            helper.handleCatchError(error);
                        });
                } else if(response.data.result == "error") {
                    setLoading(false);
                    throw {
                        type: "custom",
                        message: response.data.msg
                    }
                }
            })
            .catch(function(error){
                setLoading(false);
                helper.handleCatchError(error);
            });
    };

    const EditProfileView = () => {
        return (
            <Layout style={styles.layout} level="3">
                <Formik
                    validationSchema={validationSchema}
                    initialValues={userSettings}
                    onSubmit={(values) => saveCarrierUserData(values)}
                >
                    {({ handleSubmit, isValid, values }) => (
                        <>
                            <View style={styles.formSection}>
                                <View style={styles.formSectionTitle}>
                                    <Text category="s1">Personal Information</Text>
                                </View>
                                <View style={styles.formSectionFields}>
                                    <View style={styles.collapsibleWrapper}>
                                        <View style={styles.collapsibleHeader}>
                                            <View style={styles.collapsibleHeaderRowOne}>
                                                <Image
                                                    source={{
                                                        uri: sleekApiUrl+userData.userPhoto,
                                                        headers: Constants.RESOURCE_HEADERS
                                                    }}
                                                    style={styles.userImage}
                                                    resizeMode="contain"
                                                />
                                            </View>
                                            <View style={styles.collapsibleHeaderRowTwo}>
                                                <Pressable onPress={() => toggleCollapse('userImage')}>
                                                    <Text style={styles.collapsibleHeaderAction} status="primary" category="s2">Update Profile Picture</Text>
                                                </Pressable>
                                            </View>
                                        </View>
                                        <Collapsible style={styles.collapsibleBody} collapsed={userImageCollapsed}>
                                            {userImageUploads.length == 0 &&
                                                <>
                                                    <Text style={styles.formSectionIntro} category="p1" appearance="hint">Upload a new photo to replace your existing profile picture:</Text>
                                                    <View style={styles.buttonWrapper}>
                                                        <View style={styles.buttonView}>
                                                            <Button style={[styles.button, styles.buttonLeft]} appearance="outline" size="small" onPress={pickImage('userImage')}>Choose From Library</Button>
                                                        </View>
                                                        {!helper.isWeb() &&
                                                            <View style={styles.buttonView}>
                                                                <Button style={[styles.button, styles.buttonRight]} appearance="outline" size="small" onPress={takePhoto('userImage')}>Take Photo</Button>
                                                            </View>
                                                        }
                                                    </View>
                                                </>
                                            }
                                            {userImageUploads.length > 0 &&
                                                <>
                                                    <View style={styles.uploadsView}>
                                                        <UploadsList uploads={userImageUploads} removeUpload={removeUserImageUpload} title="Profile Picture Upload" />
                                                    </View>
                                                    <View style={styles.buttonWrapper}>
                                                        <View style={styles.buttonView}>
                                                            <Button style={styles.button} appearance="outline" size="small" onPress={resetUploads('userImage')}>Cancel</Button>
                                                        </View>
                                                    </View>
                                                </>
                                            }
                                        </Collapsible>
                                    </View>
                                    <Field
                                        component={InputField}
                                        name="firstName"
                                        label="First Name"
                                        defaultValue={userData.firstName}
                                    />
                                    <Field
                                        component={InputField}
                                        name="lastName"
                                        label="Last Name"
                                        defaultValue={userData.lastName}
                                    />
                                    <Field
                                        component={InputField}
                                        name="email"
                                        label="Email"
                                        defaultValue={userData.email}
                                    />
                                    <Field
                                        component={InputField}
                                        name="phone"
                                        label="Phone"
                                        defaultValue={userData.phone}
                                    />
                                </View>
                            </View>
                            <View style={styles.formSection}>
                                <View style={styles.formSectionTitle}>
                                    <Text category="s1">Password Management (Optional)</Text>
                                </View>
                                <View style={styles.formSectionFields}>
                                    <Text style={styles.formSectionIntro} category="p1" appearance="hint">To update your Sleek Fleet password, please enter a new password twice below. Your password must be a minimum of 8 characters.</Text>
                                    <Field
                                        component={InputField}
                                        name="password"
                                        label="New Password"
                                        defaultValue={userData.password}
                                        secureTextEntry={true}
                                    />
                                    <Field
                                        component={InputField}
                                        name="passwordConfirmation"
                                        label="Confirm New Password"
                                        defaultValue={userData.passwordConfirmation}
                                        secureTextEntry={true}
                                    />
                                </View>
                            </View>
                            <View style={styles.formSection}>
                                <View style={styles.formSectionTitle}>
                                    <Text category="s1">Notification Settings</Text>
                                </View>
                                <View style={[styles.formSectionFields, styles.formSectionFieldsNoVerticalPadding]}>
                                    <View style={styles.toggleRow}>
                                        <View style={styles.toggleLabel}>
                                            <Text category="s2" appearance="hint">Do Not Disturb</Text>
                                        </View>
                                        <View style={styles.toggleInput}>
                                            <Field
                                                component={ToggleField}
                                                name="doNotDisturb"
                                                defaultValue={(userData.doNotDisturb == 1 ? true : false)}
                                            />
                                        </View>
                                    </View>
                                    <View style={styles.toggleRow}>
                                        <View style={styles.toggleLabel}>
                                            <Text category="s2" appearance="hint">System Notifications (SMS)</Text>
                                        </View>
                                        <View style={styles.toggleInput}>
                                            <Field
                                                component={ToggleField}
                                                name="receiveSleekFleetSMSNotifications"
                                                defaultValue={(userData.receiveSleekFleetSMSNotifications == 'Yes' ? true : false)}
                                            />
                                        </View>
                                    </View>
                                    <View style={styles.toggleRow}>
                                        <View style={styles.toggleLabel}>
                                            <Text category="s2" appearance="hint">New Load Notifications (SMS)</Text>
                                        </View>
                                        <View style={styles.toggleInput}>
                                            <Field
                                                component={ToggleField}
                                                name="receiveNewLoadSMSNotifications"
                                                defaultValue={(userData.receiveNewLoadSMSNotifications == 'Yes' ? true : false)}
                                            />
                                        </View>
                                    </View>
                                    <View style={styles.toggleRow}>
                                        <View style={styles.toggleLabel}>
                                            <Text category="s2" appearance="hint">Active Load Notifications (SMS)</Text>
                                        </View>
                                        <View style={styles.toggleInput}>
                                            <Field
                                                component={ToggleField}
                                                name="receiveActiveLoadSMSNotifications"
                                                defaultValue={(userData.receiveActiveLoadSMSNotifications == 'Yes' ? true : false)}
                                            />
                                        </View>
                                    </View>
                                    <View style={styles.toggleRow}>
                                        <View style={styles.toggleLabel}>
                                            <Text category="s2" appearance="hint">System Notifications (Email)</Text>
                                        </View>
                                        <View style={styles.toggleInput}>
                                            <Field
                                                component={ToggleField}
                                                name="receiveSleekFleetEmailNotifications"
                                                defaultValue={(userData.receiveSleekFleetEmailNotifications == 'Yes' ? true : false)}
                                            />
                                        </View>
                                    </View>
                                    <View style={styles.toggleRow}>
                                        <View style={styles.toggleLabel}>
                                            <Text category="s2" appearance="hint">New Load Notifications (Email)</Text>
                                        </View>
                                        <View style={styles.toggleInput}>
                                            <Field
                                                component={ToggleField}
                                                name="receiveNewLoadEmailNotifications"
                                                defaultValue={(userData.receiveNewLoadEmailNotifications == 'Yes' ? true : false)}
                                            />
                                        </View>
                                    </View>
                                    <View style={[styles.toggleRow, styles.toggleRowLast]}>
                                        <View style={styles.toggleLabel}>
                                            <Text category="s2" appearance="hint">Active Load Notifications (Email)</Text>
                                        </View>
                                        <View style={styles.toggleInput}>
                                            <Field
                                                component={ToggleField}
                                                name="receiveActiveLoadEmailNotifications"
                                                defaultValue={(userData.receiveActiveLoadEmailNotifications == 'Yes' ? true : false)}
                                            />
                                        </View>
                                    </View>
                                </View>
                            </View>
                            {helper.checkForRoles(['driver']) &&
                                <View style={styles.formSection}>
                                    <View style={styles.formSectionTitle}>
                                        <Text category="s1">License Information</Text>
                                    </View>
                                    <View style={styles.formSectionFields}>
                                        <View style={styles.collapsibleWrapper}>
                                            <View style={styles.collapsibleHeader}>
                                                <View style={styles.collapsibleHeaderRowOne}>
                                                    <Image
                                                        source={{
                                                            uri: sleekApiUrl+userData.driverLicensePhoto,
                                                            headers: Constants.RESOURCE_HEADERS
                                                        }}
                                                        style={styles.driverLicenseImage}
                                                        resizeMode="contain"
                                                    />
                                                </View>
                                                <View style={styles.collapsibleHeaderRowTwo}>
                                                    <Pressable onPress={() => toggleCollapse('driverLicense')}>
                                                        <Text style={styles.collapsibleHeaderAction} status="primary" category="s2">Update Driver License</Text>
                                                    </Pressable>
                                                </View>
                                            </View>
                                            <Collapsible style={styles.collapsibleBody} collapsed={driverLicenseCollapsed}>
                                                {driverLicenseImageUploads.length == 0 &&
                                                    <>
                                                        <Text style={styles.formSectionIntro} category="p1" appearance="hint">Upload a new photo to replace your existing driver license:</Text>
                                                        <View style={styles.buttonWrapper}>
                                                            <View style={styles.buttonView}>
                                                                <Button style={[styles.button, styles.buttonLeft]} appearance="outline" size="small" onPress={pickImage('driverLicense')}>Choose From Library</Button>
                                                            </View>
                                                            {!helper.isWeb() &&
                                                                <View style={styles.buttonView}>
                                                                    <Button style={[styles.button, styles.buttonRight]} appearance="outline" size="small" onPress={takePhoto('driverLicense')}>Take Photo</Button>
                                                                </View>
                                                            }
                                                        </View>
                                                    </>
                                                }
                                                {driverLicenseImageUploads.length > 0 &&
                                                    <>
                                                        <View style={styles.uploadsView}>
                                                            <UploadsList uploads={driverLicenseImageUploads} removeUpload={removeDriverLicenseUpload} title="Driver License Upload" />
                                                        </View>
                                                        <View style={styles.buttonWrapper}>
                                                            <View style={styles.buttonView}>
                                                                <Button style={styles.button} appearance="outline" size="small" onPress={resetUploads('driverLicense')}>Cancel</Button>
                                                            </View>
                                                        </View>
                                                    </>
                                                }
                                            </Collapsible>
                                        </View>
                                        <Field
                                            component={InputField}
                                            name="driverLicenseNumber"
                                            label="Driver License Number"
                                            defaultValue={userData.driverLicenseNumber}
                                        />
                                        <Field
                                            component={SelectField}
                                            name="driverLicenseState"
                                            label="Driver License State"
                                            options={stateProvinceOptions}
                                            multiSelect={false}
                                            defaultValue={userData.driverLicenseState}
                                        />
                                    </View>
                                </View>
                            }
                            {helper.checkForRoles(['driver']) &&
                                <View style={styles.formSection}>
                                    <View style={styles.formSectionTitle}>
                                        <Text category="s1">Equipment Information</Text>
                                    </View>
                                    <View style={styles.formSectionFields}>
                                        <View style={styles.collapsibleWrapper}>
                                            <View style={styles.collapsibleHeader}>
                                                <View style={styles.collapsibleHeaderRowOne}>
                                                    <Image
                                                        source={{
                                                            uri: sleekApiUrl+userData.driverLicensePlatePhoto,
                                                            headers: Constants.RESOURCE_HEADERS
                                                        }}
                                                        style={styles.driverLicensePlateImage}
                                                        resizeMode="contain"
                                                    />
                                                </View>
                                                <View style={styles.collapsibleHeaderRowTwo}>
                                                    <Pressable onPress={() => toggleCollapse('driverLicensePlate')}>
                                                        <Text style={styles.collapsibleHeaderAction} status="primary" category="s2">Update License Plate</Text>
                                                    </Pressable>
                                                </View>
                                            </View>
                                            <Collapsible style={styles.collapsibleBody} collapsed={driverLicensePlateCollapsed}>
                                                {driverLicensePlateImageUploads.length == 0 &&
                                                    <>
                                                        <Text style={styles.formSectionIntro} category="p1" appearance="hint">Upload a new photo to replace your existing license plate:</Text>
                                                        <View style={styles.buttonWrapper}>
                                                            <View style={styles.buttonView}>
                                                                <Button style={[styles.button, styles.buttonLeft]} appearance="outline" size="small" onPress={pickImage('driverLicensePlate')}>Choose From Library</Button>
                                                            </View>
                                                            {!helper.isWeb() &&
                                                                <View style={styles.buttonView}>
                                                                    <Button style={[styles.button, styles.buttonRight]} appearance="outline" size="small" onPress={takePhoto('driverLicensePlate')}>Take Photo</Button>
                                                                </View>
                                                            }
                                                        </View>
                                                    </>
                                                }
                                                {driverLicensePlateImageUploads.length > 0 &&
                                                    <>
                                                        <View style={styles.uploadsView}>
                                                            <UploadsList uploads={driverLicensePlateImageUploads} removeUpload={removeDriverLicensePlateUpload} title="License Plate Upload" />
                                                        </View>
                                                        <View style={styles.buttonWrapper}>
                                                            <View style={styles.buttonView}>
                                                                <Button style={styles.button} appearance="outline" size="small" onPress={resetUploads('driverLicensePlate')}>Cancel</Button>
                                                            </View>
                                                        </View>
                                                    </>
                                                }
                                            </Collapsible>
                                        </View>
                                        <Field
                                            component={SelectField}
                                            name="truckType"
                                            label="Trailer Type"
                                            options={trailerTypeOptions}
                                            multiSelect={false}
                                            defaultValue={userData.truckType}
                                        />
                                        <Field
                                            component={SelectField}
                                            name="trailerLength"
                                            label="Trailer Length"
                                            options={trailerLengthOptions}
                                            multiSelect={false}
                                            defaultValue={userData.trailerLength}
                                        />
                                        {values.trailerLength == 'Other' &&
                                            <Field
                                                component={InputField}
                                                name="trailerLengthOther"
                                                label="Custom Trailer Length"
                                                defaultValue={(trailerLengthValues.includes(userData.trailerLength) ? '' : userData.trailerLength)}
                                            />
                                        }
                                        <Field
                                            component={SelectField}
                                            name="trailerWidth"
                                            label="Trailer Width"
                                            options={trailerWidthOptions}
                                            multiSelect={false}
                                            defaultValue={userData.trailerWidth}
                                        />
                                        {values.trailerWidth == 'Other' &&
                                            <Field
                                                component={InputField}
                                                name="trailerWidthOther"
                                                label="Custom Trailer Width"
                                                defaultValue={(trailerWidthValues.includes(userData.trailerWidth) ? '' : userData.trailerWidth)}
                                            />
                                        }
                                        <Field
                                            component={SelectField}
                                            name="trailerHeight"
                                            label="Trailer Height"
                                            options={trailerHeightOptions}
                                            multiSelect={false}
                                            defaultValue={userData.trailerHeight}
                                        />
                                        {values.trailerHeight == 'Other' &&
                                            <Field
                                                component={InputField}
                                                name="trailerHeightOther"
                                                label="Custom Trailer Height"
                                                defaultValue={(trailerHeightValues.includes(userData.trailerHeight) ? '' : userData.trailerHeight)}
                                            />
                                        }
                                        <Field
                                            component={SelectField}
                                            name="doorType"
                                            label="Door Type"
                                            options={trailerDoorTypeOptions}
                                            multiSelect={false}
                                            defaultValue={userData.doorType}
                                        />
                                        <Field
                                            component={InputField}
                                            name="maxWeightOfLoadsUnformatted"
                                            label="Max Weight"
                                            defaultValue={userData.maxWeightOfLoadsUnformatted}
                                        />
                                    </View>
                                </View>
                            }
                            <View style={styles.submitButtonView}>
                                <Button onPress={handleSubmit} disabled={!isValid}>Update Profile</Button>
                            </View>
                        </>
                    )}
                </Formik>
            </Layout>
        )
    }

    if(isLoading) {
        return (
            <Loader loadingText="Updating Profile" />
        )
    } else {
        return (
            helper.isWeb() ? (
                <View style={styles.webView}>
                    <Header breadcrumbs={breadcrumbs} title="Edit My Profile"/>
                    <ScrollView>
                        <EditProfileView />
                    </ScrollView>
                </View>
            ) : (
                <SafeAreaView style={styles.safeAreaView} edges={['top', 'left', 'right']}>
                    <Header activeScreen="MyProfileEdit" />
                    <ScrollView>
                        <EditProfileView />
                    </ScrollView>
                </SafeAreaView>
            )
        )
    }
}

const themedStyles = StyleService.create({
    // global styles
    layout: {
        flex: 1,
        paddingTop: 4,
    },
    formSection: {
        backgroundColor: "color-basic-100",
        borderRadius: 6,
        borderWidth: 1,
        borderColor: "border-generic",
        marginHorizontal: 8,
        marginVertical: 4,
        shadowColor: "shadow-color",
        shadowOffset: {
            width: 0,
            height: 0,
        },
        shadowOpacity: .1,
        shadowRadius: 2
    },
    formSectionTitle: {
        backgroundColor: "background-basic-color-2",
        borderBottomWidth: 1,
        borderBottomColor: "border-basic-color-4",
        padding: 10,
        alignItems: "center",
        flexDirection: "row",
        minHeight: 50,
        borderTopLeftRadius: 6,
        borderTopRightRadius: 6,
    },
    formSectionFields: {
        paddingVertical: 10,
        paddingHorizontal: 10,
    },
    formSectionFieldsNoVerticalPadding: {
        paddingVertical: 0,
    },
    formSectionIntro: {
        marginBottom: 10,
    },
    toggleRow: {
        flexDirection: "row",
        paddingVertical: 3,
        borderBottomColor: "border-basic-color-3",
        borderBottomWidth: 1,
        minHeight: 54,
    },
    toggleRowLast: {
        borderBottomWidth: 0,
    },
    toggleLabel: {
        flex: 2,
        justifyContent: "center",
    },
    toggleInput: {
        flex: 1,
        justifyContent: "center",
        alignItems: "flex-end",
    },
    collapsibleWrapper: {
        marginTop: 10,
        marginBottom: 15,
        backgroundColor: "color-basic-300",
        borderWidth: 1,
        borderColor: "border-generic",
        borderRadius: 4,
    },
    collapsibleHeader: {
        flexDirection: "row",
        paddingHorizontal: 10,
        paddingVertical: 5,
        alignItems: "center",
    },
    collapsibleHeaderRowOne: {
        flex: 1,
    },
    collapsibleHeaderRowTwo: {
        flex: 1,
    },
    collapsibleHeaderAction: {
        textAlign: "right",
    },
    collapsibleBody: {
        backgroundColor: "color-basic-100",
        borderTopWidth: 1,
        borderTopColor: "border-generic",
        padding: 10,
        paddingBottom: 5,
        borderBottomStartRadius: 4,
        borderBottomEndRadius: 4,
    },
    userImage: {
        width: 50,
        height: 50,
        borderRadius: 25,
    },
    driverLicenseImage: {
        width: 50,
        height: 50
    },
    driverLicensePlateImage: {
        width: 50,
        height: 50
    },
    buttonWrapper: {
        flexDirection: "row",
    },
    buttonView: {
        flex: 1,
    },
    button: {
        marginBottom: 10,
    },
    buttonLeft: {
        marginRight: 5,
    },
    buttonRight: {
        marginLeft: 5
    },
    submitButtonView: {
        paddingHorizontal: 10,
        marginBottom: 40,
        marginTop: 10
    },
    // native specific styles
    safeAreaView: {
        backgroundColor: "safe-area-view-background",
        flex: 1,
    },
    // web specific styles
    webView: {
        flex: 1,
    },
});
