import React, {useState, useEffect} from 'react';
import { View } from 'react-native';
import { Toggle, StyleService, Text, useStyleSheet } from '@ui-kitten/components';

export const ToggleField = (props) => {

    const {
        field: { name, onBlur, onChange, value, label },
        form: { errors, touched, setFieldTouched },
        ...inputProps
    } = props;

    const hasError = errors[name] && touched[name];

    const styles = useStyleSheet(themedStyles);

    const [checked, setChecked] = useState(props.field.value);
    
    const [firstLoad, setFirstLoad] = useState(true);

    useEffect(() => {
        props.form.values[props.field.name] = checked;
        if(!firstLoad) {
            setFieldTouched(name);
        }
        setFirstLoad(false);
    }, [checked]);

    return (
        <>
            <Toggle
                style={styles.toggleInput}
                checked={checked}
                disabled={props.disabled}
                onChange={nextChecked => {
                   setChecked(nextChecked);
                }}
                status="basic"
            />
            {hasError &&
                <View style={styles.errorView}>
                    <Text category="p2" status="danger">{errors[name]}</Text>
                </View>
            }
        </>
    );

}

const themedStyles = StyleService.create({
    toggleInput: {
        // styles here
    },
    errorView: {
        marginVertical: 5,
    },
    errorInput: {
        borderColor: "color-danger-500"
    }
});
