import React from 'react';
import { View } from 'react-native';
import { Button, StyleService, Text, useStyleSheet } from '@ui-kitten/components';
import * as Constants from 'config/Constants';

export const EmptyDataSet = (props) => {

    /*
        props:
        - btnAction
        - btnAppearance
        - btnSize
        - btnText
        - primaryMessage
        - secondaryMessage
    */

    const styles = useStyleSheet(themedStyles);

    let btnAppearance = props.btnAppearance != null ? props.btnAppearance : 'filled';
    let btnSize = props.btnSize != null ? props.btnSize : 'medium';

    return (
        <View style={styles.container}>
            {props.primaryMessage &&
                <Text style={styles.primaryMessage} category="s1">{props.primaryMessage}</Text>
            }
            {props.secondaryMessage &&
                <Text style={styles.secondaryMessage} category="p1">{props.secondaryMessage}</Text>
            }
            {props.btnText &&
                <Button style={styles.btn} onPress={props.btnAction} appearance={btnAppearance} size={btnSize}>{props.btnText}</Button>
            }
        </View>
    )

}

const themedStyles = StyleService.create({
    container: {
        flex: 1,
        justifyContent: "center",
        alignItems: "center",
        minHeight: 50,
        paddingVertical: 15,
    },
    primaryMessage: {
        marginBottom: 5,
        color: "color-basic-600",
    },
    secondaryMessage: {
        marginBottom: 5,
        color: "color-basic-600",
    },
    btn: {
        marginTop: 10,
    }
});
