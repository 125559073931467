import React, { useState, useEffect, useRef } from 'react';
import { LogBox, Platform } from 'react-native';
import { NavigationContainer } from '@react-navigation/native';
import { AuthStackNavigator } from 'navigation/StackNavigator';
import { DrawerNavigator } from 'navigation/DrawerNavigator';
import { AppDataContext, AuthContext, LocationServiceContext, NotificationContext, ThemeContext } from 'components/context';
import { ApplicationProvider, IconRegistry, Layout, StyleService, Text, useStyleSheet } from '@ui-kitten/components';
import { Loader } from 'components/utility';
import FlashMessage from 'react-native-flash-message';
import * as RootNavigation from 'navigation/RootNavigation';
import * as TaskManager from 'expo-task-manager';
import * as Device from 'expo-device';
import * as Linking from 'expo-linking';
import * as Routes from 'config/Routes';
import * as helper from 'utility/Helper';
import * as api from 'api/SleekTechnologiesApi';
import * as eva from '@eva-design/eva';
import * as notificationSetup from 'notifications/NotificationSetup';
import * as Constants from 'config/Constants';
import * as Notifications from 'expo-notifications';
import * as locationTracking from 'location/LocationTracking';
import { sendAnalyticsEventAsync } from 'root/analytics';
import { sleekTechnologiesLight, sleekTechnologiesDark } from 'styles';
import { library } from '@fortawesome/fontawesome-svg-core';
import { fab } from '@fortawesome/free-brands-svg-icons';
import { fas } from '@fortawesome/pro-solid-svg-icons';
import { far } from '@fortawesome/pro-regular-svg-icons';
import { fal } from '@fortawesome/pro-light-svg-icons';
import { fad } from '@fortawesome/pro-duotone-svg-icons';
import getEnvVars from 'root/environment';

const { environment } = getEnvVars();

// Add FontAwesome icon sets to Library for use across application
library.add(fab, fas, far, fal, fad);

const linking = Routes.linking;

let renderCount = 0;

// Ignore limit warning for Rolbar
LogBox.ignoreLogs(['Over free plan monthly limit']);
LogBox.ignoreLogs(['VirtualizedLists should never be nested']);

export default function App({ navigation }) {

    //renderCount++;
    //helper.log('render count is: ' + renderCount);

    //helper.log('************ App.tsx: App Entry ************');

    const [isLoading, setIsLoading] = useState(true);
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [appData, setAppData] = useState();
    const [unreadNotificationCount, setUnreadNotificationCount] = useState(0);
    const [locationService, setLocationService] = useState({instance: null});
    const [theme, setTheme] = useState('light');
    const [customTheme, setCustomTheme] = useState(sleekTechnologiesLight);
    const lastNotificationResponse = Notifications.useLastNotificationResponse();
    const routeNameRef = useRef();

    const toggleTheme = () => {
        if(theme === 'light') {
            setTheme('dark');
            setCustomTheme(sleekTechnologiesDark);
        } else {
            setTheme('light');
            setCustomTheme(sleekTechnologiesLight);
        }
    }

    const startLocationServicesCallback = (updatedLocation, locs) => {
        helper.logEvent({ category: 'Location Tracking', message: 'Foreground Callback Reached' });
    }

    useEffect(() => {

        const bootstrapAsync = async () => {

            //helper.log('************ App.tsx: bootstrapAsync ************');

            let authToken;

            try {
                if(helper.isWeb()) {
                    authToken = helper.getAsyncStoreItem('@authToken')
                        .then(function(authToken){
                            if(authToken != null) {
                                setIsLoggedIn(true);
                            } else {
                                setIsLoading(false);
                            }
                        });
                } else {
                    authToken = helper.getSecureStoreItem('authToken')
                        .then(function(authToken){
                            if(authToken != null) {
                                api.checkBackgroundRestart()
                                    .then(function(response){
                                        helper.logEvent({ category: 'API Response', message: 'checkBackgroundRestart()', data: { response: response.data } });
                                        if(response.data.result == 'success') {
                                            if(response.data.restart) {
                                                helper.logEvent({ category: 'Location Tracking', message: 'checkBackgroundRestart(): Background Restart IS Required' });
                                                //Restart Foreground Service
                                                locationTracking.restartForegroundService(locationService, startLocationServicesCallback);

                                                //Restart Background Service
                                                let origin = {
                                                    latitude: Number(response.data.originLatitude),
                                                    longitude: Number(response.data.originLongitude),
                                                }
                                                let destination = {
                                                    latitude: Number(response.data.destinationLatitude),
                                                    longitude: Number(response.data.destinationLongitude),
                                                }
                                                locationTracking.restartBackgroundServices(origin, destination);
                                            } else {
                                                helper.logEvent({ category: 'Location Tracking', message: 'checkBackgroundRestart(): Background Restart IS NOT Required' });
                                                //Shutdown Unnecessary Services
                                                shutdownServices();
                                            }

                                            setIsLoggedIn(true);
                                        } else {
                                            setIsLoading(false);
                                        }
                                    });
                            } else {
                                setIsLoading(false);
                            }
                        });
                }
            } catch (e) {
                //helper.log(e);
            }

        }

        bootstrapAsync();

    }, []);

    useEffect(() => {
        if(isLoggedIn) {
            api.getApplicationData()
                .then(function(response){
                    helper.logEvent({ category: 'API Response', message: 'getApplicationData()', data: { response: response.data } });
                    setAppData(response.data);
                    const isDebugModeEnabled = response.data.carrierUserData.debugModeEnabled == true ? 'yes' : 'no';
                    helper.setSecureStoreItem('isDebugModeEnabled', isDebugModeEnabled);
                    const isUserBlocked = response.data.carrierUserData.userBlocked == true ? 'yes' : 'no';
                    helper.setSecureStoreItem('isUserBlocked', isUserBlocked);
                    const rollbarPerson = {
                        id: response.data.carrierUserData.id,
                        username: response.data.carrierUserData.firstName+' '+response.data.carrierUserData.lastName,
                        email: helper.hasValue(response.data.carrierUserData.email) ? response.data.carrierUserData.email : response.data.carrierUserData.phone
                    }
                    if(!helper.isWeb()) {
                        helper.setSecureStoreItem('rollbarPerson', JSON.stringify(rollbarPerson));
                    }
                    notificationSetup.initiateNotificationSetup();
                    if(isLoading) {
                        setIsLoading(false);
                    }
                });
        } else {
            //Shutdown Unnecessary Services
            shutdownServices();
        }

    }, [isLoggedIn]);

    useEffect(() => {
        if(lastNotificationResponse) {

            let authToken;

            authToken = helper.getSecureStoreItem('authToken')
                .then(function(authToken){
                    if(authToken != null) {
                        if(appData == null) {
                            api.getApplicationData()
                                .then(function(response){
                                    setAppData(response.data);
                                    const isDebugModeEnabled = response.data.carrierUserData.debugModeEnabled == true ? 'yes' : 'no';
                                    helper.setSecureStoreItem('isDebugModeEnabled', isDebugModeEnabled);
                                    const isUserBlocked = response.data.carrierUserData.userBlocked == true ? 'yes' : 'no';
                                    helper.setSecureStoreItem('isUserBlocked', isUserBlocked);
                                    notificationSetup.handleLastNotificationResponse(lastNotificationResponse, locationService, response.data);
                                });
                        } else {
                            notificationSetup.handleLastNotificationResponse(lastNotificationResponse, locationService, appData);
                        }
                    }
                });
        }
    }, [lastNotificationResponse]);

    const shutdownServices = async () => {

        if(!helper.isWeb()) {
            //Terminate All Tasks
            TaskManager.unregisterAllTasksAsync();
        }

        //Reset Location Service
        if(locationService.instance) {
            locationService.instance.remove();
        }
    };

    const authContext = React.useMemo(
        () => ({
            signIn: async (data) => {
                //helper.log('************ App.tsx: useMemo - signIn ************');
                setIsLoading(true);
                if(Platform.OS == 'web') {
                    let authToken;
                    authToken = null;
                    try {
                        authToken = data.token;
                        helper.setAsyncStoreItem('@authToken', authToken);
                    } catch(e) {
                        //helper.log(e);
                    }
                    setIsLoggedIn(true);
                } else {
                    let authToken;
                    authToken = null;
                    try {
                        authToken = data.token;
                        helper.setSecureStoreItem('authToken', authToken);

                        // If a deviceIdentifier is returned, save the value in secure store
                        if(data.deviceIdentifier) {
                            helper.setSecureStoreItem('deviceIdentifier', data.deviceIdentifier);
                        }
                    } catch(e) {
                        //helper.log(e);
                    }
                    setIsLoggedIn(true);
                }
            },
            signOut: async () => {
                //helper.log('************ App.tsx: useMemo - signOut ************');
                api.nativeLogout()
                    .then(function(response){
                        //helper.log('*** deleting async / secure store items ***');
                        if(helper.isWeb()) {
                            helper.deleteAsyncStoreItem('@authToken')
                                .then(function(){
                                    //helper.log('*** dispatch SIGN_OUT ***');
                                    setIsLoggedIn(false);
                                })
                        } else {
                            helper.deleteSecureStoreItem('authToken')
                                .then(function(){
                                    //helper.log('*** dispatch SIGN_OUT ***');
                                    setIsLoggedIn(false);
                                })
                        }
                    });
            },
        }),
        []
    );

    if(isLoading) {
        return (
            <Loader />
        )
    } else {
        return (
            <AuthContext.Provider value={authContext}>
                <ThemeContext.Provider value={{theme, toggleTheme}}>
                    <AppDataContext.Provider value={{appData, setAppData}}>
                        <NotificationContext.Provider value={{unreadNotificationCount, setUnreadNotificationCount}}>
                            <LocationServiceContext.Provider value={{locationService, setLocationService}}>
                                <ApplicationProvider { ...eva } theme={{...eva[theme], ...customTheme}}>
                                    <NavigationContainer
                                        ref={RootNavigation.navigationRef}
                                        linking={linking}
                                        fallback={<Text>Loading...</Text>}
                                        documentTitle={{
                                            formatter: (options, route) =>
                                                `${options?.title ?? route?.name} - Sleek Fleet Carrier Portal`,
                                        }}
                                        onReady={() => {
                                            routeNameRef.current = RootNavigation.navigationRef.getCurrentRoute().name;
                                        }}
                                        onStateChange={async () => {
                                            const previousRouteName = routeNameRef.current;
                                            const currentRouteName = RootNavigation.navigationRef.getCurrentRoute().name;
                                            const currentTitle = RootNavigation.navigationRef.getCurrentOptions().title;
                                            const screenName = helper.hasValue(currentTitle) ? currentTitle : currentRouteName;

                                            if (previousRouteName !== currentRouteName && environment != 'local') {
                                                await sendAnalyticsEventAsync('screen_view', {screen_name: screenName});
                                            }

                                            routeNameRef.current = currentRouteName;
                                        }}
                                    >
                                        {!isLoggedIn ? (
                                            <AuthStackNavigator />
                                        ) : (
                                            <DrawerNavigator />
                                        )}
                                    </NavigationContainer>
                                    <FlashMessage position="top" />
                                </ApplicationProvider>
                            </LocationServiceContext.Provider>
                        </NotificationContext.Provider>
                    </AppDataContext.Provider>
                </ThemeContext.Provider>
            </AuthContext.Provider>
        )
    }
}
