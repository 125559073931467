import React, { useContext, useState } from 'react';
import { View } from 'react-native';
import { Button, Layout, StyleService, Text, useStyleSheet } from '@ui-kitten/components';
import { AuthContext } from 'components/context';
import { InputField } from 'components/forms';
import { Loader } from 'components/utility';
import { Formik, Field } from 'formik';
import * as yup from 'yup';
import * as api from 'api/SleekTechnologiesApi';
import * as helper from 'utility/Helper';

export default function MessageShipper(props) {

    const styles = useStyleSheet(themedStyles);
    const { signOut } = useContext(AuthContext);
    const [isLoading, setLoading] = useState(false);
    const loadDetails = props.loadDetails;
    const location = props.location;
    const validationSchema = yup.object().shape({
        message: yup
            .string()
            .required('Message is a required field'),
    });

    const messageShipper = (data) => {

        setLoading(true);

        api.submitShipperMessage(data.sleekFleetLoadId, data.message, (location != '' && location != null ? location.coords.latitude : 'none'), (location != '' && location != null ? location.coords.longitude : 'none'))
            .then(function(response){
                if(helper.hasValue(response.data.msg) && response.data.msg == "Token Not Found or Expired") {
                    signOut();
                }
                setLoading(false);
                if(response.data.result == "success") {
                    props.setModalVisible(false);
                    props.setModalError(null);
                    helper.showFlashMessage(response.data.result, response.data.msg);
                    props.getLoadDetails(loadDetails.sleekFleetLoadId);
                } else if(response.data.result == "error") {
                    throw {
                        type: "custom",
                        message: response.data.msg
                    }
                }
            })
            .catch(function(error){
                setLoading(false);
                helper.handleCatchError(error, true, props.setModalError);
            });
    }

    if(isLoading) {
        return (
            <Loader loadingText="Sending Message"/>
        )
    } else {
        return(
            <View style={styles.container}>
                <Text style={styles.instructions} category="p1">Please enter your message to the shipper below:</Text>
                <Formik
                    validationSchema={validationSchema}
                    initialValues={{
                        sleekFleetLoadId: loadDetails.sleekFleetLoadId,
                        message: "",
                    }}
                    onSubmit={(values) => messageShipper(values)}
                >
                    {({ handleSubmit, isValid, values }) => (
                        <>
                            <Field
                                component={InputField}
                                name="message"
                                placeholder="Message here..."
                                multiline={true}
                                textStyle={{ minHeight: 150 }}
                            />
                            <Button style={styles.ctaButton} onPress={handleSubmit} disabled={!isValid}>Send Message</Button>
                        </>
                    )}
                </Formik>
            </View>
        )
    }
}

const themedStyles = StyleService.create({
    container: {
        // styles here...
    },
    instructions: {
        paddingVertical: 10,
    },
    ctaButton: {
        marginTop: 5,
    }
});
