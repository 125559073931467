import React from 'react';
import { Dimensions, View } from 'react-native';
import { Button, Divider, Layout, StyleService, Text, useStyleSheet } from '@ui-kitten/components';
import * as Progress from 'react-native-progress';

export const LoadProgress = (props) => {

    /*
        props:
        - loadStatusAttributes
    */

    const styles = useStyleSheet(themedStyles);
    const loadStatusAttributes = props.loadStatusAttributes;

    return (
        <View style={styles.container}>
            <View style={styles.loadStatusWrapper}>
                <View style={styles.columnOne}>
                    <Text style={styles.loadStatus}>Load Status: {loadStatusAttributes.label}</Text>
                </View>
                <View style={styles.columnTwo}>
                    <Text style={styles.loadStep}>Step {loadStatusAttributes.activeStep} of 5</Text>
                </View>
            </View>
            <View style={styles.loadProgressWrapper}>
                <Progress.Bar
                    progress={loadStatusAttributes.progress}
                    width={Dimensions.get('window').width}
                    borderRadius={0}
                    borderColor="transparent"
                    color="#333333"
                    unfilledColor="#eeeeee"
                />
            </View>
        </View>
    )
}

const themedStyles = StyleService.create({
    container: {
        height: 70,
        backgroundColor: "#ffffff",
    },
    loadStatusWrapper: {
        padding: 10,
        flex: 1,
        flexDirection: "row"
    },
    columnOne: {
        flex: 4,
        justifyContent: "center",
    },
    loadProgressTitle: {
        fontSize: 18,
        fontFamily: "OpenSans_600SemiBold",
    },
    loadStatus: {

    },
    columnTwo: {
        flex: 1,
        justifyContent: "center",
        alignItems: "right",
    },
    loadStep: {
        fontStyle: "italic",
    },
    loadProgressWrapper: {

    }
});
