import React, { useContext, useState } from 'react';
import { Image, View } from 'react-native';
import { CommonActions, useLinkTo } from '@react-navigation/native';
import { Button, Layout, StyleService, Text, useStyleSheet } from '@ui-kitten/components';
import { Formik, Field } from 'formik';
import { InputField } from 'components/forms';
import { Loader } from 'components/utility';
import { AuthContext } from 'components/context';
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome';
import * as Constants from 'config/Constants';
import * as yup from 'yup';
import * as helper from 'utility/Helper';
import * as api from 'api/SleekTechnologiesApi';
import getEnvVars from 'root/environment';

export default function BookNow(props) {

    const styles = useStyleSheet(themedStyles);
    const { signOut } = useContext(AuthContext);
    const [isLoading, setLoading] = useState(false);
    const [bidResponse, setBidResponse] = useState(null);
    const [loadTendered, setLoadTendered] = useState(null);
    const [isAppRatingEligible, setIsAppRatingEligible] = useState(null);
    const loadDetails = props.loadDetails;
    const navigation = props.navigation;
    const linkTo = props.linkTo;
    const { sleekApiUrl } = getEnvVars();
    const logoUri = sleekApiUrl+'/images/carrierui/global/modal-logo.png';
    const validationSchema = yup.object().shape({
        bookNowPrice: yup
            .number()
            .min(loadDetails.minimumBid, 'Your Book Now Price is below the minimum. Please try increasing your price and try again.')
            .required('Book Now Price is Required'),
    });

    if(!helper.isWeb()) {
        Promise.all([
            helper.isAppRatingEligible()
        ]).then((response) => {
            const [isAppRatingEligible] = response;
            setIsAppRatingEligible(isAppRatingEligible);
        });
    }

    const submitBookNow = (data) => {

        setLoading(true);

        api.submitBookNowPrice(loadDetails.sleekFleetLoadId, data.bookNowPrice)
            .then(function(response) {
                if(helper.hasValue(response.data.msg) && response.data.msg == "Token Not Found or Expired") {
                    signOut();
                }
                setLoading(false);
                if(response.data.result == "success") {
                    props.setModalCloseDisabled(true);
                    setBidResponse(response.data.response);
                    setLoadTendered(response.data.tendered);
                    props.setModalError(null);
                } else if(response.data.result == "error") {
                    throw {
                        type: "custom",
                        message: response.data.msg
                    }
                }
            })
            .catch(function(error){
                setLoading(false);
                helper.handleCatchError(error, true, props.setModalError);
            });
    }

    const resetModal = () => {
        setBidResponse(null);
        setLoadTendered(null);
        props.setModalCloseDisabled(false);
        props.setModalVisible(false);
    }

    if(isLoading) {
        return (
            <Loader loadingText="Submitting Book Now Price"/>
        )
    } else if(bidResponse) {

        let response = {};

        // set defaults
        response.title = "Aw, Shucks!";
        response.buttonText = "Find Another Load";
        response.buttonAction = () => {
            resetModal();
            setTimeout(() => {
                navigation.dispatch(
                    CommonActions.reset({
                        index: 0,
                        routes: [
                            {
                                name: 'FindALoad',
                                params: {
                                    refreshLoads: true
                                }
                            },
                        ],
                    })
                );
                linkTo('/find-a-load');
            }, 300);
        }

        if(bidResponse == 'accepted') {
            response.title = "Fire Up Your Engine!";
            response.subtitle = "Congratulations!";
            if(loadDetails.shipperName == "Private Shipper") {
                if(loadTendered) {
                    response.message = "Your Book Now Price has been accepted and the load has been tendered to you.";
                } else {
                    response.message = "Your Book Now Price has been accepted. You will receive a notification when this load is finalized and ready for dispatch.";
                }
            } else {
                if(loadTendered) {
                    response.message = loadDetails.shipperName + " has accepted your Book Now Price and the load has been tendered to you.";
                } else {
                    response.message = loadDetails.shipperName + " has accepted your Book Now Price. You will receive a notification when this load is finalized and ready for dispatch.";
                }
            }
            response.buttonText = "View Load";
            response.buttonAction = () => {
                resetModal();

                // reset the FindALoad stack
                navigation.dispatch(
                    CommonActions.reset({
                        index: 0,
                        routes: [
                            {
                                name: 'FindALoad',
                                params: {
                                    refreshLoads: true,
                                }
                            },
                        ],
                    })
                );

                let resetStack = false;

                if(helper.isWeb()) {
                    resetStack = true;
                } else {
                    const navigationState = navigation.getParent().getParent().getState().routes;
                    navigationState.forEach(function(item) {
                        if(item.name == 'LoadDetailsStackNavigator' && helper.hasValue(item.state)) {
                            resetStack = true;
                        }
                    });
                }

                if(resetStack) {
                    navigation.dispatch(
                        CommonActions.reset({
                            index: 0,
                            routes: [
                                {
                                    name: 'LoadDetails_Generic',
                                    params: {
                                        loadId: loadDetails.sleekFleetLoadId,
                                        appRatingRequest: isAppRatingEligible
                                    }
                                },
                            ],
                        })
                    );
                }

                if(helper.isWeb()) {
                    linkTo('/load-details/'+loadDetails.sleekFleetLoadId);
                } else {
                    navigation.navigate('LoadDetailsStackNavigator', {
                        screen: 'LoadDetails_Generic',
                        params: {
                            loadId: loadDetails.sleekFleetLoadId,
                            appRatingRequest: isAppRatingEligible
                        }
                    });
                }

            }
        } else if(bidResponse == 'declined') {
            response.subtitle = "Book Now Price Declined";
            response.message = "Your book now price was too high and was unfortunately declined by " + loadDetails.shipperName + ". But have no fear, another load is near!";
        } else if(bidResponse == 'unavailable') {
            response.subtitle = "Load No Longer Available";
            response.message = "Unfortunately this load was secured by another driver while you were submitting your Book Now Price. But have no fear, another load is near!";
        } else if(bidResponse == 'already bid') {
            response.subtitle = "Book Now Price Already Submitted";
            response.message = "It looks like you already submitted a Book Now Price on this load. But have no fear, another load is near!";
        } else if(bidResponse == 'timeout') {
            response.subtitle = "Book Now Submission Restricted";
            response.message = "Your account has been temporarily restricted from submiting Book Now Prices. Please contact Sleek Fleet at 910-420-4295 for more information.";
        } else if(bidResponse == 'invalid status') {
            response.subtitle = "Invalid Permissions";
            response.message = "You do not have the appropriate status to submit a Book Now Price. Please contact support with any questions.";
            response.buttonText = "Close";
            response.buttonAction = () => {
                resetModal();
            }
        } else if(bidResponse == 'logout') {
            resetModal();
            signOut();
        } else {
            response.subtitle = "Book Now Submission Unsuccessful";
            response.message = "Book Now Price could not be processed. Please contact Sleek Fleet at 910-420-4295 if this problem persists.";
        }

        return (
            <View style={styles.bookNowResponseContainer}>
                <View style={styles.bookNowResponseHeader}>
                    <View style={styles.logoView}>
                        <Image
                            source={{
                                uri: logoUri,
                                headers: Constants.RESOURCE_HEADERS
                            }}
                            style={{width: 149, height: 125}}
                            resizeMode="contain"
                        />
                    </View>
                    <Text style={styles.bookNowResponseTitle} category="h5">{response.title}</Text>
                </View>
                <View style={styles.bookNowResponseBody}>
                    <Text style={styles.bookNowResponseSubtitle} category="s1">{response.subtitle}</Text>
                    <Text style={styles.bookNowResponseMessage} category="p1" appearance="hint">{response.message}</Text>
                    <Button style={styles.ctaButton} onPress={() => response.buttonAction()}>{response.buttonText}</Button>
                </View>
            </View>
        )
    } else {
        return (
            <View style={styles.container}>
                {loadDetails.platedVanRequired &&
                    <View style={styles.warningView}>
                        <Text category="s2" status="danger">Please note that this load requires a plated van!</Text>
                    </View>
                }
                <Text style={styles.instructions} category="p1">Your book now price should include only linehaul and fuel. All other accessorials, pre-approved or otherwise, will be paid according to the accessorial schedule.</Text>
                <Formik
                    validationSchema={validationSchema}
                    initialValues={{
                        bookNowPrice: ""
                    }}
                    onSubmit={(values) => helper.showConfirmActionAlert("Book Now Price Confirmation", "To submit, please confirm your Book Now price of $"+values.bookNowPrice, submitBookNow, values)}
                >
                    {({ handleSubmit, isValid, values }) => (
                        <>
                            <Field
                                component={InputField}
                                name="bookNowPrice"
                                placeholder="Enter Book Now Price..."
                                size="large"
                                accessoryLeft={<FontAwesomeIcon icon={['far', 'dollar-sign']} size={styles.icon.size} color={styles.icon.color} />}
                            />
                            <Button style={styles.ctaButton} onPress={handleSubmit} disabled={!isValid}>Book Now</Button>
                        </>
                    )}
                </Formik>
                <Text style={styles.disclaimer} category="p1" appearance="hint">By clicking Book Now, you acknowledge that, if your Book Now Price is accepted, you can meet the contractual obligations of this load. For full details, see our terms and conditions.</Text>
            </View>
        )
    }
}

const themedStyles = StyleService.create({
    container: {
        // styles here...
    },
    instructions: {
        paddingVertical: 10,
        marginBottom: 10,
    },
    ctaButton: {
        marginTop: 5,
    },
    disclaimer: {
        marginTop: 25,
    },
    bookNowResponseContainer: {

    },
    bookNowResponseHeader: {
        borderBottomWidth: 1,
        borderBottomColor: "border-basic-color-3",
        padding: 20,
        alignItems: "center",
    },
    logoView: {
        marginTop: 5,
    },
    bookNowResponseTitle: {
        marginTop: 20,
        marginBottom: 5,
    },
    bookNowResponseBody: {
        alignItems: "center",
        paddingVertical: 25,
        paddingHorizontal: 20,
    },
    bookNowResponseSubtitle: {
        marginBottom: 5,
        fontSize: 18,
    },
    bookNowResponseMessage: {
        textAlign: "center",
        marginBottom: 10,
        fontSize: 17,
    },
    icon: {
        size: 14,
        color: "color-basic-600"
    },
    warningView: {
        padding: 10,
        borderWidth: 1,
        borderColor: "color-danger-500",
        marginVertical: 10,
        backgroundColor: "color-danger-transparent-200",
        borderRadius: 4,
    },
});
