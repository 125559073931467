import React from 'react';
import { View } from 'react-native';
import { Input, StyleService, Text, useStyleSheet } from '@ui-kitten/components';

export const InputField = (props) => {

    const {
        field: { name, onBlur, onChange, value },
        form: { errors, touched, setFieldTouched },
        ...inputProps
    } = props;

    const hasError = errors[name] && touched[name];

    const styles = useStyleSheet(themedStyles);

    return (
        <>
            <Input
                style={styles.textInput}
                value={value}
                onChangeText={(text) => onChange(name)(text)}
                onBlur={() => {
                    setFieldTouched(name)
                    onBlur(name)
                }}
                {...inputProps}
            />
            {hasError &&
                <View style={styles.errorView}>
                    <Text category="p2" status="danger">{errors[name]}</Text>
                </View>
            }
        </>
    );

}

const themedStyles = StyleService.create({
    textInput: {
        marginBottom: 10,
    },
    errorView: {
        marginTop: -3,
        marginLeft: 10,
        marginBottom: 10,
    },
    errorInput: {
        borderColor: "color-danger-500"
    }
});
