import React from 'react';
import { View } from 'react-native';
import { Divider, StyleService, Text, useStyleSheet } from '@ui-kitten/components';
import { Badge } from 'components/utility';
import { TextIcon } from 'components/layout';
import * as helper from 'utility/Helper';

export const Accessorial = (props) => {

    /*
        props:
        - accessorialId
        - amount
        - approvedBy
        - createdBy
        - date
        - dateFull
        - details
        - hoursDetained
        - isPreLoad
        - isFirstItem
        - isLastItem
        - status
        - type
        - updatedAt
        - updatedAtFull
    */

    const styles = useStyleSheet(themedStyles);

    return (
        <View style={helper.getContainerStyles(props, styles)}>
            <View>
                <View style={styles.titleView}>
                    <Text category="s2">{props.type}: ${props.amount}</Text>
                    <Badge label={props.status} type="info" />
                </View>
                <TextIcon iconName="calendar-alt" iconWeight="far" iconSize={styles.icon.size} iconColor={styles.icon.color} text={props.date} textCategory="c2" textAppearance="hint" />
                <Text style={styles.note} appearance="hint" category="p1">{props.details}</Text>
            </View>
        </View>
    )
}

const themedStyles = StyleService.create({
    container: {
        borderBottomColor: "border-basic-color-3",
        borderBottomWidth: 1,
        flex: 1,
        paddingVertical: 10,
    },
    firstItem: {
        paddingTop: 2,
    },
    lastItem: {
        borderBottomWidth: 0,
        paddingBottom: 0,
    },
    titleView: {
        flexDirection: "row",
        alignItems: "center",
        marginBottom: 3,
    },
    note: {
        paddingTop: 8,
        paddingBottom: 2
    },
    icon: {
        size: 14,
        color: "color-basic-600"
    }
});
