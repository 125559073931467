import { Platform } from 'react-native';
import axios from 'axios';
import * as Device from 'expo-device';
import * as helper from 'utility/Helper';
import * as Notifications from 'expo-notifications';
import Constants from 'expo-constants';
import getEnvVars from 'root/environment';

const { sleekApiUrl } = getEnvVars();
const operatingSystem = Device.osName;
const modelType = Device.modelName;
const portalVersion = Constants.expoConfig?.version;
const platform = Platform.OS === 'web' ? 'react web' : 'react native';

const getHeaders = async (contentType) => {

    contentType = contentType || 'application/json';
    let authToken;
    let deviceIdentifier;

    if(Platform.OS === 'web') {
        let token = await helper.getAsyncStoreItem('@authToken');
        authToken = (helper.hasValue(token) ? token : 'undefined');
        deviceIdentifier = 'webIdentifier';
    } else  {
        authToken = await helper.getSecureStoreItem('authToken');
        deviceIdentifier = await helper.getSecureStoreItem('deviceIdentifier');
    }

    let headers = {
        'platform': platform,
        'token': authToken,
        'deviceIdentifier': deviceIdentifier,
        'content-Type': contentType,
    }

    return headers;
}

const getExpoToken = async () => {
    if(helper.isWeb()) {
        return null;
    } else {
        try {
            const expoTokenData = await Notifications.getExpoPushTokenAsync({'projectId': Constants.expoConfig.extra.eas.projectId,});
            return expoTokenData.data;
        } catch(err) {
            return 'undefined';
        }
    }
}

export const requestPasswordReset = async (username) => {
    helper.logEvent({ category: 'API Call', message: 'requestPasswordReset()', data: { username: username } });
    let headers = await getHeaders();
    const form = new FormData();
    form.append('email', username);
    return axios({
        method: 'post',
        url: sleekApiUrl+'/request-password-reset',
        data: form,
        headers: headers,
        withCredentials: true,
    });
}

export const submitW9 = async (w9Data) => {
    helper.logEvent({ category: 'API Call', message: 'submitW9()', data: { w9Data: w9Data } });
    let headers = await getHeaders();
    const form = new FormData();
    form.append('taxId', w9Data.taxId);
    form.append('name', w9Data.name);
    form.append('businessName', w9Data.businessName);
    form.append('taxClassification', w9Data.taxClassification);
    form.append('taxClassificationOther', w9Data.taxClassificationOther);
    form.append('address', w9Data.address);
    form.append('city', w9Data.city);
    form.append('state', w9Data.state);
    form.append('zip', w9Data.zip);
    form.append('accountNumbers', w9Data.accountNumbers);
    form.append('taxIdType', w9Data.taxIdType);
    form.append('exemptPayeeCode', w9Data.exemptPayeeCode);
    form.append('fatcaExemption', w9Data.fatcaExemption);
    form.append('signature', w9Data.signature);
    form.append('backupWithholding', (w9Data.backupWithholding ? 'yes' : 'no'));
    return axios({
        method: 'post',
        url: sleekApiUrl+'/submit-w9',
        data: form,
        headers: headers,
        withCredentials: true,
    });
}

export const submitW9Pdf = async (uploadedDocument, uploadType) => {
    helper.logEvent({ category: 'API Call', message: 'submitW9Pdf', data: { uploadedDocument: uploadedDocument, uploadType: uploadType } });

    let contentType = 'multipart/form-data';
    let headers = await getHeaders(contentType);
    const form = new FormData();

    form.append('uploadedDocument', uploadedDocument);
    form.append('uploadType', uploadType);

    return axios({
        method: 'post',
        url: sleekApiUrl+'/submit-w9-pdf',
        data: form,
        headers: headers,
        withCredentials: true,
    });
}

export const getNotifications = async (notificationTypeId, offset) => {
    helper.logEvent({ category: 'API Call', message: 'getNotifications()', data: { notificationTypeId: notificationTypeId, offset: offset } });

    let headers = await getHeaders();
    const form = new FormData();
    form.append('notificationTypeId', notificationTypeId);
    form.append('offset', offset);
    return axios({
        method: 'post',
        url: sleekApiUrl+'/get-notifications-data',
        data: form,
        headers: headers,
        withCredentials: true,
    });
}

export const addPreferredLocation = async (location) => {
    helper.logEvent({ category: 'API Call', message: 'addPreferredLocation()', data: { location: location } });
    let headers = await getHeaders();
    const form = new FormData();
    form.append('location', location);
    return axios({
        method: 'post',
        url: sleekApiUrl+'/add-preferred-location',
        data: form,
        headers: headers,
        withCredentials: true,
    });
}

export const getAvailableLoads = async (loadData) => {
    helper.logEvent({ category: 'API Call', message: 'getAvailableLoads()', data: { loadData: loadData } });

    let headers = await getHeaders();
    const form = new FormData();
    form.append('latitude', 'none');
    form.append('longitude', 'none');
    if(Array.isArray(loadData.loadType)) {
        for (var i = 0; i < loadData.loadType.length; i++) {
            form.append('loadType[]', loadData.loadType[i]);
        }
    } else {
        form.append('loadType', loadData.loadType);
    }
    form.append('origin', loadData.origin);
    form.append('originRadius', loadData.originRadius);
    form.append('destination', loadData.destination);
    form.append('destinationRadius', loadData.destinationRadius);
    form.append('pickupDateFrom', loadData.pickupDateFrom);
    form.append('pickupDateTo', loadData.pickupDateTo);
    form.append('sortBy', 'pickup date');
    form.append('minimumMiles', 0);
    form.append('maximumMiles', 0);
    form.append('offset', loadData.offset);
    return axios({
        method: 'post',
        url: sleekApiUrl+'/get-available-loads',
        data: form,
        headers: headers,
        withCredentials: true,
    });
}

export const getLoadDetailsData = async (sleekFleetLoadId) => {
    helper.logEvent({ category: 'API Call', message: 'getLoadDetailsData()', data: { sleekFleetLoadId: sleekFleetLoadId } });
    let headers = await getHeaders();
    const form = new FormData();
    form.append('sleekFleetLoadId', sleekFleetLoadId);
    form.append('latitude', 'none');
    form.append('longitude', 'none');
    form.append('location', 'none');
    return axios({
        method: 'post',
        url: sleekApiUrl+'/get-load-details-data',
        data: form,
        headers: headers,
        withCredentials: true,
    });
}

export const submitBookNowPrice = async (sleekFleetLoadId, bookNowPrice) => {
    helper.logEvent({ category: 'API Call', message: 'submitBookNowPrice()', data: { sleekFleetLoadId: sleekFleetLoadId, bookNowPrice: bookNowPrice } });

    let headers = await getHeaders();
    const form = new FormData();
    form.append('sleekFleetLoadId', sleekFleetLoadId);
    form.append('bookNowPrice', bookNowPrice);
    form.append('latitude', 'none');
    form.append('longitude', 'none');
    return axios({
        method: 'post',
        url: sleekApiUrl+'/submit-book-now-price',
        data: form,
        headers: headers,
        withCredentials: true,
    });
}

export const updateLoad = async (sleekFleetLoadId, loadStatus, latitude, longitude, deliveryNumber, stopNumber) => {
    helper.logEvent({
        category: 'API Call',
        message: 'updateLoad()',
        data: {
            sleekFleetLoadId: sleekFleetLoadId,
            loadStatus: loadStatus,
            latitude: latitude,
            longitude: longitude,
            deliveryNumber: deliveryNumber,
            stopNumber: stopNumber,
        }
    });
    let headers = await getHeaders();
    const form = new FormData();
    form.append('sleekFleetLoadId', sleekFleetLoadId);
    form.append('loadStatus', loadStatus);
    form.append('latitude', latitude);
    form.append('longitude', longitude);
    form.append('deliveryNumber', deliveryNumber);
    form.append('stopNumber', stopNumber);
    return axios({
        method: 'post',
        url: sleekApiUrl+'/update-load',
        data: form,
        headers: headers,
        withCredentials: true,
    });
}

export const submitShipperMessage = async (sleekFleetLoadId, message, latitude, longitude) => {
    helper.logEvent({ category: 'API Call', message: 'submitShipperMessage()', data: { sleekFleetLoadId: sleekFleetLoadId, message: message, latitude: latitude, longitude: longitude } });

    let headers = await getHeaders();
    const form = new FormData();
    form.append('sleekFleetLoadId', sleekFleetLoadId);
    form.append('message', message);
    form.append('latitude', latitude);
    form.append('longitude', longitude);
    return axios({
        method: 'post',
        url: sleekApiUrl+'/submit-shipper-message',
        data: form,
        headers: headers,
        withCredentials: true,
    });
}

export const submitAccessorial = async (sleekFleetLoadId, accessorialType, message) => {
    helper.logEvent({ category: 'API Call', message: 'submitAccessorial()', data: { sleekFleetLoadId: sleekFleetLoadId, accessorialType: accessorialType, message: message } });

    let headers = await getHeaders();
    const form = new FormData();
    form.append('sleekFleetLoadId', sleekFleetLoadId);
    form.append('accessorialType', accessorialType);
    form.append('message', message);
    return axios({
        method: 'post',
        url: sleekApiUrl+'/submit-accessorial',
        data: form,
        headers: headers,
        withCredentials: true,
    });
}

export const submitOSD = async (sleekFleetLoadId, message, uploadedImages) => {
    helper.logEvent({ category: 'API Call', message: 'submitOSD()', data: { sleekFleetLoadId: sleekFleetLoadId, message: message, uploadedImages: uploadedImages } });

    let headers = await getHeaders();
    const form = new FormData();
    form.append('sleekFleetLoadId', sleekFleetLoadId);
    form.append('message', message);

    for (var i = 0; i < uploadedImages.length; i++) {
        form.append('uploadedFiles[]', uploadedImages[i]);
    }

    return axios({
        method: 'post',
        url: sleekApiUrl+'/submit-osd',
        data: form,
        headers: headers,
        withCredentials: true,
    });
}

export const submitLocationReview = async (locationId, rating, review, uploadedImages) => {
    helper.logEvent({ category: 'API Call', message: 'submitLocationReview', data: { locationId: locationId, rating: rating, review: review, uploadedImages: uploadedImages } });

    let headers = await getHeaders();
    const form = new FormData();
    form.append('locationId', locationId);
    form.append('rating', rating);
    form.append('review', review);
    for (var i = 0; i < uploadedImages.length; i++) {
        form.append('uploadedFiles[]', uploadedImages[i]);
    }
    return axios({
        method: 'post',
        url: sleekApiUrl+'/submit-location-review',
        data: form,
        headers: headers,
        withCredentials: true,
    });
}

export const submitCompleteLoad = async (sleekFleetLoadId, uploadedImages, uploadedDocument, uploadType) => {
    helper.logEvent({ category: 'API Call', message: 'submitCompleteLoad()', data: { sleekFleetLoadId: sleekFleetLoadId, uploadedImages: uploadedImages, uploadedDocument: uploadedDocument, uploadType: uploadType } });

    const form = new FormData();
    let contentType = uploadType == "document" ? "multipart/form-data" : "application/json";
    let headers = await getHeaders(contentType);

    form.append('sleekFleetLoadId', sleekFleetLoadId);
    form.append('uploadType', uploadType);

    if(uploadType == 'document') {
        form.append('uploadedDocument', uploadedDocument);
    } else if(uploadType == 'web document') {
        form.append('uploadedWebDocument', uploadedDocument);
    } else {
        for (var i = 0; i < uploadedImages.length; i++) {
            form.append('uploadedFiles[]', uploadedImages[i]);
        }
    }

    return axios({
        method: 'post',
        url: sleekApiUrl+'/complete-load',
        data: form,
        headers: headers,
        withCredentials: true,
    });
}

export const getLoadHistoryData = async (loadFilters) => {
    helper.logEvent({ category: 'API Call', message: 'getLoadHistoryData()', data: { loadFilters: loadFilters } });

    let headers = await getHeaders();
    const form = new FormData();
    form.append('carrierDriver', loadFilters.driver);
    form.append('loadHistoryDeliveryDate', loadFilters.deliveryDate);
    form.append('loadHistoryOrigin', loadFilters.origin);
    form.append('loadHistoryDestination', loadFilters.destination);
    if(Array.isArray(loadFilters.loadStatus)) {
        for (var i = 0; i < loadFilters.loadStatus.length; i++) {
            form.append('loadHistoryLoadStatus[]', loadFilters.loadStatus[i]);
        }
    } else {
        form.append('loadHistoryLoadStatus', loadFilters.loadStatus);
    }
    if(Array.isArray(loadFilters.loadType)) {
        for (var i = 0; i < loadFilters.loadType.length; i++) {
            form.append('loadHistoryLoadType[]', loadFilters.loadType[i]);
        }
    } else {
        form.append('loadHistoryLoadType', loadFilters.loadType);
    }
    if(Array.isArray(loadFilters.shipper)) {
        for (var i = 0; i < loadFilters.shipper.length; i++) {
            form.append('loadHistoryShipper[]', loadFilters.shipper[i]);
        }
    } else {
        form.append('loadHistoryShipper', loadFilters.shipper);
    }
    form.append('loadHistorySortBy', loadFilters.sortBy);
    form.append('loadHistoryLoadNumber', loadFilters.loadNumber);
    form.append('loadHistoryDeliveryDateFrom', loadFilters.deliveryDateFrom);
    form.append('loadHistoryDeliveryDateTo', loadFilters.deliveryDateTo);
    form.append('loadHistoryOriginRadius', loadFilters.originRadius);
    form.append('loadHistoryDestinationRadius', loadFilters.destinationRadius);
    form.append('offset', loadFilters.offset);
    return axios({
        method: 'post',
        url: sleekApiUrl+'/get-load-history-data',
        data: form,
        headers: headers,
        withCredentials: true,
    });
}

export const getBookNowHistoryData = async (bookNowFilters) => {
    helper.logEvent({ category: 'API Call', message: 'getBookNowHistoryData()', data: { bookNowFilters: bookNowFilters } });

    let headers = await getHeaders();
    const form = new FormData();
    form.append('bookNowHistoryDeliveryDate', bookNowFilters.deliveryDate);
    form.append('bookNowHistoryOrigin', bookNowFilters.origin);
    form.append('bookNowHistoryDestination', bookNowFilters.destination);
    form.append('bookNowHistoryBookNowStatus', bookNowFilters.bookNowStatus);
    if(Array.isArray(bookNowFilters.loadType)) {
        for (var i = 0; i < bookNowFilters.loadType.length; i++) {
            form.append('bookNowHistoryLoadType[]', bookNowFilters.loadType[i]);
        }
    } else {
        form.append('bookNowHistoryLoadType', bookNowFilters.loadType);
    }
    if(Array.isArray(bookNowFilters.shipper)) {
        for (var i = 0; i < bookNowFilters.shipper.length; i++) {
            form.append('bookNowHistoryShipper[]', bookNowFilters.shipper[i]);
        }
    } else {
        form.append('bookNowHistoryShipper', bookNowFilters.shipper);
    }
    form.append('bookNowSortBy', bookNowFilters.sortBy);
    form.append('bookNowHistoryLoadNumber', bookNowFilters.loadNumber);
    form.append('bookNowHistoryDeliveryDateFrom', bookNowFilters.deliveryDateFrom);
    form.append('bookNowHistoryDeliveryDateTo', bookNowFilters.deliveryDateTo);
    form.append('bookNowHistoryOriginRadius', bookNowFilters.originRadius);
    form.append('bookNowHistoryDestinationRadius', bookNowFilters.destinationRadius);
    form.append('offset', bookNowFilters.offset);
    return axios({
        method: 'post',
        url: sleekApiUrl+'/get-book-now-history-data',
        data: form,
        headers: headers,
        withCredentials: true,
    });
}

export const getPaymentHistoryData = async (paymentFilters) => {
    helper.logEvent({ category: 'API Call', message: 'getPaymentHistoryData()', data: { paymentFilters } });

    let headers = await getHeaders();
    const form = new FormData();
    form.append('paymentHistoryDate', paymentFilters.paymentDate);
    if(Array.isArray(paymentFilters.paymentStatus)) {
        for (var i = 0; i < paymentFilters.paymentStatus.length; i++) {
            form.append('paymentHistoryPaymentStatus[]', paymentFilters.paymentStatus[i]);
        }
    } else {
        form.append('paymentHistoryPaymentStatus', paymentFilters.paymentStatus);
    }
    form.append('paymentHistorySortBy', paymentFilters.sortBy);
    form.append('paymentHistoryLoadNumber', paymentFilters.loadNumber);
    form.append('paymentHistoryTransactionId', paymentFilters.transactionId);
    form.append('paymentHistoryPaymentDateFrom', paymentFilters.paymentDateFrom);
    form.append('paymentHistoryPaymentDateTo', paymentFilters.paymentDateTo);
    form.append('paymentHistoryMinimum', paymentFilters.paymentMin);
    form.append('paymentHistoryMaximum', paymentFilters.paymentMax);
    form.append('offset', paymentFilters.offset);
    return axios({
        method: 'post',
        url: sleekApiUrl+'/get-payment-history-data',
        data: form,
        headers: headers,
        withCredentials: true,
    });
}

export const locationAutoComplete = async (location) => {
    helper.logEvent({ category: 'API Call', message: 'locationAutoComplete()', data: { location: location } });
    let headers = await getHeaders();
    return axios({
        method: 'get',
        url: sleekApiUrl+'/location-auto-complete',
        params: {'query': location},
        headers: headers,
        withCredentials: true,
    });
}

export const loadNumberAutoComplete = async (loadNumber) => {
    helper.logEvent({ category: 'API Call', message: 'loadNumberAutoComplete()', data: { loadNumber: loadNumber } });
    let headers = await getHeaders();
    return axios({
        method: 'get',
        url: sleekApiUrl+'/load-number-auto-complete',
        params: {'query': loadNumber},
        headers: headers,
        withCredentials: true,
    });
}

export const bookNowLoadNumberAutoComplete = async (loadNumber) => {
    helper.logEvent({ category: 'API Call', message: 'bookNowLoadNumberAutoComplete()', data: { loadNumber: loadNumber } });
    let headers = await getHeaders();
    return axios({
        method: 'get',
        url: sleekApiUrl+'/book-now-load-number-auto-complete',
        params: {'query': loadNumber},
        headers: headers,
        withCredentials: true,
    });
}

export const transactionIdAutoComplete = async (transactionId) => {
    helper.logEvent({ category: 'API Call', message: 'transactionIdAutoComplete()', data: { transactionId: transactionId } });
    let headers = await getHeaders();
    return axios({
        method: 'get',
        url: sleekApiUrl+'/transaction-id-auto-complete',
        params: {'query': transactionId},
        headers: headers,
        withCredentials: true,
    });
}

export const generateRateConfirmation = async (sleekFleetLoadId) => {
    helper.logEvent({ category: 'API Call', message: 'generateRateConfirmation()', data: { sleekFleetLoadId: sleekFleetLoadId } });
    let headers = await getHeaders();
    const form = new FormData();
    form.append('sleekFleetLoadId', sleekFleetLoadId);
    return axios({
        method: 'post',
        url: sleekApiUrl+'/generate-rate-confirmation',
        data: form,
        headers: headers,
        withCredentials: true,
    });
}

export const webSignIn = async (username, password) => {
    helper.logEvent({ category: 'API Call', message: 'webSignIn()', data: { username: username, password: 'HIDDEN FROM LOG' } });
    let headers = await getHeaders();
    const form = new FormData();
    form.append('username', username);
    form.append('password', password);
    return axios({
        method: 'post',
        url: sleekApiUrl+'/web-login',
        data: form,
        headers: headers,
    });
}

export const signIn = async (username, password) => {
    helper.logEvent({ category: 'API Call', message: 'signIn()', data: { username: username, password: 'HIDDEN FROM LOG' } });
    let headers = await getHeaders();
    const form = new FormData();
    form.append('username', username);
    form.append('password', password);
    form.append('deviceIdentifier', headers.deviceIdentifier);
    form.append('operatingSystem', operatingSystem);
    form.append('portalVersion', portalVersion);
    form.append('modelType', modelType);
    return axios({
        method: 'post',
        url: sleekApiUrl+'/native-login',
        data: form,
        headers: headers,
    });
}

export const nativeLogout = async () => {
    helper.logEvent({ category: 'API Call', message: 'nativeLogout()' });
    let headers = await getHeaders();
    return axios({
        method: 'post',
        url: sleekApiUrl+'/native-logout',
        headers: headers,
    });
}

export const savePushToken = async (pushToken) => {
    helper.logEvent({ category: 'API Call', message: 'savePushToken()', data: { pushToken: pushToken } });
    let headers = await getHeaders();
    const form = new FormData();
    form.append('pushToken', pushToken);
    return axios({
        method: 'post',
        url: sleekApiUrl+'/native-save-push-token',
        data: form,
        headers: headers,
    });
}

export const getApplicationData = async () => {
    helper.logEvent({ category: 'API Call', message: 'getApplicationData()' });
    let headers = await getHeaders();
    return axios({
        method: 'post',
        url: sleekApiUrl+'/get-application-data',
        headers: headers,
        withCredentials: true,
    });
}

export const getDashboardData = async () => {
    helper.logEvent({ category: 'API Call', message: 'getDashboardData()' });
    let headers = await getHeaders();
    let expoToken = await getExpoToken();
    const form = new FormData();
    form.append('pushToken', expoToken);
    return axios({
        method: 'post',
        url: sleekApiUrl+'/get-native-dashboard-data',
        data: form,
        headers: headers,
        withCredentials: true,
    });
}

export const updateCarrierUserData = async (carrierUserData) => {
    helper.logEvent({ category: 'API Call', message: 'updateCarrierUserData()', data: { carrierUserData: carrierUserData } });

    let headers = await getHeaders();
    const form = new FormData();
    form.append('firstName', carrierUserData.firstName);
    form.append('lastName', carrierUserData.lastName);
    form.append('password', carrierUserData.password);
    form.append('email', carrierUserData.email);
    form.append('phone', carrierUserData.phone);
    form.append('doNotDisturb', carrierUserData.doNotDisturb);
    form.append('receiveSleekFleetSMSNotifications', carrierUserData.receiveSleekFleetSMSNotifications);
    form.append('receiveNewLoadSMSNotifications', carrierUserData.receiveNewLoadSMSNotifications);
    form.append('receiveActiveLoadSMSNotifications', carrierUserData.receiveActiveLoadSMSNotifications);
    form.append('receiveSleekFleetEmailNotifications', carrierUserData.receiveSleekFleetEmailNotifications);
    form.append('receiveNewLoadEmailNotifications', carrierUserData.receiveNewLoadEmailNotifications);
    form.append('receiveActiveLoadEmailNotifications', carrierUserData.receiveActiveLoadEmailNotifications);
    form.append('driverLicenseNumber', carrierUserData.driverLicenseNumber);
    form.append('driverLicenseState', carrierUserData.driverLicenseState);
    form.append('truckType', carrierUserData.truckType);
    form.append('trailerLength', carrierUserData.trailerLength);
    form.append('trailerWidth', carrierUserData.trailerWidth);
    form.append('trailerHeight', carrierUserData.trailerHeight);
    form.append('doorType', carrierUserData.doorType);
    form.append('maxWeightOfLoads', carrierUserData.maxWeightOfLoadsUnformatted);
    form.append('userImage', carrierUserData.userImage);
    form.append('driverLicenseImage', carrierUserData.driverLicenseImage);
    form.append('driverLicensePlateImage', carrierUserData.driverLicensePlateImage);
    return axios({
        method: 'post',
        url: sleekApiUrl+'/update-carrier-user-data',
        data: form,
        headers: headers,
        withCredentials: true,
    });
}

export const updateCarrierData = async (carrierData) => {
    helper.logEvent({ category: 'API Call', message: 'updateCarrierData()', data: { carrierData: carrierData } });

    let headers = await getHeaders();
    const form = new FormData();
    form.append('preferredPaymentMethod', carrierData.preferredPaymentMethod);
    form.append('billingName', carrierData.billingName);
    form.append('billingAddress', carrierData.billingAddress);
    form.append('billingAddress2', carrierData.billingAddress2);
    form.append('billingCity', carrierData.billingCity);
    form.append('billingState', carrierData.billingState);
    form.append('billingZip', carrierData.billingZip);
    form.append('paypalEmail', carrierData.paypalEmail);
    form.append('carrierName', carrierData.carrierName);
    form.append('email', carrierData.email);
    form.append('phone', carrierData.phone);
    form.append('address', carrierData.address);
    form.append('address2', carrierData.address2);
    form.append('city', carrierData.city);
    form.append('state', carrierData.state);
    form.append('zip', carrierData.zip);
    form.append('carrierPhotoContent', carrierData.carrierPhotoContent);
    return axios({
        method: 'post',
        url: sleekApiUrl+'/update-carrier-data',
        data: form,
        headers: headers,
        withCredentials: true,
    });
}

export const getBookedLoadsData = async () => {
    helper.logEvent({ category: 'API Call', message: 'getBookedLoadsData()' });
    let headers = await getHeaders();
    return axios({
        method: 'post',
        url: sleekApiUrl+'/get-booked-loads-data',
        headers: headers,
        withCredentials: true,
    });
}

export const getCarrierDrivers = async () => {
    helper.logEvent({ category: 'API Call', message: 'getCarrierDrivers()' });
    let headers = await getHeaders();
    return axios({
        method: 'post',
        url: sleekApiUrl+'/get-carrier-drivers',
        headers: headers,
        withCredentials: true,
    });
}

export const getDriverDetails = async (driverId) => {
    helper.logEvent({ category: 'API Call', message: 'getDriverDetails()', data: { driverId: driverId } });
    let headers = await getHeaders();
    const form = new FormData();
    form.append('driverId', driverId);
    return axios({
        method: 'post',
        url: sleekApiUrl+'/get-driver-details',
        data: form,
        headers: headers,
        withCredentials: true,
    });
}

export const getCarrierUserDetails = async (carrierUserId) => {
    helper.logEvent({ category: 'API Call', message: 'getCarrierUserDetails()', data: { carrierUserId: carrierUserId } });
    let headers = await getHeaders();
    const form = new FormData();
    form.append('carrierUserId', carrierUserId);
    return axios({
        method: 'post',
        url: sleekApiUrl+'/get-carrier-user-details',
        data: form,
        headers: headers,
        withCredentials: true,
    });
}

export const createCarrierUser = async (newCarrierUser) => {
    helper.logEvent({ category: 'API Call', message: 'createCarrierUser()', data: { newCarrierUser: newCarrierUser } });
    let headers = await getHeaders();
    const form = new FormData();
    form.append('firstName', newCarrierUser.firstName);
    form.append('lastName', newCarrierUser.lastName);
    form.append('email', newCarrierUser.email);
    form.append('phone', newCarrierUser.phone);
    form.append('admin', newCarrierUser.admin);
    form.append('manager', newCarrierUser.manager);
    form.append('driver', newCarrierUser.driver);
    return axios({
        method: 'post',
        url: sleekApiUrl+'/create-carrier-user',
        data: form,
        headers: headers,
        withCredentials: true,
    });
}

export const updateCarrierUserPermissions = async (carrierUserPermissions) => {
    helper.logEvent({ category: 'API Call', message: 'updateCarrierUserPermissions()', data: { carrierUserPermissions: carrierUserPermissions } });
    let headers = await getHeaders();
    const form = new FormData();
    form.append('carrierUserId', carrierUserPermissions.carrierUserId);
    form.append('isActive', carrierUserPermissions.isActive);
    form.append('admin', carrierUserPermissions.admin);
    form.append('manager', carrierUserPermissions.manager);
    form.append('driver', carrierUserPermissions.driver);
    return axios({
        method: 'post',
        url: sleekApiUrl+'/update-carrier-user-permissions',
        data: form,
        headers: headers,
        withCredentials: true,
    });
}

export const deletePreferredLocation = async (preferredLocation) => {
    helper.logEvent({ category: 'API Call', message: 'deletePreferredLocation()', data: { preferredLocation: preferredLocation } });
    let headers = await getHeaders();
    const form = new FormData();
    form.append('preferredLocation', preferredLocation);
    return axios({
        method: 'post',
        url: sleekApiUrl+'/remove-preferred-location',
        data: form,
        headers: headers,
        withCredentials: true,
    });
}

export const messageDriver = async (carrierUserId, message, loadId) => {
    helper.logEvent({ category: 'API Call', message: 'messageDriver()', data: { carrierUserId: carrierUserId, message: message, loadId: loadId } });

    let headers = await getHeaders();
    const form = new FormData();
    form.append('carrierUserId', carrierUserId);
    form.append('message', message);
    form.append('loadId', loadId);
    return axios({
        method: 'post',
        url: sleekApiUrl+'/message-driver',
        data: form,
        headers: headers,
        withCredentials: true,
    });
}

export const updateDriverAssignment = async (type, loadId, carrierUserId, firstName, lastName, email, phone) => {
    helper.logEvent({
        category: 'API Call',
        message: 'updateDriverAssignment()',
        data: {
            type: type,
            loadId: loadId,
            carrierUserId: carrierUserId,
            firstName: firstName,
            lastName: lastName,
            email: email,
            phone: phone,
        }
    });

    let headers = await getHeaders();
    const form = new FormData();
    form.append('type', type);
    form.append('loadId', loadId);
    form.append('carrierUserId', carrierUserId);
    form.append('firstName', firstName);
    form.append('lastName', lastName);
    form.append('email', email);
    form.append('phone', phone);
    return axios({
        method: 'post',
        url: sleekApiUrl+'/assign-driver',
        data: form,
        headers: headers,
        withCredentials: true,
    });
}

export const driverLocationUpdateNative = async (latitude, longitude) => {
    helper.logEvent({ category: 'API Call', message: 'driverLocationUpdateNative()', data: { latitude: latitude, longitude: longitude } });

    let headers = await getHeaders();
    const form = new FormData();
    form.append('latitude', latitude);
    form.append('longitude', longitude);
    return axios({
        method: 'post',
        url: sleekApiUrl+'/driver-location-update-native',
        data: form,
        headers: headers,
        withCredentials: true,
    });
}

export const driverLocationUpdateNativeAnon = async (expoToken, latitude, longitude) => {
    helper.logEvent({ category: 'API Call', message: 'driverLocationUpdateNativeAnon()', data: { expoToken: expoToken, latitude: latitude, longitude: longitude } });
    let headers = await getHeaders();
    const form = new FormData();
    form.append('expoToken', expoToken);
    form.append('latitude', latitude);
    form.append('longitude', longitude);
    return axios({
        method: 'post',
        url: sleekApiUrl+'/driver-location-update-native-anon',
        data: form,
        headers: headers,
        withCredentials: true,
    });
}

export const checkForActiveLoad = async (carrierUserId) => {
    helper.logEvent({ category: 'API Call', message: 'checkForActiveLoad()', data: { carrierUserId: carrierUserId } });
    let headers = await getHeaders();
    const form = new FormData();
    form.append('carrierUserId', carrierUserId);
    return axios({
        method: 'post',
        url: sleekApiUrl+'/check-for-active-load',
        data: form,
        headers: headers,
        withCredentials: true,
    });
}

export const sendNotification = async (expoToken, title, message, category, data) => {
    helper.logEvent({ category: 'API Call', message: 'sendNotification()', data: { expoToken: expoToken, title: title, message: message, category: category, data: data } });
    let headers = await getHeaders();
    const form = new FormData();
    form.append('expoToken', expoToken);
    form.append('title', title);
    form.append('message', message);
    form.append('category', category);
    form.append('data', data);
    return axios({
        method: 'post',
        url: sleekApiUrl+'/send-native-notification',
        data: form,
        headers: headers,
        withCredentials: true,
    });
}

export const sendNotificationAnon = async (carrierUserId, expoToken, title, message, category, data) => {
    helper.logEvent({ category: 'API Call', message: 'sendNotificationAnon()', data: { carrierUserId: carrierUserId, expoToken: expoToken, title: title, message: message, category: category, data: data } });

    let headers = await getHeaders();
    const form = new FormData();
    form.append('carrierUserId', carrierUserId);
    form.append('expoToken', expoToken);
    form.append('title', title);
    form.append('message', message);
    form.append('category', category);
    form.append('data', data);
    return axios({
        method: 'post',
        url: sleekApiUrl+'/send-native-notification-anon',
        data: form,
        headers: headers,
        withCredentials: true,
    });
}

export const getActiveLoadStatus = async () => {
    helper.logEvent({ category: 'API Call', message: 'getActiveLoadStatus()' });
    let headers = await getHeaders();
    return axios({
        method: 'post',
        url: sleekApiUrl+'/get-active-load-status',
        headers: headers,
        withCredentials: true,
    });
}

export const getActiveLoadStatusAnon = async (expoToken) => {
    helper.logEvent({ category: 'API Call', message: 'getActiveLoadStatusAnon()', data: { expoToken: expoToken } });

    let headers = await getHeaders();
    const form = new FormData();
    form.append('expoToken', expoToken);
    return axios({
        method: 'post',
        url: sleekApiUrl+'/get-active-load-status-anon',
        data: form,
        headers: headers,
        withCredentials: true,
    });
}

export const checkBackgroundRestart = async () => {
    helper.logEvent({ category: 'API Call', message: 'checkBackgroundRestart()' });

    let headers = await getHeaders();
    return axios({
        method: 'post',
        url: sleekApiUrl+'/check-background-restart',
        headers: headers,
        withCredentials: true,
    });
}

export const reportLocationPermissionIssue = async (sleekFleetLoadId) => {
    helper.logEvent({ sendToRollbar: true, category: 'API Call', message: 'reportLocationPermissionIssue()', data: { sleekFleetLoadId: sleekFleetLoadId } });

    let headers = await getHeaders();
    const form = new FormData();
    form.append('sleekFleetLoadId', sleekFleetLoadId);
    return axios({
        method: 'post',
        url: sleekApiUrl+'/report-location-permission-issue',
        data: form,
        headers: headers,
        withCredentials: true,
    });
}
