import React, {useContext, useState} from 'react';
import { Image, ImageBackground, Pressable, SafeAreaView, View, TouchableWithoutFeedback } from 'react-native';
import { Button, Input, Layout, StyleService, Text, useStyleSheet } from '@ui-kitten/components';
import { CustomModal, KeyboardAvoidingView } from 'components/layout';
import { AuthContext } from 'components/context';
import { Formik, Field } from 'formik';
import { InputField, ToggleField } from 'components/forms';
import { Loader } from 'components/utility';
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome';
import { ForgotPassword } from 'screens/authentication/modals';
import * as Updates from 'expo-updates';
import * as Constants from 'config/Constants';
import * as Linking from 'expo-linking';
import * as yup from 'yup';
import * as api from 'api/SleekTechnologiesApi';
import * as helper from 'utility/Helper';
import getEnvVars from 'root/environment';

export default function SignIn({ navigation }) {

    const styles = useStyleSheet(themedStyles);
    const { signIn } = useContext(AuthContext);
    const { sleekApiUrl, environment } = getEnvVars();
    const logoUri = sleekApiUrl+'/images/carrierui/global/modal-logo.png';
    const [passwordVisible, setPasswordVisible] = useState(false);
    const [modalVisible, setModalVisible] = useState(false);
    const [modalError, setModalError] = useState(null);
    const [isLoading, setLoading] = useState(false);

    const validationSchema = yup.object().shape({
        username: yup
            .string()
            .required('Please enter a valid mobile number or email address'),
        password: yup
            .string()
            .required('Please enter your password'),
    });

    const onPasswordIconPress = (): void => {
        setPasswordVisible(!passwordVisible);
    };

    const renderPasswordIcon = (props) => (
        <TouchableWithoutFeedback onPress={onPasswordIconPress}>
            {passwordVisible ? (
                <FontAwesomeIcon style={{marginRight: 8}} icon={['fal', 'eye-slash']} size={styles.icon.size} color={styles.icon.color} />
            ) : (
                <FontAwesomeIcon style={{marginRight: 8}} icon={['fal', 'eye']} size={styles.icon.size} color={styles.icon.color} />
            )}
        </TouchableWithoutFeedback>
    );

    const submitLoginForm = (data) => {
        setLoading(true);

        if(helper.isWeb()) {
            api.webSignIn(data.username, data.password)
                .then(function(response){
                    if(response.data.result == "success" && response.data.token) {
                        const token = response.data.token;
                        setLoading(false);
                        signIn({token});
                    } else if(response.data.result == "error") {
                        throw {
                            type: "custom",
                            message: response.data.msg
                        }
                    }
                })
                .catch(function(error){
                    setLoading(false);
                    helper.handleCatchError(error);
                });
        } else {
            api.signIn(data.username, data.password)
                .then(function(response){
                    if(response.data.result == "success" && response.data.token) {
                        const token = response.data.token;
                        const deviceIdentifier = response.data.deviceIdentifier;
                        signIn({token, deviceIdentifier});
                    } else if(response.data.result == "error") {
                        throw {
                            type: "custom",
                            message: response.data.msg
                        }
                    }
                })
                .catch(function(error){
                    setLoading(false);
                    helper.handleCatchError(error);
                });
        }
    }

    const SignInView = () => {
        return (
            <Layout style={styles.layout} category="3">
                <View style={styles.header}>
                    <View style={styles.logoView}>
                        <Image
                            source={{
                                uri: logoUri,
                                headers: Constants.RESOURCE_HEADERS
                            }}
                            style={styles.logo}
                        />
                        <Text style={styles.title} category="h5">Sleek Fleet</Text>
                        <Text style={styles.subtitle} category="s2" appearance="hint">Carrier Portal Login</Text>
                        {!helper.isWeb() && !helper.isProduction() &&
                            <>
                                <Text style={styles.subtitle} category="s2" appearance="hint">Channel: {Updates.channel}</Text>
                                <Text style={styles.subtitle} category="s2" appearance="hint">Environment: {environment}</Text>
                                <Text style={styles.subtitle} category="s2" appearance="hint">API URL: {sleekApiUrl}</Text>
                            </>
                        }
                    </View>
                </View>
                <View style={styles.body}>
                    <View style={styles.formView}>
                        {isLoading &&
                            <Loader loadingText="Signing In..."/>
                        }
                        {!isLoading &&
                            <Formik
                                validationSchema={validationSchema}
                                initialValues={{
                                    username: '',
                                    password: '',
                                }}
                                onSubmit={(values) => submitLoginForm(values)}
                            >
                                {({ handleSubmit, isValid, values }) => (
                                    <>
                                        <Field
                                            component={InputField}
                                            name="username"
                                            placeholder="Mobile Phone or Email"
                                            defaultValue={''}
                                            accessoryRight={<FontAwesomeIcon icon={['fal', 'user']} size={styles.icon.size} color={styles.icon.color} />}
                                            size="large"
                                        />
                                        <Field
                                            component={InputField}
                                            name="password"
                                            placeholder="Password"
                                            defaultValue={''}
                                            accessoryRight={renderPasswordIcon}
                                            size="large"
                                            secureTextEntry={!passwordVisible}
                                        />
                                        <View style={styles.buttonView}>
                                            <Button onPress={handleSubmit} disabled={!isValid} size="large">Sign In</Button>
                                        </View>
                                    </>
                                )}
                            </Formik>
                        }
                    </View>
                </View>
                <View style={styles.footer}>
                    <Pressable style={helper.isWeb() ? {cursor: "pointer"} : {}} onPress={() => setModalVisible(true)}>
                        <Text style={styles.footerLink} category="s2">Forgot Password?</Text>
                    </Pressable>
                    {!helper.isWeb() &&
                        <>
                            <Text style={styles.footerText} category="s2" appearance="hint">Don't have an account? Contact us to get started.</Text>
                            <Pressable onPress={() => Linking.openURL(`tel:${Constants.PHONE_SUPPORT}`)}>
                                <Text style={styles.footerLink} category="s1">{Constants.PHONE_SUPPORT}</Text>
                            </Pressable>
                        </>
                    }
                    {helper.isWeb() &&
                        <>
                            <Pressable style={{cursor: "pointer"}} onPress={() => Linking.openURL('https://www.sleek-technologies.com/get-started')}>
                                <Text style={styles.footerLink} category="s2">Don't have an Account? Register Now</Text>
                            </Pressable>
                            <View style={styles.downloadAppView}>
                                <Text style={styles.downloadAppTitle} category="s1">Download our Mobile App</Text>
                                <Text style={styles.downloadAppDescription} category="p1" appearance="hint">To get the most out of the Sleek Carrier Portal, please download our mobile app available on both iOS and Android.</Text>
                                <Button style={styles.downloadAppButton} appearance="outline" onPress={() => Linking.openURL(Constants.APP_STORE_APP_LINK)} accessoryLeft={<FontAwesomeIcon icon={['fab', 'app-store-ios']} size={24} color={styles.downloadAppButton.color} />}>Download for iOS</Button>
                                <Button style={styles.downloadAppButton} appearance="outline" onPress={() => Linking.openURL(Constants.GOOGLE_PLAY_APP_LINK)} accessoryLeft={<FontAwesomeIcon icon={['fab', 'google-play']} size={24} color={styles.downloadAppButton.color} />}>Download for Android</Button>
                            </View>
                        </>
                    }
                </View>
                <CustomModal title="Forgot Password" modalVisible={modalVisible} setModalVisible={setModalVisible} modalError={modalError} setModalError={setModalError}>
                    <ForgotPassword setModalVisible={setModalVisible} setModalError={setModalError} />
                </CustomModal>
            </Layout>
        )
    }

    return (
        helper.isWeb() ? (
            <View style={styles.webView}>
                <SignInView />
            </View>
        ) : (
            <SafeAreaView style={styles.safeAreaView} edges={['top', 'left', 'right']}>
                <KeyboardAvoidingView>
                    <SignInView />
                </KeyboardAvoidingView>
            </SafeAreaView>
        )
    )
}

const themedStyles = StyleService.create({
    // global styles
    layout: {
        flex: 1,
    },
    header: {
        backgroundColor: "color-basic-200",
        alignItems: "center",
        justifyContent: "center",
        borderBottomWidth: 1,
        borderBottomColor: "color-basic-400",
        paddingVertical: 50
    },
    logoView: {
        alignItems: "center",
        justifyContent: "center",
        paddingTop: 10,
        marginHorizontal: 40,
    },
    logo: {
        width: 112,
        height: 94
    },
    title: {
        marginTop: 30,
        marginBottom: 5,
    },
    body: {
        backgroundColor: "color-basic-100",
        justifyContent: "center",
        flexDirection: "row",
    },
    formView: {
        marginTop: helper.isDesktopWeb() ? 50 : 25,
        marginBottom: 30,
        marginHorizontal: 20,
        maxWidth: 400,
        flex: 1
    },
    buttonView: {
        marginTop: 5,
    },
    footer: {
        backgroundColor: "color-basic-100",
        alignItems: "center"
    },
    footerLink: {
        color: "color-primary-500",
        paddingVertical: 10,
        textAlign: "center"
    },
    footerText: {
        paddingTop: 20,
        textAlign: "center"
    },
    icon: {
        size: 14,
        color: "color-basic-600"
    },
    // native specific styles
    safeAreaView: {
        backgroundColor: "color-basic-200",
        flex: 1,
    },
    // web specific styles
    webView: {
        flex: 1,
    },
    downloadAppView: {
        backgroundColor: "color-basic-200",
        padding: 20,
        paddingBottom: 10,
        marginTop: 35,
        marginHorizontal: 20,
        marginBottom: 20,
        maxWidth: 400,
        borderWidth: 1,
        borderColor: "color-basic-400",
        borderRadius: 4,
    },
    downloadAppTitle: {
        textAlign: "center"
    },
    downloadAppDescription: {
        paddingTop: 15,
        paddingBottom: 25,
        lineHeight: 24,
    },
    downloadAppButton: {
        marginBottom: 10,
        color: "color-primary-500"
    }
});
