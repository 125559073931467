import { AppState, Platform } from 'react-native';
import * as Location from 'expo-location';
import * as TaskManager from 'expo-task-manager';
import * as helper from 'utility/Helper';
import * as api from 'api/SleekTechnologiesApi';
import * as Tasks from 'location/Tasks';
import * as Constants from 'config/Constants';

const LOCATION_SETTINGS = {
    accuracy: Location.Accuracy.High,       // Default should be "Balanced", use "Highest" for testing -- testing out "High"
    timeInterval: 0,                        // Default should be 0
    distanceInterval: 250,                  //Default should be 250
    showBackgroundLocationIndicator: true,
    foregroundService: {
        notificationTitle: "Location",
        notificationBody: "Location tracking in background",
        notificationColor: "#fff",
    },
};

export const getInitialLocation = (loadDetails, getInitialLocationCallback) => {
    helper.logEvent({ category: 'Location Tracking', message: 'getInitialLocation()', data: { loadDetails: loadDetails, getInitialLocationCallback: getInitialLocationCallback } });
    _getLocationAsync(loadDetails, getInitialLocationCallback);
};

export const startLocationServices = (locationService, origin, destination, startLocationServicesCallback) => {
    if(helper.isWeb()) {
        return;
    }
    helper.logEvent({ category: 'Location Tracking', message: 'startLocationServices()', data: { locationService: locationService, origin: origin, destination: destination, startLocationServicesCallBack: startLocationServicesCallback } });
    startForegroundTracking(locationService, startLocationServicesCallback);
    startBackgroundTracking();
    startGeofence(origin, destination);
}

export const restartForegroundService = (locationService, startLocationServicesCallback) => {
    if(helper.isWeb()) {
        return;
    }
    helper.logEvent({ sendToRollbar: true, category: 'Location Tracking', message: 'restartForegroundService()', data: { locationService: locationService, startLocationServicesCallBack: startLocationServicesCallback } });
    if(locationService.instance) {
        locationService.instance.remove();
    }
    startForegroundTracking(locationService, startLocationServicesCallback);
}

export const restartBackgroundServices = (origin, destination) => {
    if(helper.isWeb()) {
        return;
    }
    helper.logEvent({ sendToRollbar: true, category: 'Location Tracking', message: 'restartBackgroundServices()', data: { origin: origin, destination: destination } });
    startBackgroundTracking();
    startGeofence(origin, destination);
}

export const stopLocationServices = (locationService) => {
    if(helper.isWeb()) {
        return;
    }
    helper.logEvent({ category: 'Location Tracking', message: 'stopLocationServices()', data: { locationService: locationService } });
    stopForegroundTracking(locationService);
    stopBackgroundTracking();
    stopGeoFencing();
}

const startForegroundTracking = async (locationService, startLocationServicesCallback) => {
    helper.logEvent({ sendToRollbar: true, category: 'Location Tracking', message: 'startForegroundTracking()', data: { locationService: locationService, startLocationServicesCallBack: startLocationServicesCallback } });
    let { status } = await Location.requestForegroundPermissionsAsync();
    if (status !== 'granted') {
        // do nothing -- should be handled by modal on the status view
    } else {
        const newLocationService = await Location.watchPositionAsync(LOCATION_SETTINGS, location => {
            if(AppState.currentState != 'active') {
                return;
            }

            //Send Update
            api.driverLocationUpdateNative(location.coords.latitude, location.coords.longitude)
                .then(function(response){
                    helper.logEvent({ category: 'API Response', message: 'driverLocationUpdateNative()', data: { response: response.data } });
                    startLocationServicesCallback(location, response.data.mapCoordinates);
                });
        });
        if(newLocationService) {
            locationService.instance = newLocationService;
        }
    }
}

const startBackgroundTracking = async () => {
    helper.logEvent({ sendToRollbar: true, category: 'Location Tracking', message: 'startBackgroundTracking()' });
    await stopBackgroundTracking();
    let { status } = await Location.requestBackgroundPermissionsAsync();
    if (status !== 'granted') {
        helper.logEvent({ sendToRollbar: true, category: 'Location Tracking', message: 'startBackgroundTracking(): Background Tracking Status NOT GRANTED', data: { status: status } });
        // do nothing -- should be handled by modal on the status view
    } else {
        helper.logEvent({ category: 'Location Tracking', message: 'startBackgroundTracking(): Background Tracking Status IS GRANTED', data: { status: status } });
        await Location.startLocationUpdatesAsync(Tasks.BACKGROUND_LOCATION_UPDATES_TASK, LOCATION_SETTINGS);
    }
}

const startGeofence = async (origin, destination) => {
    helper.logEvent({ sendToRollbar: true, category: 'Location Tracking', message: 'startGeoFence()', data: { origin: origin, destination: destination } });
    await stopGeoFencing();
    let { status } = await Location.requestBackgroundPermissionsAsync();
    if (status !== 'granted') {
        helper.logEvent({ sendToRollbar: true, category: 'Location Tracking', message: 'startGeoFence(): Background Tracking Status NOT GRANTED', data: { status: status } });
        // do nothing -- should be handled by modal on the status view
    } else {
        helper.logEvent({ category: 'Location Tracking', message: 'startGeoFence(): Background Tracking Status IS GRANTED', data: { status: status } });
        await Location.startGeofencingAsync(Tasks.GEO_FENCING_TASK,
            [
                {
                    identifier: 'origin',
                    latitude: origin.latitude,
                    longitude: origin.longitude,
                    radius: 1609
                },
                {
                    identifier: 'destination',
                    latitude: destination.latitude,
                    longitude: destination.longitude,
                    radius: 1609
                }
            ]
        );
    }
};

const stopForegroundTracking = async (locationService) => {
    helper.logEvent({ sendToRollbar: true, category: 'Location Tracking', message: 'stopForegroundTracking()', data: { locationService: locationService } });
    if(locationService.instance) {
        locationService.instance.remove();
    }
}

const stopBackgroundTracking = async () => {
    helper.logEvent({ sendToRollbar: true, category: 'Location Tracking', message: 'stopBackgroundTracking()' });
    let isRegistered = await TaskManager.isTaskRegisteredAsync(Tasks.BACKGROUND_LOCATION_UPDATES_TASK);
    if(isRegistered) {
        helper.logEvent({ category: 'Location Tracking', message: 'stopBackgroundTracking(): Background Tracking Service Found' });
        Location.stopLocationUpdatesAsync(Tasks.BACKGROUND_LOCATION_UPDATES_TASK);
    }
}

const stopGeoFencing = async () => {
    helper.logEvent({ sendToRollbar: true, category: 'Location Tracking', message: 'stopGeoFencing()' });
    let isRegistered = await TaskManager.isTaskRegisteredAsync(Tasks.GEO_FENCING_TASK);
    if(isRegistered) {
        helper.logEvent({ category: 'Location Tracking', message: 'stopGeoFencing(): Geofence Service Found' });
        Location.stopGeofencingAsync(Tasks.GEO_FENCING_TASK);
    }
}

const _getLocationAsync = async (loadDetails, getInitialLocationCallback) => {
    helper.logEvent({ category: 'Location Tracking', message: '_getLocationAsync()', data: { loadDetails: loadDetails, getInitialLocationCallback: getInitialLocationCallback } });
    let location = '';
    if(Platform.OS === 'ios' || Platform.OS === 'android') {
        let { status } = await Location.requestForegroundPermissionsAsync();
        if (status !== 'granted') {
            location = '';
        } else {
            location = await Location.getCurrentPositionAsync({});
        }
    }

    helper.logEvent({ category: 'Location Tracking', message: '_getLocationAsync(): User Location', data: { location: location } })

    getInitialLocationCallback(location, loadDetails);
}
