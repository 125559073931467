import React, { useContext, useState, useEffect } from 'react';
import { useFocusEffect } from '@react-navigation/native';
import { createMaterialTopTabNavigator } from '@react-navigation/material-top-tabs';
import { ScrollView, View } from 'react-native';
import { SafeAreaView } from 'react-native-safe-area-context';
import { Layout, StyleService, Tab, TabBar, Text, useStyleSheet } from '@ui-kitten/components';
import { AuthContext } from 'components/context';
import { CustomModal, Header } from 'components/layout';
import { Loader } from 'components/utility';
import LocationTrackingDisclosure from 'location/modals/LocationTrackingDisclosure';
import * as helper from 'utility/Helper';
import * as api from 'api/SleekTechnologiesApi';
import { ActiveLoads, UpcomingLoads, CompletedLoads } from 'screens/booked-loads/tabs';

export default function BookedLoads({ route, navigation }) {

    const styles = useStyleSheet(themedStyles);
    const { signOut } = useContext(AuthContext);
    const [isLoading, setLoading] = useState(true);
    const [isRefreshing, setRefreshing] = useState(false);
    const [bookedLoads, setBookedLoads] = useState([]);
    const [myActiveLoad, setMyActiveLoad] = useState(null);
    const [modalVisible, setModalVisible] = useState(false);

    const breadcrumbs = [
        {
            label: 'Dashboard',
            path: '/dashboard'
        },
        {
            label: 'Booked Loads',
            path: ''
        }
    ]

    const getBookedLoads = async (isRefresh = false) => {
        if(isRefresh) {
            setRefreshing(true);
        }
        const viewedLocationTrackingDisclosure = await helper.getSecureStoreItem('viewedLocationTrackingDisclosure');
        api.getBookedLoadsData()
            .then(function(response){
                if(helper.hasValue(response.data.msg) && response.data.msg == "Token Not Found or Expired") {
                    signOut();
                }
                if(response.data.result == "success") {
                    setBookedLoads(response.data.bookedLoads);
                    setMyActiveLoad(response.data.myActiveLoad);
                    if(isRefresh) {
                        setRefreshing(false);
                    } else {
                        setLoading(false);
                    }
                    if(!helper.isWeb() && viewedLocationTrackingDisclosure != 'yes') {
                        helper.setSecureStoreItem('viewedLocationTrackingDisclosure', 'yes');
                        setModalVisible(true);
                    }
                } else if(response.data.result == "error") {
                    throw {
                        type: "custom",
                        message: response.data.msg
                    }
                }
            })
            .catch(function(error){
                if(isRefresh) {
                    setRefreshing(false);
                } else {
                    setLoading(false);
                }
                helper.handleCatchError(error);
            });
    }

    useFocusEffect(
        React.useCallback(() => {
            getBookedLoads();
        }, [])
    );

    useEffect(() => {
        if(helper.hasValue(route.params?.tab)) {
            navigation.navigate('BookedLoads', {
                screen: route.params.tab
            });
        }
    }, [route.params]);

    const { Navigator, Screen } = createMaterialTopTabNavigator();

    const TopTabBar = ({ navigation, state }) => {
        return (
            <TabBar
                selectedIndex={state.index}
                onSelect={index => navigation.navigate(state.routeNames[index])}>
                <Tab title="Active"/>
                <Tab title="Upcoming"/>
                <Tab title="Completed"/>
            </TabBar>
        )
    }

    if(isLoading) {
        return (
            <Loader loadingText="Getting Booked Loads"/>
        )
    } else {
        return (
            helper.isWeb() ? (
                <View style={styles.webView}>
                    <Header breadcrumbs={breadcrumbs} title="Booked Loads" />
                    <Navigator style={styles.navigator} tabBar={props => <TopTabBar {...props} />} initialRouteName={(helper.hasValue(route.params?.tab) ? route.params.tab : 'active')} backBehavior="history">
                        <Screen
                            name="active"
                            children={() =>
                                <ActiveLoads
                                    myActiveLoad={myActiveLoad}
                                    activeLoads={bookedLoads.active}
                                    getBookedLoads={getBookedLoads}
                                    isRefreshing={isRefreshing}
                                />
                            }
                        />
                        <Screen
                            name="upcoming"
                            children={() =>
                                <UpcomingLoads
                                    upcomingLoads={bookedLoads.upcoming}
                                    getBookedLoads={getBookedLoads}
                                    isRefreshing={isRefreshing}
                                />
                            }
                        />
                        <Screen
                            name="completed"
                            children={() =>
                                <CompletedLoads
                                    completedLoads={bookedLoads.recent}
                                    getBookedLoads={getBookedLoads}
                                    isRefreshing={isRefreshing}
                                />
                            }
                        />
                    </Navigator>
                    <CustomModal title="Sleek Fleet" modalVisible={modalVisible} setModalVisible={setModalVisible}>
                        <LocationTrackingDisclosure setModalVisible={setModalVisible} />
                    </CustomModal>
                </View>
            ) : (
                <SafeAreaView style={styles.safeAreaView} edges={['top', 'left', 'right']}>
                    <Header activeScreen="BookedLoads" />
                    <Navigator style={styles.navigator} tabBar={props => <TopTabBar {...props} />} initialRouteName={(helper.hasValue(route.params?.tab) ? route.params.tab : 'active')}>
                        <Screen
                            name="active"
                            children={() =>
                                <ActiveLoads
                                    myActiveLoad={myActiveLoad}
                                    activeLoads={bookedLoads.active}
                                    getBookedLoads={getBookedLoads}
                                    isRefreshing={isRefreshing}
                                />
                            }
                        />
                        <Screen
                            name="upcoming"
                            children={() =>
                                <UpcomingLoads
                                    upcomingLoads={bookedLoads.upcoming}
                                    getBookedLoads={getBookedLoads}
                                    isRefreshing={isRefreshing}
                                />
                            }
                        />
                        <Screen
                            name="completed"
                            children={() =>
                                <CompletedLoads
                                    completedLoads={bookedLoads.recent}
                                    getBookedLoads={getBookedLoads}
                                    isRefreshing={isRefreshing}
                                />
                            }
                        />
                    </Navigator>
                    <CustomModal title="Sleek Fleet" modalVisible={modalVisible} setModalVisible={setModalVisible}>
                        <LocationTrackingDisclosure setModalVisible={setModalVisible} />
                    </CustomModal>
                </SafeAreaView>
            )
        )
    }
}

const themedStyles = StyleService.create({
    // global styles
    navigator: {
        backgroundColor: "color-basic-400"
    },
    // native specific styles
    safeAreaView: {
        backgroundColor: "safe-area-view-background",
        flex: 1,
    },
    // web specific styles
    webView: {
        flex: 1,
    },
});
