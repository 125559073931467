import React from 'react';
import { Image, View } from 'react-native';
import { Button, Layout, StyleService, Text, useStyleSheet } from '@ui-kitten/components';
import { CustomLink } from 'components/utility';
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome';
import * as Constants from 'config/Constants';
import getEnvVars from 'root/environment';

export const DriverSummary = (props) => {

    /*
        props:
        - activeLoad
        - activeLoadDestination
        - activeLoadOrigin
        - activeLoadStatus
        - day1 {}
        - day2 {}
        - day3 {}
        - day4 {}
        - day5 {}
        - day6 {}
        - day7 {}
        - email
        - id
        - isActive
        - isAdmin
        - name
        - phone
        - upcomingLoadCount
        - userPhoto
        - userType
    */

    const styles = useStyleSheet(themedStyles);
    const { sleekApiUrl } = getEnvVars();
    const driverImgUri = sleekApiUrl+props.userPhoto;

    return (
        <CustomLink styleProps={styles.customLink} to={'/drivers/'+props.id}>
            <View style={styles.container}>
                <View style={styles.columnOne}>
                    <Image
                        source={{
                            uri: driverImgUri,
                            headers: Constants.RESOURCE_HEADERS
                        }}
                        style={{width: 50, height: 50, borderRadius: 25}}
                    />
                </View>
                <View style={styles.columnTwo}>
                    <Text style={styles.name} category="s2" status="primary">{props.name}</Text>
                    <View style={styles.loadStatusWrapper}>
                        <Text category="s2" appearance="hint">{props.activeLoad == 'Yes' ? '1 Active Load' : 'No Active Load'}</Text>
                        <Text style={styles.loadStatusBullet} category="s2" appearance="hint">&bull;</Text>
                        <Text category="s2" appearance="hint">{props.upcomingLoadCount == 1 ? '1 Upcoming Load' : props.upcomingLoadCount + ' Upcoming Loads'}</Text>
                    </View>
                </View>
                <View style={styles.actionView}>
                    <FontAwesomeIcon style={styles.forwardIcon} icon={['fal', 'angle-right']} size={styles.forwardIcon.size} color={styles.forwardIcon.color} />
                </View>
            </View>
        </CustomLink>
    )
}

const themedStyles = StyleService.create({
    customLink: {
        flex: 1,
    },
    container: {
        flex: 1,
        flexDirection: "row",
        paddingHorizontal: 5,
    },
    columnOne: {
        width: 60,
        justifyContent: "center",
        alignItems: "center",
        paddingRight: 5,
    },
    columnTwo: {
        flex: 8,
        padding: 5,
    },
    columnThree: {
        flex: 1,
        justifyContent: "center",
        alignItems: "center",
    },
    name: {
        fontSize: 16,
    },
    loadStatusBullet: {
        paddingHorizontal: 8,
    },
    loadStatusWrapper: {
        flexDirection: "row",
        alignItems: "center",
        marginTop: 3,
    },
    actionView: {
        position: "absolute",
        right: 5,
        top: "50%",
        marginTop: -10
    },
    forwardIcon: {
        color: "color-primary-500",
        size: 20,
    },
});
