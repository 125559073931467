import React from 'react';
import { ScrollView, View } from 'react-native';
import { Layout, Divider, StyleService, List, ListItem, Text, useStyleSheet } from '@ui-kitten/components';
import { useNavigation } from '@react-navigation/native';
import { EmptyDataSet } from 'components/utility';
import { LoadSummary } from 'components/loads';
import { Section } from 'components/layout';
import * as helper from 'utility/Helper';

export default function CompletedLoads(props) {

    const styles = useStyleSheet(themedStyles);
    const completedLoads = props.completedLoads;
    const navigation = useNavigation();

    const CompletedLoadsList = ({ item, index }) => {
        if(completedLoads.length > 0) {
            return (
                <List style={styles.listView} data={completedLoads} renderItem={renderLoadSummary} ItemSeparatorComponent={Divider} onRefresh={() => props.getBookedLoads(true)} refreshing={props.isRefreshing} />
            )
        } else {
            if(helper.checkForRoles(['manager'])) {
                return (
                    <EmptyDataSet primaryMessage="No Recently Completed Loads" btnText="Find Your Next Load" btnAction={() => navigation.navigate('FindALoadStackNavigator')}/>
                )
            } else {
                return (
                    <EmptyDataSet primaryMessage="No Recently Completed Loads" />
                )
            }
        }
    }

    const renderLoadSummary = ({ item, index }) => {
        return (
            <ListItem style={styles.listItem} children={<LoadSummary {...item} loadSummaryType="completed" />} />
        )
    }

    React.useLayoutEffect(() => {
        if(helper.isWeb()) {
            navigation.setOptions({
                title: 'Booked Loads - Completed'
            })
        }
    }, [navigation]);

    return (
        helper.isWeb() ? (
            <Layout style={styles.layout} level="3">
                {helper.isDesktopWeb() &&
                    <ScrollView>
                        <Section title="Completed Loads" subtitle={completedLoads.length == 1 ? completedLoads.length + ' Load' : completedLoads.length + ' Loads'} noBodyPadding={completedLoads.length > 0 ? true : false} renderBody={<CompletedLoadsList />}/>
                    </ScrollView>
                }
                {helper.isMobileWeb() &&
                    <CompletedLoadsList />
                }
            </Layout>
        ) : (
            <Layout style={styles.layout} level="3">
                <CompletedLoadsList />
            </Layout>
        )
    )
}

const themedStyles = StyleService.create({
    layout: {
        flex: 1,
        marginTop: helper.isDesktopWeb() ? 5 : 0
    },
    listView: {
        borderBottomLeftRadius: helper.isDesktopWeb() ? 6 : 0,
        borderBottomRightRadius: helper.isDesktopWeb() ? 6 : 0
    },
    listItem: {
        paddingHorizontal: 0,
        paddingVertical: 0,
    }
});
