import React from 'react';
import { View } from 'react-native';
import { StyleService, Text, useStyleSheet } from '@ui-kitten/components';

export const Badge = (props) => {

    /*
        props:
        - label
        - noBorder
        - noMargin
        - type
    */

    const styles = useStyleSheet(themedStyles);
    let containerStyles = [styles.container];
    let textStyles = [styles.label];

    if(props.noMargin) {
        containerStyles.push(styles.noMargin);
    }

    if(props.noBorder) {
        containerStyles.push(styles.noBorder);
    }

    if(props.type == 'info') {
        containerStyles.push(styles.infoView);
        textStyles.push(styles.infoText);
    } else if(props.type == 'success') {
        containerStyles.push(styles.successView);
        textStyles.push(styles.successText);
    } else if(props.type == 'primary') {
        containerStyles.push(styles.primaryView);
        textStyles.push(styles.primaryText);
    } else if(props.type == 'warning') {
        containerStyles.push(styles.warningView);
        textStyles.push(styles.warningText);
    } else if(props.type == 'danger') {
        containerStyles.push(styles.dangerView);
        textStyles.push(styles.dangerText);
    } else {
        containerStyles.push(styles.basicView);
        textStyles.push(styles.basicText);
    }

    return (
        <View style={containerStyles}>
            <Text style={textStyles} appearance="alternative" category="c2">{props.label}</Text>
        </View>
    )

}

const themedStyles = StyleService.create({
    container: {
        borderRadius: 2,
        paddingHorizontal: 3,
        marginLeft: 5,
        height: 16,
        justifyContent: "center",
        borderWidth: 1,
    },
    noMargin: {
        marginLeft: 0,
    },
    noBorder: {
        borderWidth: 0,
    },
    label: {
        textAlign: "center",
        fontSize: 12,
    },
    basicView: {
        backgroundColor: "color-basic-transparent-100",
        borderColor: "color-basic-transparent-500",
    },
    basicText: {
        color: "color-basic-600",
    },
    infoView: {
        backgroundColor: "color-info-transparent-100",
        borderColor: "color-info-transparent-500",
        borderWidth: 1,
    },
    infoText: {
        color: "color-info-600",
    },
    primaryView: {
        backgroundColor: "color-primary-transparent-100",
        borderColor: "color-primary-transparent-500",
    },
    primaryText: {
        color: "color-primary-500",
    },
    successView: {
        backgroundColor: "color-success-transparent-100",
        borderColor: "color-success-transparent-500",
    },
    successText: {
        color: "color-success-600",
    },
    warningView: {
        backgroundColor: "color-warning-transparent-100",
        borderColor: "color-warning-transparent-600",
    },
    warningText: {
        color: "color-warning-700",
    },
    dangerView: {
        backgroundColor: "color-danger-transparent-100",
        borderColor: "color-danger-transparent-500",
    },
    dangerText: {
        color: "color-danger-600",
    },
});
