import React, { useContext, useState } from 'react';
import { ScrollView, View } from 'react-native';
import { SafeAreaView } from 'react-native-safe-area-context';
import { useFocusEffect } from "@react-navigation/native";
import { Button, Divider, Layout, List, ListItem, StyleService, Text, useStyleSheet } from '@ui-kitten/components';
import { AppDataContext, AuthContext } from 'components/context';
import { CustomModal, Header, Section } from 'components/layout';
import { UserSummary } from 'components/users';
import { AddUser } from 'screens/manage-users/modals';
import * as helper from 'utility/Helper';
import * as api from 'api/SleekTechnologiesApi';

export default function ManageUsers({ navigation }) {

    const styles = useStyleSheet(themedStyles);
    const { appData, setAppData } = React.useContext(AppDataContext);
    const { signOut } = useContext(AuthContext);
    const [users, setUsers] = useState(appData.carrierUsers);
    const [modalVisible, setModalVisible] = useState(false);
    const [modalError, setModalError] = useState(null);
    const [isRefreshing, setRefreshing] = useState(false);

    const breadcrumbs = [
        {
            label: 'Dashboard',
            path: '/dashboard'
        },
        {
            label: 'Manage Users',
            path: ''
        }
    ]

    const refreshUsers = () => {
        setRefreshing(true);
        api.getApplicationData()
            .then(function(response){
                if(helper.hasValue(response.data.msg) && response.data.msg == "Token Not Found or Expired") {
                    signOut();
                }
                if(response.data.result == "success") {
                    setAppData({...response.data});
                    setUsers(response.data.carrierUsers);
                    setRefreshing(false);
                } else if(response.data.result == "error") {
                    throw {
                        type: "custom",
                        message: response.data.msg
                    }
                }
            })
            .catch(function(error){
                helper.handleCatchError(error);
            });
    }

    useFocusEffect(
        React.useCallback(() => {
            setUsers(appData.carrierUsers);
        }, [appData])
    );

    const renderUserSummary = ({ item, index }) => {
        return (
            <ListItem children={<UserSummary {...item} />} />
        )
    }

    const getPageActions = () => {
        return (
            <Button appearance="outline" size="small" onPress={() => setModalVisible(true)}>Add User</Button>
        )
    }

    const renderUserList = () => {
        return (
            <List style={styles.listView} data={users} renderItem={renderUserSummary} ItemSeparatorComponent={Divider} onRefresh={() => refreshUsers()} refreshing={isRefreshing}/>
        )
    }

    const renderAddUser = () => {
        return (
            <AddUser setModalVisible={setModalVisible} setModalError={setModalError} isDriver={false} refreshAction={refreshUsers}/>
        )
    }

    return (
        helper.isWeb() ? (
            <View style={styles.webView}>
                <Header breadcrumbs={breadcrumbs} title="Manage Users" pageActions={getPageActions()} />
                <Layout style={styles.layout} level="3">
                    {helper.isDesktopWeb() &&
                        <ScrollView>
                            <Section title="Users" subtitle={users.length == 1 ? users.length + ' User' : users.length + ' Users'} noBodyPadding={users.length > 0 ? true : false} renderBody={renderUserList()} />
                        </ScrollView>
                    }
                    {helper.isMobileWeb() &&
                        renderUserList()
                    }
                </Layout>
                <CustomModal title="Add User" modalVisible={modalVisible} setModalVisible={setModalVisible} modalError={modalError} setModalError={setModalError}>
                    {renderAddUser()}
                </CustomModal>
            </View>
        ) : (
            <SafeAreaView style={styles.safeAreaView} edges={['top', 'left', 'right']}>
                <Header activeScreen="ManageUsers" setModalVisible={setModalVisible}/>
                <Layout style={styles.layout} level="3">
                    {renderUserList()}
                </Layout>
                <CustomModal title="Add User" modalVisible={modalVisible} setModalVisible={setModalVisible} modalError={modalError} setModalError={setModalError}>
                    {renderAddUser()}
                </CustomModal>
            </SafeAreaView>
        )
    )
}

const themedStyles = StyleService.create({
    // global styles
    layout: {
        flex: 1,
        marginTop: helper.isDesktopWeb() ? 5 : 0
    },
    listView: {
        borderBottomLeftRadius: helper.isDesktopWeb() ? 6 : 0,
        borderBottomRightRadius: helper.isDesktopWeb() ? 6 : 0
    },
    // native specific styles
    safeAreaView: {
        backgroundColor: "safe-area-view-background",
        flex: 1,
    },
    // web specific styles
    webView: {
        flex: 1,
    },
});
