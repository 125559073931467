import React, { useContext, useState } from 'react';
import { View } from 'react-native';
import { Button, Layout, StyleService, Text, useStyleSheet } from '@ui-kitten/components';
import { InputField, SelectField } from 'components/forms';
import { AppDataContext, AuthContext } from 'components/context';
import { Loader } from 'components/utility';
import { Formik, Field } from 'formik';
import * as yup from 'yup';
import * as helper from 'utility/Helper';
import * as api from 'api/SleekTechnologiesApi';

export default function AssignDriver(props) {

    const styles = useStyleSheet(themedStyles);
    const { appData } = useContext(AppDataContext);
    const { signOut } = useContext(AuthContext);
    const [isLoading, setLoading] = useState(false);
    const loadDetails = props.loadDetails;
    const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/

    yup.addMethod(yup.string, "phoneLength", function (errorMessage) {
        return this.test(`test-phone-length`, errorMessage, function (value) {
            const { path, createError } = this;

            return (
                ((value && value.replace(/-/g, "").length === 10) || typeof value == 'undefined') ||
                createError({ path, message: errorMessage })
            );
        });
    });

    const validationSchema = yup.object().shape({
        carrierUserId: yup
            .mixed()
            .required('Please select a driver'),
        firstName: yup
            .string()
            .when('carrierUserId', {is: value => value && value == 'new', then: yup.string().required('First Name is Required')}),
        lastName: yup
            .string()
            .when('carrierUserId', {is: value => value && value == 'new', then: yup.string().required('Last Name is Required')}),
        email: yup
            .string()
            .when('carrierUserId', {is: value => value && value == 'new', then: yup.string().test('oneOfRequired', 'Email or Phone is Required', function(item){return(this.parent.email || this.parent.phone)}).email('Email must be a valid email')}),
        phone: yup
            .string()
            .when('carrierUserId', {is: value => value && value == 'new', then: yup.string().test('oneOfRequired', 'Email or Phone is Required', function(item){return(this.parent.email || this.parent.phone)}).matches(phoneRegExp, 'Phone number is not valid').phoneLength('Phone number must be 10 digits')}),
    });

    let carrierDrivers = appData.carrierDrivers;

    let newOption = {
        label: "New Driver",
        value: "new"
    };
    carrierDrivers = helper.prependArrayItem(newOption, carrierDrivers);

    if(loadDetails.status == 'Awarded' || loadDetails.status == 'Tendered') {
        let unassignedOption = {
            label: "Unassigned",
            value: "unassigned"
        };
        carrierDrivers = helper.prependArrayItem(unassignedOption, carrierDrivers);
    }

    // default the selected driver to the first driver in the list
    let carrierUserId = carrierDrivers[0]['value'];

    carrierDrivers.forEach(driver => {
        if(driver.value == loadDetails.carrierDriverId) {
            carrierUserId = driver.value;
        }
    });

    const assignDriver = (data) => {

        setLoading(true);

        let type;

        if(data.carrierUserId == 'unassigned') {
            type = 'Remove';
        } else {
            type = 'Change';
        }

        //Format Phone & Email
        data.email = (typeof data.email == 'undefined' ? '' : data.email);
        data.phone = (typeof data.phone == 'undefined' ? '' : data.phone);
        if(data.phone != '') {
            let submittedPhone = data.phone.replace(/-/g, "");
            data.phone = submittedPhone.substr(0,3)+'-'+submittedPhone.substr(3,3)+'-'+submittedPhone.substr(6,4);
        }

        api.updateDriverAssignment(type, data.loadId, data.carrierUserId, data.firstName, data.lastName, data.email, data.phone)
            .then(function(response){
                if(helper.hasValue(response.data.msg) && response.data.msg == "Token Not Found or Expired") {
                    signOut();
                }
                setLoading(false);
                if(response.data.result == "success") {
                    props.setModalVisible(false);
                    props.setModalError(null);
                    helper.showFlashMessage(response.data.result, response.data.msg);
                    props.getLoadDetails(loadDetails.sleekFleetLoadId);
                } else if(response.data.result == "error") {
                    throw {
                        type: "custom",
                        message: response.data.msg
                    }
                }
            })
            .catch(function(error){
                setLoading(false);
                helper.handleCatchError(error, true, props.setModalError);
            });
    }

    if(isLoading) {
        return (
            <Loader loadingText="Assigning Driver" />
        )
    } else {
        return(
            <View style={styles.container}>
                <Text style={styles.instructions} category="p1">Please select an option from the list below to update the driver assignment on this load.</Text>
                <Formik
                    validationSchema={validationSchema}
                    initialValues={{
                        type: "",
                        loadId: loadDetails.id,
                        carrierUserId: carrierUserId,
                    }}
                    onSubmit={(values) => assignDriver(values)}
                >
                    {({ handleSubmit, isValid, values }) => (
                        <>
                            <Field
                                component={SelectField}
                                name="carrierUserId"
                                options={carrierDrivers}
                                multiSelect={false}
                            />
                            {values.carrierUserId == 'new' &&
                                <>
                                    <View style={{paddingTop:5, paddingBottom:15}}>
                                        <Text category="s2">Add New Driver</Text>
                                        <Text category="p2" appearance="hint">When adding a new driver you may provide phone number or email address. Only one is required.</Text>
                                    </View>
                                    <Field
                                        component={InputField}
                                        name="firstName"
                                        label="First Name"
                                        defaultValue={''}
                                    />
                                    <Field
                                        component={InputField}
                                        name="lastName"
                                        label="Last Name"
                                        defaultValue={''}
                                    />
                                    <Field
                                        component={InputField}
                                        name="email"
                                        label="Email"
                                        defaultValue={''}
                                    />
                                    <Field
                                        component={InputField}
                                        name="phone"
                                        label="Phone"
                                        defaultValue={''}
                                    />
                                </>
                            }
                            <Button style={styles.ctaButton} onPress={handleSubmit} disabled={!isValid}>{(values.carrierUserId == 'new' ? 'Add Driver & Assign Load' : 'Assign Driver')}</Button>
                        </>
                    )}
                </Formik>
            </View>
        )
    }

}

const themedStyles = StyleService.create({
    container: {
        // styles here...
    },
    instructions: {
        paddingVertical: 10,
    },
    ctaButton: {
        marginTop: 5,
    }
});
