import * as Linking from 'expo-linking';
import { LinkingOptions } from "@react-navigation/native";

const prefix = Linking.createURL('/');

export const linking: LinkingOptions = {
    prefixes: [prefix],
    config: {
        screens: {
            SignIn: {
                path: 'sign-in'
            },
            LoadDetailsStackNavigator: {
                screens: {
                    LoadDetails_Generic: {
                        path: 'load-details/:loadId/:callback?/:tab?',
                        stringify: {
                            tab: (tab) => tab.replace(/([-a-z])\w+/g, '')
                        }
                    }
                }
            },
            DashboardStackNavigator: {
                screens: {
                    Dashboard: {
                        path: 'dashboard'
                    }
                }
            },
            FindALoadStackNavigator: {
                screens: {
                    FindALoad: {
                        path: 'find-a-load'
                    },
                    LoadDetails_FindALoad: {
                        path: 'find-a-load/load-details/:loadId/:tab?',
                        stringify: {
                            tab: (tab) => tab.replace(/([-a-z])\w+/g, '')
                        }
                    },
                }
            },
            BookedLoadsStackNavigator: {
                screens: {
                    BookedLoads: {
                        path: 'booked-loads/:tab?',
                        stringify: {
                            tab: (tab) => tab.replace(/([-a-z])\w+/g, '')
                        }
                    },
                    LoadDetails_BookedLoads: {
                        path: 'booked-loads/load-details/:loadId/:callback?/:tab?',
                        stringify: {
                            tab: (tab) => tab.replace(/([-a-z])\w+/g, ''),
                        }
                    },
                }
            },
            DriversStackNavigator: {
                screens: {
                    Drivers: {
                        path: 'drivers'
                    },
                    DriverDetails: {
                        path: 'drivers/:driverId/:tab?',
                        stringify: {
                            tab: (tab) => tab.replace(/([-a-z])\w+/g, '')
                        }
                    },
                    LoadDetails_Drivers: {
                        path: 'drivers/load-details/:loadId/:tab?',
                        stringify: {
                            tab: (tab) => tab.replace(/([-a-z])\w+/g, '')
                        }
                    }
                }
            },
            ReportsStackNavigator: {
                screens: {
                    Reports: {
                        path: 'reports/:tab?',
                        stringify: {
                            tab: (tab) => tab.replace(/([-a-z])\w+/g, '')
                        }
                    },
                    LoadDetails_Reports: {
                        path: 'reports/load-details/:loadId/:tab?',
                        stringify: {
                            tab: (tab) => tab.replace(/([-a-z])\w+/g, '')
                        }
                    }
                }
            },
            MyProfileStackNavigator: {
                screens: {
                    MyProfile: {
                        path: 'my-profile'
                    },
                    MyProfileEdit: {
                        path: 'my-profile/edit'
                    }
                }
            },
            CarrierProfileStackNavigator: {
                screens: {
                    CarrierProfile: {
                        path: 'carrier-profile'
                    },
                    CarrierProfileEdit: {
                        path: 'carrier-profile/edit'
                    }
                }
            },
            ManageUsersStackNavigator: {
                screens: {
                    ManageUsers: {
                        path: 'manage-users'
                    },
                    UserDetails: {
                        path: 'manage-users/:userId'
                    },
                    EditUser: {
                        path: 'manage-users/:userId/edit'
                    }
                }
            },
            PreferredLocationsStackNavigator: {
                screens: {
                    PreferredLocations: {
                        path: 'preferred-locations'
                    }
                }
            },
            SupportStackNavigator: {
                screens: {
                    Support: {
                        path: 'support'
                    }
                }
            },
            PrivacyStackNavigator: {
                screens: {
                    Privacy: {
                        path: 'privacy'
                    }
                }
            },
            NotificationsStackNavigator: {
                screens: {
                    Notifications: {
                        path: 'notifications'
                    },
                    LoadDetails_Notifications: {
                        path: 'notifications/load-details/:loadId/:tab?',
                        stringify: {
                            tab: (tab) => tab.replace(/([-a-z])\w+/g, '')
                        }
                    }
                }
            },
            ErrorStackNavigator: {
                screens: {
                    ScreenNotFound: '*'
                }
            }
        },
    },
}
