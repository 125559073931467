import React, { useState } from 'react';
import { Dimensions, Image, View } from 'react-native';
import { Layout, StyleService, Text, useStyleSheet } from '@ui-kitten/components';
import { Loader } from 'components/utility';
import Swiper from 'react-native-web-swiper';
import * as Constants from 'config/Constants';
import * as helper from 'utility/Helper';
import getEnvVars from 'root/environment';

export default function ClaimDetails(props) {

    /*
        claimDetails:
        - claimId
        - claimImages [claimDocumentId, filePath, createdat]
        - createdAt
        - createdAtFull
        - createdBy
        - details
        - updatedAt
        - updatedAtFull
    */

    const styles = useStyleSheet(themedStyles);
    const claimDetails = props.claimDetails;
    const [isLoading, setLoading] = useState(false);
    const { sleekApiUrl } = getEnvVars();

    const renderClaimImages = () => {
        return (
            claimDetails.claimImages.map((item, index) => (
                <View style={styles.claimImageCarouselItem} key={index}>
                    {isLoading &&
                        <View style={styles.claimImageLoaderView}>
                            <Loader />
                        </View>
                    }
                    <Image
                        source={{
                            uri: sleekApiUrl+item.filePath,
                            headers: Constants.RESOURCE_HEADERS
                        }}
                        style={styles.claimImage}
                        onLoadStart={() => setLoading(true)}
                        onLoadEnd={() => setLoading(false)}
                        resizeMode="contain"
                    />
                    {!isLoading &&
                        <Text style={styles.claimImageCount} category="p1" appearance="hint">Claim Image {index + 1} of {claimDetails.claimImages.length}</Text>
                    }
                </View>
            ))
        )
    }

    const renderClaimCarousel = () => {
        if(claimDetails.claimImages.length > 0) {
            return (
                <Swiper controlsProps={{
                    dotsWrapperStyle: {
                        marginTop: styles.dotsWrapper.marginTop
                    },
                    dotActiveStyle: {
                        backgroundColor: styles.dots.backgroundColor
                    },
                    prevTitleStyle: {
                        color: styles.navigation.color,
                        marginTop: styles.navigation.marginTop
                    },
                    nextTitleStyle: {
                        color: styles.navigation.color,
                        marginTop: styles.navigation.marginTop
                    }
                }}>
                    {renderClaimImages()}
                </Swiper>
            )
        } else {
            return null;
        }
    }

    return (
        <View style={styles.container}>
            <View style={styles.claimInformationView}>
                <Text category="s2">Claim Description:</Text>
                <Text category="p1" appearance="hint">{claimDetails.details}</Text>
            </View>
            <View style={styles.claimImagesView}>
                {renderClaimCarousel()}
            </View>
        </View>
    )
}

const windowWidth = Dimensions.get('window').width;
const itemSize = helper.isDesktopWeb() ? (windowWidth * .65) - 30 : windowWidth - 30;

const themedStyles = StyleService.create({
    container: {
        flex: 1,
    },
    claimInformationView: {

    },
    claimImageLoaderView: {
        top: 150,
    },
    claimImagesView: {
        marginTop: 20,
        width: itemSize,
        height: itemSize + 100,
    },
    claimImageCarouselItem: {
        width: itemSize,
        backgroundColor: "color-basic-200",
        borderRadius: 8,
        borderWidth: 1,
        borderColor: "color-basic-400",
        paddingBottom: 10,
        marginBottom: 20,
        elevation: 7,
    },
    claimImage: {
        width: itemSize,
        height: itemSize,
        borderTopLeftRadius: 8,
        borderTopRightRadius: 8,
    },
    claimImageCount: {
        paddingTop: 17,
        paddingBottom: 40,
        textAlign: "center",
        fontStyle: "italic",
    },
    dotsWrapper: {
        marginTop: -35
    },
    dots: {
        backgroundColor: "color-primary-500"
    },
    navigation: {
        color: "color-primary-500",
        marginTop: -30
    }
});
