import React from 'react';
import { Image, Pressable, View } from 'react-native';
import { SafeAreaView } from 'react-native-safe-area-context';
import { Layout, StyleService, Text, useStyleSheet } from '@ui-kitten/components';
import { Header } from 'components/layout';
import * as Constants from 'config/Constants';
import * as Linking from 'expo-linking';
import * as helper from 'utility/Helper';
import getEnvVars from 'root/environment';

export default function Support({ navigation }) {

    const styles = useStyleSheet(themedStyles);
    const { sleekApiUrl } = getEnvVars();
    const logoUri = sleekApiUrl+'/images/carrierui/global/modal-logo.png';

    const breadcrumbs = [
        {
            label: 'Dashboard',
            path: '/dashboard'
        },
        {
            label: 'Support',
            path: ''
        }
    ]

    const SupportView = () => {
        return (
            <Layout style={styles.layout} level="3">
                <View style={styles.logoView}>
                    <Image
                        source={{
                            uri: logoUri,
                            headers: Constants.RESOURCE_HEADERS
                        }}
                        style={styles.logo}
                    />
                    <Text style={styles.title} category="h5">Need Help?</Text>
                </View>
                <View style={styles.contactView}>
                    <View style={styles.contactMethodView}>
                        <Text style={styles.contactMethod} category="s1">Phone:</Text>
                        <Pressable onPress={() => Linking.openURL(`tel:${Constants.PHONE_SUPPORT}`)}>
                            <Text style={styles.contactMethod} status="primary" category="s1">{Constants.PHONE_SUPPORT}</Text>
                        </Pressable>
                    </View>
                    <View style={styles.contactMethodView}>
                        <Text style={styles.contactMethod} category="s1">Email:</Text>
                        <Pressable onPress={() => Linking.openURL(`mailto:${Constants.EMAIL_SUPPORT}`)}>
                            <Text style={styles.contactMethod} status="primary" category="s1">{Constants.EMAIL_SUPPORT}</Text>
                        </Pressable>
                    </View>
                </View>
            </Layout>
        )
    }

    return (
        helper.isWeb() ? (
            <View style={styles.webView}>
                <Header breadcrumbs={breadcrumbs} title="Support" />
                <SupportView />
            </View>
        ) : (
            <SafeAreaView style={styles.safeAreaView} edges={['top', 'left', 'right']}>
                <Header activeScreen="Support" />
                <SupportView />
            </SafeAreaView>
        )
    )

}

const themedStyles = StyleService.create({
    // global styles
    layout: {
        flex: 1,
    },
    logoView: {
        alignItems: "center",
        justifyContent: "center",
        paddingTop: 50,
        paddingBottom: 30,
        marginHorizontal: 40,
        borderBottomWidth: 1,
        borderBottomColor: "border-generic"
    },
    logo: {
        width: 149,
        height: 125
    },
    title: {
        marginTop: 30
    },
    contactView: {
        marginTop: 30,
        alignItems: "center",
    },
    contactMethodView: {
        paddingBottom: 15,
        flexDirection: "row",
        flexWrap: "wrap",
    },
    contactMethod: {
        fontSize: 18,
        paddingRight: 5
    },
    // native specific styles
    safeAreaView: {
        backgroundColor: "safe-area-view-background",
        flex: 1,
    },
    // web specific styles
    webView: {
        flex: 1,
    },
});
