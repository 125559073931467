import React from 'react';
import { Dimensions, Pressable, ScrollView, View } from 'react-native';
import { Button, Divider, Layout, StyleService, Text, useStyleSheet } from '@ui-kitten/components';
import { useNavigation } from '@react-navigation/native';
import { Accessorial, Claim, LoadAttribute, LoadDocument, LoadNote, Payment, RateConfirmation, ShipperMessage } from 'components/loads';
import { EmptyDataSet } from 'components/utility';
import { Section } from 'components/layout';
import * as helper from 'utility/Helper';

export default function DetailsView(props) {

    const isCarrierLoad = props.isCarrierLoad;

    const styles = useStyleSheet(themedStyles);
    const load = props.loadDetails;
    const loadAttributes = [
        {
            name: "Load ID",
            value: load.sleekFleetLoadId,
            display: true,
        },
        {
            name: "Load Status",
            value: load.status,
            display: true,
        },
        {
            name: "Customer",
            value: load.shipperName,
            display: true,
        },
        {
            name: "Driver",
            value: load.carrierDriverName != "" ? load.carrierDriverName : "-",
            display: isCarrierLoad ? true : false,
        },
        {
            name: "Route",
            value: load.stops.length > 0 ? load.route + ' (+'+ load.stops.length +' '+ (load.stops.length == 1 ? 'Stop' : 'Stops')+ ')' : load.route,
            display: true,
            valueFlex: 3
        },
        {
            name: "Miles",
            value: load.miles + ' Miles',
            display: true,
        },
        {
            name: "Commodity",
            value: load.commodity,
            display: true,
        },
        {
            name: "PU Appt",
            value: load.pickupAppointmentDetails,
            display: true,
        },
        {
            name: "Del Appt",
            value: load.deliveryAppointmentDetails,
            display: true,
        },
        {
            name: "Trailer Type",
            value: load.trailerType,
            display: true,
        },
        {
            name: "Door Type",
            value: load.doorType,
            display: true,
        },
        {
            name: "Load Type",
            value: load.type,
            display: true,
        },
        {
            name: "Temp Requirements",
            value: load.tempRequirement,
            display: true,
        },
        {
            name: "Load Weight",
            value: load.weight + ' lbs.',
            display: true,
        },
        {
            name: "Lumper Charge*",
            value: load.destinationLumper,
            display: true,
        },
    ];
    const navigation = useNavigation();

    // Load Information
    const renderLoadAttributes = () => {
        return (
            <>
                {loadAttributes.map((item, index) => (
                    <LoadAttribute
                        {...item}
                        key={index}
                        isFirstItem={index == 0 ? true : false}
                        isLastItem={index + 1 == loadAttributes.length ? true : false}
                    />
                ))}
                <Text style={styles.disclaimer} appearance="hint" category="p2">*Driver is responsible for paying all lumper fees. Sleek Fleet to reimburse on load payment.</Text>
            </>
        )
    }

    // Rate Confirmation
    const renderRateConfirmation = () => {
        return(
            <RateConfirmation sleekFleetLoadId={load.sleekFleetLoadId} />
        )
    }

    // Load Documents
    const renderLoadDocuments = () => {
        if(load.documents.length > 0) {
            return (
                load.documents.map((item, index) => (
                    <LoadDocument
                        {...item}
                        key={index}
                        isFirstItem={index == 0 ? true : false}
                        isLastItem={index + 1 == load.documents.length ? true : false}
                        setActiveModal={props.setActiveModal}
                        setModalVisible={props.setModalVisible}
                        loadDocumentDetails={item}
                        setLoadDocumentDetails={props.setLoadDocumentDetails}
                    />
                ))
            )
        } else {
            return (
                <EmptyDataSet primaryMessage="No Load Documents" />
            )
        }
    }

    // Load Notes
    const renderLoadNotes = () => {
        if(load.driverLoadNotes.length > 0) {
            return (
                load.driverLoadNotes.map((item, index) => (
                    <LoadNote
                        {...item}
                        key={index}
                        isFirstItem={index == 0 ? true : false}
                        isLastItem={index + 1 == load.driverLoadNotes.length ? true : false}
                    />
                ))
            )
        } else {
            return (
                <EmptyDataSet primaryMessage="No Load Notes" />
            )
        }
    }

    // Accessorials
    const renderAccessorials = () => {
        if(load.accessorialItems.length > 0) {
            return (
                load.accessorialItems.map((item, index) => (
                    <Accessorial
                        {...item}
                        key={index}
                        isFirstItem={index == 0 ? true : false}
                        isLastItem={index + 1 == load.accessorialItems.length ? true : false}
                    />
                ))
            )
        } else {
            return (
                <EmptyDataSet primaryMessage="No Accessorials" />
            )
        }
    }

    // Accessorial Schedule
    const renderAccessorialHeaderRight = () => {

        const showModal = (modalName) => {
            props.setActiveModal(modalName);
            props.setModalVisible(true);
        }

        if(load.accessorialSchedule.length > 0) {
            return (
                <Pressable onPress={() => showModal('AccessorialSchedule')}>
                    <Text style={{textAlign: "right", fontSize: 14}} status="primary" category="s2">View Schedule</Text>
                </Pressable>
            )
        } else {
            return null;
        }
    }

    // Shipper Messages
    const renderShipperMessages = () => {
        if(load.shipperMessages.length > 0) {
            return (
                load.shipperMessages.map((item, index) => (
                    <ShipperMessage
                        {...item}
                        key={index}
                        isFirstItem={index == 0 ? true : false}
                        isLastItem={index + 1 == load.shipperMessages.length ? true : false}
                    />
                ))
            )
        } else {
            return (
                <EmptyDataSet primaryMessage="No Shipper Messages" />
            )
        }
    }

    // Claims
    const renderClaims = () => {
        if(load.claims.length > 0) {
            return (
                load.claims.map((item, index) => (
                    <Claim
                        {...item}
                        key={index}
                        isFirstItem={index == 0 ? true : false}
                        isLastItem={index + 1 == load.claims.length ? true : false}
                        setActiveModal={props.setActiveModal}
                        setModalVisible={props.setModalVisible}
                        claimDetails={item}
                        setClaimDetails={props.setClaimDetails}
                    />
                ))
            )
        } else {
            return (
                <EmptyDataSet primaryMessage="No Claims" />
            )
        }
    }

    // Payments
    const renderPayments = () => {
        if(load.payments.length > 0) {
            return (
                load.payments.map((item, index) => (
                    <Payment
                        {...item}
                        key={index}
                        isFirstItem={index == 0 ? true : false}
                        isLastItem={index + 1 == load.payments.length ? true : false}
                    />
                ))
            )
        } else {
            return (
                <EmptyDataSet primaryMessage="No Payments" />
            )
        }
    }

    React.useLayoutEffect(() => {
        if(helper.isWeb()) {
            navigation.setOptions({
                title: 'Load Details'
            })
        }
    }, [navigation]);

    return (
        <ScrollView contentContainerStyle={styles.scrollView}>
            <Layout style={styles.layout} level="3">
                {load.platedVanRequired &&
                    <View style={styles.warningView}>
                        <Text category="s2" status="danger">Please note that this load requires a plated van!</Text>
                    </View>
                }
                <Section isFirstItem={true} title="Load Information" titleBadge={load.tonu == '1' ? 'TONU' : null} subtitle={'Updated on ' + load.lastUpdated} renderBody={renderLoadAttributes()} />
                {isCarrierLoad &&
                    <>
                        {helper.checkForRoles(['manager']) &&
                            <Section title="Rate Confirmation" renderBody={renderRateConfirmation()} />
                        }
                        <Section title={'Load Documents ('+load.documents.length+')'} renderBody={renderLoadDocuments()} />
                    </>
                }
                <Section title={'Load Notes ('+load.driverLoadNotes.length+')'} renderBody={renderLoadNotes()} />
                <Section isLastItem={isCarrierLoad ? false : true } title={'Accessorials ('+load.accessorialItems.length+')'} headerRight={renderAccessorialHeaderRight()} renderBody={renderAccessorials()} />
                {isCarrierLoad &&
                    <>
                        <Section title={'Shipper Messages ('+load.shipperMessages.length+')'} renderBody={renderShipperMessages()} />
                        <Section isLastItem={helper.checkForRoles(['manager']) ? false : true} title={'Claims ('+load.claims.length+')'} renderBody={renderClaims()} />
                        {helper.checkForRoles(['manager']) &&
                            <Section isLastItem={true} title={'Payments ('+load.payments.length+')'} renderBody={renderPayments()} />
                        }
                    </>
                }
            </Layout>
        </ScrollView>
    )
}

const themedStyles = StyleService.create({
    scrollView: {
        flexGrow: 1,
    },
    layout: {
        flex: 1,
        marginVertical: 5
    },
    disclaimer: {
        paddingTop: 10,
    },
    warningView: {
        marginHorizontal: 10,
        padding: 10,
        borderWidth: 1,
        borderColor: "color-danger-500",
        marginVertical: 10,
        backgroundColor: "color-danger-transparent-200",
        borderRadius: 4,
    },
});
