import React, {useState, useEffect, useContext } from 'react';
import { Keyboard, View } from 'react-native';
import { AuthContext } from 'components/context';
import { Autocomplete, AutocompleteItem, StyleService, Text, useStyleSheet } from '@ui-kitten/components';
import * as api from 'api/SleekTechnologiesApi';
import * as helper from 'utility/Helper';

export const AutocompleteField = (props) => {

    const {
        field: { name, onBlur, onChange, values },
        form: { errors, touched, setFieldTouched, setFieldValue },
        ...inputProps
    } = props;

    const hasError = errors[name] && touched[name];
    const styles = useStyleSheet(themedStyles);
    const { signOut } = useContext(AuthContext);

    const filter = (item, query) => item.title.toLowerCase().includes(query.toLowerCase());

    const [value, setValue] = useState(null);
    const [data, setData] = useState(props.options);
    
    const onSelect = (index) => {
        setValue(data[index].title);
        setFieldValue(name, data[index].title);
        Keyboard.dismiss();
    };

    function applySuggestions(matchingItems) {
        let suggestions = [];
        var i = 0, len = matchingItems.length;
        while (i < len) {
            suggestions.push({ title: matchingItems[i] });
            i++
        }
        setData(suggestions);
    }

    const onChangeText = (query) => {
        setValue(query);
        if(query.length > 2) {
            switch(props.autoCompleteLookup) {
                case 'location':
                    api.locationAutoComplete(query)
                        .then(function(response){
                            if(helper.hasValue(response.data.msg) && response.data.msg == "Token Not Found or Expired") {
                                signOut();
                            }
                            applySuggestions(response.data.suggestions);
                        });
                    break;
                case 'loadNumber':
                    api.loadNumberAutoComplete(query)
                        .then(function(response){
                            if(helper.hasValue(response.data.msg) && response.data.msg == "Token Not Found or Expired") {
                                signOut();
                            }
                            applySuggestions(response.data.suggestions);
                        });
                    break;
                case 'bookNowLoadNumber':
                    api.bookNowLoadNumberAutoComplete(query)
                        .then(function(response){
                            if(helper.hasValue(response.data.msg) && response.data.msg == "Token Not Found or Expired") {
                                signOut();
                            }
                            applySuggestions(response.data.suggestions);
                        });
                    break;
                case 'transactionId':
                    api.transactionIdAutoComplete(query)
                        .then(function(response){
                            if(helper.hasValue(response.data.msg) && response.data.msg == "Token Not Found or Expired") {
                                signOut();
                            }
                            applySuggestions(response.data.suggestions);
                        });
                    break;
                default:
                    //do nothing
            }
        }
    };

    const renderOption = (item, index) => (
        <AutocompleteItem
            key={index}
            title={item.title}
        />
    );

    return (
        <>
            <Autocomplete
                style={styles.autocompleteInput}
                size="large"
                status="basic"
                placeholder='Place your Text'
                //autoFocus
                value={value}
                onSelect={onSelect}
                onChangeText={onChangeText}
                {...inputProps}
            >
                {data.map(renderOption)}
            </Autocomplete>
            {hasError &&
                <View style={styles.errorView}>
                    <Text category="p2" status="danger">{errors[name]}</Text>
                </View>
            }
        </>
    );

}

const themedStyles = StyleService.create({
    autocompleteInput: {
        marginBottom: 10,
    },
    errorView: {
        marginTop: -3,
        marginLeft: 10,
        marginBottom: 10,
    },
    errorInput: {
        borderColor: "color-danger-500"
    }
});
