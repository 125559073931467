import React, {useContext, useState} from 'react';
import { Dimensions, Image, ImageBackground, SafeAreaView, View } from 'react-native';
import { createDrawerNavigator } from '@react-navigation/drawer';
import { useLinkTo } from '@react-navigation/native';
import { DrawerItem } from '@react-navigation/drawer';
import { CustomModal } from 'components/layout';
import { AuthContext, AppDataContext } from 'components/context';
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome';
import * as terms from 'screens/terms/Terms';
import * as helper from 'utility/Helper';
import * as Linking from 'expo-linking';
import * as AppConstants from 'config/Constants';
import Constants from 'expo-constants';
import getEnvVars from 'root/environment';
import {
    Avatar,
    Button,
    Divider,
    Drawer,
    DrawerElement,
    IndexPath,
    Layout,
    StyleService,
    Text,
    useStyleSheet,
} from '@ui-kitten/components';
import {
    NotificationsStackNavigator,
    LoadDetailsStackNavigator,
    DashboardStackNavigator,
    MyProfileStackNavigator,
    CarrierProfileStackNavigator,
    ManageUsersStackNavigator,
    PreferredLocationsStackNavigator,
    SupportStackNavigator,
    FindALoadStackNavigator,
    BookedLoadsStackNavigator,
    DriversStackNavigator,
    ReportsStackNavigator,
    ErrorStackNavigator,
} from 'navigation/StackNavigator';

const windowWidth = Dimensions.get('window').width;
const windowHeight = Dimensions.get('window').height;

const DrawerContainer = createDrawerNavigator();

const DrawerHeader = () => {

    const styles = useStyleSheet(themedStyles);
    const { appData } = useContext(AppDataContext);
    const { sleekApiUrl } = getEnvVars();

    return (
        <View style={styles.headerView}>
            <View style={styles.headerImageView}>
                <Image style={styles.headerImage} source={require('../../assets/sleek-fleet-logo.png')} resizeMode="contain" />
            </View>
        </View>
    )
}

const DrawerFooter = () => {

    const styles = useStyleSheet(themedStyles);
    const [modalVisible, setModalVisible] = useState(false);

    return (
        <View>
            <View style={styles.footer}>
                <Text style={styles.appVersion}>Sleek Fleet App Version {Constants.expoConfig?.version}</Text>
                <Text style={styles.terms} onPress={() => setModalVisible(true)}>Terms & Conditions</Text>
                <Text style={styles.privacy} onPress={() => Linking.openURL('https://www.sleek-technologies.com/privacy-policy')}>Privacy Policy</Text>
            </View>
            <CustomModal title="Terms & Conditions" modalVisible={modalVisible} setModalVisible={setModalVisible}>
                {terms.getTerms()}
            </CustomModal>
        </View>
    )
}

const renderPrimaryDrawerItem = (activeRoute, activeRouteCheck, label, path, icon) => {

    const styles = useStyleSheet(themedStyles);
    const linkTo = useLinkTo();

    return (
        <DrawerItem
            style={styles.primaryDrawerItemView}
            labelStyle={activeRoute == activeRouteCheck ? styles.primaryActiveDrawerItemText : styles.primaryDrawerItemText}
            activeTintColor={styles.activeText.color}
            inactiveTintColor={styles.inactiveText.color}
            activeBackgroundColor={styles.activeBackground.color}
            inactiveBackgroundColor={styles.inactiveBackground.color}
            label={label}
            focused={activeRoute == activeRouteCheck ? true : false}
            onPress={() => linkTo(path)}
            icon={() =>
                <FontAwesomeIcon
                    style={styles.icon}
                    icon={['fad', icon]}
                    size={styles.icon.size}
                    color={activeRoute == activeRouteCheck ? styles.activeIcon.color : styles.inactiveIcon.color}
                />
            }
        />
    )
}

const renderSecondaryDrawerItem = (activeRoute, activeRouteCheck, label, path, icon) => {

    const styles = useStyleSheet(themedStyles);
    const linkTo = useLinkTo();

    return (
        <DrawerItem
            style={styles.secondaryDrawerItemView}
            labelStyle={activeRoute == activeRouteCheck ? styles.secondaryActiveDrawerItemText : styles.secondaryDrawerItemText}
            activeTintColor={styles.activeText.color}
            inactiveTintColor={styles.inactiveText.color}
            activeBackgroundColor={styles.activeBackground.color}
            inactiveBackgroundColor={styles.inactiveBackground.color}
            label={label}
            focused={activeRoute == activeRouteCheck ? true : false}
            onPress={() => linkTo(path)}
            icon={() =>
                <FontAwesomeIcon
                    style={styles.secondaryIcon}
                    icon={['fad', icon]}
                    size={styles.secondaryIcon.size}
                    color={activeRoute == activeRouteCheck ? styles.activeIcon.color : styles.inactiveIcon.color}
                />
            }
        />
    )
}

const renderLogoutDrawerItem = () => {

    const styles = useStyleSheet(themedStyles);
    const { signOut } = useContext(AuthContext);

    return (
        <DrawerItem
            style={styles.secondaryDrawerItemView}
            labelStyle={styles.secondaryDrawerItemText}
            activeTintColor={styles.activeText.color}
            inactiveTintColor={styles.inactiveText.color}
            activeBackgroundColor={styles.activeBackground.color}
            inactiveBackgroundColor={styles.inactiveBackground.color}
            label="Logout"
            onPress={() => signOut()}
            icon={() =>
                <FontAwesomeIcon
                    style={styles.secondaryIcon}
                    icon={['fad', 'sign-out']}
                    size={styles.secondaryIcon.size}
                    color={styles.inactiveIcon.color}
                />
            }
        />
    )
}

const DrawerContent = ({ navigation, state }) => {

    const styles = useStyleSheet(themedStyles);
    const activeRoute = state.index;

    let routes;
    let getDrawerItems;
    let getMobileDrawerItems;

    if(helper.checkForRoles(['manager']) && helper.checkForRoles(['admin'])) {
        routes = {
            dashboard: 0,
            findALoad: 1,
            bookedLoads: 2,
            drivers: 3,
            reports: 4,
            myProfile: 5,
            carrierProfile: 6,
            manageUsers: 7,
            preferredLocations: 8,
            notifications: 9,
            support: 10
        }
        getDrawerItems = () => {
            return (
                <>
                    {renderPrimaryDrawerItem(activeRoute, routes.dashboard, 'Dashboard', '/dashboard', 'home')}
                    {renderPrimaryDrawerItem(activeRoute, routes.findALoad, 'Find A Load', '/find-a-load', 'barcode-read')}
                    {renderPrimaryDrawerItem(activeRoute, routes.bookedLoads, 'Booked Loads', '/booked-loads', 'shipping-fast')}
                    {renderPrimaryDrawerItem(activeRoute, routes.drivers, 'Drivers', '/drivers', 'steering-wheel')}
                    {renderPrimaryDrawerItem(activeRoute, routes.reports, 'Reports', '/reports', 'chart-bar')}
                </>
            )
        }
        getMobileDrawerItems = () => {
            return (
                <>
                    {renderSecondaryDrawerItem(activeRoute, routes.myProfile, 'My Profile', '/my-profile', 'user-cog')}
                    {renderSecondaryDrawerItem(activeRoute, routes.carrierProfile, 'Carrier Profile', '/carrier-profile', 'truck')}
                    {renderSecondaryDrawerItem(activeRoute, routes.manageUsers, 'Manage Users', '/manage-users', 'users-cog')}
                    {renderSecondaryDrawerItem(activeRoute, routes.preferredLocations, 'Preferred Locations', '/preferred-locations', 'star')}
                    {renderSecondaryDrawerItem(activeRoute, routes.notifications, 'Notifications', '/notifications', 'bell')}
                    {renderSecondaryDrawerItem(activeRoute, routes.support, 'Support', '/support', 'question-circle')}
                    {renderLogoutDrawerItem()}
                </>
            )
        }
    } else if(helper.checkForRoles(['manager'])) {
        routes = {
            dashboard: 0,
            findALoad: 1,
            bookedLoads: 2,
            drivers: 3,
            reports: 4,
            myProfile: 5,
            carrierProfile: null,
            manageUsers: 6,
            preferredLocations: 7,
            notifications: 8,
            support: 9
        }
        getDrawerItems = () => {
            return (
                <>
                    {renderPrimaryDrawerItem(activeRoute, routes.dashboard, 'Dashboard', '/dashboard', 'home')}
                    {renderPrimaryDrawerItem(activeRoute, routes.findALoad, 'Find A Load', '/find-a-load', 'barcode-read')}
                    {renderPrimaryDrawerItem(activeRoute, routes.bookedLoads, 'Booked Loads', '/booked-loads', 'shipping-fast')}
                    {renderPrimaryDrawerItem(activeRoute, routes.drivers, 'Drivers', '/drivers', 'steering-wheel')}
                    {renderPrimaryDrawerItem(activeRoute, routes.reports, 'Reports', '/reports', 'chart-bar')}
                </>
            )
        }
        getMobileDrawerItems = () => {
            return (
                <>
                    {renderSecondaryDrawerItem(activeRoute, routes.myProfile, 'My Profile', '/my-profile', 'user-cog')}
                    {renderSecondaryDrawerItem(activeRoute, routes.manageUsers, 'Manage Users', '/manage-users', 'users-cog')}
                    {renderSecondaryDrawerItem(activeRoute, routes.preferredLocations, 'Preferred Locations', '/preferred-locations', 'star')}
                    {renderSecondaryDrawerItem(activeRoute, routes.notifications, 'Notifications', '/notifications', 'bell')}
                    {renderSecondaryDrawerItem(activeRoute, routes.support, 'Support', '/support', 'question-circle')}
                    {renderLogoutDrawerItem()}
                </>
            )
        }
    } else if(helper.checkForRoles(['admin'])) {
        routes = {
            dashboard: 0,
            findALoad: null,
            bookedLoads: 1,
            drivers: 2,
            reports: 3,
            myProfile: 4,
            carrierProfile: 5,
            manageUsers: 6,
            preferredLocations: null,
            notifications: 7,
            support: 8
        }
        getDrawerItems = () => {
            return (
                <>
                    {renderPrimaryDrawerItem(activeRoute, routes.dashboard, 'Dashboard', '/dashboard', 'home')}
                    {renderPrimaryDrawerItem(activeRoute, routes.bookedLoads, 'Booked Loads', '/booked-loads', 'shipping-fast')}
                    {renderPrimaryDrawerItem(activeRoute, routes.drivers, 'Drivers', '/drivers', 'steering-wheel')}
                    {renderPrimaryDrawerItem(activeRoute, routes.reports, 'Reports', '/reports', 'chart-bar')}
                </>
            )
        }
        getMobileDrawerItems = () => {
            return (
                <>
                    {renderSecondaryDrawerItem(activeRoute, routes.myProfile, 'My Profile', '/my-profile', 'user-cog')}
                    {renderSecondaryDrawerItem(activeRoute, routes.carrierProfile, 'Carrier Profile', '/carrier-profile', 'truck')}
                    {renderSecondaryDrawerItem(activeRoute, routes.manageUsers, 'Manage Users', '/manage-users', 'users-cog')}
                    {renderSecondaryDrawerItem(activeRoute, routes.notifications, 'Notifications', '/notifications', 'bell')}
                    {renderSecondaryDrawerItem(activeRoute, routes.support, 'Support', '/support', 'question-circle')}
                    {renderLogoutDrawerItem()}
                </>
            )
        }
    } else {
        routes = {
            dashboard: 0,
            findALoad: null,
            bookedLoads: 1,
            drivers: null,
            reports: 2,
            myProfile: 3,
            carrierProfile: null,
            manageUsers: null,
            preferredLocations: null,
            notifications: 4,
            support: 5
        }
        getDrawerItems = () => {
            return (
                <>
                    {renderPrimaryDrawerItem(activeRoute, routes.dashboard, 'Dashboard', '/dashboard', 'home')}
                    {renderPrimaryDrawerItem(activeRoute, routes.bookedLoads, 'Booked Loads', '/booked-loads', 'shipping-fast')}
                    {renderPrimaryDrawerItem(activeRoute, routes.reports, 'Reports', '/reports', 'chart-bar')}
                </>
            )
        }
        getMobileDrawerItems = () => {
            return (
                <>
                    {renderSecondaryDrawerItem(activeRoute, routes.myProfile, 'My Profile', '/my-profile', 'user-cog')}
                    {renderSecondaryDrawerItem(activeRoute, routes.notifications, 'Notifications', '/notifications', 'bell')}
                    {renderSecondaryDrawerItem(activeRoute, routes.support, 'Support', '/support', 'question-circle')}
                    {renderLogoutDrawerItem()}
                </>
            )
        }
    }

    return (
        <ImageBackground style={styles.drawerBackground} source={require('../../assets/steel-texture.jpg')} resizeMode="repeat">
            <Drawer
                style={{backgroundColor: "transparent"}}
                header={<DrawerHeader />}
                footer={<DrawerFooter />}
                selectedIndex={new IndexPath(state.index)}
            >
                <View style={styles.primaryMenuView}>
                    {getDrawerItems()}
                </View>
                {helper.isMobileWeb() &&
                    <View style={styles.secondaryMenuView}>
                        {getMobileDrawerItems()}
                    </View>
                }
            </Drawer>
        </ImageBackground>
    )
}

const DrawerLayout = () => {
    return (
        <DrawerContainer.Navigator
            drawerContent={props => <DrawerContent {...props}/>}
            screenOptions={{
                headerShown: false,
                drawerType: helper.isDesktopWeb() ? 'permanent' : 'front',
                drawerStyle: {
                    width: 250,
                    borderRightWidth: 0,
                }
            }}
            backBehavior="history"
        >
            <DrawerContainer.Screen name="DashboardStackNavigator" component={DashboardStackNavigator} />
            {helper.checkForRoles(['manager']) &&
                <DrawerContainer.Screen name="FindALoadStackNavigator" component={FindALoadStackNavigator} />
            }
            <DrawerContainer.Screen name="BookedLoadsStackNavigator" component={BookedLoadsStackNavigator} />
            {helper.checkForRoles(['admin', 'manager']) &&
                <DrawerContainer.Screen name="DriversStackNavigator" component={DriversStackNavigator} />
            }
            <DrawerContainer.Screen name="ReportsStackNavigator" component={ReportsStackNavigator} />
            <DrawerContainer.Screen name="MyProfileStackNavigator" component={MyProfileStackNavigator} />
            {helper.checkForRoles(['admin']) &&
                <DrawerContainer.Screen name="CarrierProfileStackNavigator" component={CarrierProfileStackNavigator} />
            }
            {helper.checkForRoles(['admin', 'manager']) &&
                <DrawerContainer.Screen name="ManageUsersStackNavigator" component={ManageUsersStackNavigator} />
            }
            {helper.checkForRoles(['manager']) &&
                <DrawerContainer.Screen name="PreferredLocationsStackNavigator" component={PreferredLocationsStackNavigator} />
            }
            <DrawerContainer.Screen name="NotificationsStackNavigator" component={NotificationsStackNavigator} />
            <DrawerContainer.Screen name="SupportStackNavigator" component={SupportStackNavigator} />
            <DrawerContainer.Screen name="LoadDetailsStackNavigator" component={LoadDetailsStackNavigator} />
            <DrawerContainer.Screen name="ErrorStackNavigator" component={ErrorStackNavigator} />
        </DrawerContainer.Navigator>
    )
}

/*---------------------------------------------------------
-- Export Drawer Navigator
---------------------------------------------------------*/
export const DrawerNavigator = () => {
    return (
        <DrawerLayout />
    )
}

/*---------------------------------------------------------
-- Drawer Navigator Styling
---------------------------------------------------------*/
const themedStyles = StyleService.create({
    //drawer background
    drawerBackground: {
        height: "100%"
    },
    // header
    headerBackground: {
        height: 100,
    },
    headerView: {
        height: 100,
        paddingHorizontal: 16,
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: "rgba(0,0,0,.35)",
        borderBottomWidth: 1,
        borderBottomColor: "rgba(0,0,0,.40)",
    },
    headerImageView: {
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center"
    },
    headerImage: {
        width: 199,
        height: 58
    },
    // drawer item colors
    activeText: {
        color: "color-primary-500"
    },
    inactiveText: {
        color: "color-basic-500"
    },
    icon: {
        size: 20
    },
    secondaryIcon: {
        size: 16
    },
    activeIcon: {
        color: "color-primary-500",
    },
    inactiveIcon: {
        color: "color-basic-500",
    },
    activeBackground: {
        color: "rgba(0,0,0,.20)"
    },
    inactiveBackground: {
        color: "transparent"
    },
    // primary menu
    primaryMenuView: {
        marginTop: 7,
    },
    primaryDrawerItemView: {
        marginBottom: 0,
        cursor: "pointer",
    },
    primaryDrawerItemText: {
        marginLeft: -20,
    },
    primaryActiveDrawerItemText: {
        marginLeft: -20,
        fontWeight: 700
    },
    // secondary menu
    secondaryMenuView: {
        marginTop: 10,
    },
    secondaryDrawerItemView: {
        marginBottom: 0,
        cursor: "pointer",
    },
    secondaryDrawerItemText: {
        marginLeft: -20,
        fontSize: 12
    },
    secondaryActiveDrawerItemText: {
        marginLeft: -20,
        fontSize: 12,
        fontWeight: 700
    },
    // footer
    footer: {
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        paddingTop: 16,
        paddingBottom: 5,
        backgroundColor: "rgba(0,0,0,.35)",
        borderTopWidth: 1,
        borderTopColor: "rgba(0,0,0,.40)"
    },
    appVersion: {
        color: "text-hint-color",
        fontSize: 14,
    },
    terms: {
        color: "color-primary-500",
        fontSize: 13,
        paddingTop: 10
    },
    privacy: {
        color: "color-primary-500",
        fontSize: 13,
        paddingVertical: 8
    },
});
