import React, { useContext, useState, useEffect, useCallback, useRef } from 'react';
import { Dimensions, ScrollView, View } from 'react-native';
import { useFocusEffect, useNavigation } from '@react-navigation/native';
import { Button, Divider, Layout, List, ListItem, StyleService, Text, useStyleSheet } from '@ui-kitten/components';
import { AuthContext } from 'components/context';
import { EmptyDataSet, Loader } from 'components/utility';
import { CustomModal, Header, Section } from 'components/layout';
import { BookNowHistoryFilters } from 'screens/reports/modals';
import { BookNowSummary } from 'components/loads';
import * as helper from 'utility/Helper';
import * as api from 'api/SleekTechnologiesApi';
import * as Constants from 'config/Constants';
import moment from 'moment';

export default function BookNowHistoryReport(props) {

    const styles = useStyleSheet(themedStyles);
    const { signOut } = useContext(AuthContext);
    const [bids, setBids] = useState([]);
    const [isLoading, setLoading] = useState(false);
    const [isRefreshing, setRefreshing] = useState(false);
    const [recordCount, setRecordCount] = useState(null);
    const loadMore = useRef(false);
    const navigation = useNavigation();

    // format from / to date
    const dateFrom = moment().format("MM/DD/YYYY");
    const dateTo = moment().format("MM/DD/YYYY");

    // book now history filters
    const defaultBookNowHistoryFilters = {
        loadNumber: "",
        shipper: "all",
        loadType: "all",
        bookNowStatus: "all",
        origin: "all",
        originRadius: 100,
        destination: "all",
        destinationRadius: 100,
        deliveryDate: 30,
        deliveryDateFrom: dateFrom,
        deliveryDateTo: dateTo,
        sortBy: "book now date",
        offset: 0
    };
    const defaultBookNowHistoryFilterDisplayValues = {
        loadNumber: "",
        shipper: helper.getShipperLoadList('string'),
        loadType: Constants.LOAD_TYPE_VALUES,
        bookNowStatus: "all",
        origin: "",
        originRadius: 100,
        destination: "",
        destinationRadius: 100,
        deliveryDate: 30,
        deliveryDateFrom: dateFrom,
        deliveryDateTo: dateTo,
        sortBy: "book now date",
        offset: 0
    };
    const [bookNowHistoryFilters, setBookNowHistoryFilters] = useState(defaultBookNowHistoryFilters);
    const [bookNowHistoryFilterDisplayValues, setBookNowHistoryFilterDisplayValues] = useState(defaultBookNowHistoryFilterDisplayValues);

    const windowWidth = Dimensions.get('window').width;
    const windowHeight = Dimensions.get('window').height;
    const bodyHeight = windowHeight - 170;

    const getBookNowHistoryData = () => {
        api.getBookNowHistoryData(bookNowHistoryFilters)
            .then(function(response) {
                if(helper.hasValue(response.data.msg) && response.data.msg == "Token Not Found or Expired") {
                    signOut();
                }
                if(response.data.result == "success") {
                    if(loadMore.current) {
                        setBids(bids => {
                            return [...bids, ...response.data.bids]
                        });
                    } else {
                        setBids(response.data.bids);
                    }
                    setRecordCount(response.data.recordCount);
                    props.setLastLoadTime(Date.now()/1000);
                    setLoading(false);
                    setRefreshing(false);
                    loadMore.current = false;
                } else if(response.data.result == "error") {
                    throw {
                        type: "custom",
                        message: response.data.msg
                    }
                }
            })
            .catch(function(error){
                setLoading(false);
                setRefreshing(false);
                helper.handleCatchError(error);
            });
    }

    useFocusEffect(
        useCallback(() => {
            getBookNowHistoryData();
        }, [bookNowHistoryFilters])
    );

    const renderBidSummary = ({ item, index }) => {
        return (
            <ListItem style={styles.listItem} children={<BookNowSummary {...item} />} />
        )
    }

    const renderListFooter = () => {
        if(isLoading && !isRefreshing) {
            return (
                <View style={styles.loader}>
                    <Loader loadingText="Getting Book Now History"/>
                </View>
            )
        } else {
            return null;
        }
    }

    const renderListEmptyComponent = () => {
        if(recordCount == 0 && !isLoading) {
            return (
                <EmptyDataSet primaryMessage="No Results Found" secondaryMessage="Use Filters to Expand your Search" />
            )
        } else {
            return null;
        }
    }

    const handleLoadMore = () => {
        if(bids.length < recordCount && !isLoading) {
            loadMore.current = true;
            setBookNowHistoryFilters({
                ...bookNowHistoryFilters,
                offset: bids.length
            });
        }
    }

    const refreshData = () => {
        setRefreshing(true);
        setBids([]);
        setRecordCount(null);
        setBookNowHistoryFilters({
            ...bookNowHistoryFilters,
            offset: 0
        });
    }

    const renderBookNowHistoryList = () => {
        return (
            <List
                style={styles.listView}
                contentContainerStyle={[styles.listContainer, { justifyContent: bids.length > 0 ? "flex-start" : "center"}]}
                data={bids}
                renderItem={renderBidSummary}
                ItemSeparatorComponent={Divider}
                keyExtractor={(item, index) => index.toString()}
                ListFooterComponent={renderListFooter}
                ListEmptyComponent={renderListEmptyComponent}
                onEndReached={handleLoadMore}
                onEndReachedThreshold={Constants.END_OF_LIST_THRESHOLD}
                onRefresh={() => refreshData()}
                refreshing={isRefreshing}
            />
        )
    }

    const renderBookNowHistoryFilters = () => {
        return (
            <BookNowHistoryFilters
                setModalVisible={props.setModalVisible}
                setLoading={setLoading}
                setBids={setBids}
                defaultBookNowHistoryFilters={defaultBookNowHistoryFilters}
                defaultBookNowHistoryFilterDisplayValues={defaultBookNowHistoryFilterDisplayValues}
                bookNowHistoryFilters={bookNowHistoryFilters}
                setBookNowHistoryFilters={setBookNowHistoryFilters}
                bookNowHistoryFilterDisplayValues={bookNowHistoryFilterDisplayValues}
                setBookNowHistoryFilterDisplayValues={setBookNowHistoryFilterDisplayValues}
            />
        )
    }

    React.useLayoutEffect(() => {
        if(helper.isWeb()) {
            navigation.setOptions({
                title: 'Reports - Book Now History'
            })
        }
    }, [navigation]);

    return (
        helper.isWeb() ? (
            <>
                <Layout style={styles.layout} level="3">
                    {helper.isDesktopWeb() &&
                        <ScrollView>
                            <View style={styles.main}>
                                <View style={styles.columnOne}>
                                    <Section title="Filters" noBodyPadding={false} renderBody={renderBookNowHistoryFilters()} />
                                </View>
                                <View style={styles.columnTwo}>
                                    <Section title="Book Now History" subtitle={recordCount != null ? recordCount + ' Matching Results' : ''} noBodyPadding={bids.length > 0 ? true : false} bodyHeight={bodyHeight} renderBody={renderBookNowHistoryList()} />
                                </View>
                            </View>
                        </ScrollView>
                    }
                    {helper.isMobileWeb() &&
                        <>
                            {renderBookNowHistoryList()}
                        </>
                    }
                </Layout>
                {helper.isMobileWeb() &&
                    <CustomModal title="Filter Book Now History" modalVisible={props.modalVisible} setModalVisible={props.setModalVisible}>
                        {renderBookNowHistoryFilters()}
                    </CustomModal>
                }
            </>
        ) : (
            <Layout style={styles.layout} level="3">
                {renderBookNowHistoryList()}
                <CustomModal title="Filter Book Now History" modalVisible={props.modalVisible} setModalVisible={props.setModalVisible}>
                    {renderBookNowHistoryFilters()}
                </CustomModal>
            </Layout>
        )
    )

}

const themedStyles = StyleService.create({
    layout: {
        flex: 1,
        marginTop: helper.isDesktopWeb() ? 5 : 0
    },
    loader: {
        marginTop: 10,
        paddingVertical: 20,
    },
    listContainer: {
        flexGrow: 1
    },
    listView: {
        borderBottomLeftRadius: helper.isDesktopWeb() ? 6 : 0,
        borderBottomRightRadius: helper.isDesktopWeb() ? 6 : 0
    },
    listItem: {
        paddingHorizontal: 0,
        paddingVertical: 0,
    },
    main: {
        flexDirection: "row",
    },
    columnOne: {
        flex: 3,
    },
    columnTwo: {
        flex: 6
    }
});
