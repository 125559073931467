import React, { useContext, useState } from 'react';
import { Pressable, ScrollView, View, Image } from 'react-native';
import { SafeAreaView } from 'react-native-safe-area-context';
import { Button, Layout, StyleService, Text, useStyleSheet } from '@ui-kitten/components';
import { AppDataContext } from 'components/context';
import { CustomModal, ItemAttribute, Section, Header } from 'components/layout';
import { CarrierW9 } from 'screens/carrier-profile/modals';
import { Badge } from 'components/utility';
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome';
import * as Constants from 'config/Constants';
import * as helper from 'utility/Helper';
import getEnvVars from 'root/environment';

export default function CarrierProfile({ navigation }) {

    const styles = useStyleSheet(themedStyles);
    const { sleekApiUrl } = getEnvVars();
    const { appData } = useContext(AppDataContext);
    const [modalVisible, setModalVisible] = useState(false);
    const [modalError, setModalError] = useState(false);

    const carrierData = appData.carrierData;
    const addressString = (carrierData.address != null ? carrierData.address+"\n" : '')+(carrierData.address2 != null ? carrierData.address2+"\n" : "")+(carrierData.city != null ? carrierData.city+", " : "")+(carrierData.state != null ? carrierData.state+" " : "")+(carrierData.zip != null ? carrierData.zip : "");
    const billingAddressString = (carrierData.billingName != null ? carrierData.billingName+"\n" : '')+(carrierData.billingAddress != null ? carrierData.billingAddress+"\n" : '')+(carrierData.billingAddress2 != null ? carrierData.billingAddress2+"\n" : "")+(carrierData.billingCity != null ? carrierData.billingCity+", " : "")+(carrierData.billingState != null ? carrierData.billingState+" " : "")+(carrierData.billingZip != null ? carrierData.billingZip : "");

    const carrierAttributes = [
        {
            name: "Address",
            value: addressString,
            display: true,
        },
        {
            name: "Phone",
            value: carrierData.phone,
            display: true,
            link: `tel:${carrierData.phone}`
        },
        {
            name: "Email",
            value: carrierData.email,
            valueFlex: 2,
            display: true,
            link: `mailto:${carrierData.email}`
        },
    ];
    const paymentAttributes = [
        {
            name: "Payment Method",
            value: carrierData.preferredPaymentMethod,
            display: true,
        },
        {
            name: "PayPal Email",
            valueFlex: 2,
            value: (carrierData.paypalEmail != null ? carrierData.paypalEmail : ''),
            display: true,
            link: (carrierData.paypalEmail != null ? `mailto:${carrierData.paypalEmail}` : '')
        },
        {
            name: "Billing Info",
            value: billingAddressString,
            display: true,
        }
    ];

    const breadcrumbs = [
        {
            label: 'Dashboard',
            path: '/dashboard'
        },
        {
            label: 'Carrier Profile',
            path: ''
        }
    ]

    const renderAttributes = (attributes) => {
        return (
            attributes.map((item, index) => (
                <ItemAttribute
                    {...item}
                    key={index}
                    isFirstItem={index == 0 ? true : false}
                    isLastItem={index + 1 == attributes.length ? true : false}
                />
            ))
        )
    }

    const renderW9HeaderRight = () => {
        if(carrierData.w9Status == 'Needed' || carrierData.w9Status == 'Rejected') {
            return (
                <Button onPress={() => setModalVisible(true)} appearance="outline" size="small">Complete W-9</Button>
            )
        } else {
            return null;
        }
    }

    const renderW9Status = () => {
        if(carrierData.w9Status == 'Needed') {
            return (
                <>
                    <Text style={styles.w9Status} category="s2">Information Needed</Text>
                    <Text style={styles.w9StatusDescription} category="p1" appearance="hint">Sleek Fleet requires all carriers to complete a W-9 to be authorized to receive payments. Please use the button above to submit the required information.</Text>
                </>
            )
        } else if(carrierData.w9Status == 'Submitted') {
            return (
                <>
                    <Text style={styles.w9Status} category="s2">Under Review</Text>
                    <Text style={styles.w9StatusDescription} category="p1" appearance="hint">Thank you for submitting your W-9. We are currently reviewing your submission and will be in touch if we need any additional information.</Text>
                </>
            )
        } else if(carrierData.w9Status == 'Rejected') {
            return (
                <>
                    <Text style={styles.w9Status} category="s2">Rejected</Text>
                    {carrierData.w9RejectionReason == null &&
                        <Text style={styles.w9StatusDescription} category="p1" appearance="hint">The information you provided on your W-9 submission was unfortunately rejected. Please review your W-9 information for accuracy and re-submit. Please contact support with any questions.</Text>
                    }
                    {carrierData.w9RejectionReason != null &&
                        <>
                            <Text style={styles.w9StatusDescription} category="p1" appearance="hint">The information you provided on your W-9 submission was unfortunately rejected for the following reason:</Text>
                            <Text style={[styles.w9StatusDescription, styles.w9RejectionReason]} category="p1" appearance="hint">{carrierData.w9RejectionReason}</Text>
                            <Text style={styles.w9StatusDescription} category="p1" appearance="hint">Please review your W-9 information for accuracy and re-submit.</Text>
                        </>
                    }
                </>
            )
        } else if(carrierData.w9Status == 'Approved') {
            return (
                <>
                    <Text style={styles.w9Status} category="s2">Approved</Text>
                    <Text style={styles.w9StatusDescription} category="p1" appearance="hint">Thank you for submitting your W-9. Your submission has been reviewed and approved.</Text>
                </>
            )
        }
    }

    const getPageActions = () => {
        return (
            helper.isDesktopWeb() ? (
                <Button appearance="outline" size="small" onPress={() => navigation.navigate('CarrierProfileEdit')}>Edit Carrier Profile</Button>
            ) : (
                <Pressable onPress={() => navigation.navigate('CarrierProfileEdit')}>
                    <FontAwesomeIcon style={styles.editIcon} icon={['fal', 'edit']} size={styles.editIcon.size} color={styles.editIcon.color} />
                </Pressable>
            )
        )
    }

    const CarrierProfileView = () => {
        return (
            <Layout style={styles.layout} level="3">
                <View style={styles.carrierInformationView}>
                    <Image
                        source={{
                            uri: sleekApiUrl+carrierData.carrierLogo,
                            headers: Constants.RESOURCE_HEADERS
                        }}
                        style={{width: 100, height: 100, borderRadius: 50}}
                    />
                    <Text style={styles.name} category="h6">{carrierData.carrierName}</Text>
                    <Text style={styles.dot} category="s1" appearance="hint">DOT #: {carrierData.usDotNumber}</Text>
                    <View style={styles.status}>
                        <Badge label={carrierData.sleekFleetStatus} type="info" />
                    </View>
                </View>
                <View style={helper.isDesktopWeb() ? styles.main : {}}>
                    <View style={helper.isDesktopWeb() ? styles.columnOne : {}}>
                        <Section title="Contact Information" isFirstItem={true} renderBody={renderAttributes(carrierAttributes)} />
                        <Section title="Payment Information" renderBody={renderAttributes(paymentAttributes)} />
                        {!helper.isDesktopWeb() &&
                            <Section title="W-9 Status" isLastItem={true} renderBody={renderW9Status()} headerRight={renderW9HeaderRight()}/>
                        }
                    </View>
                    <View style={helper.isDesktopWeb() ? styles.columnTwo : {}}>
                        {helper.isDesktopWeb() &&
                            <Section title="W-9 Status" isLastItem={true} renderBody={renderW9Status()} headerRight={renderW9HeaderRight()}/>
                        }
                    </View>
                </View>
                <CustomModal title="Complete W-9" modalVisible={modalVisible} setModalVisible={setModalVisible} modalError={modalError} setModalError={setModalError}>
                    <CarrierW9 setModalVisible={setModalVisible} setModalError={setModalError} />
                </CustomModal>
            </Layout>
        )
    }

    return (
        helper.isWeb() ? (
            <View style={styles.webView}>
                <Header breadcrumbs={breadcrumbs} title="Carrier Profile" subtitle={'Updated on ' + carrierData.updatedAt} pageActions={getPageActions()} />
                <ScrollView>
                    <CarrierProfileView />
                </ScrollView>
            </View>
        ) : (
            <SafeAreaView style={styles.safeAreaView} edges={['top', 'left', 'right']}>
                <Header activeScreen="CarrierProfile" subtitle={'Updated on ' + carrierData.updatedAt}/>
                <ScrollView>
                    <CarrierProfileView />
                </ScrollView>
            </SafeAreaView>
        )
    )

}

const themedStyles = StyleService.create({
    // global styles
    layout: {
        flex: 1,
        paddingBottom: 30,
    },
    carrierInformationView: {
        backgroundColor: "color-basic-100",
        alignItems: "center",
        paddingTop: 40,
        paddingBottom: 30,
        marginBottom: 8,
        borderBottomLeftRadius: 10,
        borderBottomRightRadius: 10,
        shadowColor: "shadow-color",
        shadowOffset: {
            width: 0,
            height: 2,
        },
        shadowOpacity: .2,
        shadowRadius: 2,
        marginHorizontal: helper.isWeb() ? 10 : 0,
        marginTop: helper.isWeb() ? 10 : 0,
        borderTopLeftRadius: helper.isWeb() ? 10 : 0,
        borderTopRightRadius: helper.isWeb() ? 10 : 0,
    },
    name: {
        marginTop: 15,
    },
    dot: {
        marginTop: 5,
    },
    status: {
        marginTop: 10,
    },
    w9Status: {
        fontSize: 16,
        paddingTop: 5,
        paddingBottom: 10
    },
    w9StatusDescription: {
        paddingBottom: 10
    },
    w9RejectionReason: {
        fontStyle: "italic"
    },
    // native specific styles
    safeAreaView: {
        backgroundColor: "safe-area-view-background",
        flex: 1,
    },
    // web specific styles
    webView: {
        flex: 1,
    },
    main: {
        flexDirection: "row"
    },
    columnOne: {
        flex: 7
    },
    columnTwo: {
        flex: 5
    },
    editIcon: {
        size: 24,
        color: "color-primary-500",
        cursor: "pointer"
    }
});
