import React, { useContext, useState } from 'react';
import { Pressable, ScrollView, TouchableOpacity, View } from 'react-native';
import { Button, Divider, Layout, StyleService, Text, useStyleSheet } from '@ui-kitten/components';
import { useNavigation } from '@react-navigation/native';
import { EmptyDataSet } from 'components/utility';
import { LoadAttribute, LocationReview, RouteMap } from 'components/loads';
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome';
import { Badge } from 'components/utility';
import { Section, TextIcon } from 'components/layout';
import { Loader } from 'components/utility';
import Collapsible from 'react-native-collapsible';
import { AppDataContext } from 'components/context';
import * as helper from 'utility/Helper';
import * as Linking from 'expo-linking';

export default function RouteView(props) {

    const styles = useStyleSheet(themedStyles);
    const { appData } = useContext(AppDataContext);
    const navigation = useNavigation();

    // Load & Map Marker Data
    const isCarrierLoad = props.isCarrierLoad;
    const load = props.loadDetails;
    const markers = props.markers;
    const points = props.points;

    // Origin Data Translation
    const originLocationInfo = {
        name: load.originName,
        address: load.originAddress,
        address2: load.originAddress2,
        city: load.originCity,
        state: load.originState,
        zip: load.originZip,
        type: load.originType,
        lumperLocation: load.originLumper,
        truckDirections: load.originTruckDirections,
        contactName: load.originLocationContactName,
        contactPhone: load.originLocationContactPhone,
        contactEmail: load.originLocationContactEmail,
    };
    const originAppt = [
        {
            name: "Appointment",
            value: load.pickupAppointmentDetails,
            display: true,
        },
        {
            name: "Appointment Number",
            value: load.pickupAppointmentNumber,
            display: isCarrierLoad ? true : false,
        },
        {
            name: "Schedule Type",
            value: load.originScheduleType,
            display: true,
        },
        {
            name: "Ready By",
            value: load.pickupReadyByWithTime,
            display: true,
        },
        {
            name: "Pickup By",
            value: load.mustPickupByWithTime,
            display: true,
        },
        {
            name: "Pickup Handling",
            value: load.pickupHandling,
            display: true,
            lastItem: !isCarrierLoad ? true : false,
        },
        {
            name: "Pickup Number",
            value: load.pickupNumber,
            display: isCarrierLoad ? true : false,
        },
        {
            name: "Pickup PO Number",
            value: load.shipperPickupPONumber,
            display: isCarrierLoad ? true : false,
        },
        {
            name: "Contact Name",
            value: load.originAppointmentContactName,
            display: isCarrierLoad ? true : false,
        },
        {
            name: "Contact Phone",
            value: load.originAppointmentContactPhone,
            display: isCarrierLoad ? true : false,
            link: `tel:${load.originAppointmentContactPhone}`
        },
        {
            name: "Contact Email",
            value: load.originAppointmentContactEmail,
            display: isCarrierLoad ? true : false,
            link: `mailto:${load.originAppointmentContactEmail}`,
            lastItem: true,
        },
    ];
    const originShippingHours = [
        {
            name: "Monday",
            value: (helper.hasValue(load.originShippingMondayStart) ? load.originShippingMondayStart + ' - ' + load.originShippingMondayEnd : ''),
            display: true,
        },
        {
            name: "Tuesday",
            value: (helper.hasValue(load.originShippingTuesdayStart) ? load.originShippingTuesdayStart + ' - ' + load.originShippingTuesdayEnd : ''),
            display: true,
        },
        {
            name: "Wednesday",
            value: (helper.hasValue(load.originShippingWednesdayStart) ? load.originShippingWednesdayStart + ' - ' + load.originShippingWednesdayEnd : ''),
            display: true,
        },
        {
            name: "Thursday",
            value: (helper.hasValue(load.originShippingThursdayStart) ? load.originShippingThursdayStart + ' - ' + load.originShippingThursdayEnd : ''),
            display: true,
        },
        {
            name: "Friday",
            value: (helper.hasValue(load.originShippingFridayStart) ? load.originShippingFridayStart + ' - ' + load.originShippingFridayEnd : ''),
            display: true,
        },
        {
            name: "Saturday",
            value: (helper.hasValue(load.originShippingSaturdayStart) ? load.originShippingSaturdayStart + ' - ' + load.originShippingSaturdayEnd : ''),
            display: true,
        },
        {
            name: "Sunday",
            value: (helper.hasValue(load.originShippingSundayStart) ? load.originShippingSundayStart + ' - ' + load.originShippingSundayEnd : ''),
            display: true,
            lastItem: true,
        },
    ];

    // Destination Data Translation
    const destinationLocationInfo = {
        name: load.destinationName,
        address: load.destinationAddress,
        address2: load.destinationAddress2,
        city: load.destinationCity,
        state: load.destinationState,
        zip: load.destinationZip,
        type: load.destinationType,
        lumperLocation: load.destinationLumper,
        truckDirections: load.destinationTruckDirections,
        contactName: load.destinationLocationContactName,
        contactPhone: load.destinationLocationContactPhone,
        contactEmail: load.destinationLocationContactEmail,
    };
    const destinationAppt = [
        {
            name: "Appointment",
            value: load.deliveryAppointmentDetails,
            display: true,
        },
        {
            name: "Appointment Number",
            value: load.deliveryAppointmentNumber,
            display: isCarrierLoad ? true : false,
        },
        {
            name: "Schedule Type",
            value: load.destinationScheduleType,
            display: true,
        },
        {
            name: "Earliest Delivery",
            value: load.deliveryReadyByWithTime,
            display: true,
        },
        {
            name: "Must Deliver By",
            value: load.mustDeliverByWithTime,
            display: true,
        },
        {
            name: "Delivery Handling",
            value: load.deliveryHandling,
            display: true,
            lastItem: !isCarrierLoad ? true : false,
        },
        {
            name: "Delivery Number",
            value: load.deliveryNumber,
            display: isCarrierLoad ? true : false,
        },
        {
            name: "Delivery PO Number",
            value: load.shipperDeliveryPONumber,
            display: isCarrierLoad ? true : false,
        },
        {
            name: "Contact Name",
            value: load.destinationAppointmentContactName,
            display: isCarrierLoad ? true : false,
        },
        {
            name: "Contact Phone",
            value: load.destinationAppointmentContactPhone,
            display: isCarrierLoad ? true : false,
            link: `tel:${load.destinationAppointmentContactPhone}`
        },
        {
            name: "Contact Email",
            value: load.destinationAppointmentContactEmail,
            display: isCarrierLoad ? true : false,
            link: `mailto:${load.destinationAppointmentContactEmail}`,
            lastItem: true,
        },
    ];
    const destinationReceivingHours = [
        {
            name: "Monday",
            value: (helper.hasValue(load.destinationReceivingMondayStart) ? load.destinationReceivingMondayStart + ' - ' + load.destinationReceivingMondayEnd : ''),
            display: true,
        },
        {
            name: "Tuesday",
            value: (helper.hasValue(load.destinationReceivingTuesdayStart) ? load.destinationReceivingTuesdayStart + ' - ' + load.destinationReceivingTuesdayEnd : ''),
            display: true,
        },
        {
            name: "Wednesday",
            value: (helper.hasValue(load.destinationReceivingWednesdayStart) ? load.destinationReceivingWednesdayStart + ' - ' + load.destinationReceivingWednesdayEnd : ''),
            display: true,
        },
        {
            name: "Thursday",
            value: (helper.hasValue(load.destinationReceivingThursdayStart) ? load.destinationReceivingThursdayStart + ' - ' + load.destinationReceivingThursdayEnd : ''),
            display: true,
        },
        {
            name: "Friday",
            value: (helper.hasValue(load.destinationReceivingFridayStart) ? load.destinationReceivingFridayStart + ' - ' + load.destinationReceivingFridayEnd : ''),
            display: true,
        },
        {
            name: "Saturday",
            value: (helper.hasValue(load.destinationReceivingSaturdayStart) ? load.destinationReceivingSaturdayStart + ' - ' + load.destinationReceivingSaturdayEnd : ''),
            display: true,
        },
        {
            name: "Sunday",
            value: (helper.hasValue(load.destinationReceivingSundayStart) ? load.destinationReceivingSundayStart + ' - ' + load.destinationReceivingSundayEnd : ''),
            display: true,
            lastItem: true,
        },
    ];

    const [locationReviewsVisible, setLocationReviewsVisible] = useState(false);

    // Set Accordions Open / Closed
    const initAccordionStatus = () => {
        let accordions = [];
        accordions.push(load.currentStop == 'origin' ? false : true);
        if(load.stops.length > 0) {
            load.stops.forEach(stop => {
                accordions.push(load.currentStop == stop.stopOrder ? false : true);
            })
        }
        accordions.push(load.currentStop == 'destination' ? false : true);
        return accordions;
    }
    const [accordionStatus, setAccordionStatus] = useState(initAccordionStatus());

    // Loop through stops and translate data for multi-stop loads
    let stopsData = [];
    if(load.stops.length > 0) {
        let stopData;
        load.stops.forEach(stop => {
            stopData = {
                basicInfo: {
                    stopOrder: stop.stopOrder,
                    stopStatus: stop.stopStatus,
                    appointment: stop.appointmentDetails,
                    stopType: stop.stopType,
                    stopDescription: stop.locationDescription,
                    locationId: stop.locationId,
                    warehouseReviews: stop.locationWarehouseReviews,
                    warehouseRating: stop.locationWarehouseRating,
                    allowReview: stop.locationAllowReview,
                },
                locationInfo: {
                    name: stop.locationName,
                    address: stop.locationAddress,
                    address2: stop.locationAddress2,
                    city: stop.locationCity,
                    state: stop.locationState,
                    zip: stop.locationZip,
                    type: stop.locationType,
                    lumperLocation: stop.locationLumper,
                    truckDirections: stop.locationTruckDirections,
                    contactName: stop.locationLocationContactName,
                    contactPhone: stop.locationLocationContactPhone,
                    contactEmail: stop.locationLocationContactEmail,
                },
                appointmentInfo: [
                    {
                        name: "Appointment",
                        value: stop.appointmentDetails,
                        display: true,
                    },
                    {
                        name: "Appointment Number",
                        value: stop.stopAppointmentNumber,
                        display: isCarrierLoad ? true : false,
                    },
                    {
                        name: "Schedule Type",
                        value: stop.locationScheduleType,
                        display: true,
                        lastItem: !isCarrierLoad ? true : false,
                    },
                    {
                        name: "Stop Reference Number",
                        value: stop.stopReferenceNumber,
                        display: isCarrierLoad ? true : false,
                    },
                    {
                        name: "Stop PO Number",
                        value: stop.stopPONumber,
                        display: isCarrierLoad ? true : false,
                    },
                    {
                        name: "Contact Name",
                        value: stop.locationAppointmentContactName,
                        display: isCarrierLoad ? true : false,
                    },
                    {
                        name: "Contact Phone",
                        value: stop.locationAppointmentContactPhone,
                        display: isCarrierLoad ? true : false,
                        link: `tel:${stop.locationAppointmentContactPhone}`
                    },
                    {
                        name: "Contact Email",
                        value: stop.locationAppointmentContactEmail,
                        display: isCarrierLoad ? true : false,
                        link: `mailto:${stop.locationAppointmentContactEmail}`,
                        lastItem: true,
                    },
                ],
                locationHours: [
                    {
                        name: "Monday",
                        value: (helper.hasValue(stop.locationMondayStart) ? stop.locationMondayStart + ' - ' + stop.locationMondayEnd : ''),
                        display: true,
                    },
                    {
                        name: "Tuesday",
                        value: (helper.hasValue(stop.locationTuesdayStart) ? stop.locationTuesdayStart + ' - ' + stop.locationTuesdayEnd : ''),
                        display: true,
                    },
                    {
                        name: "Wednesday",
                        value: (helper.hasValue(stop.locationWednesdayStart) ? stop.locationWednesdayStart + ' - ' + stop.locationWednesdayEnd : ''),
                        display: true,
                    },
                    {
                        name: "Thursday",
                        value: (helper.hasValue(stop.locationThursdayStart) ? stop.locationThursdayStart + ' - ' + stop.locationThursdayEnd : ''),
                        display: true,
                    },
                    {
                        name: "Friday",
                        value: (helper.hasValue(stop.locationFridayStart) ? stop.locationFridayStart + ' - ' + stop.locationFridayEnd : ''),
                        display: true,
                    },
                    {
                        name: "Saturday",
                        value: (helper.hasValue(stop.locationSaturdayStart) ? stop.locationSaturdayStart + ' - ' + stop.locationSaturdayEnd : ''),
                        display: true,
                    },
                    {
                        name: "Sunday",
                        value: (helper.hasValue(stop.locationSundayStart) ? stop.locationSundayStart + ' - ' + stop.locationSundayEnd : ''),
                        display: true,
                        lastItem: true,
                    },
                ]
            };
            stopsData.push(stopData);
        })
    }

    const renderRouteMap = () => {
        if(markers.length > 0) {
            return (
                <RouteMap load={load} markers={markers} points={points}/>
            )
        } else {
            return (
                <Loader loadingText="Loading Map Data" />
            )
        }

    }

    const toggleLocationInfo = (accordionItemIndex) => {

        let updatedAccordions = accordionStatus;

        updatedAccordions.forEach((accordionItem, index) => {
            if(accordionItemIndex == index) {
                updatedAccordions[index] = !updatedAccordions[index];
            } else {
                updatedAccordions[index] = true;
            }
        });

        setAccordionStatus([...updatedAccordions]);

    };

    const renderLocationOverview = (locationDescription) => {

        return (
            <Text category="p1">{locationDescription}</Text>
        )

    }

    const renderLocationDetails = (location) => {
        return (
            <View style={styles.locationDetailsView}>
                <View style={styles.locationDetailsColumn}>
                    <Text category="p1">{location.name}</Text>
                    <Text category="p1">{location.address}</Text>
                    {location.address2 &&
                        <Text category="p1">{location.address2}</Text>
                    }
                    <Text category="p1">{location.city}, {location.state} {location.zip}</Text>
                    <Text category="p1">Type: {location.type}</Text>
                    <Text category="p1">Lumper Location: {location.lumperLocation}</Text>
                    {location.truckDirections &&
                        <>
                            <Text category="s2">Directions:</Text>
                            <Text category="p1">{location.truckDirections}</Text>
                        </>
                    }
                </View>
                <View style={styles.locationDetailsColumn}>
                    {location.contactName &&
                        <Text category="p1">{location.contactName}</Text>
                    }
                    {location.contactPhone &&
                        <Pressable onPress={() => Linking.openURL(`tel:${location.contactPhone}`)}>
                            <Text category="p1" status="primary">{location.contactPhone}</Text>
                        </Pressable>
                    }
                    {location.contactEmail &&
                        <Pressable onPress={() => Linking.openURL(`mailto:${location.contactEmail}`)}>
                            <Text category="p1" status="primary">{location.contactEmail}</Text>
                        </Pressable>
                    }
                </View>
            </View>
        )
    }

    const renderLoadAttributes = (loadAttributes) => {
        return (
            loadAttributes.map((item, index) => (
                <LoadAttribute
                    {...item}
                    key={index}
                    isFirstItem={index == 0 ? true : false}
                    isLastItem={item.lastItem}
                />
            ))
        )
    }

    const renderLocationReviewsHeaderRight = (locationId) => {

        if(!helper.isWeb() && load.carrierDriverId == appData.carrierUserData.id) {
            const showModal = (modalName) => {
                props.setLocationId(locationId);
                props.setActiveModal(modalName);
                props.setModalVisible(true);
            }

            return (
                <Pressable onPress={() => showModal('SubmitLocationReview')}>
                    <Text style={{textAlign: "right", fontSize: 14}} status="primary" category="s2">Add Review</Text>
                </Pressable>
            )
        }
    }

    const renderLocationReviews = (rating, reviews, locationId) => {

        const showModal = (modalName) => {
            props.setLocationId(locationId);
            props.setActiveModal(modalName);
            props.setModalVisible(true);
        }

        if(reviews.length > 0) {
            return (
                <>
                    <View>
                        <Button style={styles.button} appearance="ghost" onPress={() => setLocationReviewsVisible(!locationReviewsVisible)}>{locationReviewsVisible ? "Hide Location Reviews" : "Show Location Reviews"}</Button>
                    </View>
                    {locationReviewsVisible &&
                        <View>
                            {reviews.map((item, index) => (
                                <LocationReview
                                    {...item}
                                    key={index}
                                    isFirstItem={index == 0 ? true : false}
                                    isLastItem={index + 1 == reviews.length ? true : false}
                                    setActiveModal={props.setActiveModal}
                                    setModalVisible={props.setModalVisible}
                                    locationReviewDetails={item}
                                    setLocationReviewDetails={props.setLocationReviewDetails}
                                />
                            ))}
                        </View>
                    }
                </>
            )
        } else {
            return (
                <>
                    <EmptyDataSet primaryMessage="No Location Reviews" />
                </>
            )
        }
    }

    const renderLocationRating = (rating) => {

        switch(rating) {
            case 1:
                return(
                    <View style={styles.ratingWrapper}>
                        <Text category="s2" appearance="hint">Rating:</Text>
                        <View style={styles.ratingView}>
                            <FontAwesomeIcon style={styles.star} icon={['fas', 'star']} size={styles.star.size} color={styles.star.color} />
                            <FontAwesomeIcon style={styles.star} icon={['fal', 'star']} size={styles.star.size} color={styles.star.color} />
                            <FontAwesomeIcon style={styles.star} icon={['fal', 'star']} size={styles.star.size} color={styles.star.color} />
                            <FontAwesomeIcon style={styles.star} icon={['fal', 'star']} size={styles.star.size} color={styles.star.color} />
                            <FontAwesomeIcon style={styles.star} icon={['fal', 'star']} size={styles.star.size} color={styles.star.color} />
                        </View>
                    </View>
                )
            case 2:
                return(
                    <View style={styles.ratingWrapper}>
                        <Text category="s2" appearance="hint">Rating:</Text>
                        <View style={styles.ratingView}>
                            <FontAwesomeIcon style={styles.star} icon={['fas', 'star']} size={styles.star.size} color={styles.star.color} />
                            <FontAwesomeIcon style={styles.star} icon={['fas', 'star']} size={styles.star.size} color={styles.star.color} />
                            <FontAwesomeIcon style={styles.star} icon={['fal', 'star']} size={styles.star.size} color={styles.star.color} />
                            <FontAwesomeIcon style={styles.star} icon={['fal', 'star']} size={styles.star.size} color={styles.star.color} />
                            <FontAwesomeIcon style={styles.star} icon={['fal', 'star']} size={styles.star.size} color={styles.star.color} />
                        </View>
                    </View>
                )
            case 3:
                return(
                    <View style={styles.ratingWrapper}>
                        <Text category="s2" appearance="hint">Rating:</Text>
                        <View style={styles.ratingView}>
                            <FontAwesomeIcon style={styles.star} icon={['fas', 'star']} size={styles.star.size} color={styles.star.color} />
                            <FontAwesomeIcon style={styles.star} icon={['fas', 'star']} size={styles.star.size} color={styles.star.color} />
                            <FontAwesomeIcon style={styles.star} icon={['fas', 'star']} size={styles.star.size} color={styles.star.color} />
                            <FontAwesomeIcon style={styles.star} icon={['fal', 'star']} size={styles.star.size} color={styles.star.color} />
                            <FontAwesomeIcon style={styles.star} icon={['fal', 'star']} size={styles.star.size} color={styles.star.color} />
                        </View>
                    </View>
                )
            case 4:
                return(
                    <View style={styles.ratingWrapper}>
                        <Text category="s2" appearance="hint">Rating:</Text>
                        <View style={styles.ratingView}>
                            <FontAwesomeIcon style={styles.star} icon={['fas', 'star']} size={styles.star.size} color={styles.star.color} />
                            <FontAwesomeIcon style={styles.star} icon={['fas', 'star']} size={styles.star.size} color={styles.star.color} />
                            <FontAwesomeIcon style={styles.star} icon={['fas', 'star']} size={styles.star.size} color={styles.star.color} />
                            <FontAwesomeIcon style={styles.star} icon={['fas', 'star']} size={styles.star.size} color={styles.star.color} />
                            <FontAwesomeIcon style={styles.star} icon={['fal', 'star']} size={styles.star.size} color={styles.star.color} />
                        </View>
                    </View>
                )
            case 5:
                return(
                    <View style={styles.ratingWrapper}>
                        <Text category="s2" appearance="hint">Rating:</Text>
                        <View style={styles.ratingView}>
                            <FontAwesomeIcon style={styles.star} icon={['fas', 'star']} size={styles.star.size} color={styles.star.color} />
                            <FontAwesomeIcon style={styles.star} icon={['fas', 'star']} size={styles.star.size} color={styles.star.color} />
                            <FontAwesomeIcon style={styles.star} icon={['fas', 'star']} size={styles.star.size} color={styles.star.color} />
                            <FontAwesomeIcon style={styles.star} icon={['fas', 'star']} size={styles.star.size} color={styles.star.color} />
                            <FontAwesomeIcon style={styles.star} icon={['fas', 'star']} size={styles.star.size} color={styles.star.color} />
                        </View>
                    </View>
                )
        }

    }

    const renderLocationStatus = (type) => {

        let notStartedArray;
        let inProgressArray;
        let completedArray;

        if(type == 'origin') {
            notStartedArray = ['Available', 'Cancelled', 'Awarded', 'Tendered', 'TONU', 'Dispatched'];
            inProgressArray = ['Loading'];
            completedArray = ['In Transit', 'Unloading', 'Delivered', 'Invoiced', 'Invoice Issue', 'Paid Balance Due', 'Paid In Full'];
        } else if(type == 'destination') {
            notStartedArray = ['Available', 'Cancelled', 'Awarded', 'Tendered', 'TONU', 'Dispatched', 'Loading', 'In Transit'];
            inProgressArray = ['Unloading'];
            completedArray = ['Delivered', 'Invoiced', 'Invoice Issue', 'Paid Balance Due', 'Paid In Full'];
        }

        if(helper.inArray(load.status, notStartedArray)) {
            return "Not Started";
        } else if(helper.inArray(load.status, inProgressArray)) {
            return "In Progress";
        } else if(helper.inArray(load.status, completedArray)) {
            return "Complete";
        }
    }

    const renderStops = () => {
        return (
            stopsData.map((stop, index) => (
                <View style={styles.locationWrapper} key={index}>
                    <TouchableOpacity onPress={() => toggleLocationInfo(stop.basicInfo.stopOrder)}>
                        <View style={styles.header}>
                            <View style={styles.headerTextView}>
                                <Text category="s2" status="primary">Stop #{stop.basicInfo.stopOrder}: {stop.locationInfo.city}, {stop.locationInfo.state}</Text>
                                {isCarrierLoad &&
                                    <View style={styles.badgeView}>
                                        <Badge label={stop.basicInfo.stopStatus} type="info" />
                                    </View>
                                }
                            </View>
                            <TextIcon iconName="calendar-alt" iconWeight="far" iconSize={styles.icon.size} iconColor={styles.icon.color} text={'Appointment: ' + stop.basicInfo.appointment} textCategory="s2" textAppearance="hint" />
                            <TextIcon style={styles.stopType} iconName="info-circle" iconWeight="far" iconSize={styles.icon.size} iconColor={styles.icon.color} text={'Stop Type: ' + stop.basicInfo.stopType} textCategory="s2" textAppearance="hint" />
                            <View style={styles.collapseIconView}>
                                {accordionStatus[stop.basicInfo.stopOrder] &&
                                    <FontAwesomeIcon style={styles.collapseIcon} icon={['fal', 'plus-circle']} size={styles.collapseIcon.size} color={styles.collapseIcon.color} />
                                }
                                {!accordionStatus[stop.basicInfo.stopOrder] &&
                                    <FontAwesomeIcon style={styles.collapseIcon} icon={['fal', 'minus-circle']} size={styles.collapseIcon.size} color={styles.collapseIcon.color} />
                                }
                            </View>
                        </View>
                    </TouchableOpacity>
                    <Collapsible style={styles.collapsible} collapsed={accordionStatus[stop.basicInfo.stopOrder]}>
                        {stop.basicInfo.locationDescription &&
                            <Section isFirstItem={true} title="Location Overview" renderBody={renderLocationOverview(stop.basicInfo.locationDescription)} />
                        }
                        {isCarrierLoad &&
                            <Section isFirstItem={stop.basicInfo.locationDescription == '' ? true : false} title="Location Details" renderBody={renderLocationDetails(stop.locationInfo)} />
                        }
                        <Section isFirstItem={stop.basicInfo.locationDescription == '' && !isCarrierLoad ? true : false} title="Appointment Details" renderBody={renderLoadAttributes(stop.appointmentInfo)} />
                        <Section title="Shipping Hours" renderBody={renderLoadAttributes(stop.locationHours)} />
                        <Section title={'Location Reviews ('+stop.basicInfo.warehouseReviews.length+')'} subtitle={renderLocationRating(stop.basicInfo.warehouseRating)} headerRight={stop.basicInfo.allowReview ? renderLocationReviewsHeaderRight(stop.basicInfo.locationId) : null} renderBody={renderLocationReviews(stop.basicInfo.warehouseRating, stop.basicInfo.warehouseReviews, stop.basicInfo.locationId)} />
                    </Collapsible>
                </View>
            ))
        )
    }

    const renderOrigin = () => {
        return (
            <View style={styles.locationWrapper}>
                <TouchableOpacity onPress={() => toggleLocationInfo(0)}>
                    <View style={styles.header}>
                        <View style={styles.headerTextView}>
                            <Text category="s2" status="primary">Origin: {load.originCity}, {load.originState}</Text>
                            {isCarrierLoad &&
                                <View style={styles.badgeView}>
                                    <Badge label={renderLocationStatus('origin')} type="info" />
                                </View>
                            }
                        </View>
                        <TextIcon iconName="calendar-alt" iconWeight="far" iconSize={styles.icon.size} iconColor={styles.icon.color} text={'Appointment: '+load.pickupAppointmentDetails} textCategory="s2" textAppearance="hint" />
                        <View style={styles.collapseIconView}>
                            {accordionStatus[0] &&
                                <FontAwesomeIcon style={styles.collapseIcon} icon={['fal', 'plus-circle']} size={styles.collapseIcon.size} color={styles.collapseIcon.color} />
                            }
                            {!accordionStatus[0] &&
                                <FontAwesomeIcon style={styles.collapseIcon} icon={['fal', 'minus-circle']} size={styles.collapseIcon.size} color={styles.collapseIcon.color} />
                            }
                        </View>
                    </View>
                </TouchableOpacity>
                <Collapsible style={styles.collapsible} collapsed={accordionStatus[0]}>
                    {load.originDescription &&
                        <Section isFirstItem={true} title="Origin Overview" renderBody={renderLocationOverview(load.originDescription)} />
                    }
                    {isCarrierLoad &&
                        <Section isFirstItem={load.originDescription == '' ? true : false} title="Origin Details" renderBody={renderLocationDetails(originLocationInfo)} />
                    }
                    <Section isFirstItem={load.originDescription == '' && !isCarrierLoad ? true : false} title="Appointment Details" renderBody={renderLoadAttributes(originAppt)} />
                    <Section title="Shipping Hours" renderBody={renderLoadAttributes(originShippingHours)} />
                    <Section title={'Location Reviews ('+load.originWarehouseReviews.length+')'} subtitle={renderLocationRating(load.originWarehouseRating)} headerRight={load.originAllowReview ? renderLocationReviewsHeaderRight(load.originId) : null} renderBody={renderLocationReviews(load.originWarehouseRating, load.originWarehouseReviews, load.originId)} />
                </Collapsible>
            </View>
        )
    }

    const renderDestination = () => {
        return (
            <View style={styles.locationWrapper}>
                <TouchableOpacity onPress={() => toggleLocationInfo(accordionStatus.length - 1)}>
                    <View style={styles.header}>
                        <View style={styles.headerTextView}>
                            <Text category="s2" status="primary">Destination: {load.destinationCity}, {load.destinationState}</Text>
                            {isCarrierLoad &&
                                <View style={styles.badgeView}>
                                    <Badge label={renderLocationStatus('destination')} type="info" />
                                </View>
                            }
                        </View>
                        <TextIcon iconName="calendar-alt" iconWeight="far" iconSize={styles.icon.size} iconColor={styles.icon.color} text={'Appointment: '+load.deliveryAppointmentDetails} textCategory="s2" textAppearance="hint" />
                        <View style={styles.collapseIconView}>
                            {accordionStatus[accordionStatus.length - 1] &&
                                <FontAwesomeIcon style={styles.collapseIcon} icon={['fal', 'plus-circle']} size={styles.collapseIcon.size} color={styles.collapseIcon.color} />
                            }
                            {!accordionStatus[accordionStatus.length - 1] &&
                                <FontAwesomeIcon style={styles.collapseIcon} icon={['fal', 'minus-circle']} size={styles.collapseIcon.size} color={styles.collapseIcon.color} />
                            }
                        </View>
                    </View>
                </TouchableOpacity>
                <Collapsible style={styles.collapsible} collapsed={accordionStatus[accordionStatus.length - 1]}>
                    {load.destinationDescription &&
                        <Section isFirstItem={true} title="Destination Overview" renderBody={renderLocationOverview(load.destinationDescription)} />
                    }
                    {isCarrierLoad &&
                        <Section isFirstItem={load.destinationDescription == '' ? true : false} title="Destination Details" renderBody={renderLocationDetails(destinationLocationInfo)} />
                    }
                    <Section isFirstItem={load.destinationDescription == '' && !isCarrierLoad ? true : false} title="Appointment Details" renderBody={renderLoadAttributes(destinationAppt)} />
                    <Section title="Receiving Hours" renderBody={renderLoadAttributes(destinationReceivingHours)} />
                    <Section title={'Location Reviews ('+load.destinationWarehouseReviews.length+')'} subtitle={renderLocationRating(load.destinationWarehouseRating)} headerRight={load.destinationAllowReview ? renderLocationReviewsHeaderRight(load.destinationId) : null} renderBody={renderLocationReviews(load.destinationWarehouseRating, load.destinationWarehouseReviews, load.destinationId)} />
                </Collapsible>
            </View>
        )
    }

    React.useLayoutEffect(() => {
        if(helper.isWeb()) {
            navigation.setOptions({
                title: 'Load Details - Route'
            })
        }
    }, [navigation]);

    return (
        helper.isWeb() ? (
            <ScrollView contentContainerStyle={styles.scrollView}>
                <Layout style={styles.layout} level="3">

                    {helper.isDesktopWeb() &&
                        <Section title="Map" noBodyPadding={false} bodyHeight={450} renderBody={renderRouteMap()} />
                    }
                    {!helper.isDesktopWeb() &&
                        <View style={styles.routeMapView}>
                            {renderRouteMap()}
                        </View>
                    }

                    {renderOrigin()}

                    {load.stops.length > 0 &&
                        renderStops()
                    }

                    {renderDestination()}

                </Layout>
            </ScrollView>
        ) : (
            <ScrollView contentContainerStyle={styles.scrollView}>
                <Layout style={styles.layout} level="3">

                    <View style={styles.routeMapView}>
                        {renderRouteMap()}
                    </View>

                    {renderOrigin()}

                    {load.stops.length > 0 &&
                        renderStops()
                    }

                    {renderDestination()}

                </Layout>
            </ScrollView>
        )
    )

}

const themedStyles = StyleService.create({
    scrollView: {
        flexGrow: 1,
    },
    layout: {
        flex: 1,
        marginTop: helper.isDesktopWeb() ? 5 : 0
    },
    routeMapView: {
        borderBottomColor: "border-generic",
        borderBottomWidth: 1,
    },
    locationWrapper: {
        marginHorizontal: helper.isDesktopWeb() ? 10 : 0,
        marginBottom: helper.isDesktopWeb() ? 5 : 0
    },
    header: {
        backgroundColor: "color-basic-100",
        padding: 10,
        borderBottomColor: "border-generic",
        borderBottomWidth: 1,
        minHeight: 70,
        borderLeftWidth: helper.isDesktopWeb() ? 0 : 4,
        borderLeftColor: "color-primary-500",
        justifyContent: "center",
        borderRadius: helper.isDesktopWeb() ? 6 : 0,
        borderRadius: helper.isDesktopWeb() ? 6 : 0
    },
    headerTextView: {
        flexDirection: "row",
        flexWrap: "wrap",
        paddingBottom: 2,
    },
    badgeView: {
        marginLeft: 3,
    },
    stopType: {
        marginTop: 2
    },
    collapseIconView: {
        position: "absolute",
        right: 15,
        top: '50%',
        marginTop: helper.isWeb() ? -14 : -4,
    },
    collapseIcon: {
        size: 28,
        color: "color-primary-500"
    },
    collapsible: {
        paddingTop: 5,
        paddingBottom: 5,
        backgroundColor: "color-basic-100",
        borderRadius: helper.isDesktopWeb() ? 6 : 0,
        borderRadius: helper.isDesktopWeb() ? 6 : 0
    },
    mapContainer: {
        flex: 1,
        flexDirection: "row",
        justifyContent: "center",
    },
    locationDetailsView: {
        flexDirection: "row",
    },
    locationDetailsColumn: {
        flex: 1,
        paddingRight: 10,
    },
    ratingWrapper: {
        flexDirection: "row",
        paddingTop: 8,
        alignItems: "center"
    },
    ratingView: {
        flexDirection: "row",
        marginLeft: 3
    },
    starFilled: {
        color: "color-primary-500"
    },
    star: {
        color: "color-primary-500",
        size: 14,
    },
    icon: {
        size: 14,
        color: "color-basic-600"
    },
});
