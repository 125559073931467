import React from 'react';
import { View } from 'react-native';
import { StyleService, Text, useStyleSheet } from '@ui-kitten/components';
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome';
import * as helper from 'utility/Helper';

export const TextIcon = (props) => {

    /*
        props:
        - containerSpacing
        - iconColor
        - iconName
        - iconSize
        - iconSpacing
        - iconWeight
        - showBackground
        - style
        - text
        - textCategory
        - textAppearance
    */

    const styles = useStyleSheet(themedStyles);

    const iconSize = (helper.hasValue(props.iconSize) ? parseInt(props.iconSize) : 24);
    const iconColor = (helper.hasValue(props.iconColor) ? props.iconColor : styles.icon.color);
    const iconSpacing = (helper.hasValue(props.iconSpacing) ? parseInt(props.iconSpacing) : 4);
    const category = (helper.hasValue(props.textCategory) ? props.textCategory : "c2");
    const appearance = (helper.hasValue(props.textAppearance) ? props.textAppearance : "hint");
    const containerSpacing = (helper.hasValue(props.containerSpacing) ? parseInt(props.containerSpacing) : 15);

    let containerStyles = [styles.container, { marginRight: containerSpacing }];

    if(helper.hasValue(props.style)) {
        containerStyles.push(props.style);
    }

    if(props.showBackground == true) {
        containerStyles.push(styles.background);
    }

    return (
        <View style={containerStyles}>
            <FontAwesomeIcon style={[styles.icon, {marginRight: iconSpacing}]} icon={[props.iconWeight, props.iconName]} size={iconSize} color={iconColor} />
            <Text category={category} appearance={appearance}>{props.text}</Text>
        </View>
    )

}

const themedStyles = StyleService.create({
    container: {
        flexDirection: "row",
        alignItems: "center",
    },
    background: {
        backgroundColor: "color-basic-200",
        paddingVertical: 4,
        paddingHorizontal: 6,
        borderRadius: 4,
    },
    icon: {
        color: "color-basic-600",
    },
});
