import React from 'react';
import { Dimensions, View } from 'react-native';
import { StyleService, Text, useStyleSheet } from '@ui-kitten/components';
import * as Constants from 'config/Constants';
import PDFReader from 'rn-pdf-reader-js';
import getEnvVars from 'root/environment';

export default function LoadDocumentDetails(props) {

	/*
		loadDocumentDetails:
		- createdAt
		- createdAtFull
		- createdBy
		- description
		- documentId
		- filePath
		- invoiceAttachment
		- isFirstItem
		- isLastItem
		- type
		- updatedAt
		- updatedAtFull
	*/
	
	const styles = useStyleSheet(themedStyles);
	const { sleekApiUrl } = getEnvVars();
	const loadDocument = props.loadDocumentDetails;
	const filePath = sleekApiUrl+loadDocument.filePath;
	
	return (
		<View style={styles.container}>
			<PDFReader
				source={{
					uri: filePath,
					headers: Constants.RESOURCE_HEADERS
				}}
			/>
		</View>
	)

}

const windowHeight = Dimensions.get('window').height;
const pdfViewerHeight = windowHeight - 160;

const themedStyles = StyleService.create({
	container: {
		minHeight: pdfViewerHeight
	}
});