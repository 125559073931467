import React, { useState, useContext } from 'react';
import { View } from 'react-native';
import { Button, Layout, StyleService, Text, useStyleSheet } from '@ui-kitten/components';
import { Formik, Field } from 'formik';
import { InputField } from 'components/forms';
import { Loader } from 'components/utility';
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome';
import * as yup from 'yup';
import * as helper from 'utility/Helper';
import * as api from 'api/SleekTechnologiesApi';

export default function ForgotPassword(props) {

    const styles = useStyleSheet(themedStyles);
    const [isLoading, setLoading] = useState(false);

    const validationSchema = yup.object().shape({
        username: yup
            .string()
            .required('Please enter a valid mobile number or email address'),
    });

    const submitPasswordResetRequest = (data) => {

        setLoading(true);

        api.requestPasswordReset(data.username)
            .then(function(response) {

                setLoading(false);

                if(response.data.result == 'success') {

                    props.setModalVisible(false);
                    props.setModalError(null);
                    helper.showFlashMessage(response.data.result, response.data.msg);

                } else if(response.data.result == "error") {
                    setLoading(false);
                    helper.handleCatchError(error, true, props.setModalError);
                }

            })
            .catch(function(error){
                setLoading(false);
                helper.handleCatchError(error, true, props.setModalError);
            });
    }

    if(isLoading) {
        return (
            <Loader />
        )
    } else {
        return (
            <>
                <Formik
                    validationSchema={validationSchema}
                    initialValues={{
                        username: ''
                    }}
                    onSubmit={(values) => submitPasswordResetRequest(values)}
                >
                    {({ handleSubmit, isValid, values }) => (
                        <>
                            <Text style={styles.instructions} category="p1" appearance="hint">Enter your email address or mobile number below to receive instructions for resetting your password:</Text>
                            <Field
                                component={InputField}
                                name="username"
                                placeholder="Mobile Phone or Email"
                                defaultValue={''}
                                accessoryRight={<FontAwesomeIcon icon={['fal', 'user']} />}
                                size="large"
                            />
                            <View style={styles.buttonView}>
                                <Button onPress={handleSubmit} disabled={!isValid} size="large">Submit</Button>
                            </View>
                        </>
                    )}
                </Formik>
            </>
        )
    }
}

const themedStyles = StyleService.create({
    instructions: {
        marginTop: 10,
        marginBottom: 15
    },
    buttonView: {
        marginTop: 5,
    },
});
