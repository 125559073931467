import React, { useContext, useState, useEffect, useCallback, useRef } from 'react';
import { Dimensions, ScrollView, View } from 'react-native';
import { useFocusEffect, useNavigation } from '@react-navigation/native';
import { Button, Divider, Layout, List, ListItem, StyleService, Text, useStyleSheet } from '@ui-kitten/components';
import { AuthContext } from 'components/context';
import { EmptyDataSet, Loader } from 'components/utility';
import { CustomModal, Header, Section } from 'components/layout';
import { LoadHistoryFilters } from 'screens/reports/modals';
import { LoadSummary } from 'components/loads';
import * as helper from 'utility/Helper';
import * as api from 'api/SleekTechnologiesApi';
import * as Constants from 'config/Constants';
import moment from 'moment';

export default function LoadHistoryReport(props) {

    const styles = useStyleSheet(themedStyles);
    const { signOut } = useContext(AuthContext);
    const [loads, setLoads] = useState([]);
    const [isLoading, setLoading] = useState(false);
    const [isRefreshing, setRefreshing] = useState(false);
    const [recordCount, setRecordCount] = useState(null);
    const loadMore = useRef(false);
    const navigation = useNavigation();

    // format from / to date
    const dateFrom = moment().format("MM/DD/YYYY");
    const dateTo = moment().format("MM/DD/YYYY");

    // load history filters
    const defaultLoadHistoryFilters = {
        loadNumber: "",
        driver: "all",
        shipper: "all",
        loadType: "all",
        loadStatus: "all",
        origin: "all",
        originRadius: 100,
        destination: "all",
        destinationRadius: 100,
        deliveryDate: 30,
        deliveryDateFrom: dateFrom,
        deliveryDateTo: dateTo,
        sortBy: "date completed",
        offset: 0
    };
    const defaultLoadHistoryFilterDisplayValues = {
        loadNumber: "",
        driver: "all",
        shipper: helper.getShipperLoadList('string'),
        loadType: Constants.LOAD_TYPE_VALUES,
        loadStatus: Constants.LOAD_STATUS_VALUES,
        origin: "",
        originRadius: 100,
        destination: "",
        destinationRadius: 100,
        deliveryDate: 30,
        deliveryDateFrom: dateFrom,
        deliveryDateTo: dateTo,
        sortBy: "date completed",
        offset: 0
    };
    const [loadHistoryFilters, setLoadHistoryFilters] = useState(defaultLoadHistoryFilters);
    const [loadHistoryFilterDisplayValues, setLoadHistoryFilterDisplayValues] = useState(defaultLoadHistoryFilterDisplayValues);

    const windowWidth = Dimensions.get('window').width;
    const windowHeight = Dimensions.get('window').height;
    const bodyHeight = windowHeight - 170;

    const getLoadHistoryData = () => {
        setLoading(true);
        api.getLoadHistoryData(loadHistoryFilters)
            .then(function(response) {
                if(helper.hasValue(response.data.msg) && response.data.msg == "Token Not Found or Expired") {
                    signOut();
                }
                if(response.data.result == "success") {
                    if(loadMore.current) {
                        setLoads(loads => {
                            return [...loads, ...response.data.loads]
                        });
                    } else {
                        setLoads(response.data.loads);
                    }
                    setRecordCount(response.data.recordCount);
                    props.setLastLoadTime(Date.now()/1000);
                    setLoading(false);
                    setRefreshing(false);
                    loadMore.current = false;
                } else if(response.data.result == "error") {
                    throw {
                        type: "custom",
                        message: response.data.msg
                    }
                }
            })
            .catch(function(error){
                setLoading(false);
                setRefreshing(false);
                helper.handleCatchError(error);
            });
    }

    useFocusEffect(
        useCallback(() => {
            getLoadHistoryData();
        }, [loadHistoryFilters])
    );

    const renderLoadSummary = ({ item, index }) => {
        return (
            <ListItem style={styles.listItem} children={<LoadSummary {...item} loadSummaryType="historical" />} />
        )
    }

    const renderListFooter = () => {
        if(isLoading && !isRefreshing) {
            return (
                <View style={styles.loader}>
                    <Loader loadingText="Getting Loads"/>
                </View>
            )
        } else {
            return null;
        }
    }

    const renderListEmptyComponent = () => {
        if(recordCount == 0 && !isLoading) {
            return (
                <EmptyDataSet primaryMessage="No Loads Found" secondaryMessage="Use Filters to Expand your Search" />
            )
        } else {
            return null;
        }
    }

    const handleLoadMore = () => {
        if(loads.length < recordCount && !isLoading) {
            loadMore.current = true;
            setLoadHistoryFilters({
                ...loadHistoryFilters,
                offset: loads.length
            });
        }
    }

    const refreshData = () => {
        setRefreshing(true);
        setLoads([]);
        setRecordCount(null);
        setLoadHistoryFilters({
            ...loadHistoryFilters,
            offset: 0
        });
    }

    const renderLoadHistoryList = () => {
        return (
            <List
                style={styles.listView}
                contentContainerStyle={[styles.listContainer, { justifyContent: loads.length > 0 ? "flex-start" : "center"}]}
                data={loads}
                renderItem={renderLoadSummary}
                ItemSeparatorComponent={Divider}
                keyExtractor={(item, index) => index.toString()}
                ListFooterComponent={renderListFooter}
                ListEmptyComponent={renderListEmptyComponent}
                onEndReached={handleLoadMore}
                onEndReachedThreshold={Constants.END_OF_LIST_THRESHOLD}
                onRefresh={() => refreshData()}
                refreshing={isRefreshing}
            />
        )
    }

    const renderLoadHistoryFilters = () => {
        return (
            <LoadHistoryFilters
                setModalVisible={props.setModalVisible}
                setLoading={setLoading}
                setLoads={setLoads}
                defaultLoadHistoryFilters={defaultLoadHistoryFilters}
                defaultLoadHistoryFilterDisplayValues={defaultLoadHistoryFilterDisplayValues}
                loadHistoryFilters={loadHistoryFilters}
                setLoadHistoryFilters={setLoadHistoryFilters}
                loadHistoryFilterDisplayValues={loadHistoryFilterDisplayValues}
                setLoadHistoryFilterDisplayValues={setLoadHistoryFilterDisplayValues}
            />
        )
    }

    React.useLayoutEffect(() => {
        if(helper.isWeb()) {
            navigation.setOptions({
                title: 'Reports - Load History'
            })
        }
    }, [navigation]);

    return (
        helper.isWeb() ? (
            <>
                <Layout style={styles.layout} level="3">
                    {helper.isDesktopWeb() &&
                        <ScrollView>
                            <View style={styles.main}>
                                <View style={styles.columnOne}>
                                    <Section title="Filters" noBodyPadding={false} renderBody={renderLoadHistoryFilters()} />
                                </View>
                                <View style={styles.columnTwo}>
                                    <Section title="Load History" subtitle={recordCount != null ? recordCount + ' Matching Loads' : ''} noBodyPadding={loads.length > 0 ? true : false} bodyHeight={bodyHeight} renderBody={renderLoadHistoryList()} />
                                </View>
                            </View>
                        </ScrollView>
                    }
                    {helper.isMobileWeb() &&
                        <>
                            {renderLoadHistoryList()}
                        </>
                    }
                </Layout>
                {helper.isMobileWeb() &&
                    <CustomModal title="Filter Loads" modalVisible={props.modalVisible} setModalVisible={props.setModalVisible}>
                        {renderLoadHistoryFilters()}
                    </CustomModal>
                }
            </>
        ) : (
            <Layout style={styles.layout} level="3">
                {renderLoadHistoryList()}
                <CustomModal title="Filter Loads" modalVisible={props.modalVisible} setModalVisible={props.setModalVisible}>
                    {renderLoadHistoryFilters()}
                </CustomModal>
            </Layout>
        )
    )

}

const themedStyles = StyleService.create({
    layout: {
        flex: 1,
        marginTop: helper.isDesktopWeb() ? 5 : 0
    },
    loader: {
        marginTop: 10,
        paddingVertical: 20,
    },
    listContainer: {
        flexGrow: 1
    },
    listView: {
        borderBottomLeftRadius: helper.isDesktopWeb() ? 6 : 0,
        borderBottomRightRadius: helper.isDesktopWeb() ? 6 : 0
    },
    listItem: {
        paddingHorizontal: 0,
        paddingVertical: 0,
    },
    main: {
        flexDirection: "row",
    },
    columnOne: {
        flex: 3,
    },
    columnTwo: {
        flex: 6
    }
});
