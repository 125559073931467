import React, { useEffect, useState } from 'react';
import { Animated, Easing, Dimensions, Pressable, ScrollView, View } from 'react-native';
import { Button, Layout, StyleService, Text, useStyleSheet } from '@ui-kitten/components';
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome';
import * as Constants from 'config/Constants';
import * as helper from 'utility/Helper';

// The web implementation of CustomModal is very similar to native
// but does not use the underlying Modal component from ui-kitten due to
// an issue on mobile web (Android only) when interacting with text inputs (keyboard disappears)
export const CustomModal = (props) => {

    /*
        props:
        - children
        - modalError
        - modalFooter
        - modalVisible
        - setModalVisible
        - title
    */

    const styles = useStyleSheet(themedStyles);
    const position = new Animated.ValueXY({x: 0, y: 1000});
    const opacity = new Animated.Value(0);
    const modalCloseDisabled = props.modalCloseDisabled == true ? true : false;

    useEffect(() => {
        showModal();
    }, [position, opacity])

    const showModal = () => {
        Animated.parallel([
            Animated.timing(
                position,
                {
                    toValue: {x: 0, y: 0},
                    duration: 400,
                    useNativeDriver: true,
                    easing: Easing.inOut(Easing.ease),
                }
            ),
            Animated.timing(
                opacity,
                {
                    toValue: 1,
                    duration: 400,
                    useNativeDriver: true,
                    easing: Easing.inOut(Easing.ease),
                }
            ),
        ]).start(() => {
            //animation complete
        });
    }

    const hideModal = () => {
        Animated.parallel([
            Animated.timing(
                position,
                {
                    toValue: {x: 0, y: 1000},
                    duration: 400,
                    useNativeDriver: true,
                    easing: Easing.inOut(Easing.ease),
                }
            ),
            Animated.timing(
                opacity,
                {
                    toValue: 0,
                    duration: 400,
                    useNativeDriver: true,
                    easing: Easing.inOut(Easing.ease),
                }
            ),
        ]).start(() => {
            //animation complete
            props.setModalVisible(false);
            if(props.setModalError) {
                props.setModalError(null);
            }
        });
    }

    if(!props.modalVisible) {
        return null;
    }

    return (
        <View style={styles.modalView} visible={props.modalVisible}>
            <Animated.View style={[styles.container, { opacity: opacity, transform: [{ translateY: position.y }, { translateX: position.x}] }]}>
                <View style={styles.header}>
                    <Text category="s1">{props.title}</Text>
                    {!modalCloseDisabled &&
                        <View style={styles.closeModal}>
                            <Pressable onPress={() => hideModal()}>
                                <FontAwesomeIcon style={styles.closeIcon} icon={['fal', 'times-circle']} size={styles.closeIcon.size} color={styles.closeIcon.color} />
                            </Pressable>
                        </View>
                    }
                </View>
                <ScrollView>
                    <View style={styles.body}>
                        {props.modalError &&
                            <View style={styles.errorView}>
                                <Text category="s2" status="danger">The following error occurred:</Text>
                                <Text category="p1" status="danger">{props.modalError}</Text>
                            </View>
                        }
                        {props.children}
                    </View>
                    {props.modalFooter &&
                        <View style={styles.footer}>
                            {props.modalFooter}
                        </View>
                    }
                </ScrollView>
            </Animated.View>
        </View>
    )
}

const windowWidth = Dimensions.get('window').width;
const windowHeight = Dimensions.get('window').height;
const modalWidthDesktopWeb = (windowWidth - 250) * .75; // 250 is drawer width 

const themedStyles = StyleService.create({
    modalView: {
        position: "absolute",
        top: 0,
        backgroundColor: "backdrop",
        paddingTop: 20,
        left: 0,
        right: 0,
        alignItems: "center",
    },
    container: {
        backgroundColor: "color-basic-100",
        width: helper.isDesktopWeb() ? modalWidthDesktopWeb : windowWidth,
        height: windowHeight - 20,
        borderTopStartRadius: 20,
        borderTopEndRadius: 20,
    },
    header: {
        paddingTop: 18,
        paddingBottom: 15,
        paddingHorizontal: 15,
        borderBottomColor: "border-generic",
        borderBottomWidth: 1,
    },
    closeModal: {
        position: "absolute",
        top: 15,
        right: 15,
    },
    closeIcon: {
        size: 24,
        color: "color-primary-500",
    },
    body: {
        paddingVertical: 10,
        paddingHorizontal: 15,
    },
    errorView: {
        padding: 10,
        borderWidth: 1,
        borderColor: "color-danger-500",
        marginVertical: 10,
        backgroundColor: "color-danger-transparent-200",
        borderRadius: 4,
    },
    footer: {
        paddingVertical: 15,
        paddingHorizontal: 15,
        borderTopColor: "border-generic",
        borderTopWidth: 1,
    },
});
