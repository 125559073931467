import React, { useEffect, useRef } from 'react';
import { Button, Dimensions, View } from 'react-native';
import { StyleService, Text, useStyleSheet } from '@ui-kitten/components';
import { GoogleMap, Marker, Polyline, useJsApiLoader } from '@react-google-maps/api';
import * as Constants from 'config/Constants';

export const RouteMap = (props) => {

    /*
        props:
        - load
        - markers
        - points
    */

    const styles = useStyleSheet(themedStyles);
    const markers = props.markers;
    const points = props.points;
    const map = useRef();
    const { isLoaded } = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: Constants.GOOGLE_API_KEY
    })

    const onLoad = React.useCallback(function callback(map) {
        const bounds = new window.google.maps.LatLngBounds();
        markers.map((marker, index) => {
            let myLatLng = new google.maps.LatLng(marker.latitude, marker.longitude);
            bounds.extend(myLatLng);
        });
        map.fitBounds(bounds, 30);
    }, [])

    const renderMarkers = () => {
        return markers.map((marker, index) => {
            const coords = {
                lat: marker.latitude,
                lng: marker.longitude
            };
            return (
                <Marker
                    key={index}
                    position={coords}
                    title={marker.stationName}
                    label={(marker.stationName == 'Origin' ? 'O' : (marker.stationName == 'Destination' ? 'D' : 'S'))}
                />
            );
        });
    };

    const polylinePoints = () => {
        let mapPoints = [];
        points.map((point, index) => {
            let coords = {
                lat: point.latitude,
                lng: point.longitude
            };
            mapPoints.push(coords);
        });
        return mapPoints;
    };

    return isLoaded ? (
        <GoogleMap
            mapContainerStyle={styles.mapContainer}
            onLoad={onLoad}
        >
            {renderMarkers()}
            <Polyline
                path={polylinePoints()}
                options={{
                    strokeColor: styles.polyline.color,
                    strokeOpacity: 1,
                    strokeWeight: 3,
                }}
            />
        </GoogleMap>
    ) : <></>
}

const themedStyles = StyleService.create({
    mapContainer: {
        height: 450
    },
    polyline: {
        color: "color-primary-500",
    }
});
