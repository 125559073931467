import React from 'react';
import { Image, View } from 'react-native';
import { Button, Layout, StyleService, Text, useStyleSheet } from '@ui-kitten/components';
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome';
import { Badge, CustomLink } from 'components/utility';
import { TextIcon } from 'components/layout';
import * as Constants from 'config/Constants';
import * as helper from 'utility/Helper';
import getEnvVars from 'root/environment';

export const UserSummary = (props) => {

    /*
        props:
        - allowBookNow
        - createdAt
        - email
        - hasConverted
        - id
        - isActive
        - isDriver
        - name
        - phone
        - roles []
        - updatedAt
        - userPhoto
        - userType
    */

    const styles = useStyleSheet(themedStyles);
    const { sleekApiUrl } = getEnvVars();
    const userImgUri = sleekApiUrl+props.userPhoto;
    const path = '/manage-users/'+props.id;

    return (
        <CustomLink styleProps={styles.customLink} to={path}>
            <View style={styles.container}>
                <View style={styles.columnOne}>
                    <Image
                        source={{
                            uri: userImgUri,
                            headers: Constants.RESOURCE_HEADERS
                        }}
                        style={{width: 50, height: 50, borderRadius: 25}}
                    />
                </View>
                <View style={styles.columnTwo}>
                    <View style={styles.nameView}>
                        <Text style={styles.name} category="s2" status="primary">{props.name}</Text>
                        {props.isActive == "No" &&
                            <View style={styles.status}>
                                <Badge label="Inactive" type="danger" />
                            </View>
                        }
                    </View>
                    <TextIcon iconName="calendar-alt" iconWeight="far" iconSize={styles.icon.size} iconColor={styles.icon.color} text={'Added on '+props.createdAt} textCategory="c2" textAppearance="hint" />
                    <View style={styles.roles}>
                        {helper.inArray('admin', props.roles) &&
                            <Badge noMargin={true} label="Admin" type="info"/>
                        }
                        {helper.inArray('manager', props.roles) &&
                            <Badge noMargin={helper.inArray('admin', props.roles) ? false : true} label="Manager" type="info" />
                        }
                        {helper.inArray('driver', props.roles) &&
                            <Badge noMargin={helper.inArray('admin', props.roles) || helper.inArray('manager', props.roles) ? false : true} label="Driver" type="info" />
                        }
                    </View>
                </View>
                <View style={styles.actionView}>
                    <FontAwesomeIcon style={styles.forwardIcon} icon={['fal', 'angle-right']} size={styles.forwardIcon.size} color={styles.forwardIcon.color} />
                </View>
            </View>
        </CustomLink>
    )
}

const themedStyles = StyleService.create({
    customLink: {
        flex: 1,
    },
    container: {
        flex: 1,
        flexDirection: "row",
        paddingHorizontal: 5,
    },
    columnOne: {
        width: 60,
        justifyContent: "center",
        alignItems: "center",
        paddingRight: 5,
    },
    columnTwo: {
        flex: 10,
        padding: 5,
    },
    columnThree: {
        flex: 1,
        justifyContent: "center",
        alignItems: "center",
    },
    nameView: {
        flexDirection: "row",
        flexWrap: "wrap",
    },
    name: {
        fontSize: 16,
        marginBottom: 3
    },
    status: {
        marginTop: 2
    },
    roles: {
        flexDirection: "row",
        flexWrap: "wrap",
        marginTop: 8
    },
    actionView: {
        position: "absolute",
        right: 5,
        top: "50%",
        marginTop: -10
    },
    forwardIcon: {
        color: "color-primary-500",
        size: 20,
    },
    icon: {
        size: 14,
        color: "color-basic-600"
    }
});
