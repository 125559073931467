import React, {useState, useEffect} from 'react';
import { View } from 'react-native';
import { IndexPath, Select, SelectItem, StyleService, Text, useStyleSheet } from '@ui-kitten/components';

export const SelectField = (props) => {

    const {
        field: { name, onBlur, onChange, values },
        form: { errors, touched, setFieldTouched, setFieldValue },
        ...inputProps
    } = props;

    const hasError = errors[name] && touched[name];
    const styles = useStyleSheet(themedStyles);
    const defaultIndexPath = getDefaultIndex();
    const [selectedIndex, setSelectedIndex] = useState(defaultIndexPath);
    const options = props.options;
    let displayValue = getOptionValue('display');

    function getDefaultIndex() {
        let defaultIndex;

        if(Array.isArray(props.field.value)) {
            defaultIndex = [];
            props.field.value.forEach(item => {
                props.options.filter((option, index) => {
                    if(item == option.value) {
                        defaultIndex.push(new IndexPath(index));
                    }
                });
            });
        } else if(props.field.value != '') {
            props.options.filter((option, index) => {
                if(props.field.value == option.value) {
                    defaultIndex = new IndexPath(index);
                }
            });
        } else {
            defaultIndex = new IndexPath(0);
        }
        return defaultIndex;
    }

    function getOptionValue(type) {
        if(Array.isArray(selectedIndex)) {

            let selectedOptions = [];

            selectedIndex.map(index => {
                if(type == 'display') {
                    selectedOptions.push(options[index.row].label);
                } else {
                    selectedOptions.push(options[index.row].value);
                }
            });

            if(selectedOptions.length == options.length && props.selectAllOverride) {
                if(type == 'display') {
                    return props.selectAllDisplayValue;
                } else {
                    return props.selectAllSubmittedValue;
                }
            } else {
                if(type == 'display') {
                    return selectedOptions.join(', ');
                } else {
                    return selectedOptions;
                }
            }

        } else {
            if(type == 'display') {
                return options[selectedIndex.row].label;
            } else {
                return options[selectedIndex.row].value;
            }
        }
    }

    async function updateField(name, submittedValue) {
        setFieldValue(name, submittedValue, true);
    }

    useEffect(() => {
        let cancel = false;
        displayValue = getOptionValue('display');
        let submittedValue = getOptionValue('submitted');
        updateField(name, submittedValue)
            .then(function(){
                if(cancel) return;
                setFieldTouched(name, true);
            });
        return () => {
            cancel = true;
        }
    }, [selectedIndex]);

    return (
        <>
            <Select
                style={styles.selectInput}
                size="large"
                status="basic"
                selectedIndex={selectedIndex}
                onSelect={(index) => setSelectedIndex(index)}
                onBlur={() => {
                    setFieldTouched(name)
                    onBlur(name)
                }}
                value={displayValue}
                {...inputProps}
            >
                {options.map((item, index) => (
                    <SelectItem key={index} title={item.label} />
                ))}
            </Select>
            {hasError &&
                <View style={styles.errorView}>
                    <Text category="p2" status="danger">{errors[name]}</Text>
                </View>
            }
        </>
    );

}

const themedStyles = StyleService.create({
    selectInput: {
        marginBottom: 10,
    },
    errorView: {
        marginTop: -3,
        marginLeft: 10,
        marginBottom: 10,
    },
    errorInput: {
        borderColor: "color-danger-500"
    }
});
