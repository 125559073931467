import Constants from 'expo-constants';
import * as Updates from 'expo-updates';

const ENV = {
    // @TODO - CT: Update local sleekApiUrl after fixing CORS issues with local dev
    local: {
        sleekApiUrl: "https://app-dev.sleekfleet.com",
        environment: 'local',
    },
    dev: {
        sleekApiUrl: "https://app-dev.sleekfleet.com",
        environment: 'dev',
    },
    test: {
        sleekApiUrl: "https://app-test.sleekfleet.com",
        environment: 'test',
    },
    prod: {
        sleekApiUrl: "https://app.sleekfleet.com",
        environment: 'prod',
    }
};

const hasValue = (value) => {
    if(value != '' && value !== null && typeof value !== 'undefined') {
        return true;
    } else {
        return false;
    }
}

const inArray = (item, array) => {
    if(array.indexOf(item) > -1) {
        return true;
    } else {
        return false;
    }
}

const getEnvVars = () => {

    // Check for a webBuildProfile (e.g., environment) that is passed in during the web build process via Buddy (e.g. WEB_BUILD_PROFILE='dev' expo build:web)
    const webBuildProfile = Constants.expoConfig?.extra?.webBuildProfile;
    const envArray = ['local', 'dev', 'test', 'prod'];

    if(hasValue(webBuildProfile) && inArray(webBuildProfile, envArray)) {
        return ENV[webBuildProfile];
    } else if(Updates.channel === 'prod') {
        return ENV.prod;
    } else if(Updates.channel === 'test') {
        return ENV.test;
    } else if(Updates.channel === 'dev') {  // Note: When running the DEV app (SF DEV) with --dev-client, Updates.channel will always return an empty string
        return ENV.dev;
    } else {
        return ENV.local;
    }
}

export default getEnvVars;
