import React from 'react';
import { Pressable, View } from 'react-native';
import { Divider, StyleService, Text, useStyleSheet } from '@ui-kitten/components';
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome';
import { TextIcon } from 'components/layout';
import * as helper from 'utility/Helper';

export const LocationReview = (props) => {

    /*
        general:
        - isFirstItem
        - isLastItem
        - setActiveModal
        - setClaimDetails
        - setModalVisible
        locationReviewDetails (props):
        - content
        - driverName
        - isFirstItem
        - isLastItem
        - rating
        - reviewDate
        - reviewDateSort
        - reviewImages
    */

    const styles = useStyleSheet(themedStyles);
    const locationReviewDetails = props.locationReviewDetails;

    // Location Rating
    const renderLocationRating = (rating) => {

        switch(rating) {
            case 1:
                return(
                    <View style={styles.ratingWrapper}>
                        <Text category="s2" appearance="hint">Rating:</Text>
                        <View style={styles.ratingView}>
                            <FontAwesomeIcon style={styles.star} icon={['fas', 'star']} size={styles.star.size} color={styles.star.color} />
                            <FontAwesomeIcon style={styles.star} icon={['fal', 'star']} size={styles.star.size} color={styles.star.color} />
                            <FontAwesomeIcon style={styles.star} icon={['fal', 'star']} size={styles.star.size} color={styles.star.color} />
                            <FontAwesomeIcon style={styles.star} icon={['fal', 'star']} size={styles.star.size} color={styles.star.color} />
                            <FontAwesomeIcon style={styles.star} icon={['fal', 'star']} size={styles.star.size} color={styles.star.color} />
                        </View>
                    </View>
                )
            case 2:
                return(
                    <View style={styles.ratingWrapper}>
                        <Text category="s2" appearance="hint">Rating:</Text>
                        <View style={styles.ratingView}>
                            <FontAwesomeIcon style={styles.star} icon={['fas', 'star']} size={styles.star.size} color={styles.star.color} />
                            <FontAwesomeIcon style={styles.star} icon={['fas', 'star']} size={styles.star.size} color={styles.star.color} />
                            <FontAwesomeIcon style={styles.star} icon={['fal', 'star']} size={styles.star.size} color={styles.star.color} />
                            <FontAwesomeIcon style={styles.star} icon={['fal', 'star']} size={styles.star.size} color={styles.star.color} />
                            <FontAwesomeIcon style={styles.star} icon={['fal', 'star']} size={styles.star.size} color={styles.star.color} />
                        </View>
                    </View>
                )
            case 3:
                return(
                    <View style={styles.ratingWrapper}>
                        <Text category="s2" appearance="hint">Rating:</Text>
                        <View style={styles.ratingView}>
                            <FontAwesomeIcon style={styles.star} icon={['fas', 'star']} size={styles.star.size} color={styles.star.color} />
                            <FontAwesomeIcon style={styles.star} icon={['fas', 'star']} size={styles.star.size} color={styles.star.color} />
                            <FontAwesomeIcon style={styles.star} icon={['fas', 'star']} size={styles.star.size} color={styles.star.color} />
                            <FontAwesomeIcon style={styles.star} icon={['fal', 'star']} size={styles.star.size} color={styles.star.color} />
                            <FontAwesomeIcon style={styles.star} icon={['fal', 'star']} size={styles.star.size} color={styles.star.color} />
                        </View>
                    </View>
                )
            case 4:
                return(
                    <View style={styles.ratingWrapper}>
                        <Text category="s2" appearance="hint">Rating:</Text>
                        <View style={styles.ratingView}>
                            <FontAwesomeIcon style={styles.star} icon={['fas', 'star']} size={styles.star.size} color={styles.star.color} />
                            <FontAwesomeIcon style={styles.star} icon={['fas', 'star']} size={styles.star.size} color={styles.star.color} />
                            <FontAwesomeIcon style={styles.star} icon={['fas', 'star']} size={styles.star.size} color={styles.star.color} />
                            <FontAwesomeIcon style={styles.star} icon={['fas', 'star']} size={styles.star.size} color={styles.star.color} />
                            <FontAwesomeIcon style={styles.star} icon={['fal', 'star']} size={styles.star.size} color={styles.star.color} />
                        </View>
                    </View>
                )
            case 5:
                return(
                    <View style={styles.ratingWrapper}>
                        <Text category="s2" appearance="hint">Rating:</Text>
                        <View style={styles.ratingView}>
                            <FontAwesomeIcon style={styles.star} icon={['fas', 'star']} size={styles.star.size} color={styles.star.color} />
                            <FontAwesomeIcon style={styles.star} icon={['fas', 'star']} size={styles.star.size} color={styles.star.color} />
                            <FontAwesomeIcon style={styles.star} icon={['fas', 'star']} size={styles.star.size} color={styles.star.color} />
                            <FontAwesomeIcon style={styles.star} icon={['fas', 'star']} size={styles.star.size} color={styles.star.color} />
                            <FontAwesomeIcon style={styles.star} icon={['fas', 'star']} size={styles.star.size} color={styles.star.color} />
                        </View>
                    </View>
                )
        }

    }

    const showModal = () => {
        props.setLocationReviewDetails(locationReviewDetails);
        props.setActiveModal('LocationReviewDetails');
        props.setModalVisible(true);
    }

    return (
        <Pressable style={helper.isWeb() ? {cursor: "pointer"} : {}} onPress={() => showModal()}>
            <View style={helper.getContainerStyles(props, styles)}>
                <View style={styles.dataView}>
                    <View style={styles.titleView}>
                        <Text style={styles.title} category="s2">{props.driverName}</Text>
                        {props.reviewImages.length > 0 &&
                            <FontAwesomeIcon style={styles.imageIcon} icon={['fal', 'image']} size={styles.imageIcon.size} color={styles.imageIcon.color} />
                        }
                    </View>
                    <TextIcon iconName="calendar-alt" iconWeight="far" iconSize={styles.icon.size} iconColor={styles.icon.color} text={'Submitted on '+props.reviewDate} textCategory="c2" textAppearance="hint" />
                    {renderLocationRating(parseInt(props.rating))}
                    {props.content &&
                        <Text style={styles.content} appearance="hint" category="p1">{props.content}</Text>
                    }
                </View>
                <View style={styles.actionView}>
                    <Text status="primary" category="p2">View</Text>
                </View>
            </View>
        </Pressable>
    )

}

const themedStyles = StyleService.create({
    pressable: {
        minHeight: 75,
    },
    container: {
        borderBottomColor: "border-basic-color-3",
        borderBottomWidth: 1,
        flexDirection: "row",
        flex: 1,
        paddingVertical: 5,
    },
    firstItem: {
        paddingTop: 2,
    },
    lastItem: {
        borderBottomWidth: 0,
        paddingBottom: 0,
    },
    titleView: {
        flexDirection: "row",
        alignItems: "center",
        marginBottom: 3,
    },
    ratingWrapper: {
        flexDirection: "row",
        paddingVertical: 3,
        marginTop: 3,
    },
    ratingView: {
        flexDirection: "row",
        alignItems: "center",
        marginLeft: 3
    },
    starFilled: {
        color: "color-basic-600"
    },
    star: {
        color: "color-basic-600",
        size: 14,
    },
    imageIcon: {
        color: "color-primary-500",
        size: 14,
        marginLeft: 5
    },
    content: {
        marginVertical: 3
    },
    actionView: {
        position: "absolute",
        right: 5,
        top: "50%",
        marginTop: -8
    },
    forwardIcon: {
        color: "color-primary-500",
        size: 20,
    },
    icon: {
        size: 14,
        color: "color-basic-600"
    }
});
