import React, {useContext, useState} from 'react';
import { Dimensions, Image, Pressable, ScrollView, View } from 'react-native';
import { SafeAreaView } from 'react-native-safe-area-context';
import { Button, Layout, StyleService, Text, useStyleSheet } from '@ui-kitten/components';
import { AppDataContext } from 'components/context';
import { CustomModal, Header, ItemAttribute, Section } from 'components/layout';
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome';
import * as helper from 'utility/Helper';
import * as Linking from 'expo-linking';
import * as Constants from 'config/Constants';
import getEnvVars from 'root/environment';

const IMAGE_WIDTH = Dimensions.get('window').width - 30;
const IMAGE_HEIGHT = IMAGE_WIDTH * .75;

export default function MyProfile({ navigation }) {

    const styles = useStyleSheet(themedStyles);
    const [driverLicenseModalVisible, setDriverLicenseModalVisible] = useState(false);
    const [licensePlateModalVisible, setLicensePlateModalVisible] = useState(false);
    const { sleekApiUrl } = getEnvVars();
    const { appData } = useContext(AppDataContext);
    const profileData = appData.carrierUserData;
    const settingsAttributes = [
        {
            name: "Do Not Disturb",
            valueFlex: 2,
            value: (profileData.doNotDisturb == 1 ? 'On Until ' + profileData.doNotDisturbUntil : "Off"),
            display: true,
        },
        {
            name: "System Notifications (SMS)",
            nameFlex: 2,
            value: (profileData.receiveSleekFleetSMSNotifications == 'Yes' ? 'On' : 'Off'),
            display: true,
        },
        {
            name: "New Load Notifications (SMS)",
            nameFlex: 2,
            value: (profileData.receiveNewLoadSMSNotifications == 'Yes' ? 'On' : 'Off'),
            display: true,
        },
        {
            name: "Active Load Notifications (SMS)",
            nameFlex: 2,
            value: (profileData.receiveActiveLoadSMSNotifications == 'Yes' ? 'On' : 'Off'),
            display: true,
        },
        {
            name: "System Notifications (Email)",
            nameFlex: 2,
            value: (profileData.receiveSleekFleetEmailNotifications == 'Yes' ? 'On' : 'Off'),
            display: true,
        },
        {
            name: "New Load Notifications (Email)",
            nameFlex: 2,
            value: (profileData.receiveNewLoadEmailNotifications == 'Yes' ? 'On' : 'Off'),
            display: true,
        },
        {
            name: "Active Load Notifications (Email)",
            nameFlex: 2,
            value: (profileData.receiveActiveLoadEmailNotifications == 'Yes' ? 'On' : 'Off'),
            display: true,
        },
    ];
    const licenseAttributes = [
        {
            name: "DL Number",
            value: profileData.driverLicenseNumber,
            display: true,
        },
        {
            name: "DL State / Province",
            value: profileData.driverLicenseState,
            display: true,
        },
    ];
    const equipmentAttributes = [
        {
            name: "Trailer Type",
            value: profileData.truckType,
            display: true,
        },
        {
            name: "Trailer Length",
            value: profileData.trailerLength,
            display: true,
        },
        {
            name: "Trailer Interior Width",
            value: profileData.trailerWidth,
            display: true,
        },
        {
            name: "Trailer Height",
            value: profileData.trailerHeight,
            display: true,
        },
        {
            name: "Trailer Door Type",
            value: profileData.doorType,
            display: true,
        },
        {
            name: "Max Weight",
            value: (profileData.maxWeightOfLoads != 0 ? profileData.maxWeightOfLoads+' lbs' : ''),
            display: true,
        },
    ];

    const breadcrumbs = [
        {
            label: 'Dashboard',
            path: '/dashboard'
        },
        {
            label: 'My Profile',
            path: ''
        }
    ]

    const renderAttributes = (attributes) => {
        return (
            attributes.map((item, index) => (
                <ItemAttribute
                    {...item}
                    key={index}
                    isFirstItem={index == 0 ? true : false}
                    isLastItem={index + 1 == attributes.length ? true : false}
                />
            ))
        )
    }

    const renderLicenseInformationHeaderRight = () => {
        if(profileData.driverLicensePhoto != '/images/app/logos/default-drivers-license.png') {
            return (
                <Pressable onPress={() => setDriverLicenseModalVisible(true)}>
                    <Text style={{textAlign: "right", fontSize: 14}} status="primary" category="s2">View Driver License</Text>
                </Pressable>
            )
        } else {
            return (
                <Text style={{textAlign: "right", fontSize: 14}} appearance="hint" category="s2">No License Photo</Text>
            )
        }
    }

    const renderEquipmentInformationHeaderRight = () => {
        if(profileData.driverLicensePlatePhoto != '/images/app/logos/default-license-plate.png') {
            return (
                <Pressable onPress={() => setLicensePlateModalVisible(true)}>
                    <Text style={{textAlign: "right", fontSize: 14}} status="primary" category="s2">View License Plate</Text>
                </Pressable>
            )
        } else {
            return (
                <Text style={{textAlign: "right", fontSize: 14}} appearance="hint" category="s2">No License Plate Photo</Text>
            )
        }
    }

    const getPageActions = () => {
        return (
            helper.isDesktopWeb() ? (
                <Button appearance="outline" size="small" onPress={() => navigation.navigate('MyProfileEdit')}>Edit Profile</Button>
            ) : (
                <Pressable onPress={() => navigation.navigate('MyProfileEdit')}>
                    <FontAwesomeIcon style={styles.editIcon} icon={['fal', 'edit']} size={styles.editIcon.size} color={styles.editIcon.color} />
                </Pressable>
            )
        )
    }

    const MyProfileView = () => {
        return (
            <>
                <Layout style={styles.layout} level="3">
                    <View style={styles.personalInformationView}>
                        <Image
                            source={{
                                uri: sleekApiUrl+profileData.userPhoto,
                                headers: Constants.RESOURCE_HEADERS
                            }}
                            style={{width: 100, height: 100, borderRadius: 50}}
                        />
                        <Text style={styles.name} category="h6">{profileData.firstName} {profileData.lastName}</Text>
                        {helper.hasValue(profileData.phone) &&
                            <Pressable onPress={() => Linking.openURL(`tel:${profileData.phone}`)}>
                                <Text style={styles.phone} category="s1" status="primary">{profileData.phone}</Text>
                            </Pressable>
                        }
                        {helper.hasValue(profileData.email) &&
                            <Pressable onPress={() => Linking.openURL(`mailto:${profileData.email}`)}>
                                <Text style={styles.email} category="s1" status="primary">{profileData.email}</Text>
                            </Pressable>
                        }
                    </View>
                    <View style={helper.isDesktopWeb() ? styles.main : {}}>
                        <View style={helper.isDesktopWeb() ? styles.columnOne : {}}>
                            {!helper.isDesktopWeb() &&
                                <Section title="Notification Settings" isFirstItem={true} renderBody={renderAttributes(settingsAttributes)} />
                            }
                            <Section title="License Info" headerLeftFlex={2} headerRightFlex={3} headerRight={renderLicenseInformationHeaderRight()} renderBody={renderAttributes(licenseAttributes)} />
                            <Section title="Equipment Info" isLastItem={true} headerLeftFlex={2} headerRight={renderEquipmentInformationHeaderRight()} renderBody={renderAttributes(equipmentAttributes)} />
                        </View>
                        <View style={helper.isDesktopWeb() ? styles.columnTwo : {}}>
                            {helper.isDesktopWeb() &&
                                <Section title="Notification Settings" isFirstItem={true} renderBody={renderAttributes(settingsAttributes)} />
                            }
                        </View>
                    </View>
                </Layout>
                <CustomModal title="Driver License" modalVisible={driverLicenseModalVisible} setModalVisible={setDriverLicenseModalVisible}>
                    <View style={styles.imageView}>
                        <Image
                            source={{
                                uri: sleekApiUrl+profileData.driverLicensePhoto,
                                headers: Constants.RESOURCE_HEADERS
                            }}
                            style={styles.image}
                            resizeMode="contain"
                        />
                    </View>
                </CustomModal>
                <CustomModal title="License Plate" modalVisible={licensePlateModalVisible} setModalVisible={setLicensePlateModalVisible}>
                    <View style={styles.imageView}>
                        <Image
                            source={{
                                uri: sleekApiUrl+profileData.driverLicensePlatePhoto,
                                headers: Constants.RESOURCE_HEADERS
                            }}
                            style={styles.image}
                            resizeMode="contain"
                        />
                    </View>
                </CustomModal>
            </>
        )
    }

    return (
        helper.isWeb() ? (
            <View style={styles.webView}>
                <Header breadcrumbs={breadcrumbs} title="My Profile" subtitle={'Updated on ' + profileData.updatedAt} pageActions={getPageActions()} />
                <ScrollView>
                    <MyProfileView />
                </ScrollView>
            </View>
        ) : (
            <SafeAreaView style={styles.safeAreaView} edges={['top', 'left', 'right']}>
                <Header activeScreen="MyProfile" subtitle={'Updated on ' + profileData.updatedAt}/>
                <ScrollView>
                    <MyProfileView />
                </ScrollView>
            </SafeAreaView>
        )
    )

}

const themedStyles = StyleService.create({
    // global styles
    layout: {
        flex: 1,
        paddingBottom: 30,
    },
    personalInformationView: {
        backgroundColor: "color-basic-100",
        alignItems: "center",
        paddingTop: 40,
        paddingBottom: 30,
        marginBottom: 8,
        borderBottomLeftRadius: 10,
        borderBottomRightRadius: 10,
        shadowColor: "shadow-color",
        shadowOffset: {
            width: 0,
            height: 2,
        },
        shadowOpacity: .2,
        shadowRadius: 2,
        marginHorizontal: helper.isWeb() ? 10 : 0,
        marginTop: helper.isWeb() ? 10 : 0,
        borderTopLeftRadius: helper.isWeb() ? 10 : 0,
        borderTopRightRadius: helper.isWeb() ? 10 : 0,
    },
    name: {
        marginTop: 15,
    },
    phone: {
        marginTop: 5,
    },
    email: {
        marginTop: 5
    },
    image: {
        width: IMAGE_WIDTH,
        height: IMAGE_HEIGHT
    },
    // native specific styles
    safeAreaView: {
        backgroundColor: "safe-area-view-background",
        flex: 1,
    },
    // web specific styles
    webView: {
        flex: 1,
    },
    main: {
        flexDirection: "row"
    },
    columnOne: {
        flex: 7
    },
    columnTwo: {
        flex: 5
    },
    editIcon: {
        size: 24,
        color: "color-primary-500",
        cursor: "pointer"
    }
});
