import { createNavigationContainerRef } from '@react-navigation/native';
import * as helper from 'utility/Helper';

export const navigationRef = createNavigationContainerRef();

export function navigate(name, params) {
    if (navigationRef.isReady()) {
        navigationRef.navigate(name, params);
    }
}
