import React, { useContext, useState } from 'react';
import { TouchableOpacity, View } from 'react-native';
import { useLinkTo, useNavigation } from '@react-navigation/native';
import { Button, Divider, MenuItem, OverflowMenu, StyleService, Text, useStyleSheet } from '@ui-kitten/components';
import { AuthContext, AppDataContext, NotificationContext } from 'components/context';
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome';
import { Breadcrumbs } from 'components/layout';
import * as Constants from 'config/Constants';
import * as helper from 'utility/Helper';
import getEnvVars from 'root/environment';

export const Header = (props) => {

    /*
        props:
        - breadcrumbs []
        - pageActions
        - subtitle
    */

    const styles = useStyleSheet(themedStyles);
    const { appData } = useContext(AppDataContext);
    const { signOut } = useContext(AuthContext);
    const { unreadNotificationCount, setUnreadNotificationCount } = useContext(NotificationContext);
    const { sleekApiUrl } = getEnvVars();
    const [selectedIndex, setSelectedIndex] = useState(null);
    const [visible, setVisible] = useState(false);
    const linkTo = useLinkTo();
    const navigation = useNavigation();
    const avatarSource = sleekApiUrl+appData.carrierUserData.userPhoto;

    let title = props.title ? props.title : '';
    let subtitle = props.subtitle ? props.subtitle : '';
    let pageActions = props.pageActions ? props.pageActions : null;
    let breadcrumbs = props.breadcrumbs ? props.breadcrumbs : [];

    const onItemSelect = (index) => {
        setSelectedIndex(index);
        setVisible(false);
    }

    const renderToggleButton = () => {
        return (
            <TouchableOpacity onPress={() => setVisible(true)}>
                <FontAwesomeIcon style={styles.headerIcon} icon={['fal', 'user-circle']} size={styles.headerIcon.size} color={styles.headerIcon.color} />
            </TouchableOpacity>
        )
    }

    const NotificationIcon = () => {

        Promise.all([
            helper.getUnreadNotificationCount()
        ]).then((response) => {
            const [unreadNotificationCount] = response;
            setUnreadNotificationCount(unreadNotificationCount);
        });

        return (
            <TouchableOpacity onPress={() => linkTo('/notifications')}>
                <>
                    <FontAwesomeIcon style={styles.headerIcon} icon={['fal', 'bell']} size={styles.headerIcon.size} color={styles.headerIcon.color} />
                    {unreadNotificationCount > 0 &&
                        <View style={styles.notificationIndicator} >
                            <Text style={styles.notificationCount} category="label">{unreadNotificationCount > 9 ? '9+' : unreadNotificationCount}</Text>
                        </View>
                    }
                </>
            </TouchableOpacity>
        )
    }

    return (
        <View style={styles.container}>
            {helper.isMobileWeb() &&
                <>
                    <View style={styles.mobileMenuToggleView}>
                        <TouchableOpacity onPress={() => navigation.toggleDrawer()}>
                            <FontAwesomeIcon style={styles.headerIcon} icon={['fal', 'bars']} size={styles.headerIcon.size} color={styles.headerIcon.color} />
                        </TouchableOpacity>
                    </View>
                    <View style={styles.mobileTitleView}>
                        <Text category="s2">{title}</Text>
                        {subtitle &&
                            <Text style={styles.mobileSubtitle} category="p2" appearance="hint">{subtitle}</Text>
                        }
                    </View>
                    <View style={styles.mobilePageActionsView}>
                        {pageActions}
                    </View>
                </>
            }
            {helper.isDesktopWeb() &&
                <>
                    <View style={styles.breadcrumbsView}>
                        <Breadcrumbs breadcrumbs={breadcrumbs} />
                        <>
                            {subtitle &&
                                <Text category="p2" appearance="hint">{subtitle}</Text>
                            }
                        </>
                    </View>
                    <View style={styles.pageActionsView}>
                        {pageActions}
                    </View>
                    <View style={styles.headerMenuItemContainer}>
                        <NotificationIcon />
                    </View>
                    <View style={styles.headerMenuItemContainer}>
                        <OverflowMenu
                            style={styles.overflowMenu}
                            anchor={renderToggleButton}
                            visible={visible}
                            selectedIndex={selectedIndex}
                            onSelect={onItemSelect}
                            placement="bottom end"
                            backdropStyle={styles.overflowMenuBackdrop}
                            onBackdropPress={() => setVisible(false)}
                        >
                            <MenuItem title="My Profile" onPress={() => linkTo('/my-profile')} />
                            {helper.checkForRoles(['admin']) &&
                                <MenuItem title="Carrier Profile" onPress={() => linkTo('/carrier-profile')} />
                            }
                            {helper.checkForRoles(['admin', 'manager']) &&
                                <MenuItem title="Manage Users" onPress={() => linkTo('/manage-users')} />
                            }
                            {helper.checkForRoles(['manager']) &&
                                <MenuItem title="Preferred Locations" onPress={() => linkTo('/preferred-locations')} />
                            }
                            <MenuItem title="Notifications" onPress={() => linkTo('/notifications')} />
                            <MenuItem title="Support" onPress={() => linkTo('/support')} />
                            <MenuItem title="Logout" onPress={() => signOut()} />
                        </OverflowMenu>
                    </View>
                </>
            }
        </View>
    )
}

const themedStyles = StyleService.create({
    container: {
        backgroundColor: "color-basic-100",
        height: 60,
        borderBottomWidth: 1,
        borderBottomColor: "border-generic",
        flexDirection: "row",
        paddingLeft: 10,
        paddingRight: helper.isDesktopWeb() ? 0 : 10,
    },
    mobileMenuToggleView: {
        flex: 2,
        justifyContent: "center",
    },
    mobileTitleView: {
        flex: 4,
        justifyContent: "center",
        alignItems: "center",
    },
    mobilePageActionsView: {
        flex: 2,
        justifyContent: "center",
        alignItems: "flex-end",
    },
    breadcrumbsView: {
        flex: 1,
        justifyContent: "center",
    },
    mobileSubtitle: {
        alignText: "center"
    },
    pageActionsView: {
        alignItems: "center",
        justifyContent: "center",
        paddingHorizontal: 10,
    },
    headerIcon: {
        size: 24,
        color: "color-primary-500"
    },
    headerMenuItemContainer: {
        width: 60,
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: "color-basic-200",
        borderLeftWidth: 1,
        borderLeftColor: "color-basic-400",
        paddingHorizontal: 15
    },
    overflowMenu: {
        width: 250,
        maxHeight: null
    },
    overflowMenuBackdrop: {
        backgroundColor: 'rgba(0, 0, 0, 0.2)',
    },
    notificationIndicator: {
        height: 20,
        width: 20,
        backgroundColor: "color-primary-500",
        borderRadius: 9,
        top: -7,
        right: -7,
        position: "absolute",
        justifyContent: "center"
    },
    notificationCount: {
        color: "color-basic-100",
        textAlign: "center",
        fontSize: 12,
    }
});
