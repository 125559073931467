import React, { useContext, useState, useEffect } from 'react';
import { createMaterialTopTabNavigator } from '@react-navigation/material-top-tabs';
import { CommonActions, useFocusEffect } from "@react-navigation/native";
import { View } from 'react-native';
import { SafeAreaView } from 'react-native-safe-area-context';
import { Layout, StyleService, Tab, TabBar, Text, useStyleSheet } from '@ui-kitten/components';
import { AuthContext } from 'components/context';
import { Header } from 'components/layout';
import { Loader } from 'components/utility';
import * as api from 'api/SleekTechnologiesApi';
import { DriverProfile, DriverActiveLoads, DriverUpcomingLoads, DriverCompletedLoads } from 'screens/drivers/tabs';
import * as helper from 'utility/Helper';

export default function DriverDetails({ route, navigation }) {

    const styles = useStyleSheet(themedStyles);
    const { signOut } = useContext(AuthContext);
    const { Navigator, Screen } = createMaterialTopTabNavigator();
    const [isLoading, setLoading] = useState(true);
    const [driverDetails, setDriverDetails] = useState([]);
    const [breadcrumbs, setBreadcrumbs] = useState([]);

    const TopTabBar = ({ navigation, state }) => (
        <TabBar
            selectedIndex={state.index}
            onSelect={index => navigation.navigate(state.routeNames[index])}>
            <Tab title="Profile"/>
            <Tab title="Active"/>
            <Tab title="Upcoming"/>
            <Tab title="Completed"/>
        </TabBar>
    );

    useEffect(() => {
        api.getDriverDetails(route.params.driverId)
            .then(function(response){
                if(helper.hasValue(response.data.msg) && response.data.msg == "Token Not Found or Expired") {
                    signOut();
                }
                if(response.data.result == "success") {
                    setDriverDetails(response.data.driverDetails);
                    setBreadcrumbs(
                        [
                            {
                                label: 'Dashboard',
                                path: '/dashboard'
                            },
                            {
                                label: 'Drivers',
                                path: '/drivers'
                            },
                            {
                                label: response.data.driverDetails.name,
                                path: ''
                            }
                        ]
                    );
                    setLoading(false);
                } else if(response.data.result == "error") {
                    throw {
                        type: "custom",
                        message: response.data.msg
                    }
                }
            })
            .catch(function(error){
                setLoading(false);
                helper.handleCatchError(error);
            });
    }, []);

    if(isLoading) {
        return (
            <Loader loadingText="Getting Driver Data" />
        )
    } else {
        return (
            helper.isWeb() ? (
                <View style={styles.webView}>
                    <Header breadcrumbs={breadcrumbs} title="Driver Profile" subtitle={driverDetails.name ? driverDetails.name : ''} />
                    <Navigator style={styles.navigator} tabBar={props => <TopTabBar {...props} />} initialRouteName={helper.hasValue(route.params?.tab) ? route.params.tab : 'profile'} backBehavior="history">
                        <Screen
                            name="profile"
                            children={() => <DriverProfile driverDetails={driverDetails}/>}
                        />
                        <Screen
                            name="active-loads"
                            children={() => <DriverActiveLoads activeLoad={driverDetails.activeLoad} hasActiveLoad={driverDetails.hasActiveLoad}/>}
                        />
                        <Screen
                            name="upcoming-loads"
                            children={() => <DriverUpcomingLoads upcomingLoads={driverDetails.upcomingLoads} />}
                        />
                        <Screen
                            name="completed-loads"
                            children={() => <DriverCompletedLoads completedLoads={driverDetails.completedLoads} />}
                        />
                    </Navigator>
                </View>
            ) : (
                <SafeAreaView style={styles.safeAreaView} edges={['top', 'left', 'right']}>
                    <Header activeScreen="DriverDetails" title="Driver Profile" params={route.params} driverDetails={driverDetails}/>
                    <Navigator style={styles.navigator} tabBar={props => <TopTabBar {...props} />} initialRouteName={helper.hasValue(route.params?.tab) ? route.params.tab : 'profile'}>
                        <Screen
                            name="profile"
                            children={() => <DriverProfile driverDetails={driverDetails}/>}
                        />
                        <Screen
                            name="active-loads"
                            children={() => <DriverActiveLoads activeLoad={driverDetails.activeLoad} hasActiveLoad={driverDetails.hasActiveLoad}/>}
                        />
                        <Screen
                            name="upcoming-loads"
                            children={() => <DriverUpcomingLoads upcomingLoads={driverDetails.upcomingLoads} />}
                        />
                        <Screen
                            name="completed-loads"
                            children={() => <DriverCompletedLoads completedLoads={driverDetails.completedLoads} />}
                        />
                    </Navigator>
                </SafeAreaView>
            )
        )
    }
}

const themedStyles = StyleService.create({
    // global styles
    navigator: {
        backgroundColor: "color-basic-400"
    },
    // native specific styles
    safeAreaView: {
        backgroundColor: "safe-area-view-background",
        flex: 1,
    },
    // web specific styles
    webView: {
        flex: 1,
    },
});
