import React, { useState } from 'react';
import { Dimensions, Image, View } from 'react-native';
import { Layout, StyleService, Text, useStyleSheet } from '@ui-kitten/components';
import { Loader } from 'components/utility';
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome';
import Swiper from 'react-native-web-swiper';
import * as Constants from 'config/Constants';
import * as helper from 'utility/Helper';
import getEnvVars from 'root/environment';

export default function LocationReviewDetails(props) {

    /*
        locationReviewDetails:
        - content
        - driverName
        - isFirstItem
        - isLastItem
        - rating
        - reviewDate
        - reviewDateSort
        - reviewImages
    */

    const styles = useStyleSheet(themedStyles);
    const locationReview = props.locationReviewDetails;
    const [isLoading, setLoading] = useState(false);
    const { sleekApiUrl } = getEnvVars();

    // Location Rating
    const renderLocationRating = (rating) => {

        switch(rating) {
            case 1:
                return(
                    <View style={styles.ratingWrapper}>
                        <Text category="s2" appearance="hint">Rating:</Text>
                        <View style={styles.ratingView}>
                            <FontAwesomeIcon style={styles.star} icon={['fas', 'star']} size={styles.star.size} color={styles.star.color} />
                            <FontAwesomeIcon style={styles.star} icon={['fal', 'star']} size={styles.star.size} color={styles.star.color} />
                            <FontAwesomeIcon style={styles.star} icon={['fal', 'star']} size={styles.star.size} color={styles.star.color} />
                            <FontAwesomeIcon style={styles.star} icon={['fal', 'star']} size={styles.star.size} color={styles.star.color} />
                            <FontAwesomeIcon style={styles.star} icon={['fal', 'star']} size={styles.star.size} color={styles.star.color} />
                        </View>
                    </View>
                )
            case 2:
                return(
                    <View style={styles.ratingWrapper}>
                        <Text category="s2" appearance="hint">Rating:</Text>
                        <View style={styles.ratingView}>
                            <FontAwesomeIcon style={styles.star} icon={['fas', 'star']} size={styles.star.size} color={styles.star.color} />
                            <FontAwesomeIcon style={styles.star} icon={['fas', 'star']} size={styles.star.size} color={styles.star.color} />
                            <FontAwesomeIcon style={styles.star} icon={['fal', 'star']} size={styles.star.size} color={styles.star.color} />
                            <FontAwesomeIcon style={styles.star} icon={['fal', 'star']} size={styles.star.size} color={styles.star.color} />
                            <FontAwesomeIcon style={styles.star} icon={['fal', 'star']} size={styles.star.size} color={styles.star.color} />
                        </View>
                    </View>
                )
            case 3:
                return(
                    <View style={styles.ratingWrapper}>
                        <Text category="s2" appearance="hint">Rating:</Text>
                        <View style={styles.ratingView}>
                            <FontAwesomeIcon style={styles.star} icon={['fas', 'star']} size={styles.star.size} color={styles.star.color} />
                            <FontAwesomeIcon style={styles.star} icon={['fas', 'star']} size={styles.star.size} color={styles.star.color} />
                            <FontAwesomeIcon style={styles.star} icon={['fas', 'star']} size={styles.star.size} color={styles.star.color} />
                            <FontAwesomeIcon style={styles.star} icon={['fal', 'star']} size={styles.star.size} color={styles.star.color} />
                            <FontAwesomeIcon style={styles.star} icon={['fal', 'star']} size={styles.star.size} color={styles.star.color} />
                        </View>
                    </View>
                )
            case 4:
                return(
                    <View style={styles.ratingWrapper}>
                        <Text category="s2" appearance="hint">Rating:</Text>
                        <View style={styles.ratingView}>
                            <FontAwesomeIcon style={styles.star} icon={['fas', 'star']} size={styles.star.size} color={styles.star.color} />
                            <FontAwesomeIcon style={styles.star} icon={['fas', 'star']} size={styles.star.size} color={styles.star.color} />
                            <FontAwesomeIcon style={styles.star} icon={['fas', 'star']} size={styles.star.size} color={styles.star.color} />
                            <FontAwesomeIcon style={styles.star} icon={['fas', 'star']} size={styles.star.size} color={styles.star.color} />
                            <FontAwesomeIcon style={styles.star} icon={['fal', 'star']} size={styles.star.size} color={styles.star.color} />
                        </View>
                    </View>
                )
            case 5:
                return(
                    <View style={styles.ratingWrapper}>
                        <Text category="s2" appearance="hint">Rating:</Text>
                        <View style={styles.ratingView}>
                            <FontAwesomeIcon style={styles.star} icon={['fas', 'star']} size={styles.star.size} color={styles.star.color} />
                            <FontAwesomeIcon style={styles.star} icon={['fas', 'star']} size={styles.star.size} color={styles.star.color} />
                            <FontAwesomeIcon style={styles.star} icon={['fas', 'star']} size={styles.star.size} color={styles.star.color} />
                            <FontAwesomeIcon style={styles.star} icon={['fas', 'star']} size={styles.star.size} color={styles.star.color} />
                            <FontAwesomeIcon style={styles.star} icon={['fas', 'star']} size={styles.star.size} color={styles.star.color} />
                        </View>
                    </View>
                )
        }

    }

    const renderLocationImages = () => {
        return (
            locationReview.reviewImages.map((item, index) => (
                <View style={styles.locationImageCarouselItem} key={index}>
                    {isLoading &&
                        <View style={styles.locationImageLoaderView}>
                            <Loader />
                        </View>
                    }
                    <Image
                        source={{
                            uri: sleekApiUrl+item,
                            headers: Constants.RESOURCE_HEADERS
                        }}
                        style={styles.locationImage}
                        onLoadStart={() => setLoading(true)}
                        onLoadEnd={() => setLoading(false)}
                        resizeMode="contain"
                    />
                    {!isLoading &&
                        <Text style={styles.locationImageCount} category="p1" appearance="hint">Image {index + 1} of {locationReview.reviewImages.length}</Text>
                    }
                </View>
            ))
        )
    }

    const renderLocationCarousel = () => {

        if(locationReview.reviewImages.length > 0) {
            return (
                <Swiper controlsProps={{
                    dotsWrapperStyle: {
                        marginTop: styles.dotsWrapper.marginTop
                    },
                    dotActiveStyle: {
                        backgroundColor: styles.dots.backgroundColor
                    },
                    prevTitleStyle: {
                        color: styles.navigation.color,
                        marginTop: styles.navigation.marginTop
                    },
                    nextTitleStyle: {
                        color: styles.navigation.color,
                        marginTop: styles.navigation.marginTop
                    }
                }}>
                    {renderLocationImages()}
                </Swiper>
            )
        } else {
            return null;
        }
    }

    return (
        <View style={styles.container}>
            <View style={styles.locationReviewInformationView}>
                <Text category="s1">Review by {locationReview.driverName}</Text>
                {renderLocationRating(parseInt(locationReview.rating))}
                {locationReview.content &&
                    <>
                        <Text category="s2" appearance="hint">Comments:</Text>
                        <Text style={styles.reviewContent} category="p1" appearance="hint">{locationReview.content}</Text>
                    </>
                }
            </View>
            <View style={styles.locationImagesView}>
                {renderLocationCarousel()}
            </View>
        </View>
    )

}

const windowWidth = Dimensions.get('window').width;
const itemSize = helper.isDesktopWeb() ? (windowWidth * .65) - 30 : windowWidth - 30;

const themedStyles = StyleService.create({
    container: {
        flex: 1,
    },
    locationImageLoaderView: {
        top: 150,
    },
    locationImagesView: {
        marginTop: 20,
        width: itemSize,
        height: itemSize + 100,
    },
    locationImageCarouselItem: {
        width: itemSize,
        backgroundColor: "color-basic-200",
        borderRadius: 8,
        borderWidth: 1,
        borderColor: "color-basic-400",
        paddingBottom: 10,
        marginBottom: 20,
        elevation: 7,
    },
    locationImage: {
        width: itemSize,
        height: itemSize,
        borderTopLeftRadius: 8,
        borderTopRightRadius: 8,
    },
    locationImageCount: {
        paddingTop: 17,
        paddingBottom: 40,
        textAlign: "center",
        fontStyle: "italic",
    },
    dotsWrapper: {
        marginTop: -35
    },
    dots: {
        backgroundColor: "color-primary-500"
    },
    navigation: {
        color: "color-primary-500",
        marginTop: -30
    },
    reviewContent: {
        fontStyle: "italic",
    },
    ratingWrapper: {
        flexDirection: "row",
        paddingVertical: 5,
    },
    ratingView: {
        flexDirection: "row",
    },
    starFilled: {
        color: "color-basic-600"
    },
    star: {
        color: "color-basic-600",
        size: 18,
    },
});
