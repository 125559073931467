import React, { useState, useContext } from 'react';
import { Pressable, View } from 'react-native';
import { Button, Layout, StyleService, Text, useStyleSheet } from '@ui-kitten/components';
import { AppDataContext, AuthContext } from 'components/context';
import { Formik, Field } from 'formik';
import { InputField, CheckBoxField, SelectField } from 'components/forms';
import { UploadPreview } from 'components/utility';
import { Loader } from 'components/utility';
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome';
import * as yup from 'yup';
import * as helper from 'utility/Helper';
import * as api from 'api/SleekTechnologiesApi';
import * as Constants from 'config/Constants';
import moment from 'moment';

export default function CarrierW9(props) {

    const styles = useStyleSheet(themedStyles);
    const { appData, setAppData } = useContext(AppDataContext);
    const { signOut } = useContext(AuthContext);
    const [isLoading, setLoading] = useState(false);
    const [uploads, setUploads] = useState([]);
    const stateProvinceOptions = Constants.STATE_PROVICE_OPTIONS;
    const taxClassificationOptions = Constants.TAX_CLASSIFICATION_OPTIONS;
    const taxIdTypeOptions = Constants.TAX_ID_TYPE_OPTIONS;
    const w9Methods = [
        {
            label: "Please Choose One",
            value: ""
        },
        {
            label: "Online Form",
            value: "Online Form"
        },
        {
            label: "PDF Upload",
            value: "PDF Upload"
        },
    ]

    const validationSchema = yup.object().shape({
        name: yup
            .string()
            .required('Name is Required'),
        address: yup
            .string()
            .required('Address is Required'),
        city: yup
            .string()
            .required('City is Required'),
        state: yup
            .string()
            .required('State / Province is Required'),
        zip: yup
            .string()
            .required('Zip is Required'),
        taxClassification: yup
            .string()
            //.required('Tax Classification is Required')
            .when('name', {is: value => value && value != '', then: yup.string().required('Tax Classification is Required')}),  //Using this as a workaround so the validation doesn't automatically trigger on load
        taxClassificationOther: yup
            .string()
            .when('taxClassification', {is: value => value && value == 'other', then: yup.string().required('Other Tax Classification is Required')}),
        taxIdType: yup
            .string()
            //.required('Tax Id Type is Required'),
            .when('name', {is: value => value && value != '', then: yup.string().required('Tax Id Type is Required')}),         //Using this as a workaround so the validation doesn't automatically trigger on load
        taxId: yup
            .string()
            .required('Tax Id is Required'),
        signature: yup
            .string()
            .required('Signature is Required'),
    });

    const w9Settings = {
        formMethod: '',
        name: '',
        businessName: '',
        address: (appData.carrierData.address == 'null' ? '' : appData.carrierData.address),
        city: (appData.carrierData.city == 'null' ? '' : appData.carrierData.city),
        state: (appData.carrierData.state == 'null' ? '' : appData.carrierData.state),
        zip: (appData.carrierData.zip == 'null' ? '' : appData.carrierData.zip),
        taxClassification: '',
        taxClassificationOther: '',
        taxIdType: '',
        taxId: '',
        exemptPayeeCode: '',
        fatcaExemption: '',
        backupWithholding: false,
        accountNumbers: '',
        signature: '',
    }

    const renderDate = () => {

        const todaysDate = moment().format("MM/DD/YY");

        return (
            <View style={styles.calendarView}>
                <FontAwesomeIcon style={styles.calendarIcon} icon={['fal', 'calendar']} size={styles.calendarIcon.size} color={styles.calendarIcon.color} />
                <Text category="s2" appearance="hint">{todaysDate}</Text>
            </View>
        )
    }

    const pickDocument = async () => {

        let result = await helper.pickDocument();

        if(result) {
            let newUploads = uploads;
            newUploads.push(result);
            setUploads([...newUploads]);
        }
    }

    const UploadsList = () => {
        return (
            uploads.map((item, index) => {
                let uploadNumber = index + 1;
                item.uploadMethod = 'documentPicker';
                item.uploadTitle = 'Uploaded W-9'
                item.index = index;

                return (
                    <UploadPreview key={index} removeUpload={removeUpload} {...item} />
                )
            })
        )
    }

    const resetUploads = () => {
        let newUploads = [];
        setUploads([...newUploads]);
    }

    const removeUpload = (index) => {
        let newUploads = uploads;
        newUploads.splice(index, 1);
        setUploads([...newUploads]);
    }

    const submitW9 = (data) => {

        setLoading(true);
        props.setModalError(null);

        //Submit W-9
        api.submitW9(data)
            .then(function(response) {
                if(helper.hasValue(response.data.msg) && response.data.msg == "Token Not Found or Expired") {
                    signOut();
                }
                if(response.data.result == "success") {
                    api.getApplicationData()
                        .then(function(response){
                            setLoading(false);
                            if(response.data.result == "success") {
                                setAppData({...response.data});
                                props.setModalVisible(false);
                                props.setModalError(null);
                                helper.showFlashMessage(response.data.result, "W-9 Successfully Submitted");
                            } else if(response.data.result == "error") {
                                throw {
                                    type: "custom",
                                    message: response.data.msg
                                }
                            }
                        })
                        .catch(function(error){
                            setLoading(false);
                            helper.handleCatchError(error, true, props.setModalError);
                        });
                } else if(response.data.result == "error") {
                    throw {
                        type: "custom",
                        message: response.data.msg
                    }
                }
            })
            .catch(function(error){
                setLoading(false);
                helper.handleCatchError(error, true, props.setModalError);
            });
    };

    const submitW9Pdf = () => {

        setLoading(true);
        props.setModalError(null);

        //Submit W-9 PDF
        let documentContents = (helper.isWeb() ? uploads[0].assets[0].uri :  uploads[0].assets[0]);
        let uploadType = (helper.isWeb() ? 'base64' :  'document');
        api.submitW9Pdf(documentContents, uploadType)
            .then(function(response) {
                if(helper.hasValue(response.data.msg) && response.data.msg == "Token Not Found or Expired") {
                    signOut();
                }
                if(response.data.result == "success") {
                    api.getApplicationData()
                        .then(function(response){
                            setLoading(false);
                            if(response.data.result == "success") {
                                setAppData({...response.data});
                                props.setModalVisible(false);
                                props.setModalError(null);
                                helper.showFlashMessage(response.data.result, "W-9 Successfully Submitted");
                            } else if(response.data.result == "error") {
                                throw {
                                    type: "custom",
                                    message: response.data.msg
                                }
                            }
                        })
                        .catch(function(error){
                            setLoading(false);
                            helper.handleCatchError(error, true, props.setModalError);
                        });
                } else if(response.data.result == "error") {
                    throw {
                        type: "custom",
                        message: response.data.msg
                    }
                }
            })
            .catch(function(error){
                setLoading(false);
                helper.handleCatchError(error, true, props.setModalError);
            });
    };

    if(isLoading) {
        return (
            <Loader loadingText="Submitting W-9 Form" />
        )
    } else {
        return (
            <>
                <Formik
                    validationSchema={validationSchema}
                    initialValues={w9Settings}
                    onSubmit={(values) => submitW9(values)}
                >
                    {({ handleSubmit, isValid, values }) => (
                        <>
                            <View style={styles.formSection}>
                                <View style={styles.formSectionTitle}>
                                    <Text category="s1">W-9 Method</Text>
                                </View>
                                <View style={styles.formSectionFields}>
                                    <View style={styles.formSectionIntro}>
                                        <Text category="p1" appearance="hint">Please choose whether you would like to upload a completed W-9 in PDF format or fill out an online form with the required information.</Text>
                                    </View>
                                        <Field
                                            component={SelectField}
                                            name="formMethod"
                                            options={w9Methods}
                                            multiSelect={false}
                                            defaultValue={''}
                                        />
                                </View>
                            </View>
                            {values.formMethod == 'Online Form' &&
                                <>
                                    <View style={styles.formSection}>
                                        <View style={styles.formSectionTitle}>
                                            <Text category="s1">Basic Information</Text>
                                        </View>
                                        <View style={styles.formSectionFields}>
                                            <Field
                                                component={InputField}
                                                name="name"
                                                label="Name (as shown on tax return)"
                                                defaultValue={''}
                                            />
                                            <Field
                                                component={InputField}
                                                name="businessName"
                                                label="Business Name (if different than above)"
                                                defaultValue={''}
                                            />
                                            <Field
                                                component={InputField}
                                                name="address"
                                                label="Address"
                                                defaultValue={appData.carrierData.address == 'null' ? '' : appData.carrierData.address}
                                            />
                                            <Field
                                                component={InputField}
                                                name="city"
                                                label="City"
                                                defaultValue={appData.carrierData.city == 'null' ? '' : appData.carrierData.city}
                                            />
                                            <Field
                                                component={SelectField}
                                                name="state"
                                                label="State / Province"
                                                options={stateProvinceOptions}
                                                multiSelect={false}
                                                defaultValue={appData.carrierData.state == 'null' ? '' : appData.carrierData.state}
                                            />
                                            <Field
                                                component={InputField}
                                                name="zip"
                                                label="Zip"
                                                defaultValue={appData.carrierData.zip == 'null' ? '' : appData.carrierData.zip}
                                            />
                                        </View>
                                    </View>
                                    <View style={styles.formSection}>
                                        <View style={styles.formSectionTitle}>
                                            <Text category="s1">Tax Information</Text>
                                        </View>
                                        <View style={styles.formSectionFields}>
                                            <Field
                                                component={SelectField}
                                                name="taxClassification"
                                                label="Tax Classification"
                                                options={taxClassificationOptions}
                                                multiSelect={false}
                                                defaultValue={''}
                                            />
                                            {values.taxClassification == 'other' &&
                                                <Field
                                                    component={InputField}
                                                    name="taxClassificationOther"
                                                    label="Tax Classification Other (Please Specify)"
                                                    defaultValue={''}
                                                />
                                            }
                                            <Field
                                                component={SelectField}
                                                name="taxIdType"
                                                label="Tax Id Type"
                                                options={taxIdTypeOptions}
                                                multiSelect={false}
                                                defaultValue={''}
                                            />
                                            <Field
                                                component={InputField}
                                                name="taxId"
                                                label="Tax Id"
                                                placeholder={values.taxIdType == 'ssn' ? 'SSN' : 'EIN'}
                                                defaultValue={''}
                                            />
                                        </View>
                                    </View>
                                    <View style={styles.formSection}>
                                        <View style={styles.formSectionTitle}>
                                            <Text category="s1">Exemptions</Text>
                                        </View>
                                        <View style={styles.formSectionFields}>
                                            <Field
                                                component={InputField}
                                                name="exemptPayeeCode"
                                                label="Exempt Payee Code (if any)"
                                                defaultValue={''}
                                            />
                                            <Field
                                                component={InputField}
                                                name="fatcaExemption"
                                                label="Exemption from FATCA reporting code (if any)"
                                                defaultValue={''}
                                            />
                                            <View style={styles.checkboxView}>
                                                <Field
                                                    component={CheckBoxField}
                                                    name="backupWithholding"
                                                    label="I am subject to Backup Withholding"
                                                    defaultValue={false}
                                                />
                                            </View>
                                        </View>
                                    </View>
                                    <View style={styles.formSection}>
                                        <View style={styles.formSectionTitle}>
                                            <Text category="s1">Other Information</Text>
                                        </View>
                                        <View style={styles.formSectionFields}>
                                            <Field
                                                component={InputField}
                                                name="accountNumbers"
                                                label="List account number(s) here (optional)"
                                                defaultValue={''}
                                            />
                                        </View>
                                    </View>
                                    <View style={styles.formSection}>
                                        <View style={styles.formSectionTitle}>
                                            <Text category="s1">Signature</Text>
                                        </View>
                                        <View style={styles.formSectionFields}>
                                            <View style={styles.agreementView}>
                                                <Text category="s2">Under penalties of perjury, I certify that:</Text>
                                                <View style={styles.agreementList}>
                                                    <Text style={styles.agreementListItem} category="p2" appearance="hint">1. The number shown on this form is my correct taxpayer identification number (or I am waiting for a number to be issued to me); and</Text>
                                                    <Text style={styles.agreementListItem} category="p2" appearance="hint">2. I am not subject to backup withholding because: (a) I am exempt from backup withholding, or (b) I have not been notified by the Internal Revenue Service (IRS) that I am subject to backup withholding as a result of a failure to report all interest or dividends, or (c) the IRS has notified me that I am no longer subject to backup withholding; and</Text>
                                                    <Text style={styles.agreementListItem} category="p2" appearance="hint">3. I am a U.S. citizen or other U.S. person (defined in the official IRS W-9 Form); and</Text>
                                                    <Text style={styles.agreementListItem} category="p2" appearance="hint">4. The FATCA code(s) entered on this form (if any) indicating that I am exempt from FATCA reporting is correct.</Text>
                                                </View>
                                                <Text category="p2" appearance="hint">If you are subject to backup withholding due to the reasons described in Part II of the official IRS W-9 Form, please check the "I am Subject to Backup Withholding" box above.</Text>
                                            </View>
                                            <Field
                                                component={InputField}
                                                name="signature"
                                                label="Signature"
                                                placeholder="Type Your Name Here..."
                                                accessoryRight={renderDate}
                                                defaultValue={''}
                                            />
                                        </View>
                                    </View>
                                    <View style={styles.submitButtonView}>
                                        <Button onPress={handleSubmit} disabled={!isValid}>Submit W9</Button>
                                    </View>
                                </>
                            }
                            {values.formMethod == 'PDF Upload' &&
                                <>
                                    <View style={styles.formSection}>
                                        <View style={styles.formSectionTitle}>
                                            <Text category="s1">Upload W-9</Text>
                                        </View>
                                        <View style={styles.formSectionFields}>
                                            {uploads.length == 0 &&
                                                <>
                                                    <Text style={styles.formSectionIntro} category="p1" appearance="hint">Please choose a PDF document to upload.</Text>
                                                    <View style={styles.buttonView}>
                                                        <Button style={styles.button} appearance="outline" size="small" onPress={pickDocument}>Choose File...</Button>
                                                    </View>
                                                </>
                                            }
                                            {uploads.length > 0 &&
                                                <>
                                                    <View>
                                                        <UploadsList />
                                                    </View>
                                                    <View style={styles.buttonView}>
                                                        <Button style={styles.button} appearance="outline" size="small" onPress={resetUploads}>Cancel</Button>
                                                    </View>
                                                    <View style={styles.submitButtonView}>
                                                        <Button style={styles.button} onPress={submitW9Pdf}>Submit W9</Button>
                                                    </View>
                                                </>
                                            }
                                        </View>
                                    </View>
                                </>
                            }
                        </>
                    )}
                </Formik>
            </>
        )
    }
}

const themedStyles = StyleService.create({
    formMethodInstructions: {
        paddingVertical: 10,
    },
    formMethodToggle: {
        paddingVertical: 5
    },
    formSection: {
        backgroundColor: "color-basic-100",
        marginTop: 5,
    },
    formSectionTitle: {
        backgroundColor: "background-basic-color-3",
        paddingHorizontal: 10,
        paddingVertical: 10,
        alignItems: "center",
        flexDirection: "row",
        borderBottomColor: "border-generic",
        borderBottomWidth: 1,
    },
    formSectionFields: {
        paddingVertical: 10,
        paddingHorizontal: 10,
    },
    formSectionFieldsNoVerticalPadding: {
        paddingVertical: 0,
    },
    formSectionIntro: {
        marginBottom: 10,
    },
    checkboxView: {
        paddingVertical: 10,
    },
    agreementView: {
        paddingVertical: 15,
    },
    agreementList: {
        paddingLeft: 20,
        paddingVertical: 15,
    },
    agreementListItem: {
        paddingVertical: 5
    },
    calendarView: {
        flexDirection: "row",
        flexWrap: "wrap",
        alignItems: "center",
    },
    calendarIcon: {
        size: 18,
        color: "color-basic-600",
        marginRight: 5
    },
    submitButtonView: {
        marginBottom: 40,
        marginTop: 20
    },
    buttonView: {
        flex: 1,
    },
    button: {
        marginBottom: 10,
    },
    buttonLeft: {
        marginRight: 5,
    },
    buttonRight: {
        marginLeft: 5
    },
});
