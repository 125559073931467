import React from 'react';
import { View } from 'react-native';
import { Divider, Layout, StyleService, Text, useStyleSheet } from '@ui-kitten/components';
import { AccessorialScheduleItem } from 'components/loads';

export default function AccessorialSchedule(props) {

    const styles = useStyleSheet(themedStyles);
    const loadDetails = props.loadDetails;

    return (
        loadDetails.accessorialSchedule.map((item, index) => (
            <AccessorialScheduleItem
                {...item}
                key={index}
                isFirstItem={index == 0 ? true : false}
                isLastItem={index + 1 == loadDetails.accessorialSchedule.length ? true : false}
            />
        ))
    )
}

const themedStyles = StyleService.create({
    container: {
        // styles here
    },
});
