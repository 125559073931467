import React from 'react';
import * as SecureStore from 'expo-secure-store';
import * as Notifications from 'expo-notifications';
import { Alert, Dimensions, Platform } from 'react-native';
import { AppDataContext } from 'components/context';
import Rollbar from 'rollbar/src/react-native/rollbar';
import Constants from 'expo-constants';
import AsyncStorage from '@react-native-async-storage/async-storage';
import * as AppConstants from 'config/Constants';
import * as ImagePicker from 'expo-image-picker';
import * as ImageManipulator from 'expo-image-manipulator';
import * as DocumentPicker from 'expo-document-picker';
import * as StoreReview from 'expo-store-review';
import moment from 'moment';
import { showMessage, hideMessage } from 'react-native-flash-message';
import getEnvVars from 'root/environment';

const { environment } = getEnvVars();

export const isWeb = () => {
    if(Platform.OS == 'web') {
        return true;
    } else {
        return false;
    }
}

export const isMobileWeb = () => {

    const windowWidth = Dimensions.get('window').width;

    if(Platform.OS == 'web' && windowWidth < AppConstants.MOBILE_WEB_BREAKPOINT) {
        return true;
    } else {
        return false;
    }
}

export const isDesktopWeb = () => {

    const windowWidth = Dimensions.get('window').width;

    if(Platform.OS == 'web' && windowWidth >= AppConstants.MOBILE_WEB_BREAKPOINT) {
        return true;
    } else {
        return false;
    }
}

export const isProduction = () => {
    if(environment == 'prod') {
        return true;
    } else {
        return false;
    }
}

export const log = (message) => {
    console.log(message);
}

export const setSecureStoreItem = async (key, value) => {
    if(isWeb()) {
        return;
    }
    await SecureStore.setItemAsync(key, value, {keychainAccessible: SecureStore.ALWAYS});
}

export const getSecureStoreItem = async (key, value) => {
    if(isWeb()) {
        return;
    }
    try {
        const result = await SecureStore.getItemAsync(key);
        //log('getSecureStoreItem result for ' + key + ' is: ' + result);
        return result;
    } catch(error) {
        log(error);
    }
}

export const deleteSecureStoreItem = async (key) => {
    if(isWeb()) {
        return;
    }
    log('deleting SecureStoreItem with key ' + key);
    await SecureStore.deleteItemAsync(key);
}

export const setAsyncStoreItem = async (key, value) => {
    if(!isWeb()) {
        return;
    }
    await AsyncStorage.setItem(key, value);
}

export const getAsyncStoreItem = async (key, value) => {
    if(!isWeb()) {
        return;
    }
    try {
        const result = await AsyncStorage.getItem(key);
        return result;
    } catch(error) {
        log(error);
    }
}

export const deleteAsyncStoreItem = async (key) => {
    if(!isWeb()) {
        return;
    }
    log('deleting AsyncStoreItem with key ' + key);
    await AsyncStorage.removeItem(key);
}

export const getUpcomingDateList = () => {
    let dates = [];

    for (let i=0; i<8; i++) {
        let dateItem = {};

        if(i==0) {
            dateItem.label = 'Today';
            dateItem.value = moment().format("MM/DD/YYYY");
        } else if(i==1) {
            dateItem.label = 'Tomorrow';
            dateItem.value = moment().add(i, 'days').format("MM/DD/YYYY");
        } else {
            dateItem.label = moment().add(i, 'days').format("ddd, MMM Do");
            dateItem.value = moment().add(i, 'days').format("MM/DD/YYYY");
        }
        dates.push(dateItem);
    }
    return dates;
}

export const getOriginOptions = () => {
    const { appData } = React.useContext(AppDataContext);

    let originOptions = [];
    let currentLocationOption = {
        label: 'All',
        value: 'all',
    };
    let cityStateOption = {
        label: 'Search By City & State',
        value: 'Search By City & State',
    };

    originOptions.push(currentLocationOption);
    originOptions.push(cityStateOption);

    if(appData.preferredLocations.length > 0) {
        appData.preferredLocations.forEach(preferredLocation => {
            originOptions.push(preferredLocation);
        });
    }

    return originOptions;
}

export const getShipperLoadList = (arrayType) => {

    arrayType = arrayType || 'object';

    const { appData } = React.useContext(AppDataContext);

    let shipperOptions = [];

    if(appData.shipperLoadList.length > 0) {
        appData.shipperLoadList.forEach(shipper => {
            if(arrayType == 'string') {
                shipperOptions.push(shipper.value);
            } else {
                shipperOptions.push(shipper);
            }
        });
    }

    return shipperOptions;
}

export const getDriverList = () => {
    const { appData } = React.useContext(AppDataContext);

    let driverOptions = [];

    let defaultOption = {
        label: 'All',
        value: 'all',
    };
    driverOptions.push(defaultOption);

    if(appData.carrierDrivers.length > 0) {
        appData.carrierDrivers.forEach(driver => {
            driverOptions.push(driver);
        });
    }

    return driverOptions;
}

export const checkForRoles = (roles) => {
    let hasRole = false;
    const { appData } = React.useContext(AppDataContext);

    roles.forEach(role => {
        if(appData.carrierUserData.roles.includes(role)) {
            hasRole = true;
        }
    });

    return hasRole;
}

export const prependArrayItem = (value, array) => {
    let newArray = array.slice();
    newArray.unshift(value);
    return newArray;
}

export const getLoadStatusAttributes = (load) => {

    let activeStep = "";
    let buttonText = "";
    let confirmationMessage = "";
    let icon = "";
    let instructions = "";
    let label = "";
    let loadStatusMessage;
    let nextStatus = "";
    let progress = 0;
    let showLoadStatus = false;
    let stopType = "";
    let stopNumber = "";

    switch(load.status) {

        case 'Available':

            loadStatusMessage = "Available";

            break;

        case 'Cancelled':

            loadStatusMessage = "Cancelled";

            break;

        case 'Awarded':

            icon = "hourglass";
            instructions = "This load has been awarded to you, but isn't quite ready yet. You will receive a notificaiton when this load is ready to go!";
            label = "Waiting on Shipper";
            loadStatusMessage = "Awarded: Waiting on Shipper";
            nextStatus = "Dispatched";
            showLoadStatus = true;

            break;

        case 'Tendered':

            activeStep = "0";
            buttonText = "Get Started";
            confirmationMessage = "Please confirm that you are ready to start hauling this load.";
            icon = "spinner"
            instructions = "Tap the button below to begin hauling this load, then start heading to the load origin.";
            label = "Ready to Get Started";
            loadStatusMessage = "Ready to Get Started";
            nextStatus = "Dispatched";
            progress = 0;
            showLoadStatus = true;

            break;

        case 'TONU':

            loadStatusMessage = "TONU";

        case 'Dispatched':

            activeStep = "1";
            buttonText = "Arrived & Checked In";
            confirmationMessage = "Please confirm that you have arrived at the origin location and checked in.";
            icon = "shipping-fast";
            instructions = "Tap the button below once you have arrived and checked in at the origin location.";
            label = "Dispatched to Origin";
            loadStatusMessage = "Step 1 of 5: Dispatched to Origin";
            nextStatus = "Loading";
            progress = .2;
            showLoadStatus = true;

            break;

        case 'Loading':

            activeStep = "2";
            buttonText = "Truck is Loaded";
            confirmationMessage = "Please confirm that the truck has been successfully loaded.";
            icon = "truck-loading";
            instructions = "Tap the button below once the truck has been loaded.";
            label = "Loading Truck at Origin";
            loadStatusMessage = "Step 2 of 5: Loading Truck";
            nextStatus = "In Transit";
            progress = .4;
            showLoadStatus = true;

            break;

        case 'In Transit':

            activeStep = "3";
            icon = "map-marked-alt";
            loadStatusMessage = "Step 3 of 5: En Route";
            progress = .6;
            showLoadStatus = true;

            if(load.currentStop == 'destination') {

                buttonText = "Arrived & Checked In";
                confirmationMessage = "Please confirm that you have arrived at the destination location and checked in.";
                instructions = "Tap the button below once you have arrived and checked in at the destination location.";
                label = "En Route to Destination";
                nextStatus = "Unloading";

            } else {

                let stopIndex = load.currentStop - 1;

                if(load.stops[stopIndex].stopStatus == 'Not Started') {
                    buttonText = "Arrived At Stop";
                    confirmationMessage = "Please confirm that you have arrived at the next stop.";
                    instructions = "Tap the button below once you have arrived at the next stop ("+load.stops[stopIndex].locationCity+", "+load.stops[stopIndex].locationState+").";
                    label = "En Route to Next Stop";
                    nextStatus = "In Transit";
                    stopType = "stop arrival";
                    stopNumber = load.stops[stopIndex].stopOrder;
                } else {
                    buttonText = "Departing Stop";
                    confirmationMessage = "Please confirm that your stop is complete and you are ready to depart.";
                    instructions = "Tap the button below once you have completed your stop and ready to depart ("+load.stops[stopIndex].locationCity+", "+load.stops[stopIndex].locationState+").";
                    label = (load.stops[stopIndex].stopType == 'Loading' ? "Loading at Stop" : "Unloading at Stop");
                    nextStatus = (load.currentStop == load.stops.length ? "Unloading" : "In Transit");
                    stopType = "stop departure";
                    stopNumber = load.stops[stopIndex].stopOrder;
                }

            }

            break;

        case 'Unloading':

            activeStep = "4";
            buttonText = "Truck is Unloaded";
            confirmationMessage = "Please confirm that the truck has been successfully unloaded.";
            icon = "ramp-loading";
            instructions = "Tap the button below once the truck has been unloaded.";
            label = "Unloading Truck at Destination";
            loadStatusMessage = "Step 4 of 5: Unloading Truck";
            nextStatus = "Delivered";
            progress = .8;
            showLoadStatus = true;

            break;

        case 'Delivered':

            activeStep = "5";
            buttonText = "Upload Bill of Lading";
            icon = "check-circle";
            instructions = "Tap the button below to upload the bill of lading.";
            label = "Delivery Complete";
            loadStatusMessage = "Step 5 of 5: Upload Bill of Lading";
            progress = 1;
            showLoadStatus = true;

            break;

        case 'Invoiced':

            loadStatusMessage = "Delivery Complete";

        case 'Invoice Issue':

            loadStatusMessage = "Delivery Complete";

        case 'Paid Balance Due':

            loadStatusMessage = "Delivery Complete";

        case 'Paid In Full':

            loadStatusMessage = "Delivery Complete";

    }

    let loadStatusAttributes = {
        activeStep: activeStep,
        buttonText: buttonText,
        confirmationMessage: confirmationMessage,
        icon: icon,
        instructions: instructions,
        label: label,
        loadStatusMessage: loadStatusMessage,
        nextStatus: nextStatus,
        progress: progress,
        showLoadStatus: showLoadStatus,
        stopType: stopType,
        stopNumber: stopNumber,
    };

    return loadStatusAttributes;
}

export const number_format = (number, decimals, dec_point, thousands_sep) => {
    // Strip all characters but numerical ones.
    number = (number + '').replace(/[^0-9+\-Ee.]/g, '');
    var n = !isFinite(+number) ? 0 : +number,
    prec = !isFinite(+decimals) ? 0 : Math.abs(decimals),
    sep = (typeof thousands_sep === 'undefined') ? ',' : thousands_sep,
    dec = (typeof dec_point === 'undefined') ? '.' : dec_point,
    s = '',
    toFixedFix = function (n, prec) {
        var k = Math.pow(10, prec);
        return '' + Math.round(n * k) / k;
    };
    // Fix for IE parseFloat(0.55).toFixed(0) = 0;
    s = (prec ? toFixedFix(n, prec) : '' + Math.round(n)).split('.');
    if (s[0].length > 3) {
        s[0] = s[0].replace(/\B(?=(?:\d{3})+(?!\d))/g, sep);
    }
    if ((s[1] || '').length < prec) {
        s[1] = s[1] || '';
        s[1] += new Array(prec - s[1].length + 1).join('0');
    }
    return s.join(dec);
}

export const showFlashMessage = (status, message) => {

    // See docs for flash message here:
    // https://github.com/lucasferreira/react-native-flash-message
    // Supported types: success (green), info (blue), warning (orange), danger (red)

    let title;
    let description = '';
    let type;
    let duration;

    switch(status) {
        case 'success':
            title = message;
            type = 'success';
            duration = 2000;
            break;
        case 'error':
            title = 'The following error occurred:';
            description = message;
            type = 'danger';
            duration = 5000;
            break;
    }

    showMessage({
        message: title,
        description: description,
        type: type,
        duration: duration,
        icon: 'auto',
    });
}

export const handleCatchError = (error, isModal, setModalError) => {

    isModal = isModal || false;
    setModalError = setModalError || null;

    let errorMessage;

    log(error);

    if(error.type == "custom") {
        errorMessage = error.message;
    } else if(error.response) {
        // The request was made and the server responded with a status code that falls out of the range of 2xx
        log(error.response.data);
        log(error.response.status);
        log(error.response.headers);
        errorMessage = "There was an error communicating with the server. Please try again. If this issue persists, please contact support.";
    } else if(error.request) {
        // The request was made but no response was received
        // `error.request` is an instance of XMLHttpRequest in the browser and an instance of http.ClientRequest in node.js
        log(error.request);
        errorMessage = "The server took too long to respond. Please try again. If this issue persists, please contact support.";
    } else {
        // Something happened in setting up the request that triggered an Error
        log('Error', error.message);
        errorMessage = "An unknown error occurred. Please try again. If this issue persists, please contact support.";
    }

    log(error.config);
    logEvent({ sendToRollbar: true, level: 'error', category: 'General', message: 'handleCatchError', data: { error: error }});

    if(isModal) {
        setModalError(errorMessage);
    } else {
        showFlashMessage('error', errorMessage);
    }

}

export const showConfirmActionAlert = (title, message, confirmAction, data = null) => {

    if(isWeb()) {
        // @TODO - CT: Implement web version of alert - temporarily executing confirmAction for testing on web
        data != null ? confirmAction(data) : confirmAction();
    } else {
        Alert.alert(
            title,
            message,
            [
                {
                    text: "Cancel",
                    style: "cancel",
                },
                {
                    text: "Confirm",
                    onPress: data != null ? () => confirmAction(data) : confirmAction,
                }
            ]
        )
    }
}

export const calculateDistance = (lat1, lon1, lat2, lon2, unit) => {
    if ((lat1 == lat2) && (lon1 == lon2)) {
        return 0;
    }
    else {
        var radlat1 = Math.PI * lat1/180;
        var radlat2 = Math.PI * lat2/180;
        var theta = lon1-lon2;
        var radtheta = Math.PI * theta/180;
        var dist = Math.sin(radlat1) * Math.sin(radlat2) + Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
        if (dist > 1) {
            dist = 1;
        }
        dist = Math.acos(dist);
        dist = dist * 180/Math.PI;
        dist = dist * 60 * 1.1515;
        if (unit=="K") { dist = dist * 1.609344 }
        if (unit=="N") { dist = dist * 0.8684 }
        return dist;
    }
}

export const getContainerStyles = (item, styles) => {

    let containerStyles = [styles.container];

    if(item.isFirstItem) {
        containerStyles.push(styles.firstItem);
    }

    if(item.isLastItem) {
        containerStyles.push(styles.lastItem);
    }

    return containerStyles;

}

export const inArray = (item, array) => {
    if(array.indexOf(item) > -1) {
        return true;
    } else {
        return false;
    }
}

export const pickImage = async (
        allowEditing = true,
        aspectRatio = [1,1],
        base64 = false,
        resizeBasis = 'equal',
        width = AppConstants.IMAGE_UPLOAD_DEFAULT_WIDTH,
        height = AppConstants.IMAGE_UPLOAD_DEFAULT_HEIGHT
    ) => {

    if(!isWeb()) {
        const { status } = await ImagePicker.requestMediaLibraryPermissionsAsync();
        if (status !== 'granted') {
            alert('Sorry, but you need to allow photo library access to upload an image.');
        } else {
            let result = await ImagePicker.launchImageLibraryAsync({
                mediaTypes: ImagePicker.MediaTypeOptions.Images,
                allowsEditing: allowEditing,
                aspect: aspectRatio, // applies to Android only; iOS is always a square crop tool (1:1)
                quality: .5,
                base64: base64,
            });

            if(!result.canceled) {
                const resizedImage = await resizeImage(result.assets[0].uri, resizeBasis, width, height);
                return resizedImage;
            } else {
                return false;
            }
        }
    } else {
        let result = await ImagePicker.launchImageLibraryAsync({
            mediaTypes: ImagePicker.MediaTypeOptions.Images,
            //allowsEditing: allowEditing,
            //aspect: aspectRatio, // applies to Android only; iOS is always a square crop tool (1:1)
            quality: .5,
            base64: base64,
        });

        if(!result.canceled) {     //MAY NEED TO CHANGE THE RE-SIZE FUNCTIONALITY FOR WEB (as not all images are squares -- see inputs above )
            const resizedImage = await resizeImage(result.assets[0].uri, resizeBasis, width, height);
            return resizedImage;
        } else {
            return false;
        }
    }
}

export const takePhoto = async (
        allowEditing = true,
        aspectRatio = [1,1],
        base64 = false,
        resizeBasis = 'equal',
        width = AppConstants.IMAGE_UPLOAD_DEFAULT_WIDTH,
        height = AppConstants.IMAGE_UPLOAD_DEFAULT_HEIGHT
    ) => {

    if(!isWeb()) {
        const { status } = await ImagePicker.requestCameraPermissionsAsync();
        if (status !== 'granted') {
            alert('Sorry, but you need to allow camera access to upload an image.');
        } else {
            let result = await ImagePicker.launchCameraAsync({
                mediaTypes: ImagePicker.MediaTypeOptions.Images,
                allowsEditing: allowEditing,
                aspect: aspectRatio, // applies to Android only; iOS is always a square crop tool (1:1)
                quality: .5,
                base64: base64,
            });

            if(!result.canceled) {
                const resizedImage = await resizeImage(result.assets[0].uri, resizeBasis, width, height);
                return resizedImage;
            } else {
                return false;
            }
        }
    } else {
        // @TODO - CT: Implement web version here...
        return false;
    }
}

export const pickDocument = async (type = 'application/pdf') => {

    let result = await DocumentPicker.getDocumentAsync({
        type: type,
    });

    if(result.type != 'cancel') {
        return result;
    } else {
        return false;
    }
}

const resizeImage = async (srcImage, resizeBasis, width, height) => {

    let resize;

    if(resizeBasis == 'width') {
        resize = {
            width: width
        }
    } else if(resizeBasis == 'height') {
        resize = {
            height: height
        }
    } else {
        resize = {
            width: width,
            height: height
        }
    }
    // resizedImage returns image object with: base64, uri, width and height
    const resizedImage = await ImageManipulator.manipulateAsync(
        srcImage,
        [{
            resize
        }],
        {
            base64: true,
            compress: 1,
            format: ImageManipulator.SaveFormat.JPEG
        }
    );

    return resizedImage;

}

export const getUnreadNotificationCount = async () => {

    const { appData } = React.useContext(AppDataContext);
    const realTimeNotificationCount = parseInt(appData.notificationCount);
    let storedNotificationCount;
    let unreadNotificationCount;
    if(isWeb()) {
        storedNotificationCount = await getAsyncStoreItem('@storedNotificationCount');
    } else {
        storedNotificationCount = await getSecureStoreItem('storedNotificationCount');
    }

    if(Number.isInteger(parseInt(storedNotificationCount))) {
        unreadNotificationCount = realTimeNotificationCount - parseInt(storedNotificationCount);
    } else {
        storedNotificationCount = 0;
        if(isWeb()) {
            setAsyncStoreItem('@storedNotificationCount', '0');
        } else {
            setSecureStoreItem('storedNotificationCount', '0');
        }
        unreadNotificationCount = realTimeNotificationCount;
    }

    return unreadNotificationCount;

}

export const hasValue = (value) => {

    if(value != '' && value !== null && typeof value !== 'undefined') {
        return true;
    } else {
        return false;
    }
}

export const isAppRatingEligible = async () => {

    /*
        Rating / Review Prompt Placement:
        - Drivers: After successful load execution (i.e., after truck has been unloaded - rating / review prompt takes priority over location review modal)
        - Non-Drivers: After successful load booking (i.e., when navigating to the load details screen after winning bid)

        Rating / Review Prompt Criteria:
        - Platform is iOS or Android
        - Minimum of 1 week since registration (registeredOn)
        - Minimum of 2 load executions for drivers (numberOfLoadsExecuted)
        - Minimum of 2 load bookings for non-drivers (numberOfLoadsBooked)
        - Subsequent attempts must be a minimum of 3 months since last request (managed in SecureStore)
    */

    if(isWeb()) {
        return false;
    }

    const { appData } = React.useContext(AppDataContext);
    const isDriver = checkForRoles(['driver']);
    const today = new Date();

    // Get days since registration
    const registrationDate = new Date(appData.carrierUserData.registeredOn);
    const timeSinceRegistration = today.getTime() - registrationDate.getTime();
    const daysSinceRegistration = Math.round(timeSinceRegistration / (1000 * 3600 * 24));

    // Get days since last request
    const lastAppRatingRequestDate = await getSecureStoreItem('lastAppRatingRequestDate');

    let lastDateRequested;
    let timeSinceLastRequest;
    let daysSinceLastRequest;

    if(lastAppRatingRequestDate != null) {
        lastDateRequested = new Date(JSON.parse(lastAppRatingRequestDate));
        timeSinceLastRequest = today.getTime() - lastDateRequested.getTime();
        daysSinceLastRequest = Math.round(timeSinceLastRequest / (1000 * 3600 * 24));
    } else {
        daysSinceLastRequest = 'Never';
    }

    //Get number of loads booked and executed
    const numberOfLoadsBooked = appData.carrierUserData.numberOfLoadsBooked;
    const numberOfLoadsExecuted = appData.carrierUserData.numberOfLoadsExecuted;

    logEvent({
        category: 'App Rating',
        message: 'isAppRatingEligible()',
        data: {
            daysSinceLastRequest: daysSinceLastRequest,
            daysSinceRegistration: daysSinceRegistration,
            numberOfLoadsBooked: numberOfLoadsBooked,
            numberOfLoadsExecuted: numberOfLoadsExecuted
        }
    });

    if((daysSinceLastRequest >= 90 || daysSinceLastRequest == 'Never') && daysSinceRegistration >= 7) {
        if(isDriver) {
            if(numberOfLoadsExecuted >= 2) {
                return true;
            }
        } else if(numberOfLoadsBooked >= 2) {
            return true;
        } else {
            return false;
        }
    } else {
        return false;
    }

}

export const requestAppRating = async () => {
    if(StoreReview.isAvailableAsync()) {
        await StoreReview.requestReview()
            .then(() => {
                const now = new Date();
                setSecureStoreItem('lastAppRatingRequestDate', JSON.stringify(now));
                logEvent({ sendToRollbar: true, category: 'App Rating', message: 'Request Sent' });
            });
    }
}

// Rollbar Implementation
// @TODO - CT: Decide if we are using this on web
const rollbar = Rollbar.init({ "accessToken": AppConstants.ROLLBAR_ACCESS_TOKEN });

if(!isWeb()) {

    const rollbarPersonData = getSecureStoreItem('rollbarPerson')
        .then((response) => {
            let rollbarPerson;

            if(response != null) {
                rollbarPerson = JSON.parse(response);
            } else {
                rollbarPerson = {
                    id: null,
                    username: null,
                    email: null
                }
            }

            const config = {
                environment: environment,
                verbose: true,
                captureDeviceInfo: true,
                payload: {
                    client: {
                        javascript: {
                            source_map_enabled: true,
                            code_version: Constants.expoConfig?.version,
                        }
                    },
                    person: {
                        id: rollbarPerson.id ? rollbarPerson.id : null,
                        username: rollbarPerson.username ? rollbarPerson.username : null,
                        email: rollbarPerson.email ? rollbarPerson.email : null,
                    }
                }
            };
            rollbar.configure(config);
        });
}

export const logEvent = async (event) => {

    /*
        Required Props:
        - message
        - category ('API Call', 'API Response', 'App Rating', 'General', 'Location Tracking', 'Notifications')

        Optional Props:
        - sendToRollbar
        - sendToConsole
        - level (log, debug, info, warning, error, critical)
        - data
        - callback
    */

    // See here for more console debugging options:
    // https://developer.mozilla.org/en-US/docs/Web/API/console

    // Setup default logging options
    const isDebugModeEnabled = await getSecureStoreItem('isDebugModeEnabled');
    const isUserBlocked = await getSecureStoreItem('isUserBlocked');
    const forceConsole = false;
    const forceRollbar = false;
    const visibleConsoleCategories = [
        'API Call',
        'API Response',
        'App Rating',
        'General',
        'Location Tracking',
        'Notifications',
    ];
    const visibleRollbarCategories = [
        'API Call',
        'API Response',
        'App Rating',
        'General',
        'Location Tracking',
        'Notifications',
    ];
    const now = new Date();
    const timestamp = now.toLocaleString('en-US', { hour: '2-digit', minute: '2-digit', second: '2-digit', hour12:true });
    const level = event.level ? event.level : 'debug';
    const callback = event.callback ? event.callback : null;
    const data = event.data ? event.data : null;
    const category = event.category;
    const message = event.message;
    const consoleMessage = '***** '+timestamp+': '+category+': '+message+' *****';
    const rollbarMessage = category+': '+message;

    // Whether to log the event in the console
    const sendToConsole = () => {

        if(isProduction()) {
            return false;
        }

        if(forceConsole) {
            return true;
        }

        if(!visibleConsoleCategories.includes(event.category)) {
            return false;
        }

        let logConsoleEvent = event.sendToConsole ? event.sendToConsole : true;

        return logConsoleEvent;

    }

    // Whether to log the event in Rollbar
    const sendToRollbar = () => {

        if(isWeb()) {
            return false;
        }

        if(forceRollbar) {
            return true;
        }

        if(!visibleRollbarCategories.includes(event.category)) {
            return false;
        }
        
        if(isUserBlocked == 'yes') {
            return false;
        }

        if(isDebugModeEnabled == 'yes') {
            return true;
        }

        let logRollbarEvent = event.sendToRollbar ? event.sendToRollbar : false;

        return logRollbarEvent;

    }

    switch(level) {
        case 'log':
            if(sendToConsole()) {
                console.log(consoleMessage, data);
            }
            if(sendToRollbar()) {
                rollbar.log(rollbarMessage, data, callback);
            }
            break;
        case 'debug':
            if(sendToConsole()) {
                console.debug(consoleMessage, data);
            }
            if(sendToRollbar()) {
                rollbar.debug(rollbarMessage, data, callback);
            }
            break;
        case 'info':
            if(sendToConsole()) {
                console.info(consoleMessage, data);
            }
            if(sendToRollbar()) {
                rollbar.info(rollbarMessage, data, callback);
            }
            break;
        case 'warning':
            if(sendToConsole()) {
                console.warn(consoleMessage, data);
            }
            if(sendToRollbar()) {
                rollbar.warning(rollbarMessage, data, callback);
            }
            break;
        case 'error':
            if(sendToConsole()) {
                console.error(consoleMessage, data);
            }
            if(sendToRollbar()) {
                rollbar.error(rollbarMessage, data, callback);
            }
            break;
        case 'critical':
            if(sendToConsole()) {
                console.error(consoleMessage, data);
            }
            if(sendToRollbar()) {
                rollbar.critical(rollbarMessage, data, callback);
            }
            break;
    }

}
