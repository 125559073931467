import React, { useContext, useState, useEffect } from 'react';
import { ScrollView, View } from 'react-native';
import { SafeAreaView } from 'react-native-safe-area-context';
import { useFocusEffect } from "@react-navigation/native";
import { Button, Divider, Layout, List, ListItem, StyleService, Text, useStyleSheet } from '@ui-kitten/components';
import { AppDataContext, AuthContext } from 'components/context';
import { CustomModal, Header, Section } from 'components/layout';
import { Loader } from 'components/utility';
import { DriverSummary } from 'components/drivers';
import * as helper from 'utility/Helper';
import * as api from 'api/SleekTechnologiesApi';
import { AddUser } from 'screens/manage-users/modals';

export default function Drivers({ route, navigation }) {

    const styles = useStyleSheet(themedStyles);
    const { appData, setAppData } = React.useContext(AppDataContext);
    const { signOut } = useContext(AuthContext);
    const [isLoading, setLoading] = useState(true);
    const [drivers, setDrivers] = useState();
    const [modalVisible, setModalVisible] = useState(false);
    const [modalError, setModalError] = useState(null);
    const [isRefreshing, setRefreshing] = useState(false);

    const breadcrumbs = [
        {
            label: 'Dashboard',
            path: '/dashboard'
        },
        {
            label: 'Drivers',
            path: ''
        }
    ]

    const getCarrierDrivers = (isRefresh = false) => {
        if(isRefresh) {
            setRefreshing(true);
        }
        api.getCarrierDrivers()
            .then(function(response){
                if(helper.hasValue(response.data.msg) && response.data.msg == "Token Not Found or Expired") {
                    signOut();
                }
                if(response.data.result == "success") {
                    setDrivers(response.data.carrierDrivers);
                    if(isRefresh) {
                        setRefreshing(false);
                        //Refresh Application Data
                        api.getApplicationData()
                            .then(function(response){
                                if(response.data.result == "success") {
                                    setAppData({...response.data});
                                }
                            });
                    } else {
                        setLoading(false);
                    }
                } else if(response.data.result == "error") {
                    throw {
                        type: "custom",
                        message: response.data.msg
                    }
                }
            })
            .catch(function(error){
                if(isRefresh) {
                    setRefreshing(false);
                } else {
                    setLoading(false);
                }
                helper.handleCatchError(error);
            });
    }

    useFocusEffect(
        React.useCallback(() => {
            if(!isLoading) {
                setLoading(true);
            }
            setTimeout(() => {
                getCarrierDrivers();
            }, 1000);
        }, [])
    );

    const renderDriverSummary = ({ item, index }) => {
        return (
            <ListItem children={<DriverSummary {...item} />} />
        )
    }

    const getPageActions = () => {
        return (
            <Button appearance="outline" size="small" onPress={() => setModalVisible(true)}>Add Driver</Button>
        )
    }

    const renderDriverList = () => {
        return (
            <List style={styles.listView} data={drivers} renderItem={renderDriverSummary} ItemSeparatorComponent={Divider} onRefresh={() => getCarrierDrivers(true)} refreshing={isRefreshing}/>
        )
    }

    const renderAddUser = () => {
        return (
            <AddUser setModalVisible={setModalVisible} setModalError={setModalError} isDriver={true} refreshAction={getCarrierDrivers} isRefresh={true}/>
        )
    }

    if(isLoading) {
        return (
            <Loader loadingText="Getting Driver Data" />
        )
    } else {
        return (
            helper.isWeb() ? (
                <View style={styles.webView}>
                    <Header breadcrumbs={breadcrumbs} title="Drivers" pageActions={getPageActions()} />
                    <Layout style={styles.layout} level="3">
                        {helper.isDesktopWeb() &&
                            <ScrollView>
                                <Section title="Drivers" subtitle={drivers.length == 1 ? drivers.length + ' Driver' : drivers.length + ' Drivers'} noBodyPadding={drivers.length > 0 ? true : false} renderBody={renderDriverList()} />
                            </ScrollView>
                        }
                        {helper.isMobileWeb() &&
                            renderDriverList()
                        }
                    </Layout>
                    <CustomModal title="Add Driver" modalVisible={modalVisible} setModalVisible={setModalVisible} modalError={modalError} setModalError={setModalError}>
                        {renderAddUser()}
                    </CustomModal>
                </View>
            ) : (
                <SafeAreaView style={styles.safeAreaView} edges={['top', 'left', 'right']}>
                    <Header activeScreen="Drivers" setModalVisible={setModalVisible}/>
                    <Layout style={styles.layout} level="3">
                        {renderDriverList()}
                    </Layout>
                    <CustomModal title="Add Driver" modalVisible={modalVisible} setModalVisible={setModalVisible} modalError={modalError} setModalError={setModalError}>
                        {renderAddUser()}
                    </CustomModal>
                </SafeAreaView>
            )
        )
    }

}

const themedStyles = StyleService.create({
    // global styles
    layout: {
        flex: 1,
        marginTop: helper.isDesktopWeb() ? 5 : 0
    },
    listView: {
        borderBottomLeftRadius: helper.isDesktopWeb() ? 6 : 0,
        borderBottomRightRadius: helper.isDesktopWeb() ? 6 : 0
    },
    // native specific styles
    safeAreaView: {
        backgroundColor: "safe-area-view-background",
        flex: 1,
    },
    // web specific styles
    webView: {
        flex: 1,
    },
});
