import React from 'react';
import { Pressable, View } from 'react-native';
import { StyleService, Text, useStyleSheet } from '@ui-kitten/components';
import { TextIcon } from 'components/layout';
import * as WebBrowser from 'expo-web-browser';
import * as helper from 'utility/Helper';
import getEnvVars from 'root/environment';

export const LoadDocument = (props) => {

    /*
        general:
        - isFirstItem
        - isLastItem
        - setActiveModal
        - setLoadDocumentDetails
        - setModalVisible
        loadDocumentDetails (props):
        - createdAt
        - createdAtFull
        - createdBy
        - description
        - documentId
        - filePath
        - invoiceAttachment
        - isFirstItem
        - isLastItem
        - type
        - updatedAt
        - updatedAtFull
    */

    const styles = useStyleSheet(themedStyles);
    const loadDocumentDetails = props.loadDocumentDetails;
    const { sleekApiUrl } = getEnvVars();
    const filePath = sleekApiUrl+loadDocumentDetails.filePath;

    const showModal = () => {
        props.setLoadDocumentDetails(loadDocumentDetails);
        props.setActiveModal('LoadDocumentDetails');
        props.setModalVisible(true);
    }

    const renderBody = () => {
        return (
            <View style={helper.getContainerStyles(props, styles)}>
                <View style={styles.columnOne}>
                    <Text style={styles.type} category="s2">{props.type}</Text>
                    <TextIcon iconName="calendar-alt" iconWeight="far" iconSize={styles.icon.size} iconColor={styles.icon.color} text={props.createdAt} textCategory="c2" textAppearance="hint" />
                    <Text style={styles.description} appearance="hint" category="p1">{props.description}</Text>
                </View>
                <View style={styles.actionView}>
                    <Text status="primary" category="p2">View</Text>
                </View>
            </View>
        )
    }

    return (
        helper.isWeb() ? (
            <Pressable style={{cursor: "pointer"}} onPress={() => WebBrowser.openBrowserAsync(filePath)}>
                {renderBody()}
            </Pressable>
        ) : (
            <Pressable onPress={() => showModal()}>
                {renderBody()}
            </Pressable>
        )
    )
}

const themedStyles = StyleService.create({
    container: {
        borderBottomColor: "border-basic-color-3",
        borderBottomWidth: 1,
        flexDirection: "row",
        flex: 1,
        paddingVertical: 5,
        minHeight: 60,
    },
    firstItem: {
        paddingTop: 2,
    },
    lastItem: {
        borderBottomWidth: 0,
        paddingBottom: 0,
    },
    columnOne: {
        flex: 9,
        justifyContent: "center",
    },
    columnTwo: {
        flex: 1,
        justifyContent: "center",
        aligntItems: "center",
    },
    type: {
        marginBottom: 3,
    },
    description: {
        marginTop: 8,
        paddingRight: 60,
    },
    actionView: {
        position: "absolute",
        right: 5,
        top: "50%",
        marginTop: -8
    },
    forwardIcon: {
        color: "color-primary-500",
        size: 20,
    },
    icon: {
        size: 14,
        color: "color-basic-600"
    },
});
