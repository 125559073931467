import React from 'react';
import { ActivityIndicator, Text, View } from 'react-native';
import { Layout, StyleService, useStyleSheet } from '@ui-kitten/components';

export const Loader = (props) => {

    /*
        props:
        - loadingText
    */

    const styles = useStyleSheet(themedStyles);

    return (
        <View style={styles.container}>
            <ActivityIndicator size="large" color={styles.loader.color} />
            <Text style={styles.loadingText}>{props.loadingText ? props.loadingText : "Loading"}</Text>
        </View>
    )
}

const themedStyles = StyleService.create({
    container: {
        flex: 1,
        alignItems: "center",
        justifyContent: "center"
    },
    loader: {
        color: "#8390a8",
    },
    loadingText: {
        marginTop: 10,
        color: "#8390a8",
        fontSize: 15,
        fontWeight: "600",
    }
});
