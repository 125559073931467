import React from 'react';
import { useNavigation, useRoute } from '@react-navigation/native';
import { Dimensions, Image, View } from 'react-native';
import { Button, Divider, Layout, StyleService, Text, useStyleSheet } from '@ui-kitten/components';
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome';
import { TextIcon } from 'components/layout';
import { Badge, CustomLink } from 'components/utility';
import * as Constants from 'config/Constants';
import getEnvVars from 'root/environment';
import * as helper from 'utility/Helper';

export const LoadSummary = (props) => {

    /*
        props for booked loads (active, upcoming, completed):
        - delivery
        - deliveryAppointmentSet
        - destination
        - driverName
        - loadRevenue
        - loadStatusId
        - loadSummaryType (injected prop - available, active, upcoming, or completed)
        - loadType (upcoming loads only)
        - numberOfStops
        - origin
        - pickup
        - pickupAppointmentSet
        - roundTrip
        - shipperLogo
        - shipperName
        - showDivider
        - sleekFleetLoadId
        - status
        - statusMessage
        - tonu

        props for available loads:
        - deliveryAppointmentSet
        - deliveryReadyBy
        - destination
        - driverMiles
        - milesToOrigin
        - milesToOriginRaw
        - mustDeliverBy
        - mustPickupBy
        - numberOfStops
        - origin
        - originLatitude
        - originLongitude
        - pickupAppointmentSet
        - pickupReadyBy
        - roundTrip
        - shipperLogo
        - shipperName
        - showDivider
        - sleekFleetLoadId
        - type
        - weight

        props for historical loads:
        - deliveryAppointmentSet
        - deliveryDate
        - destination
        - driverName
        - loadRevenue
        - loadRevenueSort
        - loadStatusId
        - numberOfStops
        - origin
        - pickupAppointmentSet
        - pickupDate
        - roundTrip
        - shipperLogo
        - shipperName
        - showDivider
        - sleekFleetLoadId
        - status
        - statusMessage
    */

    const styles = useStyleSheet(themedStyles);
    const navigation = useNavigation();
    const route = useRoute();
    const { sleekApiUrl } = getEnvVars();
    const shipperLogoUri = sleekApiUrl+props.shipperLogo;
    const isManager = helper.checkForRoles(['manager']);

    let resetStack = false;
    let resetStackConfig = {};
    let path;

    const allowedRoutes = ["FindALoad", "active", "upcoming", "completed", "active-loads", "upcoming-loads", "completed-loads", "load-history"];

    if(route.name == 'FindALoad') {
        path = '/find-a-load/load-details/'+props.sleekFleetLoadId;
    } else if(helper.inArray(route.name, ['active', 'upcoming', 'completed'])) {
        path = '/booked-loads/load-details/'+props.sleekFleetLoadId;
    } else if(helper.inArray(route.name, ['active-loads', 'upcoming-loads', 'completed-loads'])) {
        path = '/drivers/load-details/'+props.sleekFleetLoadId;
    } else if(route.name == 'load-history') {
        path = '/reports/load-details/'+props.sleekFleetLoadId;
    } else {
        path = '/load-details/'+props.sleekFleetLoadId;
    }

    if(!allowedRoutes.includes(route.name)) {

        if(helper.isWeb()) {
            resetStack = true;
        } else {
            const navigationState = navigation.getParent().getParent().getState().routes;
            navigationState.forEach(function(item) {
                if(item.name == 'LoadDetailsStackNavigator' && helper.hasValue(item.state)) {
                    resetStack = true;
                }
            });
        }

        if(resetStack) {
            resetStackConfig = {
                index: 0,
                routes: [
                    {
                        name: 'LoadDetails_Generic',
                        params: {
                            loadId: props.sleekFleetLoadId,
                        }
                    },
                ],
            }
        }
    }

    const Route = () => {
        return (
            <View style={styles.routeView}>
                <Text style={styles.route} status="primary" category="s2">{props.origin}</Text>
                {props.roundTrip ? (
                    <FontAwesomeIcon style={styles.roundTripIcon} icon={['fal', 'repeat']} size={styles.roundTripIcon.size} color={styles.roundTripIcon.color} />
                ) : (
                    <FontAwesomeIcon style={styles.routeIcon} icon={['fal', 'angle-right']} size={styles.routeIcon.size} color={styles.routeIcon.color} />
                )}
                <Text style={styles.route} status="primary" category="s2">{props.destination}</Text>
                {props.numberOfStops > 0 &&
                    <Badge label={props.numberOfStops == 1 ? '+1 Stop' : '+'+props.numberOfStops+' Stops'} type="info" />
                }
                {props.roundTrip &&
                    <Badge label="Round Trip" type="info" />
                }
                {helper.isDesktopWeb() && props.loadSummaryType != 'available' &&
                    <Badge label={props.status} type={props.status == 'Invoice Issue' ? 'danger' : 'info'} />
                }
            </View>
        )
    }

    const LoadDataOne = () => {
        if(props.loadSummaryType == 'available') {
            return (
                <View style={[styles.loadDataView, styles.loadDataOne]}>
                    <TextIcon iconName="box" iconWeight="far" iconSize={styles.icon.size} iconColor={styles.icon.color} text={props.type} textCategory="c2" textAppearance="hint" />
                    <TextIcon iconName="gas-pump" iconWeight="far" iconSize={styles.icon.size} iconColor={styles.icon.color} text={props.driverMiles+ ' Mi'} textCategory="c2" textAppearance="hint" />
                    <TextIcon iconName="dumbbell" iconWeight="far" iconSize={styles.icon.size} iconColor={styles.icon.color} text={props.weight+' Lbs'} textCategory="c2" textAppearance="hint" />
                </View>
            )
        } else {
            return (
                <View style={[styles.loadDataView, styles.loadDataOne]}>
                    <TextIcon iconName="steering-wheel" iconWeight="far" iconSize={styles.icon.size} iconColor={styles.icon.color} text={helper.hasValue(props.driverName) ? props.driverName : 'Unassigned'} textCategory="c2" textAppearance="hint" containerSpacing={10} />
                    {isManager &&
                        <TextIcon iconName="box-usd" iconWeight="far" iconSize={styles.icon.size} iconColor={styles.icon.color} text={'$'+props.loadRevenue} textCategory="c2" textAppearance="hint" containerSpacing={5} />
                    }
                    {!helper.isDesktopWeb() &&
                        <Badge label={props.status} type={props.status == 'Invoice Issue' ? 'danger' : 'info'} />
                    }
                </View>
            )
        }
    }

    const LoadDataTwo = () => {
        if(props.loadSummaryType == 'available') {
            return (
                <View style={[styles.loadDataView, styles.loadDataTwo]}>
                    <TextIcon iconName="calendar-alt" iconWeight="far" iconSize={styles.icon.size} iconColor={styles.icon.color} text={'PU: '+(props.pickupAppointment != '' ? props.pickupAppointment : props.pickupReadyBy)} textCategory="c2" textAppearance="hint" />
                    <TextIcon iconName="calendar-alt" iconWeight="far" iconSize={styles.icon.size} iconColor={styles.icon.color} text={'DEL: '+(props.deliveryAppointment != '' ? props.deliveryAppointment : props.deliveryReadyBy)} textCategory="c2" textAppearance="hint" />
                </View>
            )
        } else if(props.loadSummaryType == 'completed') {
            return (
                <View style={[styles.loadDataView, styles.loadDataTwo]}>
                    <TextIcon iconName="calendar-alt" iconWeight="far" iconSize={styles.icon.size} iconColor={styles.icon.color} text={'Delivered On: '+props.delivery} textCategory="c2" textAppearance="hint" />
                </View>
            )
        } else if(props.loadSummaryType == 'historical') {
            return (
                <View style={[styles.loadDataView, styles.loadDataTwo]}>
                    <TextIcon iconName="calendar-alt" iconWeight="far" iconSize={styles.icon.size} iconColor={styles.icon.color} text={'Delivered On: '+props.deliveryDate} textCategory="c2" textAppearance="hint" />
                </View>
            )
        } else {
            return (
                <View style={[styles.loadDataView, styles.loadDataTwo]}>
                    <TextIcon iconName="calendar-alt" iconWeight="far" iconSize={styles.icon.size} iconColor={styles.icon.color} text={'PU: '+(props.pickupAppointment != '' ? props.pickupAppointment : props.pickup)} textCategory="c2" textAppearance="hint" />
                    <TextIcon iconName="calendar-alt" iconWeight="far" iconSize={styles.icon.size} iconColor={styles.icon.color} text={'DEL: '+(props.deliveryAppointment != '' ? props.deliveryAppointment : props.delivery)} textCategory="c2" textAppearance="hint" />
                </View>
            )
        }
    }

    return (
        <>
            <CustomLink styleProps={styles.customLink} to={path} resetStack={resetStack} resetStackConfig={resetStackConfig}>
                <View style={styles.container}>
                    <View style={styles.logoView}>
                        <Image
                            source={{
                                uri: shipperLogoUri,
                                headers: Constants.RESOURCE_HEADERS
                            }}
                            style={styles.image}
                            resizeMode="contain"
                        />
                    </View>
                    <View style={styles.dataView}>
                        <Route />
                        <View style={styles.loadDataWrapper}>
                            <LoadDataOne />
                            <LoadDataTwo />
                        </View>
                    </View>
                    <View style={styles.actionView}>
                        <FontAwesomeIcon style={styles.forwardIcon} icon={['fal', 'angle-right']} size={styles.forwardIcon.size} color={styles.forwardIcon.color} />
                    </View>
                </View>
            </CustomLink>
            {props.showDivider &&
                <Divider />
            }
        </>
    )
}

const windowWidth = Dimensions.get('window').width;

const themedStyles = StyleService.create({
    customLink: {
        flex: 1,
    },
    container: {
        flexDirection: "row",
        marginVertical: helper.isDesktopWeb() ? 10 : 18,
    },
    logoView: {
        borderRightWidth: 1,
        borderRightColor: "color-basic-400",
        padding: 5,
        justifyContent: "center",
        alignItems: "center",
        paddingHorizontal: 12,
    },
    image: {
        width: helper.isDesktopWeb() ? 40 : 54,
        height: helper.isDesktopWeb() ? 40 : 54,
        paddingHorizontal: 10
    },
    dataView: {
        flex: 1,
        justifyContent: "center",
        paddingLeft: 10,
    },
    routeView: {
        flexDirection: "row",
        alignItems: "center",
        marginBottom: 5,
        flexWrap: "wrap",
    },
    route: {
        fontSize: 15,
        marginBottom: 3,
    },
    routeIcon: {
        size: 16,
        color: "color-primary-500",
    },
    roundTripIcon: {
        size: 16,
        color: "color-primary-500",
        marginHorizontal: 3,
    },
    loadDataWrapper: {
        flexDirection: helper.isDesktopWeb() ? "row" : "column",
    },
    loadDataView: {
        flexDirection: "row",
        flexWrap: "wrap",
    },
    loadDataOne: {
        marginBottom: helper.isDesktopWeb() ? 4 : 10,
        marginRight: helper.isDesktopWeb() ? 5 : 30,
    },
    loadDataTwo: {
        marginBottom: 4,
        marginRight: 30,
    },
    actionView: {
        position: "absolute",
        right: 5,
        top: "50%",
        marginTop: -10
    },
    forwardIcon: {
        color: "color-primary-500",
        size: 20,
    },
    icon: {
        size: 14,
        color: "color-basic-600"
    }
});
