import { initializeApp } from "firebase/app";
import { getAnalytics, logEvent } from "firebase/analytics";

// configure and initialize Firebase for web
const firebaseConfig = {
    apiKey: "AIzaSyB_JWdc4IqPMe-HfvaN6aukBSfGKNxhuIk",
    authDomain: "sleek-carrier-portal-607e1.firebaseapp.com",
    projectId: "sleek-carrier-portal-607e1",
    storageBucket: "sleek-carrier-portal-607e1.appspot.com",
    messagingSenderId: "32419097543",
    appId: "1:32419097543:web:e32b17946490bad596c7e2",
    measurementId: "G-GC4RPX9RNQ"
};

const app = initializeApp(firebaseConfig);
const webAnalytics = getAnalytics(app);

export async function sendAnalyticsEventAsync(event, params) {
    await logEvent(webAnalytics, event, params);
}