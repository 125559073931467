import React from 'react';
import { View } from 'react-native';
import { Button, Divider, Layout, StyleService, Text, useStyleSheet } from '@ui-kitten/components';
import { Badge } from 'components/utility';
import * as helper from 'utility/Helper';

export const Section = (props) => {

    /*
        props:
        - headerLeftFlex
        - headerRightFlex
        - headerRight
        - isFirstItem
        - isLastItem
        - noBodyPadding
        - renderBody
        - renderFooter
        - subtitle
        - title
        - titleBadge
    */

    const styles = useStyleSheet(themedStyles);
    let headerLeftFlex = {
        flex: props.headerLeftFlex !== undefined ? props.headerLeftFlex : 3
    }
    let headerRightFlex = {
        flex: props.headerRightFlex !== undefined ? props.headerRightFlex : 2,
    }

    let bodyStyles = [];

    if(props.noBodyPadding) {
        bodyStyles.push(styles.noBodyPadding);
    } else {
        bodyStyles.push(styles.bodyPadding)
    }

    if(props.bodyHeight) {
        bodyStyles.push({height: props.bodyHeight})
    }

    return (
        <View style={helper.getContainerStyles(props, styles)}>
            <View style={styles.header}>
                <View style={headerLeftFlex}>
                    <View style={styles.titleView}>
                        <Text style={styles.title} category="s1">{props.title}</Text>
                        {props.titleBadge &&
                            <Badge label={props.titleBadge} type="basic" />
                        }
                    </View>
                    <>
                        {props.subtitle &&
                            <Text style={styles.subtitle} category="s2" appearance="hint">{props.subtitle}</Text>
                        }
                    </>
                </View>
                {props.headerRight &&
                    <View style={headerRightFlex}>
                        {props.headerRight}
                    </View>
                }
            </View>
            <View style={bodyStyles}>
                {props.renderBody}
            </View>
            {props.renderFooter &&
                <View style={styles.footer}>
                    {props.renderFooter}
                </View>
            }
        </View>
    )
}

const themedStyles = StyleService.create({
    container: {
        backgroundColor: "color-basic-100",
        borderWidth: 1,
        borderColor: "border-generic",
        borderRadius: 6,
        marginHorizontal: 8,
        marginVertical: 4,
        shadowColor: "shadow-color",
        shadowOffset: {
            width: 0,
            height: 0,
        },
        shadowOpacity: .1,
        shadowRadius: 2
    },
    firstItem: {
        // first item styles here...
    },
    lastItem: {
        borderBottomWidth: 0,
    },
    header: {
        backgroundColor: "background-basic-color-2",
        borderBottomWidth: 1,
        borderBottomColor: "border-basic-color-4",
        flexDirection: "row",
        padding: 10,
        alignItems: "center",
        minHeight: 50,
        borderTopLeftRadius: 6,
        borderTopRightRadius: 6,
    },
    titleView: {
        flexDirection: "row",
        flewWrap: "wrap",
    },
    title: {

    },
    subtitle: {

    },
    bodyPadding: {
        padding: 10,
    },
    noBodyPadding: {
        padding: 0,
    },
    footer: {

    },
});
