import React, { useState } from 'react';
import { CommonActions, useLinkTo, useNavigation, useRoute } from '@react-navigation/native';
import { View } from 'react-native';
import { Button, StyleService, Text, useStyleSheet } from '@ui-kitten/components';
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome';
import { Badge } from 'components/utility';
import * as helper from 'utility/Helper';

export const MyActiveLoadSummary = (props) => {

    /*
        props:
        - accessorialItems
        - accessorialSchedule
        - activity
        - actualBuyRate
        - allowAccessorial
        - allowAssignDriver
        - allowClaim
        - allowShipperMessage
        - carrierDriverId
        - carrierDriverName
        - carrierUserDeviceId
        - claims
        - commodity
        - currentStop
        - deliveryAppointmentDetails
        - deliveryAppointmentFrom
        - deliveryAppointmentNumber
        - deliveryAppointmentTo
        - deliveryHandling
        - deliveryNumber
        - deliveryNumberRequired
        - deliveryReadyBy
        - deliveryReadyByWithTime
        - destinationAddress
        - destinationAddress2
        - destinationAllowReview
        - destinationAppointmentContactEmail
        - destinationAppointmentContactName
        - destinationAppointmentContactPhone
        - destinationCity
        - destinationCountry
        - destinationDescription
        - destinationId
        - destinationLatitude
        - destinationLocationContactEmail
        - destinationLocationContactName
        - destinationLocationContactPhone
        - destinationLongitude
        - destinationLumper
        - destinationName
        - destinationReceivingFridayEnd
        - destinationReceivingFridayStart
        - destinationReceivingMondayEnd
        - destinationReceivingMondayStart
        - destinationReceivingSaturdayEnd
        - destinationReceivingSaturdayStart
        - destinationReceivingSundayEnd
        - destinationReceivingSundayStart
        - destinationReceivingThursdayEnd
        - destinationReceivingThursdayStart
        - destinationReceivingTuesdayEnd
        - destinationReceivingTuesdayStart
        - destinationReceivingWednesdayEnd
        - destinationReceivingWednesdayStart
        - destinationScheduleType
        - destinationSchedulingMethod
        - destinationState
        - destinationTruckDirections
        - destinationType
        - destinationWarehouseRating
        - destinationWarehouseReviews
        - destinationWebsite
        - destinationZip
        - documents
        - doorType
        - driverId
        - driverLoadNotes
        - driverName
        - dropTrailer
        - id
        - lastUpdated
        - legSummaryMessage
        - loadRevenue
        - mapCoordinates
        - miles
        - milesToOrigin
        - minimumBid
        - mustDeliverBy
        - mustDeliverByWithTime
        - mustPickupBy
        - mustPickupByWithTime
        - originAddress
        - originAddress2
        - originAllowReview
        - originAppointmentContactEmail
        - originAppointmentContactName
        - originAppointmentContactPhone
        - originCity
        - originCountry
        - originDescription
        - originId
        - originLatitude
        - originLocationContactEmail
        - originLocationContactName
        - originLocationContactPhone
        - originLongitude
        - originLumper
        - originName
        - originScheduleType
        - originSchedulingMethod
        - originShippingFridayEnd
        - originShippingFridayStart
        - originShippingMondayEnd
        - originShippingMondayStart
        - originShippingSaturdayEnd
        - originShippingSaturdayStart
        - originShippingSundayEnd
        - originShippingSundayStart
        - originShippingThursdayEnd
        - originShippingThursdayStart
        - originShippingTuesdayEnd
        - originShippingTuesdayStart
        - originShippingWednesdayEnd
        - originShippingWednesdayStart
        - originState
        - originTruckDirections
        - originType
        - originWarehouseRating
        - originWarehouseReviews
        - originWebsite
        - originZip
        - payments
        - pickupAppointmentDetails
        - pickupAppointmentFrom
        - pickupAppointmentNumber
        - pickupAppointmentTo
        - pickupHandling
        - pickupNumber
        - pickupReadyBy
        - pickupReadyByWithTime
        - roundTrip
        - route
        - shipperDeliveryPONumber
        - shipperLogo
        - shipperMessages
        - shipperName
        - shipperPickupPONumber
        - sleekFleetLoadId
        - status
        - stops
        - tempRequirement
        - tonu
        - trailerType
        - type
        - weight

    */

    const styles = useStyleSheet(themedStyles);
    const navigation = useNavigation();
    const loadStatusAttributes = helper.getLoadStatusAttributes(props);
    const linkTo = useLinkTo();
    const route = useRoute();

    let origin;
    let destination;

    if(props.origin != null) {
        origin = props.origin;
    } else {
        origin = props.originCity + ', ' + props.originState;
    }

    if(props.destination != null) {
        destination = props.destination;
    } else {
        destination = props.destinationCity + ', ' + props.destinationState;
    }

    const updateLoadStatus = () => {
        const allowedRoutes = ["active"];
        if(allowedRoutes.includes(route.name)) {
            linkTo('/booked-loads/load-details/'+props.sleekFleetLoadId+'/update-load-status');
        } else {

            let resetStack = false;

            if(helper.isWeb()) {
                resetStack = true;
            } else {
                const navigationState = navigation.getParent().getParent().getState().routes;
                navigationState.forEach(function(item) {
                    if(item.name == 'LoadDetailsStackNavigator' && helper.hasValue(item.state)) {
                        resetStack = true;
                    }
                });
            }

            if(resetStack) {
                navigation.dispatch(
                    CommonActions.reset({
                        index: 0,
                        routes: [
                            {
                                name: 'LoadDetails_Generic',
                                params: {
                                    loadId: props.sleekFleetLoadId,
                                    callback: 'update-load-status'
                                }
                            },
                        ],
                    })
                );
                linkTo('/load-details/'+props.sleekFleetLoadId+'/update-load-status');
            } else {
                linkTo('/load-details/'+props.sleekFleetLoadId+'/update-load-status');
            }
        }
    }

    return (
        <View style={styles.container}>
            <View style={styles.header}>
                <View style={styles.columnOne}>
                    <View style={styles.routeView}>
                        <Text style={styles.route} status="primary" category="s2">{origin}</Text>
                        {props.roundTrip ? (
                            <FontAwesomeIcon style={styles.roundTripIcon} icon={['fal', 'repeat']} size={styles.roundTripIcon.size} color={styles.roundTripIcon.color} />
                        ) : (
                            <FontAwesomeIcon style={styles.routeIcon} icon={['fal', 'angle-right']} size={styles.routeIcon.size} color={styles.routeIcon.color} />
                        )}
                        <Text style={styles.route} status="primary" category="s2">{destination}</Text>
                        {props.stops.length > 0 &&
                            <Badge label={props.stops.length == 1 ? '+1 Stop' : '+'+props.stops.length+' Stops'} type="info" />
                        }
                        {props.roundTrip &&
                            <Badge label="Round Trip" type="info" />
                        }
                    </View>
                    <View style={styles.statusView}>
                        <Text category="s2" appearance="hint">Status: {props.status}</Text>
                    </View>
                </View>
            </View>
            <View style={styles.body}>
                <Text category="s2">Instructions:</Text>
                <Text style={styles.instructions} category="p1" appearance="hint">{loadStatusAttributes.instructions}</Text>
                <Button
                    style={styles.ctaButton}
                    onPress={() => helper.showConfirmActionAlert("Load Update Confirmation", loadStatusAttributes.confirmationMessage, updateLoadStatus)}
                >
                    {loadStatusAttributes.buttonText}
                </Button>
            </View>
        </View>
    )

}

const themedStyles = StyleService.create({
    container: {
        backgroundColor: "color-basic-100",
        marginVertical: 10,
        marginHorizontal: 5,
        borderRadius: 4,
    },
    header: {
        borderBottomWidth: 1,
        borderBottomColor: "border-basic-color-4",
        borderTopStartRadius: 4,
        borderTopEndRadius: 4,
        flexDirection: "row",
        paddingBottom: 15,
        marginBottom: 15,
    },
    body: {

    },
    columnOne: {
        flex: 15,
    },
    columnTwo: {
        flex: 1,
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "row",
    },
    routeView: {
        flexDirection: "row",
        alignItems: "center",
        paddingBottom: 3,
    },
    route: {
        fontSize: 16,
    },
    routeIcon: {
        size: 20,
        color: "color-primary-500",
    },
    roundTripIcon: {
        size: 16,
        color: "color-primary-500",
        marginHorizontal: 3,
    },
    instructions: {
        paddingTop: 5,
        paddingBottom: 15,
    },
    forwardIcon: {
        color: "color-basic-500",
        width: 32,
        height: 32,
    },
    ctaButton: {

    }
});
