import React from 'react';

export const KeyboardAvoidingView = (props) => {
    // Intentionally returning null for web version
    // Without this file, the web implementation will fail on this line in the native version:
    // const lib = require('@codler/react-native-keyboard-aware-scroll-view');
    
    return null;

};
