import React, { useState, useContext } from 'react';
import { View } from 'react-native';
import { Button, Layout, StyleService, Text, useStyleSheet } from '@ui-kitten/components';
import { AppDataContext, AuthContext } from 'components/context';
import { OtherAutocompleteField } from 'components/forms';
import { Loader } from 'components/utility';
import { Formik, Field } from 'formik';
import * as yup from 'yup';
import * as helper from 'utility/Helper';
import * as api from 'api/SleekTechnologiesApi';

export default function AddPreferredLocation(props) {

    const styles = useStyleSheet(themedStyles);
    const { appData, setAppData } = useContext(AppDataContext);
    const { signOut } = useContext(AuthContext);
    const [isLoading, setLoading] = useState(false);
    const validationSchema = yup.object().shape({
        newLocation: yup
            .string()
            .required('Location is Required'),
    });

    const newLocationSettings = {
        newLocation: '',
    }

    const cityStateAutocomplete = [];

    const createPreferredLocation = (data) => {

        setLoading(true);

        api.addPreferredLocation(data.newLocation)
            .then(function(response){
                if(helper.hasValue(response.data.msg) && response.data.msg == "Token Not Found or Expired") {
                    signOut();
                }
                setLoading(false);
                if(response.data.result == 'success') {
                    let newPreferredLocationArray = {
                        label: data.newLocation,
                        value: data.newLocation,
                    }
                    let updatedPreferredLocations = appData.preferredLocations;
                    updatedPreferredLocations.push(newPreferredLocationArray);
                    updatedPreferredLocations.sort((a,b) => a.label > b.label ? 1 : -1);
                    setAppData({
                        ...appData,
                        preferredLocations: updatedPreferredLocations
                    });
                    props.refreshAction(updatedPreferredLocations);
                    props.setModalVisible(false);
                    props.setModalError(null);
                    helper.showFlashMessage(response.data.result, response.data.msg);
                } else if(response.data.result == "error") {
                    throw {
                        type: "custom",
                        message: response.data.msg
                    }
                }
            })
            .catch(function(error){
                setLoading(false);
                helper.handleCatchError(error, true, props.setModalError);
            });
    };

    if(isLoading) {
        return (
            <Loader />
        )
    } else {
        return(
            <>
                <Formik
                    validationSchema={validationSchema}
                    initialValues={newLocationSettings}
                    onSubmit={(values) => createPreferredLocation(values)}
                >
                    {({ handleSubmit, isValid, values }) => (
                        <>
                            <View style={styles.instructionsView}>
                                <Text style={styles.instructions} category="p1" appearance="hint">Preferred locations are used to make finding new loads quicker and easier. Create a new location by entering the name of the city that you would like to add below:</Text>
                            </View>
                            <Field
                                component={OtherAutocompleteField}
                                name="newLocation"
                                placeholder="Search By City & State..."
                                options={cityStateAutocomplete}
                                autoCompleteLookup="location"
                                defaultValue={''}
                            />
                            <Button onPress={handleSubmit} disabled={!isValid}>Add Location</Button>
                        </>
                    )}
                </Formik>
            </>
        )
    }

}

const themedStyles = StyleService.create({
    instructionsView: {
        paddingTop: 10,
        paddingBottom: 20,
    }
});
