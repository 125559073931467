import React, { useContext, useState } from 'react';
import { Pressable, View } from 'react-native';
import { Button, Layout, StyleService, Text, useStyleSheet } from '@ui-kitten/components';
import { AuthContext } from 'components/context';
import { InputField, SelectField } from 'components/forms';
import { Loader } from 'components/utility';
import { Formik, Field } from 'formik';
import * as yup from 'yup';
import * as api from 'api/SleekTechnologiesApi';
import * as helper from 'utility/Helper';
import * as Linking from 'expo-linking';
import * as Constants from 'config/Constants';

export default function ReportAccessorial(props) {

    const styles = useStyleSheet(themedStyles);
    const { signOut } = useContext(AuthContext);
    const [isLoading, setLoading] = useState(false);
    const loadDetails = props.loadDetails;
    const accessorialTypeOptions = Constants.ACCESSORIAL_TYPE_OPTIONS;
    const validationSchema = yup.object().shape({
        accessorialType: yup
            .number()
            .required('Accessorial Type is a required field'),
        message: yup
            .string()
            .required('Message is a required field'),
    });

    const submitAccessorial = (data) => {

        setLoading(true);

        api.submitAccessorial(data.sleekFleetLoadId, data.accessorialType, data.message)
            .then(function(response) {
                if(helper.hasValue(response.data.msg) && response.data.msg == "Token Not Found or Expired") {
                    signOut();
                }
                setLoading(false);
                if(response.data.result == "success") {
                    props.setModalVisible(false);
                    props.setModalError(null);
                    helper.showFlashMessage(response.data.result, response.data.msg);
                    props.getLoadDetails(loadDetails.sleekFleetLoadId);
                } else if(response.data.result == "error") {
                    throw {
                        type: "custom",
                        message: response.data.msg
                    }
                }
            })
            .catch(function(error){
                setLoading(false);
                helper.handleCatchError(error, true, props.setModalError);
            });
    }

    if(isLoading) {
        return (
            <Loader loadingText="Submitting Accessorial"/>
        )
    } else {
        return (
            <View style={styles.container}>
                <Text style={styles.instructions} category="p1">Please choose an accessorial type and enter details below:</Text>
                <Formik
                    validationSchema={validationSchema}
                    initialValues={{
                        sleekFleetLoadId: loadDetails.sleekFleetLoadId,
                        accessorialType: 1,
                        message: "",
                    }}
                    onSubmit={(values) => submitAccessorial(values)}
                >
                    {({ handleSubmit, isValid, values }) => (
                        <>
                            <Field
                                component={SelectField}
                                name="accessorialType"
                                options={accessorialTypeOptions}
                                multiSelect={false}
                            />
                            <Field
                                component={InputField}
                                name="message"
                                placeholder="Accessorial details here..."
                                multiline={true}
                                textStyle={{ minHeight: 150 }}
                            />
                            <Button style={styles.ctaButton} onPress={handleSubmit} disabled={!isValid}>Submit Accessorial</Button>
                        </>
                    )}
                </Formik>
                <View style={styles.disclaimer}>
                    <Text category="p1" appearance="hint">If you need to report an accessorial that does not match a specific accessorial type above, please contact support at
                        <Text onPress={() => Linking.openURL(`tel:${Constants.PHONE_SUPPORT}`)} status="primary"> {Constants.PHONE_SUPPORT}</Text>.
                    </Text>
                </View>
            </View>
        )
    }
}

const themedStyles = StyleService.create({
    container: {
        // styles here...
    },
    instructions: {
        paddingVertical: 10,
    },
    ctaButton: {
        marginTop: 5,
    },
    disclaimer: {
        marginTop: 15,
    }
});
