import React, { useState } from 'react';
import { Pressable, Text, View } from 'react-native';
import { CommonActions, useNavigation, useLinkProps, useLinkTo } from '@react-navigation/native';
import * as helper from 'utility/Helper';

export const CustomLink = ({ to, action, children, resetStack = false, resetStackConfig = null, styleProps = {} }) => {

    /*
        This component was based on the documentation found here:
        https://reactnavigation.org/docs/use-link-props

        props:
        - action: used for alternative in-page navigation behavior (e.g., replace)
        - children: the component(s) rendered between the <CustomLink> tag (component)
        - resetStack: whether to reset the stack prior to performing navigation (boolean)
        - resetStackConfig: object containing the routing configuration if resetStack is true (object)
        - styleProps: additonal styles to apply to the wrapping View element on web
        - to: absolute path to route with any params (string) OR navigation object with screen and props (object)
    */

    const { onPress, ...props } = useLinkProps({ to, action });
    const [isHovered, setIsHovered] = useState(false);
    const navigation = useNavigation();
    const linkTo = useLinkTo();

    const handlePress = () => {
        if(resetStack) {
            navigation.dispatch(
                CommonActions.reset(resetStackConfig)
            )
        }

        if(helper.isWeb()) {
            linkTo(to);
        } else {
            onPress();
        }

    }

    if(helper.isWeb()) {
        if(resetStack) {
            return (
                <View
                    onClick={handlePress}
                    onMouseEnter={() => setIsHovered(true)}
                    onMouseLeave={() => setIsHovered(false)}
                    style={[{ transitionDuration: "150ms", opacity: isHovered ? 0.8 : 1, cursor: "pointer" }, styleProps]}
                    // removing {...props} when resetting stack as this creates a full re-load on web
                >
                    {children}
                </View>
            )
        } else {
            return (
                <View
                    onClick={onPress}
                    onMouseEnter={() => setIsHovered(true)}
                    onMouseLeave={() => setIsHovered(false)}
                    style={[{ transitionDuration: "150ms", opacity: isHovered ? 0.8 : 1, cursor: "pointer" }, styleProps]}
                    {...props}
                >
                    {children}
                </View>
            )
        }

    } else {
        return (
            <Pressable style={{flex: 1, justifyContent: "center"}} onPress={handlePress} {...props}>
                {children}
            </Pressable>
        )
    }

}
