import React from 'react';

export const AuthContext = React.createContext();

export const ThemeContext = React.createContext({
    theme: 'light',
    toggleTheme: () => {},
});

export const AppDataContext = React.createContext({
    appData: {},
    setAppData: () => {},
});

export const LocationServiceContext = React.createContext({
    locationService: {},
    setLocationService: () => {},
});

export const NotificationContext = React.createContext({
    unreadNotificationCount: 0,
    setUnreadNotificationCount: () => {},
});

export const RollbarContext = React.createContext({
    rollbar: {}
});
