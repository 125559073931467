import React, {useState} from 'react';
import { View } from 'react-native';
import { Button, Layout, StyleService, Text, useStyleSheet } from '@ui-kitten/components';
import { Formik, Field } from 'formik';
import { DatepickerField, InputField, OtherAutocompleteField, SelectField } from 'components/forms';
import { Loader } from 'components/utility';
import * as yup from 'yup';
import * as helper from 'utility/Helper';
import * as api from 'api/SleekTechnologiesApi';
import * as Constants from 'config/Constants';
import moment from 'moment';

export default function PaymentHistoryFilters(props) {

    const defaultPaymentHistoryFilters = props.defaultPaymentHistoryFilters;
    const defaultPaymentHistoryFilterDisplayValues = props.defaultPaymentHistoryFilterDisplayValues;
    const paymentHistoryFilters = props.paymentHistoryFilters;
    const paymentHistoryFilterDisplayValues = props.paymentHistoryFilterDisplayValues;
    const [initialValues, setInitialValues] = useState(paymentHistoryFilterDisplayValues);

    const styles = useStyleSheet(themedStyles);
    const [resettingFilters, setResettingFilters] = useState(false);

    const validationSchema = yup.object().shape({
        paymentStatus: yup.lazy(val => (
            Array.isArray(val) ? yup.array().min(1, 'Please select at least 1 Payment Status') : yup.string().required('Please select at least 1 Payment Status'))
        )
    });

    const paymentStatusOptions = Constants.PAYMENT_STATUS_OPTIONS;
    const paymentDateOptions = Constants.DELIVERY_DATE_OPTIONS;
    const minDate = moment().subtract(365, 'days');

    const loadNumberAutocomplete = [];
    const transactionIdAutocomplete = [];
    const cityStateAutocomplete = [];

    const applyFilters = (data) => {

        let updatedPaymentHistoryFilters = {
            loadNumber: data.loadNumber,
            transactionId: data.transactionId,
            paymentStatus: data.paymentStatus,
            paymentDate: data.paymentDate,
            paymentDateFrom: data.paymentDateFrom,
            paymentDateTo: data.paymentDateTo,
            paymentMax: data.paymentMax,
            paymentMin: data.paymentMin,
            sortBy: "payment date",
            offset: 0
        };

        let updatedPaymentHistoryFilterDisplayValues = {
            loadNumber: data.loadNumber,
            transactionId: data.transactionId,
            paymentStatus: (data.paymentStatus == 'all' ? Constants.PAYMENT_STATUS_VALUES : data.paymentStatus),
            paymentDate: data.paymentDate,
            paymentDateFrom: data.paymentDateFrom,
            paymentDateTo: data.paymentDateTo,
            paymentMax: data.paymentMax,
            paymentMin: data.paymentMin,
            sortBy: "payment date",
            offset: 0
        };

        props.setLoading(true);
        props.setPayments([]);
        props.setPaymentHistoryFilters(updatedPaymentHistoryFilters);
        props.setPaymentHistoryFilterDisplayValues(updatedPaymentHistoryFilterDisplayValues);
        props.setModalVisible(false);
    };

    const resetFilters = () => {
        setResettingFilters(true);
        setInitialValues(defaultPaymentHistoryFilterDisplayValues);
        setTimeout(() => {
            setResettingFilters(false);
        }, 100);
    }

    if(resettingFilters) {
        return (
            <Loader loadingText="Resetting Filters"/>
        )
        } else {
        return (
            <View style={styles.container}>
                <Formik
                    validationSchema={validationSchema}
                    initialValues={initialValues}
                    onSubmit={(values) => applyFilters(values)}
                >
                    {({ handleSubmit, isValid, values }) => (
                        <>
                            <Field
                                component={OtherAutocompleteField}
                                name="loadNumber"
                                label="Search by Load Number"
                                placeholder="Enter Load Number..."
                                options={loadNumberAutocomplete}
                                autoCompleteLookup="loadNumber"
                                defaultValue={paymentHistoryFilters.loadNumber}
                            />
                            <Field
                                component={OtherAutocompleteField}
                                name="transactionId"
                                label="Search by Transaction Id"
                                placeholder="Enter Transaction Id..."
                                options={transactionIdAutocomplete}
                                autoCompleteLookup="transactionId"
                                defaultValue={paymentHistoryFilters.transactionId}
                            />
                            <Field
                                component={SelectField}
                                name="paymentDate"
                                label="Payment Date"
                                options={paymentDateOptions}
                                multiSelect={false}
                            />
                            {values.paymentDate == 'custom date range' &&
                                <View style={styles.paymentDateView}>
                                    <Field
                                        style={styles.paymentDateFrom}
                                        component={DatepickerField}
                                        name="paymentDateFrom"
                                        label="Payment Date (Start)"
                                        minDate={minDate}
                                    />
                                    <Field
                                        style={styles.paymentDateTo}
                                        component={DatepickerField}
                                        name="paymentDateTo"
                                        label="Payment Date (End)"
                                        minDate={minDate}
                                    />
                                </View>
                            }
                            <View style={styles.paymentRangeView}>
                                <Field
                                    style={styles.paymentMinimum}
                                    component={InputField}
                                    name="paymentMin"
                                    label="Minimum Amount"
                                />
                                <Field
                                    style={styles.paymentMaximum}
                                    component={InputField}
                                    name="paymentMax"
                                    label="Maximum Amount"
                                />
                            </View>
                            <Field
                                component={SelectField}
                                name="paymentStatus"
                                label="Payment Status"
                                options={paymentStatusOptions}
                                multiSelect={true}
                                selectAllOverride={true}
                                selectAllSubmittedValue={'all'}
                                selectAllDisplayValue={'All Payment Statuses'}
                            />
                            <Button style={styles.ctaButton} onPress={handleSubmit} disabled={!isValid}>Apply Filters</Button>
                            <Button style={styles.secondaryButton} appearance="ghost" onPress={() => resetFilters()}>Reset Filters</Button>
                        </>
                    )}
                </Formik>
            </View>
        )
    }
}

const themedStyles = StyleService.create({
    container: {
        paddingTop: 5,
    },
    paymentDateView: {
        flexDirection: helper.isDesktopWeb() ? "column" : "row",
        marginBottom: 10,
    },
    paymentDateFrom: {
        flex: 1,
        marginRight: helper.isDesktopWeb() ? 0 : 5,
        marginBottom: helper.isDesktopWeb() ? 10 : 0
    },
    paymentDateTo: {
        flex: 1,
        marginLeft: helper.isDesktopWeb() ? 0 : 5
    },
    paymentRangeView: {
        flexDirection: "row",
        marginBottom: 10,
    },
    paymentMinimum: {
        flex: 1,
        marginRight: 5,
    },
    paymentMaximum: {
        flex: 1,
        marginLeft: 5
    },
    ctaButton: {
        marginTop: 10,
    },
    secondaryButton: {
        marginVertical: 10,
    },
});
