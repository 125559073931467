import React from 'react';
import { Image, View } from 'react-native';
import { SafeAreaView } from 'react-native-safe-area-context';
import { Button, Layout, StyleService, Text, useStyleSheet } from '@ui-kitten/components';
import { Header } from 'components/layout';
import * as Constants from 'config/Constants';
import * as helper from 'utility/Helper';
import getEnvVars from 'root/environment';

export default function ScreenNotFound({ navigation }) {

    const styles = useStyleSheet(themedStyles);
    const { sleekApiUrl } = getEnvVars();
    const logoUri = sleekApiUrl+'/images/carrierui/global/modal-logo.png';

    const breadcrumbs = [
        {
            label: 'Page Not Found',
            path: ''
        }
    ]

    const PageNotFoundView = () => {
        return (
            <Layout style={styles.layout} level="3">
                <View style={styles.logoView}>
                    <Image
                        source={{
                            uri: logoUri,
                            headers: Constants.RESOURCE_HEADERS
                        }}
                        style={styles.logo}
                    />
                    <Text style={styles.title} category="h6">Page Not Found</Text>
                    <Text style={styles.description} category="s2" appearance="hint">We're sorry, but the page you are looking for doesn't exist or is no longer available.</Text>
                    <View style={styles.btnView}>
                        <Button style={styles.btn} onPress={() => navigation.navigate('DashboardStackNavigator')} appearance="primary">Back To Dashboard</Button>
                    </View>
                </View>
            </Layout>
        )
    }

    return (
        helper.isWeb() ? (
            <View style={styles.webView}>
                <Header breadcrumbs={breadcrumbs} title="Page Not Found" />
                <PageNotFoundView />
            </View>
        ) : (
            <SafeAreaView style={styles.safeAreaView} edges={['top', 'left', 'right']}>
                <Header activeScreen="ScreenNotFound" />
                <PageNotFoundView />
            </SafeAreaView>
        )
    )

}

const themedStyles = StyleService.create({
    // global styles
    layout: {
        flex: 1,
    },
    logoView: {
        alignItems: "center",
        justifyContent: "center",
        paddingTop: 100,
        paddingBottom: 30,
        marginHorizontal: 40,
    },
    logo: {
        width: 149,
        height: 125
    },
    title: {
        marginTop: 30,
        marginBottom: 15
    },
    description: {

    },
    btnView: {
        marginTop: 30
    },
    btn: {

    },
    // native specific styles
    safeAreaView: {
        backgroundColor: "safe-area-view-background",
        flex: 1,
    },
    // web specific styles
    webView: {
        flex: 1,
    },
});
