import React, { useContext, useEffect, useState } from 'react';
import { Pressable, ScrollView, View } from 'react-native';
import { Button, Divider, Layout, StyleService, Text, useStyleSheet } from '@ui-kitten/components';
import { AppDataContext } from 'components/context';
import { useNavigation } from '@react-navigation/native';
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome';
import * as Constants from 'config/Constants';
import * as Linking from 'expo-linking';
import * as helper from 'utility/Helper';

export default function StatusView(props) {

    const styles = useStyleSheet(themedStyles);
    const loadDetails = props.loadDetails;
    const loadStatusAttributes = props.loadStatusAttributes;
    const navigation = useNavigation();
    const { appData } = useContext(AppDataContext);

    const handleLoadExecutionStep = () => {
        if(loadStatusAttributes.activeStep == "5") {
            props.setActiveModal('CompleteLoad');
            props.setModalVisible(true);
        } else {
            helper.showConfirmActionAlert("Load Update Confirmation", loadStatusAttributes.confirmationMessage, props.updateLoadStatus, loadDetails);
        }
    }

    const handlePromptDriver = () => {
        helper.showConfirmActionAlert("Request Dispatch Confirmation", "Please confirm you want to request driver dispatch.", props.requestDispatch, loadDetails);
    }

    const getIcon = () => {
        return (
            <FontAwesomeIcon style={styles.icon} icon={['fal', loadStatusAttributes.icon]} size={styles.icon.size} color={styles.icon.color} />
        )
    }

    const showModal = (modalName) => {
        props.setActiveModal(modalName);
        props.setModalVisible(true);
    }

    const renderInstructions = () => {
        if(!helper.hasValue(loadDetails.carrierDriverId)) {
            return (
                <>
                    <Text style={styles.instructions} category="p1">This load is not currently assigned to a driver. Please assign a driver before proceeding with executing this load.</Text>
                    <Button onPress={() => showModal('AssignDriver')}>Assign Driver</Button>
                </>
            )
        } else if(!loadDetails.allowedToStart && loadDetails.status != 'Delivered') {
            return (
                <>
                    <Text style={styles.instructions} category="p1">The driver assigned to this load is actively delivering another load. Please assign another driver or complete delivery of active load before starting this load.</Text>
                    <Button disabled={true}>Get Started</Button>
                </>
            )
        } else if(helper.isWeb() && loadDetails.status == 'Tendered' && loadDetails.carrierDriverId == appData.carrierUserData.id) {
            return (
                <>
                    <Text style={styles.instructions} category="p1">To provide the best service to our customers, we require self assigned loads to be dispatched via our mobile app for proper location tracking. Please download our app on iOS or Android to get started.</Text>
                    <Button style={{marginBottom: 10}} onPress={() => Linking.openURL(Constants.APP_STORE_APP_LINK)} accessoryLeft={<FontAwesomeIcon icon={['fab', 'app-store-ios']} size={24} color="#ffffff" />}>Download for iOS</Button>
                    <Button onPress={() => Linking.openURL(Constants.GOOGLE_PLAY_APP_LINK)} accessoryLeft={<FontAwesomeIcon icon={['fab', 'google-play']} size={24} color="#ffffff" />}>Download for Android</Button>
                </>
            )
        } else if(loadDetails.status == 'Tendered' && loadDetails.carrierDriverId != appData.carrierUserData.id) {
            if(props.dispatchRequested) {
                return (
                    <>
                        <Text style={styles.instructionsTitle} category="s1">Dispatch Requested</Text>
                        <Text style={styles.instructions} category="p1">A message has been sent to the driver requesting their dispatch on this load.</Text>
                    </>
                )
            } else {
                return (
                    <>
                        <Text style={styles.instructions} category="p1">Tap the button below to send a message to this load's assigned driver requesting they dispatch themselves from their Sleek application.</Text>
                        <Button onPress={() => handlePromptDriver()}>Request Dispatch</Button>
                    </>
                )
            }
        } else {
            return (
                <>
                    <Text style={styles.instructions} category="p1">{loadStatusAttributes.instructions}</Text>
                    {loadStatusAttributes.buttonText != "" &&
                        <Button onPress={() => handleLoadExecutionStep()}>{loadStatusAttributes.buttonText}</Button>
                    }
                </>
            )
        }
    }

    React.useLayoutEffect(() => {
        if(helper.isWeb()) {
            navigation.setOptions({
                title: 'Load Details - Status'
            })
        }
    }, [navigation]);

    return (
        <ScrollView contentContainerStyle={styles.scrollView}>
            <Layout style={styles.container} level="2">
                <View style={styles.iconView}>
                    {getIcon()}
                </View>
                <View style={styles.statusView}>
                    <Text style={styles.status} category="h5">{loadStatusAttributes.label}</Text>
                </View>
                <Layout style={styles.instructionsView} level="3">
                    {!props.dispatchRequested &&
                        <Text style={styles.instructionsTitle} category="s1">Next Step</Text>
                    }
                    {renderInstructions()}
                </Layout>
            </Layout>
        </ScrollView>
    )
}

const themedStyles = StyleService.create({
    scrollView: {
        flexGrow: 1,
    },
    container: {
        flex: 1,
        alignItems: "center",
        paddingHorizontal: 20,
    },
    iconView: {
        paddingTop: 60,
        paddingBottom: 15,
    },
    icon: {
        size: 100,
        color: "#C5CEE0",
    },
    statusView: {
        paddingBottom: 20,
    },
    status: {

    },
    instructionsView: {
        marginTop: 40,
        padding: 20,
        alignItems: "center",
        maxWidth: 600,
    },
    instructionsTitle: {
        paddingBottom: 5,
    },
    instructions: {
        textAlign: "center",
        paddingBottom: 20,
    },
    appStoreLink: {
        color: "color-primary-500"
    }
});
