import React, { useContext, useState, useEffect, useRef } from 'react';
import { createMaterialTopTabNavigator } from '@react-navigation/material-top-tabs';
import { useFocusEffect, useLinkTo } from "@react-navigation/native";
import { View } from 'react-native';
import { SafeAreaView } from 'react-native-safe-area-context';
import { Button, Layout, MenuItem, OverflowMenu, StyleService, Tab, TabBar, Text, useStyleSheet } from '@ui-kitten/components';
import { CustomModal, Header } from 'components/layout';
import { Loader } from 'components/utility';
import { AppDataContext, AuthContext, LocationServiceContext } from 'components/context';
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome';
import * as Location from 'expo-location';
import * as helper from 'utility/Helper';
import * as api from 'api/SleekTechnologiesApi';
import * as locationTracking from 'location/LocationTracking';
import { StatusView, DetailsView, RouteView, ActivityView } from 'screens/load-details/tabs';
import {
    AccessorialSchedule,
    AssignDriver,
    BookNow,
    ClaimDetails,
    CompleteLoad,
    LoadDocumentDetails,
    LocationReviewDetails,
    LocationServicesDisabled,
    MessageDriver,
    MessageShipper,
    ReportAccessorial,
    ReportOSD,
    SubmitLocationReview,
} from 'screens/load-details/modals';

export default function LoadDetails({ route, navigation }) {

    const styles = useStyleSheet(themedStyles);

    const { appData } = useContext(AppDataContext);
    const { signOut } = useContext(AuthContext);
    const { locationService } = useContext(LocationServiceContext);

    const [isLoading, setLoading] = useState(true);
    const [loadingText, setLoadingText] = useState('Getting Load Information');
    const [loadDetails, setLoadDetails] = useState([]);
    const [claimDetails, setClaimDetails] = useState(null);
    const [locationReviewDetails, setLocationReviewDetails] = useState(null);
    const [loadDocumentDetails, setLoadDocumentDetails] = useState(null);
    const [activeModal, setActiveModal] = useState(null);
    const [modalVisible, setModalVisible] = useState(false);
    const [modalError, setModalError] = useState(null);
    const [modalCloseDisabled, setModalCloseDisabled] = useState(false);
    const [location, setLocation] = useState(null);
    const [markers, setMarkers] = useState([]);
    const [points, setPoints] = useState([]);
    const [nearDestination, setNearDestination] = useState(false);
    const [locationId, setLocationId] = useState(null);
    const [callback, setCallback] = useState(route.params?.callback != undefined ? route.params.callback : null);
    const [loadingComplete, setLoadingComplete] = useState(false);
    const [breadcrumbs, setBreadcrumbs] = useState([]);
    const [selectedMenuItemIndex, setSelectedMenuItemIndex] = useState(null);
    const [menuVisible, setMenuVisible] = useState(false);
    const [dispatchRequested, setDispatchRequested] = useState(false);
    const [isAppRatingEligible, setIsAppRatingEligible] = useState(null);
    
    if(!helper.isWeb()) {
        Promise.all([
            helper.isAppRatingEligible()
        ]).then((response) => {
            const [isAppRatingEligible] = response;
            setIsAppRatingEligible(isAppRatingEligible);
        });
    }

    const isCarrierLoad = useRef(false);
    const loadStatusAttributes = useRef(null);
    const initialRouteName = useRef(helper.hasValue(route.params?.tab) ? route.params.tab : 'details');
    const isMounted = useRef(true);
    const linkTo = useLinkTo();

    const getModal = (attribute) => {

        let modalTitle;
        let modalBody;

        switch(activeModal) {
            case 'AccessorialSchedule':
                modalTitle = 'Accessorial Schedule';
                modalBody = <AccessorialSchedule loadDetails={loadDetails}/>;
                break;
            case 'AssignDriver':
                modalTitle = 'Assign Driver';
                modalBody = <AssignDriver setModalVisible={setModalVisible} setModalError={setModalError} loadDetails={loadDetails} getLoadDetails={getLoadDetails} />;
                break;
            case 'BookNow':
                modalTitle = 'Book Now';
                modalBody = <BookNow setModalVisible={setModalVisible} setModalError={setModalError} loadDetails={loadDetails} setModalCloseDisabled={setModalCloseDisabled} navigation={navigation} linkTo={linkTo}/>;
                break;
            case 'CompleteLoad':
                modalTitle = 'Complete Load';
                modalBody = <CompleteLoad setModalVisible={setModalVisible} setModalError={setModalError} loadDetails={loadDetails} getLoadDetails={getLoadDetails} setModalCloseDisabled={setModalCloseDisabled} />;
                break;
            case 'ClaimDetails':
                modalTitle = 'Claim Details';
                modalBody = <ClaimDetails setModalVisible={setModalVisible} claimDetails={claimDetails} />;
                break;
            case 'LoadDocumentDetails':
                modalTitle = 'Load Document';
                modalBody = <LoadDocumentDetails setModalVisible={setModalVisible} loadDocumentDetails={loadDocumentDetails} />;
                break;
            case 'LocationReviewDetails':
                modalTitle = 'Location Review';
                modalBody = <LocationReviewDetails setModalVisible={setModalVisible} locationReviewDetails={locationReviewDetails} />;
                break;
            case 'LocationServicesDisabled':
                modalTitle = '';
                modalBody = <LocationServicesDisabled setModalVisible={setModalVisible} />
                break;
            case 'MessageDriver':
                modalTitle = 'Message Driver';
                modalBody = <MessageDriver setModalVisible={setModalVisible} setModalError={setModalError} loadDetails={loadDetails} getLoadDetails={getLoadDetails} />;
                break;
            case 'MessageShipper':
                modalTitle = 'Message Shipper';
                modalBody = <MessageShipper setModalVisible={setModalVisible} setModalError={setModalError} loadDetails={loadDetails} getLoadDetails={getLoadDetails} location={location} />;
                break;
            case 'ReportAccessorial':
                modalTitle = 'Report Accessorial';
                modalBody = <ReportAccessorial setModalVisible={setModalVisible} setModalError={setModalError} loadDetails={loadDetails} getLoadDetails={getLoadDetails} />;
                break;
            case 'ReportOSD':
                modalTitle = 'Report OSD';
                modalBody = <ReportOSD setModalVisible={setModalVisible} setModalError={setModalError} loadDetails={loadDetails} getLoadDetails={getLoadDetails} />;
                break;
            case 'SubmitLocationReview':
                modalTitle = 'Submit Location Review';
                modalBody = <SubmitLocationReview setModalVisible={setModalVisible} setModalError={setModalError} loadDetails={loadDetails} getLoadDetails={getLoadDetails} locationId={locationId} />;
                break;
        }

        if(attribute == 'title') {
            return modalTitle;
        } else {
            return modalBody;
        }

    }

    const { Navigator, Screen } = createMaterialTopTabNavigator();

    const TopTabBar = ({ navigation, state }) => {
        if(isCarrierLoad.current && loadStatusAttributes.current.showLoadStatus) {
            return (
                <TabBar
                    selectedIndex={state.index}
                    onSelect={index => navigation.navigate(state.routeNames[index])}>
                    <Tab title="Status"/>
                    <Tab title="Details"/>
                    <Tab title="Route"/>
                    <Tab title="Activity"/>
                </TabBar>
            )
        } else if(isCarrierLoad.current && !loadStatusAttributes.current.showLoadStatus) {
            return (
                <TabBar
                    selectedIndex={state.index}
                    onSelect={index => navigation.navigate(state.routeNames[index])}>
                    <Tab title="Details"/>
                    <Tab title="Route"/>
                    <Tab title="Activity"/>
                </TabBar>
            )
        } else {
            return (
                <TabBar
                    selectedIndex={state.index}
                    onSelect={index => navigation.navigate(state.routeNames[index])}>
                    <Tab title="Details"/>
                    <Tab title="Route"/>
                </TabBar>
            )
        }
    }

    const getInitialLocationCallback = (updatedLocation, updatedLoadDetails) => {

        //helper.log("********* getInitialLocationCallback *********");

        const updatedMarkers = [];

        //Set Origin Marker
        let origin = {
            latitude: Number(updatedLoadDetails.originLatitude),
            longitude: Number(updatedLoadDetails.originLongitude),
            description: updatedLoadDetails.originCity+', '+updatedLoadDetails.originState,
            stationName: 'Origin',
            identifier: 'origin',
            pinColor: 'red',
        }
        updatedMarkers.push(origin);

        //Set Stop Markers (if multi-stop)
        if(updatedLoadDetails.stops.length > 0) {
            updatedLoadDetails.stops.forEach(stop => {
                let stopMarker = {
                    latitude: Number(stop.locationLatitude),
                    longitude: Number(stop.locationLongitude),
                    description: stop.locationCity+', '+stop.locationState,
                    stationName: 'Stop #'+stop.stopOrder,
                    identifier: 'stop-'+stop.stopOrder,
                    pinColor: 'green',
                }
                updatedMarkers.push(stopMarker);
            })
        }

        //Set Destination Marker
        let destination = {
            latitude: Number(updatedLoadDetails.destinationLatitude),
            longitude: Number(updatedLoadDetails.destinationLongitude),
            description: updatedLoadDetails.destinationCity+', '+updatedLoadDetails.destinationState,
            stationName: 'Destination',
            identifier: 'destination',
            pinColor: 'red',
        }
        updatedMarkers.push(destination);

        //Set Current Location Marker, if available
        if(updatedLocation != '' && ((updatedLoadDetails.status == 'Available' || updatedLoadDetails.status == 'Awarded' || updatedLoadDetails.status == 'Tendered') || (updatedLoadDetails.carrierDriverId == appData.carrierUserData.id && (updatedLoadDetails.status == 'Dispatched' || updatedLoadDetails.status == 'Loading' || updatedLoadDetails.status == 'In Transit' || updatedLoadDetails.status == 'Unloading')))) {
            let currentLocation = {
                latitude: Number(updatedLocation.coords.latitude),
                longitude: Number(updatedLocation.coords.longitude),
                description: '',
                stationName: 'Current Location',
                pinColor: 'blue',
                identifier: 'current location',
            }
            updatedMarkers.push(currentLocation);
        }

        helper.logEvent({ category: 'Location Tracking', message: 'setLocation', data: { location: updatedLocation }});
        setMarkers(updatedMarkers);
        setLocation(updatedLocation);

        //Set Points
        const updatedPoints = [];
        if(updatedLoadDetails.mapCoordinates.length > 0) {
            for (var i = 0; i < updatedLoadDetails.mapCoordinates.length; i++) {
                updatedPoints.push({ latitude: updatedLoadDetails.mapCoordinates[i].latitude, longitude: updatedLoadDetails.mapCoordinates[i].longitude });
            }
        }
        setPoints(updatedPoints);

        //Calculate Distance to Destination
        if(updatedLocation != '' && helper.calculateDistance(updatedLocation.coords.latitude, updatedLocation.coords.longitude, updatedLoadDetails.destinationLatitude, updatedLoadDetails.destinationLongitude, 'M') < 1) {
            setNearDestination(nearDestination);
        }

        setLoadingComplete(true);
    }

    const startLocationServicesCallback = (updatedLocation, locs) => {
        //helper.log("********* startLocationServicesCallBack *********");

        //Update Current Location Marker
        let currentLocation = {
            latitude: Number(updatedLocation.coords.latitude),
            longitude: Number(updatedLocation.coords.longitude),
            description: '',
            stationName: 'Current Location',
            pinColor: 'blue',
            identifier: 'current location',
        }
        let updatedMarkers = [];
        markers.map((item, index) => {
            if(item.identifier == 'current location') {
                updatedMarkers[index] = currentLocation;
            } else {
                updatedMarkers[index] = item;
            }
        });
        setMarkers(updatedMarkers);

        //Update Location and Location Service
        helper.logEvent({ category: 'Location Tracking', message: 'setLocation', data: { location: updatedLocation }});
        setLocation(updatedLocation);

        //Set Points
        const updatedPoints = [];
        if(Array.isArray(locs) && locs.length > 0) {
            for (var i = 0; i < locs.length; i++) {
                updatedPoints.push({ latitude: locs[i].latitude, longitude: locs[i].longitude });
            }
        }
        setPoints(updatedPoints);

        //Calculate Distance to Destination
        if(helper.calculateDistance(updatedLocation.coords.latitude, updatedLocation.coords.longitude, loadDetails.destinationLatitude, loadDetails.destinationLongitude, 'M') < 1) {
            setNearDestination(nearDestination);
        }
    }

    const startLocationServices = () => {
        //helper.log("********* startLocationServices *********");
        let origin = {
            latitude: Number(loadDetails.originLatitude),
            longitude: Number(loadDetails.originLongitude),
        }
        let destination = {
            latitude: Number(loadDetails.destinationLatitude),
            longitude: Number(loadDetails.destinationLongitude),
        }
        locationTracking.startLocationServices(locationService, origin, destination, startLocationServicesCallback);
    }

    const stopLocationServices = () => {
        //helper.log("********* stopLocationServices *********");
        locationTracking.stopLocationServices(locationService);
    }

    const getLoadDetails = (loadId, startLocationService = false, stopLocationService = false, reviewLocationId = null, resetCallback = false, askForAppRating = false) => {
        if(!isLoading) {
            setLoading(true);
        }
        api.getLoadDetailsData(loadId)
            .then(function(response){
                if(helper.hasValue(response.data.msg) && response.data.msg == "Token Not Found or Expired") {
                    signOut();
                }
                if(response.data.result == "success") {

                    setLoadDetails(response.data.loadDetails);

                    let breadcrumbsData;

                    switch (route.name) {
                        case 'LoadDetails_Generic':
                            breadcrumbsData = [
                                {
                                    label: 'Dashboard',
                                    path: '/dashboard'
                                },
                                {
                                    label: response.data.loadDetails.sleekFleetLoadId,
                                    path: ''
                                }
                            ]
                            break;
                        case 'LoadDetails_FindALoad':
                            breadcrumbsData = [
                                {
                                    label: 'Dashboard',
                                    path: '/dashboard'
                                },
                                {
                                    label: 'Find A Load',
                                    path: '/find-a-load'
                                },
                                {
                                    label: response.data.loadDetails.sleekFleetLoadId,
                                    path: ''
                                }
                            ]
                            break;
                        case 'LoadDetails_BookedLoads':
                            breadcrumbsData = [
                                {
                                    label: 'Dashboard',
                                    path: '/dashboard'
                                },
                                {
                                    label: 'Booked Loads',
                                    path: '/booked-loads'
                                },
                                {
                                    label: response.data.loadDetails.sleekFleetLoadId,
                                    path: ''
                                }
                            ]
                            break;
                        case 'LoadDetails_Drivers':
                            breadcrumbsData = [
                                {
                                    label: 'Dashboard',
                                    path: '/dashboard'
                                },
                                {
                                    label: 'Drivers',
                                    path: '/drivers'
                                },
                                {
                                    label: response.data.loadDetails.carrierDriverName,
                                    path: '/drivers/'+response.data.loadDetails.carrierDriverId
                                },
                                {
                                    label: response.data.loadDetails.sleekFleetLoadId,
                                    path: ''
                                }
                            ]
                            break;
                        case 'LoadDetails_Reports':
                            breadcrumbsData = [
                                {
                                    label: 'Dashboard',
                                    path: '/dashboard'
                                },
                                {
                                    label: 'Reports',
                                    path: '/reports'
                                },
                                {
                                    label: response.data.loadDetails.sleekFleetLoadId,
                                    path: ''
                                }
                            ]
                            break;
                        case 'LoadDetails_Notifications':
                            breadcrumbsData = [
                                {
                                    label: 'Dashboard',
                                    path: '/dashboard'
                                },
                                {
                                    label: 'Notifications',
                                    path: '/notifications'
                                },
                                {
                                    label: response.data.loadDetails.sleekFleetLoadId,
                                    path: ''
                                }
                            ]
                            break;
                        default:
                            breadcrumbsData = [
                                {
                                    label: 'Dashboard',
                                    path: '/dashboard'
                                },
                                {
                                    label: response.data.loadDetails.sleekFleetLoadId,
                                    path: ''
                                }
                            ]
                            break;
                    }
                    setBreadcrumbs(breadcrumbsData);

                    let newLoadStatusAttributes = helper.getLoadStatusAttributes(response.data.loadDetails);
                    loadStatusAttributes.current = newLoadStatusAttributes;

                    if(callback == 'update-load-status' && !resetCallback) {
                        updateLoadStatus(response.data.loadDetails);
                    } else {

                        if(response.data.loadDetails.driverId == appData.carrierData.id) {
                            isCarrierLoad.current = true;
                        }

                        if(response.data.loadDetails.driverId == appData.carrierData.id && newLoadStatusAttributes.showLoadStatus && !helper.hasValue(route.params?.tab)) {
                            initialRouteName.current = "status";
                        }

                        locationTracking.getInitialLocation(response.data.loadDetails, getInitialLocationCallback);

                        if(startLocationService) {
                            startLocationServices();
                        }

                        if(stopLocationService) {
                            stopLocationServices();
                        }

                        if(resetCallback) {
                            setCallback(null);
                        }

                        if(askForAppRating) {
                            helper.requestAppRating();
                        } else if(helper.hasValue(reviewLocationId) && !helper.isWeb() && response.data.loadDetails.carrierDriverId == appData.carrierUserData.id) {
                            setTimeout(() => {
                                setLocationId(reviewLocationId);
                                setActiveModal('SubmitLocationReview');
                                setModalVisible(true);
                            }, 4000);
                        }

                        setLoading(false);
                    }

                } else if(response.data.result == "error") {
                    throw {
                        type: "custom",
                        message: response.data.msg
                    }
                }
            })
            .catch(function(error){
                setLoading(false);
                helper.handleCatchError(error);
            });
    }

    const updateLoadStatus = async (load) => {

        if(!isLoading) {
            setLoading(true);
        }

        setLoadingText('Updating Load');

        let loadStatus = (loadStatusAttributes.current.stopType != "" ? loadStatusAttributes.current.stopType : loadStatusAttributes.current.nextStatus.toLowerCase());

        if(loadStatus == 'dispatched' && load.carrierDriverId == appData.carrierUserData.id && !helper.isWeb()) {
            let foregroundService = await Location.requestForegroundPermissionsAsync();
            let backgroundService = await Location.requestBackgroundPermissionsAsync();

            if(foregroundService.status !== 'granted' || backgroundService.status !== 'granted') {
                if(foregroundService.status !== 'granted') {
                    helper.logEvent({ sendToRollbar: true, category: 'Location Tracking', message: 'Load Update Cancelled: Foreground Location Tracking is Turned Off', data: { status: foregroundService.status } });
                }
                if(backgroundService.status !== 'granted') {
                    helper.logEvent({ sendToRollbar: true, category: 'Location Tracking', message: 'Load Update Cancelled: Background Location Tracking is Turned Off', data: { status: backgroundService.status } });
                }

                api.reportLocationPermissionIssue(load.sleekFleetLoadId);

                setLoading(false);
                setActiveModal('LocationServicesDisabled');
                setModalVisible(true);
                return;
            }
        }

        // Log event if user's location is not properly set
        if(location === null) {
            helper.logEvent({ sendToRollbar: true, category: 'Location Tracking', message: 'LoadDetails.tsx: No Location Detected', data: { location: location, sleekFleetLoadId: load.sleekFleetLoadId }});
        }

        api.updateLoad(load.sleekFleetLoadId, loadStatus, (location != '' && location != null ? location.coords.latitude : 'none'), (location != '' && location != null ? location.coords.longitude : 'none'), "", loadStatusAttributes.current.stopNumber)
            .then(function(response){

                if(helper.hasValue(response.data.msg) && response.data.msg == "Token Not Found or Expired") {
                    signOut();
                }
                if(response.data.result == "success") {

                    let startLocationService = false;
                    let stopLocationService = false;
                    let reviewLocationId = null;
                    let resetCallback = false;
                    let askForAppRating = false;

                    if(load.carrierDriverId == appData.carrierUserData.id && !helper.isWeb()) {
                        if(loadStatus == 'dispatched') {
                            startLocationService = true;
                        } else if(loadStatus == 'delivered') {
                            stopLocationService = true;
                        }
                    }

                    if(loadStatus == 'in transit' || loadStatus == 'delivered') {
                        reviewLocationId = (loadStatus == 'in transit' ? load.originId : load.destinationId);
                    }

                    if(callback != null) {
                        resetCallback = true;
                    }

                    if(loadStatus == 'delivered' && isAppRatingEligible == true) {
                        askForAppRating = true;
                    }

                    getLoadDetails(load.sleekFleetLoadId, startLocationService, stopLocationService, reviewLocationId, resetCallback, askForAppRating);

                } else if(response.data.result == "error") {
                    throw {
                        type: "custom",
                        message: response.data.msg
                    }
                }
            })
            .catch(function(error){
                if(isLoading) {
                    setLoading(false);
                }
                helper.handleCatchError(error);
            });

    }

    const requestDispatch = async (load) => {
        if(!isLoading) {
            setLoading(true);
        }

        setLoadingText('Requesting Dispatch');

        api.messageDriver(load.carrierDriverId, "Your dispatcher has requested that you mark yourself as dispatched. Please click the Get Started button for load "+load.sleekFleetLoadId+" within the Sleek application.", load.id)
            .then(function(response){

                if(helper.hasValue(response.data.msg) && response.data.msg == "Token Not Found or Expired") {
                    signOut();
                }
                if(response.data.result == "success") {
                    setDispatchRequested(true);
                    setLoading(false);
                } else if(response.data.result == "error") {
                    throw {
                        type: "custom",
                        message: response.data.msg
                    }
                }
            })
            .catch(function(error){
                if(isLoading) {
                    setLoading(false);
                }
                helper.handleCatchError(error);
            });
    }

    useFocusEffect(
        React.useCallback(() => {
            const appRatingRequest = route.params?.appRatingRequest ? route.params.appRatingRequest : false;
            getLoadDetails(route.params.loadId, false, false, null, false, appRatingRequest);
            return () => {
                // Update component status to avoid updates on an unmounted component
                isMounted.current = false;
            }
        }, [])
    );

    useEffect(() => {
        //Restart Foreground Tracking, if appropriate
        let statusArray = ['Dispatched', 'Loading', 'In Transit', 'Unloading'];
        if(loadDetails.carrierDriverId == appData.carrierUserData.id && helper.inArray(loadDetails.status, statusArray) && !helper.isWeb()) {
            locationTracking.restartForegroundService(locationService, startLocationServicesCallback);
        }
    }, [loadingComplete]);

    const showModal = (modalName) => {
        setActiveModal(modalName);
        setModalVisible(true);
    }

    const getPageActions = () => {
        if(isCarrierLoad.current) {

            const onItemSelect = (index) => {
                setSelectedMenuItemIndex(index);
                setMenuVisible(false);
            }

            const renderToggleButton = () => (
                <Button appearance={helper.isDesktopWeb() ? "outline" : "ghost"} size={helper.isDesktopWeb() ? "small" : "medium"} accessoryRight={<FontAwesomeIcon style={styles.moreIcon} icon={['far', 'ellipsis-h']} size={styles.moreIcon.size} color={styles.moreIcon.color} />} onPress={() => setMenuVisible(true)}>{helper.isDesktopWeb() ? 'Load Actions' : ''}</Button>
            )

            return (
                <>
                    {helper.checkForRoles(['manager', 'driver']) &&
                        <OverflowMenu
                            style={styles.overflowMenu}
                            anchor={renderToggleButton}
                            visible={menuVisible}
                            selectedIndex={selectedMenuItemIndex}
                            onSelect={onItemSelect}
                            placement="bottom end"
                            backdropStyle={styles.overflowMenuBackdrop}
                            onBackdropPress={() => setMenuVisible(false)}
                        >
                            {loadDetails.allowAssignDriver && helper.checkForRoles(['manager']) &&
                                <MenuItem title="Assign Driver" onPress={() => showModal('AssignDriver')}/>
                            }
                            {loadDetails.carrierDriverId != '' && helper.checkForRoles(['manager']) &&
                                <MenuItem title="Message Driver" onPress={() => showModal('MessageDriver')}/>
                            }
                            {loadDetails.allowShipperMessage && helper.checkForRoles(['manager', 'driver']) &&
                                <MenuItem title="Message Shipper" onPress={() => showModal('MessageShipper')}/>
                            }
                            {loadDetails.allowAccessorial && helper.checkForRoles(['manager', 'driver']) &&
                                <MenuItem title="Report Accessorial" onPress={() => showModal('ReportAccessorial')}/>
                            }
                            {loadDetails.allowClaim && helper.checkForRoles(['manager', 'driver']) &&
                                <MenuItem title="Report OSD" onPress={() => showModal('ReportOSD')}/>
                            }
                            {loadDetails.status == 'Delivered' && helper.checkForRoles(['manager', 'driver']) &&
                                <MenuItem title="Complete Load" onPress={() => showModal('CompleteLoad')}/>
                            }
                        </OverflowMenu>
                    }
                </>
            )
        } else {
            if(helper.checkForRoles(['manager'])) {
                return (
                    <Button appearance="outline" size={helper.isMobileWeb() ? 'tiny' : 'small'} onPress={() => showModal('BookNow')}>Book Now</Button>
                )
            }
        }
    }

    if(isLoading) {
        return (
            <Loader loadingText={loadingText} />
        )
    } else {
        return (
            helper.isWeb() ? (
                <View style={styles.webView}>
                    <Header breadcrumbs={breadcrumbs} title="Load Details" subtitle={helper.isMobileWeb() ? loadStatusAttributes.current.loadStatusMessage : 'Load Status: ' + loadStatusAttributes.current.loadStatusMessage} pageActions={getPageActions()}/>
                    <Navigator style={styles.navigator} tabBar={props => <TopTabBar {...props} />} initialRouteName={initialRouteName.current} backBehavior="history">
                        {isCarrierLoad.current && loadStatusAttributes.current.showLoadStatus &&
                            <Screen
                                name="status"
                                children={() =>
                                    <StatusView
                                        loadDetails={loadDetails}
                                        updateLoadStatus={updateLoadStatus}
                                        requestDispatch={requestDispatch}
                                        loadStatusAttributes={loadStatusAttributes.current}
                                        setModalVisible={setModalVisible}
                                        setActiveModal={setActiveModal}
                                        dispatchRequested={dispatchRequested}
                                    />
                                }
                            />
                        }
                        <Screen
                            name="details"
                            children={() =>
                                <DetailsView
                                    loadDetails={loadDetails}
                                    isCarrierLoad={isCarrierLoad.current}
                                    setModalVisible={setModalVisible}
                                    setActiveModal={setActiveModal}
                                    setClaimDetails={setClaimDetails}
                                    setLoadDocumentDetails={setLoadDocumentDetails}
                                />
                            }
                        />
                        <Screen
                            name="route"
                            children={() =>
                                <RouteView
                                    loadDetails={loadDetails}
                                    isCarrierLoad={isCarrierLoad.current}
                                    markers={markers}
                                    points={points}
                                    setModalVisible={setModalVisible}
                                    setActiveModal={setActiveModal}
                                    setLocationId={setLocationId}
                                    setLocationReviewDetails={setLocationReviewDetails}
                                />
                            }
                        />
                        {isCarrierLoad.current &&
                            <Screen
                                name="activity"
                                children={() =>
                                    <ActivityView
                                        loadDetails={loadDetails}
                                    />
                                }
                            />
                        }
                    </Navigator>
                    <CustomModal title={getModal('title')} modalVisible={modalVisible} setModalVisible={setModalVisible} modalError={modalError} modalCloseDisabled={modalCloseDisabled} setModalError={setModalError}>
                        {getModal('body')}
                    </CustomModal>
                </View>
            ) : (
                <SafeAreaView style={styles.safeAreaView} edges={['top', 'left', 'right']}>
                    <Header
                        activeScreen="LoadDetails"
                        subtitle={loadStatusAttributes.current.loadStatusMessage}
                        loadDetails={loadDetails}
                        isCarrierLoad={isCarrierLoad.current}
                        setModalVisible={setModalVisible}
                        setActiveModal={setActiveModal}
                    />
                    <Navigator style={styles.navigator} tabBar={props => <TopTabBar {...props} />} initialRouteName={initialRouteName.current}>
                        {isCarrierLoad.current && loadStatusAttributes.current.showLoadStatus &&
                            <Screen
                                name="status"
                                children={() =>
                                    <StatusView
                                        loadDetails={loadDetails}
                                        updateLoadStatus={updateLoadStatus}
                                        requestDispatch={requestDispatch}
                                        loadStatusAttributes={loadStatusAttributes.current}
                                        setModalVisible={setModalVisible}
                                        setActiveModal={setActiveModal}
                                        dispatchRequested={dispatchRequested}
                                    />
                                }
                            />
                        }
                        <Screen
                            name="details"
                            children={() =>
                                <DetailsView
                                    loadDetails={loadDetails}
                                    isCarrierLoad={isCarrierLoad.current}
                                    setModalVisible={setModalVisible}
                                    setActiveModal={setActiveModal}
                                    setClaimDetails={setClaimDetails}
                                    setLoadDocumentDetails={setLoadDocumentDetails}
                                />
                            }
                        />
                        <Screen
                            name="route"
                            children={() =>
                                <RouteView
                                    loadDetails={loadDetails}
                                    isCarrierLoad={isCarrierLoad.current}
                                    markers={markers}
                                    points={points}
                                    setModalVisible={setModalVisible}
                                    setActiveModal={setActiveModal}
                                    setLocationId={setLocationId}
                                    setLocationReviewDetails={setLocationReviewDetails}
                                />
                            }
                        />
                        {isCarrierLoad.current &&
                            <Screen
                                name="activity"
                                children={() =>
                                    <ActivityView
                                        loadDetails={loadDetails}
                                    />
                                }
                            />
                        }
                    </Navigator>
                    <CustomModal title={getModal('title')} modalVisible={modalVisible} setModalVisible={setModalVisible} modalError={modalError} modalCloseDisabled={modalCloseDisabled} setModalError={setModalError}>
                        {getModal('body')}
                    </CustomModal>
                </SafeAreaView>
            )
        )
    }

}

const themedStyles = StyleService.create({
    // global styles
    navigator: {
        backgroundColor: "color-basic-400"
    },
    // native specific styles
    safeAreaView: {
        backgroundColor: "safe-area-view-background",
        flex: 1,
    },
    // web specific styles
    webView: {
        flex: 1,
    },
    overflowMenu: {
        width: 250,
    },
    overflowMenuBackdrop: {
        backgroundColor: 'rgba(0, 0, 0, 0.2)',
    },
    moreIcon: {
        size: 24,
        color: "color-primary-500"
    },
});
