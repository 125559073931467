import React from 'react';
import { View } from 'react-native';
import { Divider, StyleService, Text, useStyleSheet } from '@ui-kitten/components';
import * as helper from 'utility/Helper';

export const AccessorialScheduleItem = (props) => {

    /*
        props:
        - terms
        - type
        - isFirstItem
        - isLastItem
    */

    const styles = useStyleSheet(themedStyles);

    return (
        <View style={helper.getContainerStyles(props, styles)}>
            <Text category="s2">{props.type}</Text>
            <Text category="p1" appearance="hint">{props.terms}</Text>
        </View>
    )
}

const themedStyles = StyleService.create({
    container: {
        paddingVertical: 10,
        borderBottomColor: "border-basic-color-3",
        borderBottomWidth: 1,
    },
    firstItem: {
        paddingTop: 2,
    },
    lastItem: {
        borderBottomWidth: 0,
        paddingBottom: 0,
    },
});
