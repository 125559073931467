import React, { useContext, useState, useEffect, useCallback, useRef } from 'react';
import { Dimensions, ScrollView, View } from 'react-native';
import { useFocusEffect, useNavigation } from '@react-navigation/native';
import { Button, Divider, Layout, List, ListItem, StyleService, Text, useStyleSheet } from '@ui-kitten/components';
import { AuthContext } from 'components/context';
import { EmptyDataSet, Loader } from 'components/utility';
import { CustomModal, Header, Section } from 'components/layout';
import { PaymentHistoryFilters } from 'screens/reports/modals';
import { PaymentSummary } from 'components/loads';
import * as helper from 'utility/Helper';
import * as api from 'api/SleekTechnologiesApi';
import * as Constants from 'config/Constants';
import moment from 'moment';

export default function PaymentHistoryReport(props) {

    const styles = useStyleSheet(themedStyles);
    const { signOut } = useContext(AuthContext);
    const [payments, setPayments] = useState([]);
    const [isLoading, setLoading] = useState(false);
    const [isRefreshing, setRefreshing] = useState(false);
    const [recordCount, setRecordCount] = useState(null);
    const loadMore = useRef(false);
    const navigation = useNavigation();

    // format from / to date
    const dateFrom = moment().format("MM/DD/YYYY");
    const dateTo = moment().format("MM/DD/YYYY");

    // payment history filters
    const defaultPaymentHistoryFilters = {
        loadNumber: "",
        transactionId: "",
        paymentStatus: "all",
        paymentDate: 30,
        paymentDateFrom: dateFrom,
        paymentDateTo: dateTo,
        paymentMax: "",
        paymentMin: "",
        sortBy: "payment date",
        offset: 0
    };
    const defaultPaymentHistoryFilterDisplayValues = {
        loadNumber: "",
        transactionId: "",
        paymentStatus: Constants.PAYMENT_STATUS_VALUES,
        paymentDate: 30,
        paymentDateFrom: dateFrom,
        paymentDateTo: dateTo,
        paymentMax: "",
        paymentMin: "",
        sortBy: "payment date",
        offset: 0
    };
    const [paymentHistoryFilters, setPaymentHistoryFilters] = useState(defaultPaymentHistoryFilters);
    const [paymentHistoryFilterDisplayValues, setPaymentHistoryFilterDisplayValues] = useState(defaultPaymentHistoryFilterDisplayValues);

    const windowWidth = Dimensions.get('window').width;
    const windowHeight = Dimensions.get('window').height;
    const bodyHeight = windowHeight - 170;

    const getPaymentHistoryData = () => {
        api.getPaymentHistoryData(paymentHistoryFilters)
            .then(function(response) {
                if(helper.hasValue(response.data.msg) && response.data.msg == "Token Not Found or Expired") {
                    signOut();
                }
                if(response.data.result == "success") {
                    if(loadMore.current) {
                        setPayments(payments => {
                            return [...payments, ...response.data.payments]
                        });
                    } else {
                        setPayments(response.data.payments);
                    }
                    setRecordCount(response.data.recordCount);
                    props.setLastLoadTime(Date.now()/1000);
                    setLoading(false);
                    setRefreshing(false);
                    loadMore.current = false;
                } else if(response.data.result == "error") {
                    throw {
                        type: "custom",
                        message: response.data.msg
                    }
                }
            })
            .catch(function(error){
                setLoading(false);
                setRefreshing(false);
                helper.handleCatchError(error);
            });
    }

    useFocusEffect(
        useCallback(() => {
            getPaymentHistoryData();
        }, [paymentHistoryFilters])
    );

    const renderPaymentSummary = ({ item, index }) => {
        return (
            <ListItem style={styles.listItem} children={<PaymentSummary {...item} />} />
        )
    }

    const renderListFooter = () => {
        if(isLoading && !isRefreshing) {
            return (
                <View style={styles.loader}>
                    <Loader loadingText="Getting Payment History"/>
                </View>
            )
        } else {
            return null;
        }
    }

    const renderListEmptyComponent = () => {
        if(recordCount == 0 && !isLoading) {
            return (
                <EmptyDataSet primaryMessage="No Payments Found" secondaryMessage="Use Filters to Expand your Search" />
            )
        } else {
            return null;
        }
    }

    const handleLoadMore = () => {
        if(payments.length < recordCount && !isLoading) {
            loadMore.current = true;
            setPaymentHistoryFilters({
                ...paymentHistoryFilters,
                offset: payments.length
            });
        }
    }

    const refreshData = () => {
        setRefreshing(true);
        setPayments([]);
        setRecordCount(null);
        setPaymentHistoryFilters({
            ...paymentHistoryFilters,
            offset: 0
        });
    }

    const renderPaymentHistoryList = () => {
        return (
            <List
                style={styles.listView}
                contentContainerStyle={[styles.listContainer, { justifyContent: payments.length > 0 ? "flex-start" : "center"}]}
                data={payments}
                renderItem={renderPaymentSummary}
                ItemSeparatorComponent={Divider}
                keyExtractor={(item, index) => index.toString()}
                ListFooterComponent={renderListFooter}
                ListEmptyComponent={renderListEmptyComponent}
                onEndReached={handleLoadMore}
                onEndReachedThreshold={Constants.END_OF_LIST_THRESHOLD}
                onRefresh={() => refreshData()}
                refreshing={isRefreshing}
            />
        )
    }

    const renderPaymentHistoryFilters = () => {
        return (
            <PaymentHistoryFilters
                setModalVisible={props.setModalVisible}
                setLoading={setLoading}
                setPayments={setPayments}
                defaultPaymentHistoryFilters={defaultPaymentHistoryFilters}
                defaultPaymentHistoryFilterDisplayValues={defaultPaymentHistoryFilterDisplayValues}
                paymentHistoryFilters={paymentHistoryFilters}
                setPaymentHistoryFilters={setPaymentHistoryFilters}
                paymentHistoryFilterDisplayValues={paymentHistoryFilterDisplayValues}
                setPaymentHistoryFilterDisplayValues={setPaymentHistoryFilterDisplayValues}
            />
        )
    }

    React.useLayoutEffect(() => {
        if(helper.isWeb()) {
            navigation.setOptions({
                title: 'Reports - Payment History'
            })
        }
    }, [navigation]);

    return (
        helper.isWeb() ? (
            <>
                <Layout style={styles.layout} level="3">
                    {helper.isDesktopWeb() &&
                        <ScrollView>
                            <View style={styles.main}>
                                <View style={styles.columnOne}>
                                    <Section title="Filters" noBodyPadding={false} renderBody={renderPaymentHistoryFilters()} />
                                </View>
                                <View style={styles.columnTwo}>
                                    <Section title="Payment History" subtitle={recordCount != null ? recordCount + ' Matching Payments' : ''} noBodyPadding={payments.length > 0 ? true : false} bodyHeight={bodyHeight} renderBody={renderPaymentHistoryList()} />
                                </View>
                            </View>
                        </ScrollView>
                    }
                    {helper.isMobileWeb() &&
                        <>
                            {renderPaymentHistoryList()}
                        </>
                    }
                </Layout>
                {helper.isMobileWeb() &&
                    <CustomModal title="Filter Payments" modalVisible={props.modalVisible} setModalVisible={props.setModalVisible}>
                        {renderPaymentHistoryFilters()}
                    </CustomModal>
                }
            </>
        ) : (
            <Layout style={styles.layout} level="3">
                {renderPaymentHistoryList()}
                <CustomModal title="Filter Payments" modalVisible={props.modalVisible} setModalVisible={props.setModalVisible}>
                    {renderPaymentHistoryFilters()}
                </CustomModal>
            </Layout>
        )
    )

}

const themedStyles = StyleService.create({
    layout: {
        flex: 1,
        marginTop: helper.isDesktopWeb() ? 5 : 0
    },
    loader: {
        marginTop: 10,
        paddingVertical: 20,
    },
    listContainer: {
        flexGrow: 1
    },
    listView: {
        borderBottomLeftRadius: helper.isDesktopWeb() ? 6 : 0,
        borderBottomRightRadius: helper.isDesktopWeb() ? 6 : 0
    },
    listItem: {
        paddingHorizontal: 0,
        paddingVertical: 0,
    },
    main: {
        flexDirection: "row",
    },
    columnOne: {
        flex: 3,
    },
    columnTwo: {
        flex: 6
    }
});
