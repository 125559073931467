import React, {useState} from 'react';
import { Dimensions, Image, Pressable, ScrollView, View } from 'react-native';
import { Layout, StyleService, Text, useStyleSheet } from '@ui-kitten/components';
import { useNavigation } from '@react-navigation/native';
import { CustomModal, Header, ItemAttribute, Section } from 'components/layout';
import * as Constants from 'config/Constants';
import * as Linking from 'expo-linking';
import * as helper from 'utility/Helper';
import getEnvVars from 'root/environment';

const IMAGE_WIDTH = Dimensions.get('window').width - 30;
const IMAGE_HEIGHT = IMAGE_WIDTH * .75;

export default function DriverProfile(props) {

    const styles = useStyleSheet(themedStyles);
    const [driverLicenseModalVisible, setDriverLicenseModalVisible] = useState(false);
    const [licensePlateModalVisible, setLicensePlateModalVisible] = useState(false);
    const { sleekApiUrl } = getEnvVars();
    const profileData = props.driverDetails;
    const licenseAttributes = [
        {
            name: "DL Number",
            value: profileData.driverLicenseNumber,
            display: true,
        },
        {
            name: "DL State / Province",
            value: profileData.driverLicenseState,
            display: true,
        },
    ];
    const equipmentAttributes = [
        {
            name: "Trailer Type",
            value: profileData.truckType,
            display: true,
        },
        {
            name: "Trailer Length",
            value: profileData.trailerLength,
            display: true,
        },
        {
            name: "Trailer Interior Width",
            value: profileData.trailerWidth,
            display: true,
        },
        {
            name: "Trailer Height",
            value: profileData.trailerHeight,
            display: true,
        },
        {
            name: "Trailer Door Type",
            value: profileData.doorType,
            display: true,
        },
        {
            name: "Max Weight",
            value: (profileData.maxWeightOfLoads != 0 ? profileData.maxWeightOfLoads+' lbs' : ''),
            display: true,
        },
    ];
    const navigation = useNavigation();

    const renderAttributes = (attributes) => {
        return (
            attributes.map((item, index) => (
                <ItemAttribute
                    {...item}
                    key={index}
                    isFirstItem={index == 0 ? true : false}
                    isLastItem={index + 1 == attributes.length ? true : false}
                />
            ))
        )
    }

    const renderOutlookHeaderRight = () => {
        return (
            <View style={styles.legendWrapper}>
                <View style={[styles.legendView, styles.legendViewAvailable]}>
                    <View style={[styles.legendVisual, styles.legendVisualAvailable]}></View>
                    <View style={styles.legendDescriptionView}>
                        <Text category="s2" appearance="hint" style={styles.legendDescription}>Available</Text>
                    </View>
                </View>
                <View style={[styles.legendView, styles.legendViewBooked]}>
                    <View style={[styles.legendVisual, styles.legendVisualBooked]}></View>
                    <View style={styles.legendDescriptionView}>
                        <Text category="s2" appearance="hint" style={styles.legendDescription}>Booked</Text>
                    </View>
                </View>
            </View>
        )
    }

    const Day = (day) => {

        let dayStyles = [styles.dayView];

        if(day.status == 'available') {
            dayStyles.push(styles.dayViewAvailable);
        } else {
            dayStyles.push(styles.dayViewBooked);
        }

        return (
            <View style={dayStyles}>
                <Text style={styles.dayName} appearance="hint">{day.name}</Text>
                <Text style={styles.dayDate} category="s2">{day.date}</Text>
            </View>
        )
    }

    const renderOutlook = () => {
        return (
            <View style={styles.outlookView}>
                <Day name={profileData.day1.dayOfWeek} date={profileData.day1.date} status={profileData.day1.status} />
                <Day name={profileData.day2.dayOfWeek} date={profileData.day2.date} status={profileData.day2.status} />
                <Day name={profileData.day3.dayOfWeek} date={profileData.day3.date} status={profileData.day3.status} />
                <Day name={profileData.day4.dayOfWeek} date={profileData.day4.date} status={profileData.day4.status} />
                <Day name={profileData.day5.dayOfWeek} date={profileData.day5.date} status={profileData.day5.status} />
            </View>
        )
    }

    const renderLicenseInformationHeaderRight = () => {
        if(profileData.driverLicensePhoto != '/images/app/logos/default-drivers-license.png') {
            return (
                <Pressable onPress={() => setDriverLicenseModalVisible(true)}>
                    <Text style={{textAlign: "right", fontSize: 14}} status="primary" category="s2">View Driver License</Text>
                </Pressable>
            )
        } else {
            return (
                <Text style={{textAlign: "right", fontSize: 14}} appearance="hint" category="s2">No License Photo</Text>
            )
        }
    }

    const renderEquipmentInformationHeaderRight = () => {
        if(profileData.driverLicensePlatePhoto != '/images/app/logos/default-license-plate.png') {
            return (
                <Pressable onPress={() => setLicensePlateModalVisible(true)}>
                    <Text style={{textAlign: "right", fontSize: 14}} status="primary" category="s2">View License Plate</Text>
                </Pressable>
            )
        } else {
            return (
                <Text style={{textAlign: "right", fontSize: 14}} appearance="hint" category="s2">No License Plate Photo</Text>
            )
        }
    }

    React.useLayoutEffect(() => {
        if(helper.isWeb()) {
            navigation.setOptions({
                title: 'Driver Details - Profile'
            })
        }
    }, [navigation]);

    return (
        <ScrollView contentContainerStyle={styles.scrollView}>
            <Layout style={styles.layout} level="3">
                <View style={styles.personalInformationView}>
                    <Image
                        source={{
                            uri: sleekApiUrl+profileData.userPhoto,
                            headers: Constants.RESOURCE_HEADERS
                        }}
                        style={{width: 100, height: 100, borderRadius: 50}}
                    />
                    <Text style={styles.name} category="h6">{profileData.name}</Text>
                    {helper.hasValue(profileData.phone) &&
                        <Pressable onPress={() => Linking.openURL(`tel:${profileData.phone}`)}>
                            <Text style={styles.phone} category="s1" status="primary">{profileData.phone}</Text>
                        </Pressable>
                    }
                    {helper.hasValue(profileData.email) &&
                        <Pressable onPress={() => Linking.openURL(`mailto:${profileData.email}`)}>
                            <Text style={styles.email} category="s1" status="primary">{profileData.email}</Text>
                        </Pressable>
                    }
                </View>
                <View style={helper.isDesktopWeb() ? styles.main : {}}>
                    <View style={helper.isDesktopWeb() ? styles.columnOne : {}}>
                        {!helper.isDesktopWeb() &&
                            <>
                                <Section title="5 Day Outlook" isFirstItem={true} headerLeftFlex={2} headerRightFlex={3} headerRight={renderOutlookHeaderRight()} renderBody={renderOutlook()} />
                                <Section title="License Info" headerLeftFlex={2} headerRightFlex={3} headerRight={renderLicenseInformationHeaderRight()} renderBody={renderAttributes(licenseAttributes)} />
                            </>
                        }
                        <Section title="Equipment Info" isLastItem={true} headerLeftFlex={2} headerRightFlex={3} headerRight={renderEquipmentInformationHeaderRight()} renderBody={renderAttributes(equipmentAttributes)} />
                    </View>
                    <View style={helper.isDesktopWeb() ? styles.columnTwo : {}}>
                        {helper.isDesktopWeb() &&
                            <>
                                <Section title="5 Day Outlook" isFirstItem={true} headerLeftFlex={2} headerRightFlex={3} headerRight={renderOutlookHeaderRight()} renderBody={renderOutlook()} />
                                <Section title="License Info" headerLeftFlex={2} headerRightFlex={3} headerRight={renderLicenseInformationHeaderRight()} renderBody={renderAttributes(licenseAttributes)} />
                            </>
                        }
                    </View>
                </View>
            </Layout>
            <CustomModal title="Driver License" modalVisible={driverLicenseModalVisible} setModalVisible={setDriverLicenseModalVisible}>
                <View style={styles.imageView}>
                    <Image
                        source={{
                            uri: sleekApiUrl+profileData.driverLicensePhoto,
                            headers: Constants.RESOURCE_HEADERS
                        }}
                        style={styles.image}
                        resizeMode="contain"
                    />
                </View>
            </CustomModal>
            <CustomModal title="License Plate" modalVisible={licensePlateModalVisible} setModalVisible={setLicensePlateModalVisible}>
                <View style={styles.imageView}>
                    <Image
                        source={{
                            uri: sleekApiUrl+profileData.driverLicensePlatePhoto,
                            headers: Constants.RESOURCE_HEADERS
                        }}
                        style={styles.image}
                        resizeMode="contain"
                    />
                </View>
            </CustomModal>
        </ScrollView>
    )
}

const themedStyles = StyleService.create({
    scrollView: {
        flexGrow: 1
    },
    layout: {
        flex: 1,
    },
    personalInformationView: {
        backgroundColor: "color-basic-100",
        alignItems: "center",
        paddingTop: 40,
        paddingBottom: 30,
        marginBottom: 8,
        borderBottomLeftRadius: 10,
        borderBottomRightRadius: 10,
        shadowColor: "shadow-color",
        shadowOffset: {
            width: 0,
            height: 2,
        },
        shadowOpacity: .2,
        shadowRadius: 2,
        marginHorizontal: helper.isWeb() ? 10 : 0,
        marginTop: helper.isWeb() ? 10 : 0,
        borderTopLeftRadius: helper.isWeb() ? 10 : 0,
        borderTopRightRadius: helper.isWeb() ? 10 : 0,
    },
    name: {
        marginTop: 15,
    },
    phone: {
        marginTop: 5,
    },
    email: {
        marginTop: 5
    },
    image: {
        width: IMAGE_WIDTH,
        height: IMAGE_HEIGHT
    },
    legendWrapper: {
        justifyContent: "flex-end",
        flexDirection: "row",
        flexWrap: "wrap",
    },
    legendView: {
        flexDirection: "row",
        flexWrap: "wrap",
        alignItems: "center",
    },
    legendViewBooked: {

    },
    legendViewAvailable: {
        marginRight: 15,
    },
    legendVisual: {
        width: 20,
        height: 20,
        borderWidth: 1,
        borderColor: "border-generic",
        borderRadius: 4,
        marginRight: 5
    },
    legendVisualAvailable: {
        backgroundColor: "color-basic-100"
    },
    legendVisualBooked: {
        backgroundColor: "color-primary-transparent-100",
        borderColor: "color-primary-transparent-500"
    },
    legendDescriptionView: {

    },
    legendDescription: {

    },
    outlookView: {
        flexDirection: "row",
        flexWrap: "wrap",
        paddingVertical: 10,
        justifyContent: "space-between"
    },
    dayView: {
        padding: 5,
        height: 80,
        width: 60,
        borderColor: "border-generic",
        borderWidth: 1,
        borderRadius: 4,
    },
    dayViewAvailable: {
        backgroundColor: "color-basic-100"
    },
    dayViewBooked: {
        backgroundColor: "color-primary-transparent-100",
        borderColor: "color-primary-transparent-500"
    },
    dayName: {
        textAlign: "center",
        marginBottom: 7,
        marginTop: 3,
    },
    dayDate: {
        textAlign: "center",
        fontSize: 20
    },
    main: {
        flexDirection: "row"
    },
    columnOne: {
        flex: 7
    },
    columnTwo: {
        flex: 5
    }
});
