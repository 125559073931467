import React from 'react';
import { View } from 'react-native';
import { SafeAreaView } from 'react-native-safe-area-context';
import { Layout, StyleService, useStyleSheet } from '@ui-kitten/components';
import { Header } from 'components/layout';
import { WebView } from 'react-native-webview';
import * as helper from 'utility/Helper';

export default function Privacy({ navigation }) {

    const styles = useStyleSheet(themedStyles);

    const breadcrumbs = [
        {
            label: 'Dashboard',
            path: '/dashboard'
        },
        {
            label: 'Privacy',
            path: ''
        }
    ]

    const PrivacyView = () => {
        return (
            <Layout style={styles.layout} level="3">
                <WebView source={{ uri: 'https://www.sleek-technologies.com/privacy-policy' }} />
            </Layout>
        )
    }

    return (
        helper.isWeb() ? (
            <View style={styles.webView}>
                <Header breadcrumbs={breadcrumbs} title="Privacy" />
                <PrivacyView />
            </View>
        ) : (
            <SafeAreaView style={styles.safeAreaView} edges={['top', 'left', 'right']}>
                <Header activeScreen="Privacy" />
                <PrivacyView />
            </SafeAreaView>
        )
    )

}

const themedStyles = StyleService.create({
    // global styles
    layout: {
        flex: 1,
    },
    // native specific styles
    safeAreaView: {
        backgroundColor: "safe-area-view-background",
        flex: 1,
    },
    // web specific styles
    webView: {
        flex: 1,
    },
});
