import React from 'react';
import { Linking, Platform, View } from 'react-native';
import { Button, Layout, StyleService, Text, useStyleSheet } from '@ui-kitten/components';
import Constants from 'expo-constants';
import * as Updates from 'expo-updates';
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome';
import * as IntentLauncher from 'expo-intent-launcher';

const pkg = Updates.channel ? Constants.expoConfig?.android?.package : 'host.exp.exponent';

export default function LocationServicesDisabled(props) {

    const styles = useStyleSheet(themedStyles);

    return (
        <View>
            <View style={styles.locationIconView}>
                <FontAwesomeIcon style={styles.locationIcon} icon={['fal', 'exclamation-triangle']} size={styles.locationIcon.size} color={styles.locationIcon.color} />
                <Text style={styles.title} category="s1">Location Tracking Disabled</Text>
            </View>
            <View style={styles.section}>
                <Text style={styles.sectionText} category="p1">This application requires location services permissions during the load execution process. A load may not be executed through this applicaiton without these permissions being granted.</Text>
            </View>
            <View style={styles.section}>
                <Text style={styles.sectionText} category="p1">Your location will only be tracked during the load execution process. Once this load is delivered, location tracking will be disabled until your next load execution.</Text>
            </View>
            {Platform.OS === 'ios' &&
                <View style={styles.section}>
                    <Text style={styles.sectionText} category="p1">To enable location permissions, tap the button below, open the "Location" permission, and select the "Always" option.</Text>
                    <Button
                        style={styles.cta}
                        onPress={() => {
                            Linking.openSettings();
                        }
                    }>Manage Location Settings</Button>
                </View>
            }
            {Platform.OS === 'android' &&
                <View style={styles.section}>
                    <Text style={styles.sectionText} category="p1">To enable location permissions, tap the button below, open the "Location" permission, and select the "Allow all the time" option.</Text>
                    <Button
                        style={styles.cta}
                        onPress={() => {
                            IntentLauncher.startActivityAsync(
                                IntentLauncher.ACTION_APPLICATION_DETAILS_SETTINGS,
                                { data: 'package:' + pkg },
                            )
                        }
                    }>Manage Location Settings</Button>
                </View>
            }
        </View>
    )
}

const themedStyles = StyleService.create({
    locationIconView: {
        alignItems: "center",
        justifyContent: "center",
        paddingTop: 20,
        paddingBottom: 20,
        borderBottomWidth: 1,
        borderBottomColor: "border-generic",
        marginBottom: 20
    },
    locationIcon: {
        color: "color-primary-500",
        size: 48,
    },
    title: {
        marginTop: 15,
    },
    section: {
        marginBottom: 15,
    },
    sectionTitle: {

    },
    sectionText: {

    },
    cta: {
        marginTop: 15,
    }
});
