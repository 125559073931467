import React, { useState, useEffect, useRef } from 'react';
import { useFocusEffect } from "@react-navigation/native";
import { createMaterialTopTabNavigator } from '@react-navigation/material-top-tabs';
import { View } from 'react-native';
import { SafeAreaView } from 'react-native-safe-area-context';
import { Button, Layout, StyleService, Tab, TabBar, Text, useStyleSheet } from '@ui-kitten/components';
import { Header } from 'components/layout';
import { BookNowHistoryReport, LoadHistoryReport, PaymentHistoryReport } from 'screens/reports/tabs';
import * as helper from 'utility/Helper';

export default function Reports({ route, navigation }) {

    const styles = useStyleSheet(themedStyles);
    const { Navigator, Screen } = createMaterialTopTabNavigator();
    const activeScreen = useRef(helper.hasValue(route.params?.tab) ? route.params.tab : 'load-history');
    const lastLoadTime = useRef(Date.now()/1000);
    const [loadHistoryModalVisible, setLoadHistoryModalVisible] = useState(false);
    const [bookNowHistoryModalVisible, setBookNowHistoryModalVisible] = useState(false);
    const [paymentHistoryModalVisible, setPaymentHistoryModalVisible] = useState(false);

    const breadcrumbs = [
        {
            label: 'Dashboard',
            path: '/dashboard'
        },
        {
            label: 'Reports',
            path: ''
        }
    ]

    const TopTabBar = ({ navigation, state }) => (
        <TabBar
            selectedIndex={state.index}
            onSelect={index => onTabSelect(state.routeNames[index])}>
            <Tab title="Load History"/>
            <Tab title="Book Now History"/>
            <Tab title="Payment History"/>
        </TabBar>
    );

    const onTabSelect = (routeName) => {
        activeScreen.current = routeName;
        navigation.navigate(routeName);
    }

    const showModal = () => {
        switch(activeScreen.current) {
            case 'load-history':
                setLoadHistoryModalVisible(true);
                break;
            case 'book-now-history':
                setBookNowHistoryModalVisible(true);
                break;
            case 'payment-history':
                setPaymentHistoryModalVisible(true);
                break;
        }
    }

    const setLastLoadTime = (updatedTime) => {
        lastLoadTime.current = updatedTime;
    }

    const getTitle = () => {
        let title;
        switch(activeScreen.current) {
            case 'load-history':
                title = "Load History";
                break;
            case 'book-now-history':
                title = "Book Now History";
                break;
            case 'payment-history':
                title = "Payment History";
                break;
        }
        return title;
    }

    const getPageActions = () => {

        if(helper.isMobileWeb()) {
            switch(activeScreen.current) {
                case 'load-history':
                    return (
                        <Button appearance="outline" size="small" onPress={() => setLoadHistoryModalVisible(true)}>Filter</Button>
                    )
                    break;
                case 'book-now-history':
                    return (
                        <Button appearance="outline" size="small" onPress={() => setBookNowHistoryModalVisible(true)}>Filter</Button>
                    )
                    break;
                case 'payment-history':
                    return (
                        <Button appearance="outline" size="small" onPress={() => setPaymentHistoryModalVisible(true)}>Filter</Button>
                    )
                    break;
            }
        } else {
            return null;
        }

    }

    useFocusEffect(
        React.useCallback(() => {
            if((Date.now()/1000) - 900 > lastLoadTime.current) {
                navigation.reset({index: 0, routes: [{ name: 'Reports' }]});
            }
        }, [lastLoadTime.current])
    );

    useEffect(() => {
        if(helper.hasValue(route.params?.tab)) {
            navigation.navigate('Reports', {
                screen: route.params.tab
            });
        }
    }, [route.params]);

    return (
        helper.isWeb() ? (
            <View style={styles.webView}>
                <Header breadcrumbs={breadcrumbs} title={getTitle()} pageActions={getPageActions()} />
                {helper.checkForRoles(['admin', 'manager']) &&
                    <Navigator style={styles.navigator} tabBar={props => <TopTabBar {...props} />} initialRouteName={activeScreen.current} backBehavior="history">
                        <Screen
                            name="load-history"
                            children={() => <LoadHistoryReport modalVisible={loadHistoryModalVisible} setModalVisible={setLoadHistoryModalVisible} setLastLoadTime={setLastLoadTime} />}
                        />
                        <Screen
                            name="book-now-history"
                            children={() => <BookNowHistoryReport modalVisible={bookNowHistoryModalVisible} setModalVisible={setBookNowHistoryModalVisible} setLastLoadTime={setLastLoadTime} />}
                        />
                        <Screen
                            name="payment-history"
                            children={() => <PaymentHistoryReport modalVisible={paymentHistoryModalVisible} setModalVisible={setPaymentHistoryModalVisible} setLastLoadTime={setLastLoadTime} />}
                        />
                    </Navigator>
                }
                {!helper.checkForRoles(['admin', 'manager']) &&
                    <LoadHistoryReport modalVisible={loadHistoryModalVisible} setModalVisible={setLoadHistoryModalVisible} setLastLoadTime={setLastLoadTime} />
                }
            </View>
        ) : (
            <SafeAreaView style={styles.safeAreaView} edges={['top', 'left', 'right']}>
                <Header
                    activeScreen={activeScreen.current}
                    showModal={showModal}
                />
                {helper.checkForRoles(['admin', 'manager']) &&
                    <Navigator style={styles.navigator} tabBar={props => <TopTabBar {...props} />} initialRouteName={activeScreen.current}>
                        <Screen
                            name="load-history"
                            children={() => <LoadHistoryReport modalVisible={loadHistoryModalVisible} setModalVisible={setLoadHistoryModalVisible} setLastLoadTime={setLastLoadTime} />}
                        />
                        <Screen
                            name="book-now-history"
                            children={() => <BookNowHistoryReport modalVisible={bookNowHistoryModalVisible} setModalVisible={setBookNowHistoryModalVisible} setLastLoadTime={setLastLoadTime} />}
                        />
                        <Screen
                            name="payment-history"
                            children={() => <PaymentHistoryReport modalVisible={paymentHistoryModalVisible} setModalVisible={setPaymentHistoryModalVisible} setLastLoadTime={setLastLoadTime} />}
                        />
                    </Navigator>
                }
                {!helper.checkForRoles(['admin', 'manager']) &&
                    <LoadHistoryReport modalVisible={loadHistoryModalVisible} setModalVisible={setLoadHistoryModalVisible} setLastLoadTime={setLastLoadTime} />
                }
            </SafeAreaView>
        )
    )

}

const themedStyles = StyleService.create({
    // global styles
    navigator: {
        backgroundColor: "color-basic-400"
    },
    // native specific styles
    safeAreaView: {
        backgroundColor: "safe-area-view-background",
        flex: 1,
    },
    // web specific styles
    webView: {
        flex: 1,
    },
});
