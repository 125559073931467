import React, { useState, useContext, useEffect } from 'react';
import { Animated, ScrollView, TouchableOpacity, TouchableHighlight, View } from 'react-native';
import { SafeAreaView } from 'react-native-safe-area-context';
import { useFocusEffect } from "@react-navigation/native";
import { SwipeListView } from 'react-native-swipe-list-view';
import { Button, Layout, StyleService, Text, useStyleSheet } from '@ui-kitten/components';
import { EmptyDataSet } from 'components/utility';
import { AppDataContext, AuthContext } from 'components/context';
import { CustomModal, Header, Section } from 'components/layout';
import { AddPreferredLocation } from 'screens/preferred-locations/modals';
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome';
import * as helper from 'utility/Helper';
import * as api from 'api/SleekTechnologiesApi';

//For more usage examples of swipe actions, visit the following:
//https://github.com/jemise111/react-native-swipe-list-view/blob/master/SwipeListExample/examples/actions.js

export default function PreferredLocations({ navigation }) {

    const styles = useStyleSheet(themedStyles);
    const { appData, setAppData } = useContext(AppDataContext);
    const { signOut } = useContext(AuthContext);
    const preferredLocations = appData.preferredLocations;
    const preferredLocationsArray = [];
    for (var i = 0; i < preferredLocations.length; i++) {
        var index = i + 1;
        var preferredLocationItemArray = { key: String(index), text: preferredLocations[i].value, initialLeftActionState: index % 2 !== 0,};
        preferredLocationsArray.push(preferredLocationItemArray);
    }
    const [listData, setListData] = useState(preferredLocationsArray);
    const [modalVisible, setModalVisible] = useState(false);
    const [modalError, setModalError] = useState(null);

    const breadcrumbs = [
        {
            label: 'Dashboard',
            path: '/dashboard'
        },
        {
            label: 'Preferred Locations',
            path: ''
        }
    ]

    const deletePreferredLocation = (deletedLocation) => {

        //Delete Preferred Location
        api.deletePreferredLocation(deletedLocation)
            .then(function(response){
                if(helper.hasValue(response.data.msg) && response.data.msg == "Token Not Found or Expired") {
                    signOut();
                }
                if(response.data.result == 'success') {
                    let updatedPreferredLocations = appData.preferredLocations;
                    for (let i = 0; i < updatedPreferredLocations.length; i++) {
                        if(updatedPreferredLocations[i]['label'] == deletedLocation) {
                            updatedPreferredLocations.splice(i, 1);
                            break;
                        }
                    }
                    setAppData({
                        ...appData,
                        preferredLocations: updatedPreferredLocations
                    });
                    refreshPreferredLocations(updatedPreferredLocations);
                } else if(response.data.result == 'error') {
                    throw {
                        type: "custom",
                        message: response.data.msg
                    }
                }
            })
            .catch(function(error){
                helper.handleCatchError(error);
            });
    }

    const refreshPreferredLocations = (updatedPreferredLocations) => {
        const updatedPreferredLocationsArray = [];
        for (var i = 0; i < updatedPreferredLocations.length; i++) {
            var index = i + 1;
            var preferredLocationItemArray = { key: String(index), text: updatedPreferredLocations[i].value, initialLeftActionState: index % 2 !== 0,};
            updatedPreferredLocationsArray.push(preferredLocationItemArray);
        }
        setListData(updatedPreferredLocationsArray);
    }

    useFocusEffect(
        React.useCallback(() => {
            let refreshedPreferredLocations = appData.preferredLocations;
            let refreshedPreferredLocationsArray = [];
            for (var i = 0; i < refreshedPreferredLocations.length; i++) {
                var index = i + 1;
                var refreshedPreferredLocationItemArray = { key: String(index), text: refreshedPreferredLocations[i].value, initialLeftActionState: index % 2 !== 0,};
                refreshedPreferredLocationsArray.push(refreshedPreferredLocationItemArray);
            }
            setListData(refreshedPreferredLocationsArray);
        }, [appData])
    );

    const closeRow = (rowMap, rowKey) => {
        if (rowMap[rowKey]) {
            rowMap[rowKey].closeRow();
        }
    };

    const deleteRow = (rowMap, rowKey) => {
        const deletedIndex = listData.findIndex(item => item.key === rowKey);
        closeRow(rowMap, rowKey);
        const newData = [...listData];
        const prevIndex = listData.findIndex(item => item.key === rowKey);
        newData.splice(prevIndex, 1);
        setListData(newData);
        deletePreferredLocation(listData[deletedIndex].text);
    };

    const VisibleItem = props => {
        const {
            rowHeightAnimatedValue,
            rightActionState,
            data,
            removeRow,
        } = props;

        if (rightActionState) {
            Animated.timing(rowHeightAnimatedValue, {
                toValue: 0,
                duration: 200,
                useNativeDriver: false
            }).start(() => {
                removeRow();
            });
        }

        return (
            <Animated.View
                style={[
                    styles.rowFront,
                    { height: rowHeightAnimatedValue },
                ]}
            >
                <TouchableHighlight
                    //onPress={() => helper.log('a custom action could go here if needed')}
                    style={[
                        styles.rowFront,
                    ]}
                    underlayColor={'#ffffff'}
                >
                    <View>
                        <Text category="s2" status="primary">{data.item.text}</Text>
                    </View>
                </TouchableHighlight>
            </Animated.View>
        );
    };

    const renderItem = (data, rowMap) => {
        const rowHeightAnimatedValue = new Animated.Value(60);
        return (
            <VisibleItem
                rowHeightAnimatedValue={rowHeightAnimatedValue}
                data={data}
                removeRow={() => deleteRow(rowMap, data.item.key)}
            />
        );
    };

    const HiddenItemWithActions = props => {
        const {
            rightActionActivated,
            swipeAnimatedValue,
            rowActionAnimatedValue,
            rowHeightAnimatedValue,
            onClose,
            onDelete,
        } = props;

        if (rightActionActivated) {
            Animated.spring(rowActionAnimatedValue, {
                toValue: 500,
                useNativeDriver: false
            }).start();
        } else {
            Animated.spring(rowActionAnimatedValue, {
                toValue: 75,
                useNativeDriver: false
            }).start();
        }

        return (
            <Animated.View
                style={[
                    styles.rowBack,
                    { height: rowHeightAnimatedValue },
                ]}
            >
                <Animated.View
                    style={[
                        styles.backRightBtn,
                        styles.backRightBtnRight,
                        { flex: 1, width: rowActionAnimatedValue },
                    ]}
                >
                    <TouchableOpacity
                        style={[
                            styles.backRightBtn,
                            styles.backRightBtnRight,
                        ]}
                        onPress={onDelete}
                    >
                        <Animated.View
                            style={[
                                styles.trashIcon,
                                {
                                    transform: [
                                        {
                                            scale: swipeAnimatedValue.interpolate(
                                                {
                                                    inputRange: [-90, -45],
                                                    outputRange: [1, 0],
                                                    extrapolate: 'clamp',
                                                }
                                            ),
                                        },
                                    ],
                                },
                            ]}
                        >
                            <FontAwesomeIcon style={styles.trashIcon} icon={['fal', 'trash-alt']} size={styles.trashIcon.size} color={styles.trashIcon.color} />
                        </Animated.View>
                    </TouchableOpacity>
                </Animated.View>
            </Animated.View>
        );
    };

    const renderHiddenItem = (data, rowMap) => {
        const rowActionAnimatedValue = new Animated.Value(75);
        const rowHeightAnimatedValue = new Animated.Value(60);
        return (
            <HiddenItemWithActions
                data={data}
                rowMap={rowMap}
                rowActionAnimatedValue={rowActionAnimatedValue}
                rowHeightAnimatedValue={rowHeightAnimatedValue}
                onClose={() => closeRow(rowMap, data.item.key)}
                onDelete={() => deleteRow(rowMap, data.item.key)}
            />
        );
    };

    const getPageActions = () => {
        return (
            <Button appearance="outline" size="small" onPress={() => setModalVisible(true)}>Add</Button>
        )
    }

    const renderPreferredLocationsList = () => {

        if(listData.length > 0) {
            return (
                <>
                    <SwipeListView
                        style={styles.listView}
                        data={listData}
                        renderItem={renderItem}
                        renderHiddenItem={renderHiddenItem}
                        rightOpenValue={-75}
                        rightActivationValue={-200}
                    />
                </>
            )
        } else {
            return (
                <EmptyDataSet primaryMessage="No Preferred Locations" secondaryMessage="Add a Location to Get Started"/>
            )
        }

    }

    const renderAddLocation = () => {
        return (
            <AddPreferredLocation setModalVisible={setModalVisible} setModalError={setModalError} refreshAction={refreshPreferredLocations}/>
        )
    }

    return (
        helper.isWeb() ? (
            <View style={styles.webView}>
                <Header breadcrumbs={breadcrumbs} title="Preferred Locations" subtitle="Tip: Swipe Left to Delete" pageActions={getPageActions()} />
                <Layout style={styles.layout} level="3">
                    {helper.isDesktopWeb() &&
                        <ScrollView>
                            <Section title="Preferred Locations" subtitle={listData.length == 1 ? listData.length + ' Location' : listData.length + ' Locations'} noBodyPadding={listData.length > 0 ? true : false} renderBody={renderPreferredLocationsList()} />
                        </ScrollView>
                    }
                    {helper.isMobileWeb() &&
                        renderPreferredLocationsList()
                    }
                </Layout>
                <CustomModal title="Add Preferred Location" modalVisible={modalVisible} setModalVisible={setModalVisible} modalError={modalError} setModalError={setModalError}>
                    {renderAddLocation()}
                </CustomModal>
            </View>
        ) : (
            <SafeAreaView style={styles.safeAreaView} edges={['top', 'left', 'right']}>
                <Header activeScreen="PreferredLocations" setModalVisible={setModalVisible}/>
                <Layout style={styles.layout} level="3">
                    {renderPreferredLocationsList()}
                </Layout>
                <CustomModal title="Add Preferred Location" modalVisible={modalVisible} setModalVisible={setModalVisible} modalError={modalError} setModalError={setModalError}>
                    {renderAddLocation()}
                </CustomModal>
            </SafeAreaView>
        )
    )

}

const themedStyles = StyleService.create({
    // global styles
    layout: {
        flex: 1,
        marginTop: helper.isDesktopWeb() ? 5 : 0
    },
    listView: {
        borderBottomLeftRadius: helper.isDesktopWeb() ? 6 : 0,
        borderBottomRightRadius: helper.isDesktopWeb() ? 6 : 0
    },
    rowFront: {
        paddingLeft: 10,
        backgroundColor: "color-basic-100",
        borderBottomColor: "border-generic",
        borderBottomWidth: 1,
        justifyContent: "center",
        height: 60,
    },
    rowBack: {
        backgroundColor: "color-danger-transparent-200",
        borderBottomColor: "border-generic",
        borderBottomWidth: 1,
        flex: 1,
        flexDirection: "row",
        justifyContent: "space-between",
        height: 60
    },
    backRightBtn: {
        alignItems: "flex-end",
        bottom: 0,
        justifyContent: "center",
        position: "absolute",
        top: 0,
        width: 75,
        paddingRight: 17,
    },
    backRightBtnLeft: {
        backgroundColor: 'blue',
        right: 75,
    },
    backRightBtnRight: {
        backgroundColor: "color-danger-transparent-600",
        right: 0,
    },
    trashIcon: {
        size: 32,
        color: "color-basic-100",
        marginRight: 5
    },
    // native specific styles
    safeAreaView: {
        backgroundColor: "safe-area-view-background",
        flex: 1,
    },
    // web specific styles
    webView: {
        flex: 1,
    },
});
