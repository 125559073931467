import React, { useContext, useState, useEffect } from 'react';
import { SafeAreaView } from 'react-native-safe-area-context';
import { useFocusEffect } from "@react-navigation/native";
import { Dimensions, ScrollView, View } from 'react-native';
import { Divider, Layout, List, ListItem, StyleService, Text, useStyleSheet } from '@ui-kitten/components';
import { AuthContext } from 'components/context';
import { EmptyDataSet, Loader } from 'components/utility';
import { Header, Section } from 'components/layout';
import { NotificationItem } from 'components/notifications';
import * as helper from 'utility/Helper';
import * as api from 'api/SleekTechnologiesApi';
import * as Constants from 'config/Constants';

export default function Notifications({ route, navigation }) {

    const styles = useStyleSheet(themedStyles);
    const { signOut } = useContext(AuthContext);
    const [notifications, setNotifications] = useState([]);
    const [isLoading, setLoading] = useState(false);
    const [isRefreshing, setRefreshing] = useState(false);
    const [offset, setOffset] = useState({count: 0});
    const [recordCount, setRecordCount] = useState(null);
    const [lastLoadTime, setLastLoadTime] = useState(Date.now()/1000);

    const breadcrumbs = [
        {
            label: 'Dashboard',
            path: '/dashboard'
        },
        {
            label: 'Notifications',
            path: ''
        }
    ]
    const windowWidth = Dimensions.get('window').width;
    const windowHeight = Dimensions.get('window').height;
    const bodyHeight = windowHeight - 140;

    useEffect(() => {
        setLoading(true);
        api.getNotifications('all', offset.count)
            .then(function(response){
                if(helper.hasValue(response.data.msg) && response.data.msg == "Token Not Found or Expired") {
                    signOut();
                }
                if(response.status == "200") {
                    if(helper.isWeb()) {
                        helper.setAsyncStoreItem('@storedNotificationCount', response.data.recordCount);
                    } else {
                        helper.setSecureStoreItem('storedNotificationCount', response.data.recordCount);
                    }
                    setNotifications(notifications => {
                        return [...notifications, ...response.data.notifications]
                    });
                    setRecordCount(response.data.recordCount);
                    setLastLoadTime(Date.now()/1000);
                    setLoading(false);
                    setRefreshing(false);
                } else {
                    throw {
                        type: "custom",
                        message: "There was an error loading your notifications. Please try again. If this issue persists, please contact support."
                    }
                }
            })
            .catch(function(error){
                setLoading(false);
                helper.handleCatchError(error);
            });
    }, [offset]);

    useFocusEffect(
        React.useCallback(() => {
            if((Date.now()/1000) - 300 > lastLoadTime) {
                navigation.reset({index: 0, routes: [{ name: 'Notifications' }]});
            }
        }, [lastLoadTime])
    );

    const renderNotificationItem = ({ item, index }) => {
        return (
            <ListItem
                style={styles.listItem}
                children={
                    <NotificationItem
                        {...item}
                        showDivider={false}
                    />
                }
            />
        )
    }

    const renderListFooter = () => {
        if(isLoading && !isRefreshing) {
            return (
                <View style={styles.loader}>
                    <Loader loadingText="Getting Notifications"/>
                </View>
            )
        } else {
            return null;
        }
    }

    const renderListEmptyComponent = () => {
        if(recordCount == 0 && !isLoading) {
            return (
                <EmptyDataSet primaryMessage="No Notifications" />
            )
        } else {
            return null;
        }
    }

    const handleLoadMore = () => {
        if(notifications.length < recordCount && !isLoading) {
            setOffset({count: notifications.length});
        }
    }

    const refreshData = () => {
        setRefreshing(true);
        setNotifications([]);
        setRecordCount(null);
        setOffset({count: 0});
    }

    const renderNotificationsList = () => {
        return (
            <List
                style={styles.listView}
                contentContainerStyle={[styles.listContainer, { justifyContent: notifications.length > 0 ? "flex-start" : "center"}]}
                data={notifications}
                renderItem={renderNotificationItem}
                ItemSeparatorComponent={Divider}
                keyExtractor={(item, index) => index.toString()}
                ListFooterComponent={renderListFooter}
                ListEmptyComponent={renderListEmptyComponent}
                onEndReached={handleLoadMore}
                onEndReachedThreshold={Constants.END_OF_LIST_THRESHOLD}
                onRefresh={() => refreshData()}
                refreshing={isRefreshing}
            />
        )
    }

    return (
        helper.isWeb() ? (
            <View style={styles.webView}>
                <Header breadcrumbs={breadcrumbs} title="Notifications" />
                <Layout style={styles.layout} level="3">
                    {helper.isDesktopWeb() &&
                        <ScrollView>
                            <Section title="Notifications" subtitle={recordCount != null ? recordCount + ' Notifications' : ''} noBodyPadding={notifications.length > 0 ? true : false} bodyHeight={bodyHeight} renderBody={renderNotificationsList()}/>
                        </ScrollView>
                    }
                    {helper.isMobileWeb() &&
                        renderNotificationsList()
                    }
                </Layout>
            </View>
        ) : (
            <SafeAreaView style={styles.safeAreaView} edges={['top', 'left', 'right']}>
                <Header activeScreen="Notifications" />
                <Layout style={styles.layout} level="3">
                    {renderNotificationsList()}
                </Layout>
            </SafeAreaView>
        )
    )

}

const themedStyles = StyleService.create({
    // global styles
    layout: {
        flex: 1,
        marginTop: helper.isDesktopWeb() ? 5 : 0
    },
    loader: {
        marginTop: 10,
        paddingVertical: 20,
    },
    listContainer: {
        flexGrow: 1
    },
    listView: {
        borderBottomLeftRadius: helper.isDesktopWeb() ? 6 : 0,
        borderBottomRightRadius: helper.isDesktopWeb() ? 6 : 0
    },
    listItem: {
        paddingVertical: 0,
        paddingHorizontal: 0
    },
    // native specific styles
    safeAreaView: {
        backgroundColor: "safe-area-view-background",
        flex: 1,
    },
    // web specific styles
    webView: {
        flex: 1,
    },
});
