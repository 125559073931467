import React from 'react';
import { Image, View } from 'react-native';
import { Button, StyleService, Text, useStyleSheet } from '@ui-kitten/components';
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome';
import * as Constants from 'config/Constants';

export const UploadPreview = (props) => {

    /*
        props (for image upload - uploadMethod = imagePicker):
        - base64
        - cancelled
        - height
        - index
        - removeUpload
        - type
        - uploadMethod
        - uploadTitle
        - uri
        - width

        props (for document upload - uploadMethod = documentPicker):
        - index
        - name
        - removeUpload
        - size
        - type
        - uploadMethod
        - uploadTitle
        - uri
    */

    const styles = useStyleSheet(themedStyles);

    if(props.uploadMethod == 'documentPicker') {
        return (
            <View style={styles.container}>
                <View style={styles.columnOne}>
                    <FontAwesomeIcon style={styles.fileIcon} icon={['fal', 'file-alt']} size={styles.fileIcon.size} color={styles.fileIcon.color} />
                </View>
                <View style={styles.columnTwo}>
                    <Text category="s2" appearance="hint">{props.uploadTitle}</Text>
                </View>
                <View style={styles.columnThree}>
                    <Button appearance="ghost" size="small" onPress={() => props.removeUpload(props.index)}>Remove</Button>
                </View>
            </View>
        );
    } else {
        return (
            <View style={styles.container}>
                <View style={styles.columnOne}>
                    <Image
                        source={{
                            uri: props.uri,
                            headers: Constants.RESOURCE_HEADERS
                        }}
                        style={{width: 50, height: 50}}
                    />
                </View>
                <View style={styles.columnTwo}>
                    <Text category="s2" appearance="hint">{props.uploadTitle}</Text>
                </View>
                <View style={styles.columnThree}>
                    <Button appearance="ghost" size="small" onPress={() => props.removeUpload(props.index)}>Remove</Button>
                </View>
            </View>
        );
    }

};

const themedStyles = StyleService.create({
    container: {
        flexDirection: "row",
        padding: 5,
        backgroundColor: "color-basic-300",
        borderWidth: 1,
        borderColor: "border-generic",
        borderRadius: 4,
        marginBottom: 10,
    },
    columnOne: {
        flex: 2,
        justifyContent: "center",
        alignItems: "flex-start",
        paddingRight: 5,
    },
    columnTwo: {
        flex: 7,
        alignItems: "flex-start",
        justifyContent: "center"
    },
    columnThree: {
        flex: 5,
        justifyContent: "center",
        alignItems: "flex-end",
    },
    fileIcon: {
        size: 40,
        color: "color-basic-600",
    }
});
