import { AppState } from 'react-native';
import { LocationGeofencingEventType } from 'expo-location';
import * as TaskManager from 'expo-task-manager';
import * as Notifications from 'expo-notifications';
import * as helper from 'utility/Helper';
import * as api from 'api/SleekTechnologiesApi';
import * as Constants from 'config/Constants';

export const BACKGROUND_LOCATION_UPDATES_TASK = 'background-location-updates'
TaskManager.defineTask(BACKGROUND_LOCATION_UPDATES_TASK, async ({ data, error }) => {
    helper.logEvent({ category: 'Location Tracking', message: 'TaskManager: BACKGROUND_LOCATION_UPDATES_TASK: Background Service Event Initiated', data: { data: data, error: error } });
    if (error) {
        helper.logEvent({ sendToRollbar: true, level: 'error', category: 'Location Tracking', message: 'TaskManager: BACKGROUND_LOCATION_UPDATES_TASK: Error Occurred in Background Service', data: { error: error } });
        return
    }

    let authToken = await helper.getSecureStoreItem('authToken');
    helper.logEvent({ category: 'Location Tracking', message: 'TaskManager: BACKGROUND_LOCATION_UPDATES_TASK: authToken', data: { authToken: authToken } });

    let appStateValue = (helper.hasValue(AppState) && helper.hasValue(AppState.currentState) ? AppState.currentState : 'None');
    helper.logEvent({ category: 'Location Tracking', message: 'TaskManager: BACKGROUND_LOCATION_UPDATES_TASK: appStateValue', data: { appStateValue: appStateValue } });

    if(helper.hasValue(authToken)) {
        if(helper.hasValue(AppState) && helper.hasValue(AppState.currentState) && AppState.currentState == 'active') {
            helper.logEvent({ category: 'Location Tracking', message: 'TaskManager: BACKGROUND_LOCATION_UPDATES_TASK: Deferring to Foreground Service' });
            return;
        }
        if(data) {
            helper.logEvent({ category: 'Location Tracking', message: 'TaskManager: BACKGROUND_LOCATION_UPDATES_TASK: Updating Location from Background Service', data: { data: data } });
            try {
                //Send Update
                api.driverLocationUpdateNative(data.locations[0].coords.latitude, data.locations[0].coords.longitude)
                    .then(function(response){
                        helper.logEvent({ category: 'API Response', message: 'TaskManager: BACKGROUND_LOCATION_UPDATES_TASK: driverLocationUpdateNative()', data: { response: response.data } });
                    });
            } catch (error) {
                helper.logEvent({ sendToRollbar: true, level: 'error', category: 'Location Tracking', message: 'TaskManager: BACKGROUND_LOCATION_UPDATES_TASK: API Error while Updating Location from Background Service', data: { error: error } });
            }
        } else {
            helper.logEvent({ sendToRollbar: true, level: 'error', category: 'Location Tracking', message: 'TaskManager: BACKGROUND_LOCATION_UPDATES_TASK: Background Service Failed - No Data Available' });
        }
    } else {
        let expoToken = (await Notifications.getExpoPushTokenAsync({'projectId': Constants.expoConfig.extra.eas.projectId,})).data;
        helper.logEvent({ category: 'Location Tracking', message: 'TaskManager: BACKGROUND_LOCATION_UPDATES_TASK: expoToken', data: { expoToken: expoToken } });
        helper.logEvent({ category: 'Location Tracking', message: 'TaskManager: BACKGROUND_LOCATION_UPDATES_TASK: Updating Location while App is Closed' });
        try {
            //Send Update
            api.driverLocationUpdateNativeAnon(expoToken, data.locations[0].coords.latitude, data.locations[0].coords.longitude)
                .then(function(response){
                    helper.logEvent({ category: 'API Response', message: 'TaskManager: BACKGROUND_LOCATION_UPDATES_TASK: driverLocationUpdateNativeAnon()', data: { response: response.data } });
                });
        } catch (error) {
            helper.logEvent({ sendToRollbar: true, level: 'error', category: 'Location Tracking', message: 'TaskManager: BACKGROUND_LOCATION_UPDATES_TASK: App Closed API Error While Updating Location from Background Service', data: { error: error } });
        }
    }
});

export const GEO_FENCING_TASK = 'geofence'
TaskManager.defineTask(GEO_FENCING_TASK, async ({ data: { eventType, region }, error }) => {
    helper.logEvent({ category: 'Location Tracking', message: 'TaskManager: GEO_FENCING_TASK: Geofence Task Initiated', data: { eventType: eventType, region: region, error: error } });
    if (error) {
        // check `error.message` for more details.
        helper.logEvent({ sendToRollbar: true, level: 'error', category: 'Location Tracking', message: 'TaskManager: GEO_FENCING_TASK: Error Occurred in Geofence Service', data: { error: error } });
        return;
    }

    let authToken = await helper.getSecureStoreItem('authToken');
    helper.logEvent({ category: 'Location Tracking', message: 'TaskManager: GEO_FENCING_TASK: authToken', data: { authToken: authToken } });

    if(helper.hasValue(authToken)) {
        //Get Active Load Status
        api.getActiveLoadStatus()
            .then(function(response){
                helper.logEvent({ category: 'API Response', message: 'TaskManager: GEO_FENCING_TASK: getActiveLoadStatus()', data: { response: response.data } });

                if (eventType === LocationGeofencingEventType.Enter) {
                    helper.logEvent({ sendToRollbar: true, category: 'Location Tracking', message: 'TaskManager: GEO_FENCING_TASK: Entered Region', data: { region: region } });
                    if(region.identifier == 'origin' && response.data.activeLoadStatus == 'Dispatched') {
                        sendNotification('Origin Arrival', 'It appears you are arriving at your origin, please click below to confirm your arrival.', 'loadUpdateActionNotificationCategory', '{"status":"loading", "loadId":"'+response.data.activeLoadSleekFleetId+'", "carrierUserId":"'+response.data.activeLoadCarrierUserId+'"}');
                    } else if(region.identifier == 'destination' && response.data.activeLoadStatus == 'In Transit') {
                        sendNotification('Destination Arrival', 'It appears you are arriving at your destination, please click below to confirm your arrival.', 'loadUpdateActionNotificationCategory', '{"status":"unloading", "loadId":"'+response.data.activeLoadSleekFleetId+'", "carrierUserId":"'+response.data.activeLoadCarrierUserId+'"}');
                    }
                } else if (eventType === LocationGeofencingEventType.Exit) {
                    helper.logEvent({ sendToRollbar: true, category: 'Location Tracking', message: 'TaskManager: GEO_FENCING_TASK: Exited Region', data: { region: region } });
                    if(region.identifier == 'origin' && response.data.activeLoadStatus == 'Loading') {
                        sendNotification('Origin Departure', 'It appears you are leaving the origin, please click below to confirm you are in transit to your destination.', 'loadUpdateActionNotificationCategory', '{"status":"in transit", "loadId":"'+response.data.activeLoadSleekFleetId+'", "carrierUserId":"'+response.data.activeLoadCarrierUserId+'"}');
                    } else if(region.identifier == 'destination' && response.data.activeLoadStatus == 'Unloading') {
                        sendNotification('Destination Departure', 'It appears you are leaving the destination, please click below to confirm delivery completion.', 'loadUpdateActionNotificationCategory', '{"status":"delivered", "loadId":"'+response.data.activeLoadSleekFleetId+'", "carrierUserId":"'+response.data.activeLoadCarrierUserId+'"}');
                    }
                }
            });
    } else {
        let expoToken = (await Notifications.getExpoPushTokenAsync({'projectId': Constants.expoConfig.extra.eas.projectId,})).data;
        helper.logEvent({ category: 'Location Tracking', message: 'TaskManager: GEO_FENCING_TASK: expoToken', data: { expoToken: expoToken } });

        //Get Active Load Status
        api.getActiveLoadStatusAnon(expoToken)
            .then(function(response){
                helper.logEvent({ category: 'API Response', message: 'TaskManager: GEO_FENCING_TASK: getActiveLoadStatusAnon', data: { response: response.data } });

                if (eventType === LocationGeofencingEventType.Enter) {
                    helper.logEvent({ sendToRollbar: true, category: 'Location Tracking', message: 'TaskManager: GEO_FENCING_TASK: Entered Region with App Closed', data: { region: region } });
                    if(region.identifier == 'origin' && response.data.activeLoadStatus == 'Dispatched') {
                        sendNotificationAnon(response.data.activeLoadCarrierUserId, expoToken, 'Origin Arrival', 'It appears you are arriving at your origin, please click below to confirm your arrival.', 'loadUpdateActionNotificationCategory', '{"status":"loading", "loadId":"'+response.data.activeLoadSleekFleetId+'", "carrierUserId":"'+response.data.activeLoadCarrierUserId+'"}');
                    } else if(region.identifier == 'destination' && response.data.activeLoadStatus == 'In Transit') {
                        sendNotificationAnon(response.data.activeLoadCarrierUserId, expoToken, 'Destination Arrival', 'It appears you are arriving at your destination, please click below to confirm your arrival.', 'loadUpdateActionNotificationCategory', '{"status":"unloading", "loadId":"'+response.data.activeLoadSleekFleetId+'", "carrierUserId":"'+response.data.activeLoadCarrierUserId+'"}');
                    }
                } else if (eventType === LocationGeofencingEventType.Exit) {
                    helper.logEvent({ sendToRollbar: true, category: 'Location Tracking', message: 'TaskManager: GEO_FENCING_TASK: Exited Region with App Closed', data: { region: region } });
                    if(region.identifier == 'origin' && response.data.activeLoadStatus == 'Loading') {
                        sendNotificationAnon(response.data.activeLoadCarrierUserId, expoToken, 'Origin Departure', 'It appears you are leaving the origin, please click below to confirm you are in transit to your destination.', 'loadUpdateActionNotificationCategory', '{"status":"in transit", "loadId":"'+response.data.activeLoadSleekFleetId+'", "carrierUserId":"'+response.data.activeLoadCarrierUserId+'"}');
                    } else if(region.identifier == 'destination' && response.data.activeLoadStatus == 'Unloading') {
                        sendNotificationAnon(response.data.activeLoadCarrierUserId, expoToken, 'Destination Departure', 'It appears you are leaving the destination, please click below to confirm delivery completion.', 'loadUpdateActionNotificationCategory', '{"status":"delivered", "loadId":"'+response.data.activeLoadSleekFleetId+'", "carrierUserId":"'+response.data.activeLoadCarrierUserId+'"}');
                    }
                }
            });
    }
});

const sendNotification = async (title, message, category, data) => {
    let token = (await Notifications.getExpoPushTokenAsync({'projectId': Constants.expoConfig.extra.eas.projectId,})).data;
    api.sendNotification(token, title, message, category, data)
        .then(function(response){
            helper.logEvent({ sendToRollbar: true, category: 'API Response', message: 'TaskManager: sendNotification(): Geo Fence Notification Sent', data: { response: response.data } });
        });
}

const sendNotificationAnon = async (carrierUserId, expoToken, title, message, category, data) => {
    api.sendNotificationAnon(carrierUserId, expoToken, title, message, category, data)
        .then(function(response){
            helper.logEvent({ sendToRollbar: true, category: 'API Response', message: 'TaskManager: sendNotificationAnon(): Geo Fence Notification Sent with App Closed', data: { response: response.data } });
        });
}
