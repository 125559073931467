import React, { useContext, useState } from 'react';
import { View } from 'react-native';
import { Button, Text, StyleService, useStyleSheet } from '@ui-kitten/components';
import { AuthContext } from 'components/context';
import { InputField } from 'components/forms';
import { Loader, UploadPreview } from 'components/utility';
import { Formik, Field } from 'formik';
import * as yup from 'yup';
import * as api from 'api/SleekTechnologiesApi';
import * as helper from 'utility/Helper';

export default function ReportOSD(props) {

    const styles = useStyleSheet(themedStyles);
    const { signOut } = useContext(AuthContext);
    const [isLoading, setLoading] = useState(false);
    const [uploads, setUploads] = useState([]);
    const [uploadMethod, setUploadMethod] = useState(null);
    const loadDetails = props.loadDetails;

    const validationSchema = yup.object().shape({
        message: yup
            .string()
            .required('Claim details are required'),
        uploadedFiles: yup
            .array()
            .min(1, 'Please upload a claim image')
            .required('Please upload a claim image'),
    });

    const pickImage = async (setFieldValue) => {
        let result = await helper.pickImage();

        if (result) {
            let newUploads = [...uploads, result];
            setUploads(newUploads);
            setFieldValue('uploadedFiles', newUploads);
            setUploadMethod('imagePickerLibrary');
        }
    }

    const takePhoto = async (setFieldValue) => {
        let result = await helper.takePhoto();

        if (result) {
            let newUploads = [...uploads, result];
            setUploads(newUploads);
            setFieldValue('uploadedFiles', newUploads);
            setUploadMethod('imagePickerCamera');
        }
    }

    const UploadsList = ({ setFieldValue }) => {
        return (
            uploads.map((item, index) => {
                return (
                    <UploadPreview
                        key={index}
                        removeUpload={() => removeUpload(index, setFieldValue)}
                        {...item}
                    />
                )
            })
        )
    }

    const resetUploads = (setFieldValue) => {
        setUploads([]);
        setFieldValue('uploadedFiles', []);
        setUploadMethod(null);
    }

    const removeUpload = (index, setFieldValue) => {
        let newUploads = uploads.filter((_, i) => i !== index);
        setUploads(newUploads);
        setFieldValue('uploadedFiles', newUploads);
    }

    const submitOSD = (data) => {
        setLoading(true);

        let uploadedImages = uploads.map(upload => 'data:image/jpeg;base64,' + upload.base64);

        api.submitOSD(data.sleekFleetLoadId, data.message, uploadedImages)
            .then(function (response) {
                if (response.data.msg === "Token Not Found or Expired") {
                    signOut();
                }
                setLoading(false);
                if (response.data.result === "success") {
                    props.setModalVisible(false);
                    props.setModalError(null);
                    helper.showFlashMessage(response.data.result, response.data.msg);
                    props.getLoadDetails(loadDetails.sleekFleetLoadId);
                } else if (response.data.result === "error") {
                    throw new Error(response.data.msg);
                }
            })
            .catch(function (error) {
                setLoading(false);
                helper.handleCatchError(error, true, props.setModalError);
            });
    }

    if (isLoading) {
        return <Loader loadingText="Submitting OS&D" />
    } else {
        return (
            <View style={styles.container}>
                <Text style={styles.instructions} category="p1">
                    Please upload one or more photos and provide details about your claim in the box below:
                </Text>
                <Formik
                    validationSchema={validationSchema}
                    initialValues={{
                        sleekFleetLoadId: loadDetails.sleekFleetLoadId,
                        message: "",
                        uploadedFiles: [],
                    }}
                    onSubmit={submitOSD}
                >
                    {({ handleSubmit, isValid, values, setFieldValue }) => (
                        <>
                            {uploads.length === 0 &&
                                <View style={styles.buttonWrapper}>
                                    <View style={styles.buttonView}>
                                        <Button
                                            style={[styles.button, styles.buttonLeft]}
                                            appearance="outline"
                                            size="small"
                                            onPress={() => pickImage(setFieldValue)}
                                        >
                                            Choose From Library
                                        </Button>
                                    </View>
                                    {!helper.isWeb() &&
                                        <View style={styles.buttonView}>
                                            <Button
                                                style={[styles.button, styles.buttonRight]}
                                                appearance="outline"
                                                size="small"
                                                onPress={() => takePhoto(setFieldValue)}
                                            >
                                                Take Photo
                                            </Button>
                                        </View>
                                    }
                                </View>
                            }
                            {uploads.length > 0 &&
                                <>
                                    <View style={styles.uploadsView}>
                                        <UploadsList setFieldValue={setFieldValue} />
                                    </View>
                                    <View style={styles.buttonWrapper}>
                                        <View style={styles.buttonView}>
                                            <Button
                                                style={[styles.button, styles.buttonLeft]}
                                                appearance="outline"
                                                size="small"
                                                onPress={() => pickImage(setFieldValue)}
                                            >
                                                Add Another
                                            </Button>
                                        </View>
                                        <View style={styles.buttonView}>
                                            <Button
                                                style={[styles.button, styles.buttonRight]}
                                                appearance="outline"
                                                size="small"
                                                onPress={() => resetUploads(setFieldValue)}
                                            >
                                                Cancel
                                            </Button>
                                        </View>
                                    </View>
                                </>
                            }
                            <View style={styles.formView}>
                                <Field
                                    component={InputField}
                                    name="message"
                                    placeholder="Enter claim details here. If damaged goods, please include volume and type of product damaged."
                                    multiline={true}
                                    textStyle={{ minHeight: 150 }}
                                />
                                <Button onPress={handleSubmit} disabled={!isValid}>
                                    Submit OS&D
                                </Button>
                            </View>
                        </>
                    )}
                </Formik>
            </View>
        )
    }
}

const themedStyles = StyleService.create({
    container: {
        // styles here...
    },
    instructions: {
        paddingVertical: 10,
    },
    buttonWrapper: {
        flexDirection: "row",
    },
    buttonView: {
        flex: 1,
    },
    button: {
        marginBottom: 10,
    },
    buttonLeft: {
        marginRight: 5,
    },
    buttonRight: {
        marginLeft: 5
    },
    formView: {
        marginTop: 10,
    },
    uploadsView: {
        // styles here...
    }
});
