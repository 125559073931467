import React, { useContext, useState } from 'react';
import { View } from 'react-native';
import { Button, StyleService, Text, useStyleSheet } from '@ui-kitten/components';
import { AuthContext } from 'components/context';
import { Loader } from 'components/utility';
import * as api from 'api/SleekTechnologiesApi';
import * as helper from 'utility/Helper';

export const RateConfirmation = (props) => {

    /*
        props:
        - sleekFleetLoadId
    */

    const styles = useStyleSheet(themedStyles);
    const [isLoading, setLoading] = useState(false);
    const { signOut } = useContext(AuthContext);

    const generateRateConfirmation = () => {

        setLoading(true);

        api.generateRateConfirmation(props.sleekFleetLoadId)
            .then(function(response) {
                if(helper.hasValue(response.data.msg) && response.data.msg == "Token Not Found or Expired") {
                    signOut();
                }
                if(response.data.result == "success") {
                    setLoading(false);
                    helper.showFlashMessage(response.data.result, response.data.msg);
                } else if(response.data.result == "error") {
                    throw {
                        type: "custom",
                        message: response.data.msg
                    }
                }
            })
            .catch(function(error){
                setLoading(false);
                helper.handleCatchError(error);
            });
    }

    if(isLoading) {
        return (
            <Loader />
        )
    } else {
        return (
            <View style={styles.container}>
                <View style={styles.messageView}>
                    <Text category="p1">Use the link below to generate a rate confirmation and have it sent via email to your inbox.</Text>
                </View>
                <View style={styles.buttonView}>
                    <Button style={styles.button} appearance="ghost" onPress={() => helper.showConfirmActionAlert("Generate Rate Confirmation", "Please tap the confirm option below to generate a rate confirmation.", generateRateConfirmation)}>Generate Rate Confirmation</Button>
                </View>
            </View>
        )
    }
}

const themedStyles = StyleService.create({
    container: {
        paddingVertical: 5,
    },
    buttonView: {
        alignItems: "center",
    },
    button: {
        marginTop: 20,
    }
});
