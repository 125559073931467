import React, {useState, useEffect, useContext } from 'react';
import {Keyboard, SafeAreaView, StyleSheet, Text, TouchableOpacity as Touchable, View } from 'react-native';
import {GestureHandlerRootView} from 'react-native-gesture-handler';
import Autocomplete from 'react-native-autocomplete-input';
import { AuthContext } from 'components/context';
import * as api from 'api/SleekTechnologiesApi';
import * as helper from 'utility/Helper';

export const OtherAutocompleteField = (props) => {

	const {
		field: { name, onBlur, onChange, values },
		form: { errors, touched, setFieldTouched, setFieldValue },
		...inputProps
	} = props;

	const hasError = errors[name] && touched[name];
	const { signOut } = useContext(AuthContext);

	const [value, setValue] = useState({});
	const [data, setData] = useState(props.options);

	function applySuggestions(matchingItems) {
		let suggestions = [];
		var i = 0, len = matchingItems.length;
		while (i < len) {
			suggestions.push({ key: i, title: matchingItems[i]});
			i++
		}
		setData(suggestions);
	}

	const onChangeText = (query) => {
		setValue(query);
		if(query.length > 2) {
			switch(props.autoCompleteLookup) {
				case 'location':
					api.locationAutoComplete(query)
						.then(function(response){
							if(helper.hasValue(response.data.msg) && response.data.msg == "Token Not Found or Expired") {
								signOut();
							}
							applySuggestions(response.data.suggestions);
						});
					break;
				case 'loadNumber':
					api.loadNumberAutoComplete(query)
						.then(function(response){
							if(helper.hasValue(response.data.msg) && response.data.msg == "Token Not Found or Expired") {
								signOut();
							}
							applySuggestions(response.data.suggestions);
						});
					break;
				case 'bookNowLoadNumber':
					api.bookNowLoadNumberAutoComplete(query)
						.then(function(response){
							if(helper.hasValue(response.data.msg) && response.data.msg == "Token Not Found or Expired") {
								signOut();
							}
							applySuggestions(response.data.suggestions);
						});
					break;
				case 'transactionId':
					api.transactionIdAutoComplete(query)
						.then(function(response){
							if(helper.hasValue(response.data.msg) && response.data.msg == "Token Not Found or Expired") {
								signOut();
							}
							applySuggestions(response.data.suggestions);
						});
					break;
				default:
					//do nothing
			}
		}
	};   
	  
	return (
		<>
			<Autocomplete
				inputContainerStyle={styles.autocompleteInput}
				autoCapitalize="none"
				autoCorrect={false}
				containerStyle={styles.autocompleteContainer}
				data={data}
				defaultValue={JSON.stringify(value) === '{}' ? props.defaultValue : value.title }
				value={JSON.stringify(value) === '{}' ? props.defaultValue : value.title }
				onChangeText={(text) => onChangeText(text)}
				placeholder = {props.placeholder}
				flatListProps={{
					renderItem: ({item}) => (
						<Touchable
							key={item.key}
							onPress={() => {
								setValue(item);
								setFieldValue(name, item.title);
								setData([]);
							}}>
							<Text style={styles.itemText}>
								{item.title}
							</Text>
						</Touchable>
					),
				}}
			/>
			{hasError &&
				<View style={styles.errorView}>
					<Text category="p2" status="danger">{errors[name]}</Text>
				</View>
			}
		</>
	);
	
}	 

const styles = StyleSheet.create({
	container: {
		backgroundColor: '#F5FCFF',
		flex: 1,
		padding: 16,
		marginTop: 40,
	},
	autocompleteContainer: {
		backgroundColor: '#ffffff',
		borderWidth: 0,
		marginBottom: 10,
	},
	descriptionContainer: {
		flex: 1,
		justifyContent: 'center',
	},
	itemText: {
		fontSize: 15,
		paddingTop: 5,
		paddingBottom: 5,
		margin: 2,
	},
	infoText: {
		textAlign: 'center',
		fontSize: 16,
	},
	errorView: {
		marginTop: -3,
		marginLeft: 10,
		marginBottom: 10,
	},
	autocompleteInput: {
		borderColor: '#e4e9f2',
		padding: 4,
		borderRadius: 4,
    },
});
