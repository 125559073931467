import React from 'react';
import { ScrollView, View } from 'react-native';
import { Layout, List, ListItem, Divider, StyleService, Text, useStyleSheet } from '@ui-kitten/components';
import { useNavigation } from '@react-navigation/native';
import { EmptyDataSet } from 'components/utility';
import { LoadSummary } from 'components/loads';
import { Section } from 'components/layout';
import * as helper from 'utility/Helper';

export default function DriverActiveLoads(props) {

    const styles = useStyleSheet(themedStyles);
    const activeLoad = props.activeLoad;
    const hasActiveLoad = props.hasActiveLoad;
    const navigation = useNavigation();

    const ActiveLoadsList = ({ item, index }) => {
        if(hasActiveLoad) {
            let loads = [activeLoad];
            return (
                <List style={styles.listView} data={loads} renderItem={renderLoadSummary} ItemSeparatorComponent={Divider} />
            )
        } else {
            return (
                <EmptyDataSet primaryMessage="No Active Load" />
            )
        }
    }

    const renderLoadSummary = ({ item, index }) => {

        let loadDetails = item;
        loadDetails.origin = item.originCity+', '+item.originState;
        loadDetails.destination = item.destinationCity+', '+item.destinationState;
        loadDetails.pickup = item.mustPickupBy;
        loadDetails.delivery = item.mustDeliverBy;
        loadDetails.driverName = item.carrierDriverName;

        return (
            <ListItem style={styles.listItem} children={<LoadSummary {...loadDetails} loadSummaryType="active" />} />
        )
    }

    React.useLayoutEffect(() => {
        if(helper.isWeb()) {
            navigation.setOptions({
                title: 'Driver Details - Active Loads'
            })
        }
    }, [navigation]);

    return (
        helper.isWeb() ? (
            <Layout style={styles.layout} level="3">
                {helper.isDesktopWeb() &&
                    <ScrollView>
                        <Section title="Active Loads" subtitle={hasActiveLoad ? '1 Load' : 'No Loads'} noBodyPadding={hasActiveLoad ? true : false} renderBody={<ActiveLoadsList />}/>
                    </ScrollView>
                }
                {helper.isMobileWeb() &&
                    <ActiveLoadsList />
                }
            </Layout>
        ) : (
            <Layout style={styles.layout} level="3">
                <ActiveLoadsList />
            </Layout>
        )
    )

}

const themedStyles = StyleService.create({
    layout: {
        flex: 1,
        marginTop: helper.isDesktopWeb() ? 5 : 0
    },
    viewAllView: {
        flexDirection: "row",
        flexWrap: "wrap",
        justifyContent: "flex-end",
        alignItems: "center"
    },
    forwardIcon: {
        color: "color-primary-500",
        size: 18,
    },
    listView: {
        borderBottomLeftRadius: helper.isDesktopWeb() ? 6 : 0,
        borderBottomRightRadius: helper.isDesktopWeb() ? 6 : 0
    },
    listItem: {
        paddingHorizontal: 0,
        paddingVertical: 0,
    }
});
