import React from 'react';
import { Image, View } from 'react-native';
import { Button, Layout, StyleService, Text, useStyleSheet } from '@ui-kitten/components';
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome';
import { TextIcon } from 'components/layout';
import { Badge, CustomLink } from 'components/utility';
import * as Constants from 'config/Constants';
import getEnvVars from 'root/environment';
import * as helper from 'utility/Helper';

export const BookNowSummary = (props) => {

    /*
        props:
        - bookNowDate
        - bookNowDateSort
        - bookNowPrice
        - bookNowPriceSort
        - bookNowStatus
        - destination
        - driverMiles
        - origin
        - shipperLogo
        - shipperName
        - sleekFleetLoadId
        - type
        - weight
    */

    const styles = useStyleSheet(themedStyles);
    const { sleekApiUrl } = getEnvVars();
    const shipperLogoUri = sleekApiUrl+props.shipperLogo;
    const isManager = helper.checkForRoles(['manager']);
    const path = '/reports/load-details/'+props.sleekFleetLoadId;

    const Route = () => {
        return (
            <View style={styles.routeView}>
                <Text style={styles.route} status="primary" category="s2">{props.origin}</Text>
                <FontAwesomeIcon style={styles.routeIcon} icon={['fal', 'angle-right']} size={styles.routeIcon.size} color={styles.routeIcon.color} />
                <Text style={styles.route} status="primary" category="s2">{props.destination}</Text>
                {helper.isDesktopWeb() &&
                    <Badge label={props.bookNowStatus} type={props.bookNowStatus == 'Declined' ? 'danger' : 'success'} />
                }
            </View>
        )
    }

    const LoadDataOne = () => {
        return(
            <View style={[styles.loadDataView, styles.loadDataOne]}>
                <TextIcon iconName="box" iconWeight="far" iconSize={styles.icon.size} iconColor={styles.icon.color} text={props.type} textCategory="c2" textAppearance="hint" />
                <TextIcon iconName="gas-pump" iconWeight="far" iconSize={styles.icon.size} iconColor={styles.icon.color} text={props.driverMiles+ ' Mi'} textCategory="c2" textAppearance="hint" />
                <TextIcon iconName="dumbbell" iconWeight="far" iconSize={styles.icon.size} iconColor={styles.icon.color} text={props.weight+' Lbs'} textCategory="c2" textAppearance="hint" />
            </View>
        )
    }

    const LoadDataTwo = () => {
        return (
            <View style={[styles.loadDataView, styles.loadDataTwo]}>
                <TextIcon iconName="calendar-alt" iconWeight="far" iconSize={styles.icon.size} iconColor={styles.icon.color} text={props.bookNowDate} textCategory="c2" textAppearance="hint" />
                <TextIcon iconName="box-usd" iconWeight="far" iconSize={styles.icon.size} iconColor={styles.icon.color} text={'$'+props.bookNowPrice} textCategory="c2" textAppearance="hint" containerSpacing={5} />
                {!helper.isDesktopWeb() &&
                    <Badge label={props.bookNowStatus} type={props.bookNowStatus == 'Declined' ? 'danger' : 'success'} />
                }
            </View>
        )
    }

    return (
        <CustomLink styleProps={styles.customLink} to={path}>
            <View style={styles.container}>
                <View style={styles.logoView}>
                    <Image
                        source={{
                            uri: shipperLogoUri,
                            headers: Constants.RESOURCE_HEADERS
                        }}
                        style={styles.image}
                        resizeMode="contain"
                    />
                </View>
                <View style={styles.dataView}>
                    <Route />
                    <View style={styles.loadDataWrapper}>
                        <LoadDataOne />
                        <LoadDataTwo />
                    </View>
                </View>
                <View style={styles.actionView}>
                    <FontAwesomeIcon style={styles.forwardIcon} icon={['fal', 'angle-right']} size={styles.forwardIcon.size} color={styles.forwardIcon.color} />
                </View>
            </View>
        </CustomLink>
    )
}

const themedStyles = StyleService.create({
    customLink: {
        flex: 1,
    },
    container: {
        flexDirection: "row",
        marginVertical: helper.isDesktopWeb() ? 10 : 18,
    },
    logoView: {
        borderRightWidth: 1,
        borderRightColor: "color-basic-400",
        padding: 5,
        justifyContent: "center",
        alignItems: "center",
        paddingHorizontal: 12,
    },
    image: {
        width: helper.isDesktopWeb() ? 40 : 54,
        height: helper.isDesktopWeb() ? 40 : 54,
        paddingHorizontal: 10
    },
    dataView: {
        flex: 1,
        justifyContent: "center",
        paddingLeft: 10,
    },
    routeView: {
        flexDirection: "row",
        alignItems: "center",
        marginBottom: 5,
        flexWrap: "wrap",
    },
    route: {
        fontSize: 15,
        marginBottom: 3,
    },
    routeIcon: {
        size: 16,
        color: "color-primary-500",
    },
    loadDataWrapper: {
        flexDirection: helper.isDesktopWeb() ? "row" : "column",
    },
    loadDataView: {
        flexDirection: "row",
        flexWrap: "wrap",
        marginRight: 30,
    },
    loadDataOne: {
        marginBottom: helper.isDesktopWeb() ? 4 : 10,
        marginRight: helper.isDesktopWeb() ? 5 : 30,
    },
    loadDataTwo: {
        marginBottom: 4,
        marginRight: 30,
    },
    actionView: {
        position: "absolute",
        right: 5,
        top: "50%",
        marginTop: -10
    },
    forwardIcon: {
        color: "color-primary-500",
        size: 20,
    },
    icon: {
        size: 14,
        color: "color-basic-600"
    }
});
