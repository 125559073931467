import React, { useRef } from 'react';
import { Platform } from 'react-native';
import { AppDataContext } from 'components/context';
import Constants from 'expo-constants';
import * as AppConstants from 'config/Constants';
import * as Location from 'expo-location';
import * as Notifications from 'expo-notifications';
import * as helper from 'utility/Helper';
import * as api from 'api/SleekTechnologiesApi';
import * as locationTracking from 'location/LocationTracking';
import * as RootNavigation from 'navigation/RootNavigation';
import * as Device from 'expo-device';

const loadUpdateNotificationActions = [
    {
        identifier: "confirmLoadUpdateAction",
        buttonTitle: "Confirm",
    },
    {
        identifier: "rejectLoadUpdateAction",
        buttonTitle: "Reject",
    },
];

if(!helper.isWeb()) {
    Notifications.setNotificationCategoryAsync('loadUpdateActionNotificationCategory', loadUpdateNotificationActions);
}

export const initiateNotificationSetup = () => {
    //helper.log('************ initiateNotificationSetup ************');
    
    if(!helper.isWeb()) {
        let pushToken;

        registerForPushNotificationsAsync()
            .then(function(token){
                                pushToken = token;
            })
            .then(function(){
                api.savePushToken(pushToken)
                    .then(function(response){
                                                //helper.log(response);
                    });
            });
    }
}

export const handleLastNotificationResponse = (lastNotificationResponse, locationService, appData) => {

    const categoryIdentifier = lastNotificationResponse.notification.request.content.categoryIdentifier;

    //helper.logEvent({ category: 'Notifications', message: 'Last Notification Response - Category Identifier: ' + categoryIdentifier });

    if(helper.hasValue(lastNotificationResponse.actionIdentifier)) {
        //helper.logEvent({ category: 'Notifications', message: 'Last Notification Response - Action Identifier: ' + lastNotificationResponse.actionIdentifier });
    }

    if(lastNotificationResponse.actionIdentifier == 'confirmLoadUpdateAction') {
        let loadId = lastNotificationResponse.notification.request.content.data.loadId;
        RootNavigation.navigate('LoadDetailsStackNavigator', {
            screen: 'LoadDetails_Generic',
            params: {
                loadId: loadId,
                callback: 'update-load-status'
            }
        });
    } else if(lastNotificationResponse.actionIdentifier == 'rejectLoadUpdateAction' || categoryIdentifier == 'loadUpdateActionNotificationCategory') {
        let loadId = lastNotificationResponse.notification.request.content.data.loadId;
        RootNavigation.navigate('LoadDetailsStackNavigator', {
            screen: 'LoadDetails_Generic',
            params: {
                loadId: loadId,
            }
        });
    } else if(categoryIdentifier == 'newLoadNotificationCategory') {
        let loadId = lastNotificationResponse.notification.request.content.data.loadId;
        RootNavigation.navigate('LoadDetailsStackNavigator', {
            screen: 'LoadDetails_Generic',
            params: {
                loadId: loadId,
            }
        });
    } else if(categoryIdentifier == 'generalLoadNotificationCategory') {
        let loadId = lastNotificationResponse.notification.request.content.data.loadId;
        RootNavigation.navigate('LoadDetailsStackNavigator', {
            screen: 'LoadDetails_Generic',
            params: {
                loadId: loadId,
            }
        });
    } else {
        RootNavigation.navigate('DashboardStackNavigator', {
            screen: 'Dashboard',
        });
    }
}

const registerForPushNotificationsAsync = async () => {
    //helper.log('************ Helper: registerForPushNotificationsAsync ************');
    let token;
    if (Device.isDevice) {
        const { status: existingStatus } = await Notifications.getPermissionsAsync();
        let finalStatus = existingStatus;
        if (existingStatus !== 'granted') {
            const { status } = await Notifications.requestPermissionsAsync();
            finalStatus = status;
        }
        if (finalStatus !== 'granted') {
            alert('Failed to get push token for push notification!');
            return;
        }
        token = (await Notifications.getExpoPushTokenAsync({'projectId': Constants.expoConfig.extra.eas.projectId,})).data;
    } else {
        alert('Must use physical device for Push Notifications');
    }

    if (Platform.OS === 'android') {
        Notifications.setNotificationChannelAsync('default', {
            name: 'default',
            importance: Notifications.AndroidImportance.MAX,
            vibrationPattern: [0, 250, 250, 250],
            lightColor: '#FF231F7C',
        });
    }

    return token;
}

const _getLocationAsync = async (loadId, loadStatus, carrierDriverId, carrierUserId, confirmLoadUpdateCallback, locationService) => {
    //helper.log('************ _getLocationAsync ************');
    let location = await Location.getCurrentPositionAsync({});
    confirmLoadUpdateCallback(loadId, loadStatus, location, carrierDriverId, carrierUserId, locationService);
}

const confirmLoadUpdateCallback = (loadId, loadStatus, currentLocation, carrierDriverId, carrierUserId, locationService) => {
    //helper.log('************ confirmLoadUpdateCallback ************');
    // Log event if user's location is not properly set
    if(currentLocation === null) {
        helper.logEvent({ sendToRollbar: true, category: 'Location Tracking', message: 'NotificationSetup.tsx: No Location Detected', data: { location: currentLocation, sleekFleetLoadId: loadId }});
    }
    //Update Load Status
    api.updateLoad(loadId, loadStatus, (currentLocation != '' && currentLocation != null ? currentLocation.coords.latitude : 'none'), (currentLocation != '' && currentLocation != null ? currentLocation.coords.longitude : 'none'), "", "")
        .then(function(response){
            if(carrierDriverId == carrierUserId && (Platform.OS === 'ios' || Platform.OS === 'android')) {
                if(loadStatus == 'delivered') {
                    locationTracking.stopLocationServices(locationService);
                }
                RootNavigation.navigate('LoadDetailsStackNavigator', {
                    screen: 'LoadDetails_Generic',
                    params: {
                        loadId: loadId,
                    }
                });
            }
        });
}
