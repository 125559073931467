import React, {useContext, useState, useEffect} from 'react';
import { ScrollView, View } from 'react-native';
import { SafeAreaView } from 'react-native-safe-area-context';
import { Button, Layout, StyleService, Text, useStyleSheet } from '@ui-kitten/components';
import { AppDataContext, AuthContext } from 'components/context';
import { Header } from 'components/layout';
import { Loader } from 'components/utility';
import { ToggleField } from 'components/forms';
import { Formik, Field } from 'formik';
import * as yup from 'yup';
import * as api from 'api/SleekTechnologiesApi';
import * as helper from 'utility/Helper';

export default function EditUser({ route, navigation }) {

    const styles = useStyleSheet(themedStyles);
    const { appData, setAppData } = React.useContext(AppDataContext);
    const { signOut } = useContext(AuthContext);
    const [userData, setUserData] = useState([]);
    const [userSettings, setUserSettings] = useState([]);
    const [isLoading, setLoading] = useState(false);
    const [breadcrumbs, setBreadcrumbs] = useState([]);

    const validationSchema = yup.object().shape({
        admin: yup.boolean().when(['manager', 'driver'], {
            is: (manager, driver) => !manager && !driver,
            then: yup.boolean().oneOf([true], "At least one role is required"),
        }),
        manager: yup.boolean().when(['admin', 'driver'], {
            is: (admin, driver) => !admin && !driver,
            then: yup.boolean().oneOf([true], "At least one role is required"),
        }),
        driver: yup.boolean().when(['admin', 'manager'], {
            is: (admin, manager) => !admin && !manager,
            then: yup.boolean().oneOf([true], "At least one role is required"),
        })
    }, [['admin', 'manager'], ['admin', 'driver'], ['manager','driver']]);

    useEffect(() => {
        api.getCarrierUserDetails(route.params.userId)
            .then(function(response){
                if(helper.hasValue(response.data.msg) && response.data.msg == "Token Not Found or Expired") {
                    signOut();
                }
                if(response.data.result == "success") {
                    setUserData(response.data.carrierUserDetails);
                    setBreadcrumbs(
                        [
                            {
                                label: 'Dashboard',
                                path: '/dashboard'
                            },
                            {
                                label: 'Manage Users',
                                path: '/manage-users'
                            },
                            {
                                label: response.data.carrierUserDetails.name,
                                path: '/manage-users/'+response.data.carrierUserDetails.id
                            },
                            {
                                label: 'Edit User',
                                path: ''
                            }
                        ]
                    );
                    let settings = {
                        admin: (response.data.carrierUserDetails.roles.includes("admin") ? true : false),
                        manager: (response.data.carrierUserDetails.roles.includes("manager") ? true : false),
                        driver: (response.data.carrierUserDetails.roles.includes("driver") ? true : false),
                        isActive: (response.data.carrierUserDetails.isActive != 'No' ? true : false),
                    }
                    setUserSettings(settings);
                    setLoading(false);
                } else if(response.data.result == "error") {
                    throw {
                        type: "custom",
                        message: response.data.msg
                    }
                }
            })
            .catch(function(error){
                setLoading(false);
                helper.handleCatchError(error);
            });
    }, []);

    const saveUserData = (data) => {

        setLoading(true);

        //Add Carrier User Id
        data.carrierUserId = userData.id;

        //Update Carrier User Permissions Data
        api.updateCarrierUserPermissions(data)
            .then(function(response){
                if(helper.hasValue(response.data.msg) && response.data.msg == "Token Not Found or Expired") {
                    signOut();
                }
                if(response.data.result == 'success') {
                    helper.showFlashMessage(response.data.result, response.data.msg);

                    //Refresh Application Data
                    api.getApplicationData()
                        .then(function(response){
                            if(response.data.result == "success") {
                                setAppData({...response.data});
                                navigation.reset({index: 0, routes: [{ name: 'ManageUsers' }],});
                            } else if(response.data.result == "error") {
                                throw {
                                    type: "custom",
                                    message: response.data.msg
                                }
                            }
                        })
                        .catch(function(error){
                            helper.handleCatchError(error);
                        });
                } else if(response.data.result == "error") {
                    setLoading(false);
                    throw {
                        type: "custom",
                        message: response.data.msg
                    }
                }
            })
            .catch(function(error){
                setLoading(false);
                helper.handleCatchError(error);
            });
    };

    const EditUserView = () => {
        return (
            <Layout style={styles.layout} level="3">
                <Formik
                    validationSchema={validationSchema}
                    initialValues={userSettings}
                    onSubmit={(values) => saveUserData(values)}
                >
                    {({ handleSubmit, isValid, values }) => (
                        <>
                            <View style={styles.formSection}>
                                <View style={styles.formSectionTitle}>
                                    <Text category="s1">User Status</Text>
                                </View>
                                <View style={styles.formSectionFields}>
                                    <View style={[styles.toggleRow, styles.toggleRowLast]}>
                                        <View style={styles.toggleLabel}>
                                            <Text category="s2" appearance="hint">Active</Text>
                                        </View>
                                        <View style={styles.toggleInput}>
                                            <Field
                                                component={ToggleField}
                                                disabled={helper.checkForRoles(['admin', 'manager']) && userData.id != appData.carrierUserData.id ? false : true}
                                                name="isActive"
                                                label="Active"
                                                defaultValue={(userData.isActive != 'No' ? true : false)}
                                            />
                                        </View>
                                    </View>
                                </View>
                            </View>
                            <View style={styles.formSection}>
                                <View style={styles.formSectionTitle}>
                                    <Text category="s1">Roles</Text>
                                </View>
                                <View style={styles.formSectionFields}>
                                    {userData.hasConverted != 'Yes' &&
                                        <Text style={styles.formSectionIntro} category="p1" appearance="hint">Please note, this user is currently on an older version of the Sleek Fleet application. The user must upgrade to the latest version in order to update their roles.</Text>
                                    }
                                    <View style={styles.toggleRow}>
                                        <View style={styles.toggleLabel}>
                                            <Text category="s2" appearance="hint">Admin</Text>
                                        </View>
                                        <View style={styles.toggleInput}>
                                            <Field
                                                component={ToggleField}
                                                disabled={helper.checkForRoles(['admin']) && userData.id != appData.carrierUserData.id && userData.hasConverted == 'Yes' ? false : true}
                                                name="admin"
                                                defaultValue={(helper.hasValue(userData.roles) && userData.roles.includes("admin") ? true : false)}
                                            />
                                        </View>
                                    </View>
                                    <View style={styles.toggleRow}>
                                        <View style={styles.toggleLabel}>
                                            <Text category="s2" appearance="hint">Manager</Text>
                                        </View>
                                        <View style={styles.toggleInput}>
                                            <Field
                                                component={ToggleField}
                                                disabled={helper.checkForRoles(['admin', 'manager']) && userData.id != appData.carrierUserData.id && userData.hasConverted == 'Yes' ? false : true}
                                                name="manager"
                                                defaultValue={(helper.hasValue(userData.roles) && userData.roles.includes("manager") ? true : false)}
                                            />
                                        </View>
                                    </View>
                                    <View style={[styles.toggleRow, styles.toggleRowLast]}>
                                        <View style={styles.toggleLabel}>
                                            <Text category="s2" appearance="hint">Driver</Text>
                                        </View>
                                        <View style={styles.toggleInput}>
                                            <Field
                                                component={ToggleField}
                                                disabled={helper.checkForRoles(['admin', 'manager']) && userData.hasConverted == 'Yes' ? false : true}
                                                name="driver"
                                                defaultValue={(helper.hasValue(userData.roles) && userData.roles.includes("driver") ? true : false)}
                                            />
                                        </View>
                                    </View>
                                </View>
                            </View>
                            <View style={styles.submitButtonView}>
                                <Button onPress={handleSubmit} disabled={!isValid}>Update User</Button>
                            </View>
                        </>
                    )}
                </Formik>
            </Layout>
        )
    }

    if(isLoading) {
        return (
            <Loader />
        )
    } else {
        return (
            helper.isWeb() ? (
                <View style={styles.webView}>
                    <Header breadcrumbs={breadcrumbs} title={userData.name} subtitle="Edit User"/>
                    <ScrollView>
                        <EditUserView />
                    </ScrollView>
                </View>
            ) : (
                <SafeAreaView style={styles.safeAreaView} edges={['top', 'left', 'right']}>
                    <Header activeScreen="EditUser" title={userData.name} />
                    <ScrollView>
                        <EditUserView />
                    </ScrollView>
                </SafeAreaView>
            )
        )
    }
}

const themedStyles = StyleService.create({
    // global styles
    layout: {
        flex: 1,
        paddingTop: 4,
    },
    formSection: {
        backgroundColor: "color-basic-100",
        borderRadius: 6,
        borderWidth: 1,
        borderColor: "border-generic",
        marginHorizontal: 8,
        marginVertical: 4,
        shadowColor: "shadow-color",
        shadowOffset: {
            width: 0,
            height: 0,
        },
        shadowOpacity: .1,
        shadowRadius: 2
    },
    formSectionTitle: {
        backgroundColor: "background-basic-color-2",
        borderBottomWidth: 1,
        borderBottomColor: "border-basic-color-4",
        padding: 10,
        alignItems: "center",
        flexDirection: "row",
        minHeight: 50,
        borderTopLeftRadius: 6,
        borderTopRightRadius: 6,
    },
    formSectionFields: {
        paddingVertical: 10,
        paddingHorizontal: 10,
    },
    formSectionFieldsNoVerticalPadding: {
        paddingVertical: 0,
    },
    formSectionIntro: {
        marginBottom: 10,
    },
    toggleRow: {
        flexDirection: "row",
        paddingVertical: 3,
        borderBottomColor: "border-basic-color-3",
        borderBottomWidth: 1,
        minHeight: 54,
    },
    toggleRowLast: {
        borderBottomWidth: 0,
    },
    toggleLabel: {
        flex: 2,
        justifyContent: "center",
    },
    toggleInput: {
        flex: 1,
        justifyContent: "center",
        alignItems: "flex-end",
    },
    submitButtonView: {
        paddingHorizontal: 10,
        marginBottom: 40,
        marginTop: 10
    },
    // native specific styles
    safeAreaView: {
        backgroundColor: "safe-area-view-background",
        flex: 1,
    },
    // web specific styles
    webView: {
        flex: 1,
    },
});
