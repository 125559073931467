import React, { useContext, useState, useEffect } from 'react';
import { Image, View } from 'react-native';
import { Button, Divider, Layout, StyleService, Text, useStyleSheet } from '@ui-kitten/components';
import { AuthContext } from 'components/context';
import { InputField } from 'components/forms';
import { Loader, UploadPreview } from 'components/utility';
import { Formik, Field } from 'formik';
import * as Constants from 'config/Constants';
import * as yup from 'yup';
import * as api from 'api/SleekTechnologiesApi';
import * as helper from 'utility/Helper';
import getEnvVars from 'root/environment';

export default function CompleteLoad(props) {

    const styles = useStyleSheet(themedStyles);
    const { signOut } = useContext(AuthContext);
    const [isLoading, setLoading] = useState(false);
    const [uploads, setUploads] = useState([]);
    const [uploadMethod, setUploadMethod] = useState(null);
    const [bolSubmitted, setBolSubmitted] = useState(false);
    const loadDetails = props.loadDetails;
    const { sleekApiUrl } = getEnvVars();
    const logoUri = sleekApiUrl+'/images/carrierui/global/modal-logo.png';

    const pickDocument = async () => {

        let result = await helper.pickDocument();

        if(result) {
            let newUploads = uploads;
            newUploads.push(result);
            setUploads([...newUploads]);
            setUploadMethod('documentPicker');
        }
    }

    const pickImage = async () => {

        let result = await helper.pickImage(false, [8.5, 11], true, 'width');

        if(result) {
            let newUploads = uploads;
            newUploads.push(result);
            setUploads([...newUploads]);
            setUploadMethod('imagePickerLibrary');
        }
    }

    const takePhoto = async () => {

        let result = await helper.takePhoto(false, [8.5, 11], true, 'width');

        if(result) {
            let newUploads = uploads;
            newUploads.push(result);
            setUploads([...newUploads]);
            setUploadMethod('imagePickerCamera');
        }
    }

    const UploadsList = () => {
        return (
            uploads.map((item, index) => {
                let uploadNumber = index + 1;
                item.uploadMethod = uploadMethod;
                item.uploadTitle = 'File upload ' + uploadNumber + ' of ' + uploads.length;
                item.index = index;

                return (
                    <UploadPreview key={index} removeUpload={removeUpload} {...item} />
                )
            })
        )
    }

    const resetUploads = () => {
        let newUploads = [];
        setUploads([...newUploads]);
        setUploadMethod(null);
    }

    const addUpload = () => {

        switch(uploadMethod) {
            case 'imagePickerLibrary':
                pickImage();
                break;
            case 'imagePickerCamera':
                takePhoto();
                break;
        }
    }

    const removeUpload = (index) => {

        let newUploads = uploads;
        newUploads.splice(index, 1);
        setUploads([...newUploads]);

    }

    const submitCompleteLoad = () => {

        setLoading(true);
        props.setModalError(null);

        let uploadType;
        let uploadedImages = [];
        let uploadedImage;
        let uploadedDocument = "";

        if(uploadMethod == 'documentPicker') {
            if(helper.isWeb()) {
                uploadType = "web document";
                uploadedDocument = uploads[0].assets[0].uri;
            } else {
                uploadType = "document";
                uploadedDocument = uploads[0].assets[0];
            }
        } else {
            uploadType = "image";
            uploads.forEach(upload => {
                uploadedImage = 'data:image/jpeg;base64,'+upload.base64;
                uploadedImages.push(uploadedImage);
            });
        }

        api.submitCompleteLoad(loadDetails.sleekFleetLoadId, uploadedImages, uploadedDocument, uploadType)
            .then(function(response){
                if(helper.hasValue(response.data.msg) && response.data.msg == "Token Not Found or Expired") {
                    signOut();
                }
                setLoading(false);
                if(response.data.result == "success") {
                    props.setModalCloseDisabled(true);
                    setBolSubmitted(true);
                    props.setModalError(null);
                } else if(response.data.result == "error") {
                    throw {
                        type: "custom",
                        message: response.data.msg
                    }
                }
            })
            .catch(function(error){
                setLoading(false);
                helper.handleCatchError(error, true, props.setModalError);
            });
    }

    const resetModal = () => {
        setBolSubmitted(false);
        props.setModalCloseDisabled(false);
        props.setModalVisible(false);
    }

    const bolSuccessAction = () => {
        resetModal();
        props.getLoadDetails(loadDetails.sleekFleetLoadId);
    }

    if(isLoading) {
        return(
            <Loader loadingText="Submitting Bill of Lading"/>
        )
    } else if(bolSubmitted) {
        return (
            <View style={styles.bolSuccessContainer}>
                <View style={styles.bolSuccessHeader}>
                    <View style={styles.logoView}>
                        <Image
                            source={{
                                uri: logoUri,
                                headers: Constants.RESOURCE_HEADERS
                            }}
                            style={{width: 149, height: 125}}
                            resizeMode="contain"
                        />
                    </View>
                    <Text style={styles.bolSuccessTitle} category="h5">Mission Complete!</Text>
                </View>
                <View style={styles.bolSuccessBody}>
                    <Text style={styles.bolSuccessSubtitle} category="s1">Congratulations!</Text>
                    <Text style={styles.bolSuccessMessage} category="p1" appearance="hint">You have successfully completed the delivery of this load!</Text>
                    <Button style={styles.ctaButton} onPress={() => bolSuccessAction()}>Back To Load Details</Button>
                </View>
            </View>
        )
    } else if(uploads.length == 0) {
        return (
            <View style={styles.container}>
                <Text style={styles.instructions} category="p1">Your delivery should now be complete. Please upload the Bill of Lading:</Text>
                {!helper.isWeb() &&
                    <View style={styles.buttonWrapper}>
                        <View style={styles.buttonView}>
                            <Button style={[styles.button, styles.buttonLeft]} appearance="outline" size="small" onPress={pickImage}>Choose From Library</Button>
                        </View>
                        <View style={styles.buttonView}>
                            <Button style={[styles.button, styles.buttonRight]} appearance="outline" size="small" onPress={takePhoto}>Take Photo</Button>
                        </View>
                    </View>
                }
                <View style={styles.buttonView}>
                    <Button style={[styles.button]} appearance="outline" size="small" onPress={pickDocument}>Upload PDF</Button>
                </View>
            </View>
        )
    } else {
        return(
            <View style={styles.container}>
                <Text style={styles.instructions} category="p1">Below are the files that will be submitted. Tap "Submit & Complete Load" once all files have been uploaded.</Text>
                <View>
                    <UploadsList />
                </View>
                <View style={styles.buttonWrapper}>
                    {uploadMethod != "documentPicker" &&
                        <View style={styles.buttonView}>
                            <Button style={[styles.button, styles.buttonLeft]} appearance="outline" size="small" onPress={addUpload}>Add Another</Button>
                        </View>
                    }
                    <View style={styles.buttonView}>
                        <Button style={styles.button} appearance="outline" size="small" onPress={resetUploads}>Cancel</Button>
                    </View>
                </View>
                <View style={styles.buttonSubmitView}>
                    <Button style={styles.buttonSubmit} onPress={submitCompleteLoad}>Submit & Complete Load</Button>
                </View>
                <View style={styles.disclaimerView}>
                    <Text category="p1" appearance="hint">By clicking "Submit & Complete" you acknowledge that this delivery has been successfully completed.</Text>
                </View>
            </View>
        )
    }

}

const themedStyles = StyleService.create({
    container: {
        // styles here...
    },
    instructions: {
        paddingVertical: 10,
        marginBottom: 5,
    },
    buttonWrapper: {
        flexDirection: "row",
        marginVertical: 5,
    },
    buttonView: {
        flex: 1,
    },
    button: {
        marginBottom: 10,
    },
    buttonLeft: {
        marginRight: 5,
    },
    buttonRight: {
        marginLeft: 5
    },
    buttonSubmit: {
        marginVertical: 10,
    },
    disclaimerView: {
        marginTop: 10,
    },
    bolSuccessContainer: {

    },
    bolSuccessHeader: {
        borderBottomWidth: 1,
        borderBottomColor: "border-basic-color-3",
        padding: 20,
        alignItems: "center",
    },
    logoView: {
        marginTop: 5,
    },
    bolSuccessTitle: {
        marginTop: 20,
        marginBottom: 5,
    },
    bolSuccessBody: {
        alignItems: "center",
        paddingVertical: 25,
        paddingHorizontal: 20,
    },
    bolSuccessSubtitle: {
        marginBottom: 5,
        fontSize: 18,
    },
    bolSuccessMessage: {
        textAlign: "center",
        marginBottom: 10,
        fontSize: 17,
    }
});
