import React from 'react';
import { Image, View } from 'react-native';
import { useNavigation, useRoute } from '@react-navigation/native';
import { Divider, Layout, StyleService, Text, useStyleSheet } from '@ui-kitten/components';
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome';
import { Badge, CustomLink } from 'components/utility';
import { TextIcon } from 'components/layout';
import * as Constants from 'config/Constants';
import * as helper from 'utility/Helper';
import getEnvVars from 'root/environment';

export const NotificationItem = (props) => {

    /*
        props:
        - content
        - date
        - isFirstItem
        - isLastItem
        - loadId
        - notificationLogo
        - notificationLogoName
        - showDivider
        - type
    */

    const styles = useStyleSheet(themedStyles);
    const navigation = useNavigation();
    const route = useRoute();
    const { sleekApiUrl } = getEnvVars();
    const logoUri = sleekApiUrl+props.notificationLogo;
    const path = helper.hasValue(props.loadId) ? '/notifications/load-details/'+props.loadId : null;

    const renderNotificationBody = () => {
        return (
            <>
                <View style={helper.getContainerStyles(props, styles)}>
                    <View style={styles.header}>
                        <View style={styles.headerColumnOne}>
                            <View style={styles.imageView}>
                                <Image
                                    source={{
                                        uri: logoUri,
                                        headers: Constants.RESOURCE_HEADERS
                                    }}
                                    style={styles.image}
                                    resizeMode="contain"
                                />
                            </View>
                        </View>
                        <View style={styles.headerColumnTwo}>
                            <View style={styles.nameView}>
                                <Text style={styles.name} category="s2">{props.notificationLogoName}</Text>
                                {props.type == 'Action Required Notification' &&
                                    <Badge label="Action Required" type="danger"/>
                                }
                            </View>
                            <TextIcon style={styles.date} iconName="calendar-alt" iconWeight="far" iconSize={styles.icon.size} iconColor={styles.icon.color} text={props.date} textCategory="c2" textAppearance="hint" />
                        </View>
                    </View>
                    <View style={styles.body}>
                        <Text style={styles.message} category="p1" appearance="hint">{props.content}</Text>
                    </View>
                    {props.loadId != '' &&
                        <View style={styles.actionView}>
                            <FontAwesomeIcon style={styles.forwardIcon} icon={['fal', 'angle-right']} size={styles.forwardIcon.size} color={styles.forwardIcon.color} />
                        </View>
                    }
                </View>
                {props.showDivider &&
                    <Divider />
                }
            </>
        )
    }

    if(path != null) {

        let resetStack = false;
        let resetStackConfig = {};

        if(helper.isWeb()) {
            resetStack = true;
        } else {
            const navigationState = (route.name == 'Dashboard' ? navigation.getParent().getParent().getState().routes : navigation.getParent().getState().routes);

            navigationState.forEach(function(item) {
                if(item.name == 'NotificationsStackNavigator' && helper.hasValue(item.state)) {
                    resetStack = true;
                }
            });
        }

        if(resetStack) {
            resetStackConfig = {
                index: 0,
                routes: [
                    {
                        name: 'LoadDetails_Notifications',
                        params: {
                            loadId: props.loadId,
                        }
                    },
                ],
            }
        }

        return (
            <CustomLink styleProps={styles.customLink} to={path} resetStack={resetStack} resetStackConfig={resetStackConfig} >
                {renderNotificationBody()}
            </CustomLink>
        )
    } else {
        return (
            <>
                {renderNotificationBody()}
            </>
        )
    }

}

const themedStyles = StyleService.create({
    customLink: {
        width: '100%'
    },
    container: {
        marginHorizontal: 5,
        marginVertical: 10,
    },
    firstItem: {
        // first item styling here...
    },
    lastItem: {
        // last item styling here...
    },
    header: {
        flexDirection: "row",
        marginLeft: 5,
    },
    headerColumnOne: {
        marginRight: 5,
        justifyContent: "center",
        alignItems: "center",
    },
    imageView: {
        backgroundColor: "color-basic-100",
        borderWidth: 1,
        borderColor: "color-basic-400",
        borderRadius: 2,
        padding: 5,
        justifyContent: "center",
        alignItems: "center",
        width: 40,
        height: 40,
    },
    image: {
        width: 30,
        height: 30,
    },
    headerColumnTwo: {
        justifyContent: "center",
    },
    nameView: {
        flexWrap: "wrap",
        flexDirection: "row",
        alignItems: "center",
        paddingBottom: 3,
    },
    body: {
        paddingHorizontal: 5,
        paddingTop: 7,
        paddingRight: 40,
    },
    date: {
        paddingBottom: 3,
        fontSize: 13,
    },
    message: {

    },
    actionView: {
        position: "absolute",
        right: 5,
        top: "50%",
        marginTop: -10
    },
    forwardIcon: {
        color: "color-primary-500",
        size: 20,
    },
    badgeView: {
        position: "absolute",
        top: 10,
        right: 10,
    },
    icon: {
        size: 14,
        color: "color-basic-600"
    }
});
