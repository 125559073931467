import React, { useContext, useState, useEffect } from 'react';
import { Image, Pressable, ScrollView, View } from 'react-native';
import { SafeAreaView } from 'react-native-safe-area-context';
import { Button, Layout, StyleService, Text, useStyleSheet } from '@ui-kitten/components';
import { AuthContext } from 'components/context';
import { ItemAttribute, Section, Header } from 'components/layout';
import { Badge, Loader } from 'components/utility';
import * as Constants from 'config/Constants';
import * as helper from 'utility/Helper';
import * as Linking from 'expo-linking';
import * as api from 'api/SleekTechnologiesApi';
import getEnvVars from 'root/environment';

export default function UserDetails({ route, navigation }) {

    const styles = useStyleSheet(themedStyles);
    const { signOut } = useContext(AuthContext);
    const { sleekApiUrl } = getEnvVars();
    const [isLoading, setLoading] = useState(true);
    const [userDetails, setUserDetails] = useState([]);
    const [roleAttributes, setRoleAttributes] = useState([]);
    const [breadcrumbs, setBreadcrumbs] = useState([]);

    useEffect(() => {
        api.getCarrierUserDetails(route.params.userId)
            .then(function(response){
                if(helper.hasValue(response.data.msg) && response.data.msg == "Token Not Found or Expired") {
                    signOut();
                }
                if(response.data.result == "success") {
                    setUserDetails(response.data.carrierUserDetails);
                    setBreadcrumbs(
                        [
                            {
                                label: 'Dashboard',
                                path: '/dashboard'
                            },
                            {
                                label: 'Manage Users',
                                path: '/manage-users'
                            },
                            {
                                label: response.data.carrierUserDetails.name,
                                path: ''
                            }
                        ]
                    );
                    let roleData = [
                        {
                            name: "Admin",
                            value: (response.data.carrierUserDetails.roles.includes("admin") ? 'Yes' : 'No'),
                            display: true,
                        },
                        {
                            name: "Manager",
                            value: (response.data.carrierUserDetails.roles.includes("manager") ? 'Yes' : 'No'),
                            display: true,
                        },
                        {
                            name: "Driver",
                            value: (response.data.carrierUserDetails.roles.includes("driver") ? 'Yes' : 'No'),
                            display: true,
                        },
                    ];
                    setRoleAttributes(roleData);
                    setLoading(false);
                } else if(response.data.result == "error") {
                    throw {
                        type: "custom",
                        message: response.data.msg
                    }
                }
            })
            .catch(function(error){
                setLoading(false);
                helper.handleCatchError(error);
            });
    }, []);

    const renderAttributes = (attributes) => {
        return (
            attributes.map((item, index) => (
                <ItemAttribute
                    {...item}
                    key={index}
                    isFirstItem={index == 0 ? true : false}
                    isLastItem={index + 1 == attributes.length ? true : false}
                />
            ))
        )
    }

    const getPageActions = () => {
        return (
            <Button appearance="outline" size="small" onPress={() => navigation.navigate('EditUser', { userId: userDetails.id })}>Edit User</Button>
        )
    }

    const UserDetailsView = () => {
        return (
            <Layout style={styles.layout} level="3">
                <View style={styles.personalInformationView}>
                    <Image
                        source={{
                            uri: sleekApiUrl+userDetails.userPhoto,
                            headers: Constants.RESOURCE_HEADERS
                        }}
                        style={{width: 100, height: 100, borderRadius: 50}}
                    />
                    <Text style={styles.name} category="h6">{userDetails.name}</Text>
                    {helper.hasValue(userDetails.phone) &&
                        <Pressable onPress={() => Linking.openURL(`tel:${userDetails.phone}`)}>
                            <Text style={styles.phone} category="s1" status="primary">{userDetails.phone}</Text>
                        </Pressable>
                    }
                    {helper.hasValue(userDetails.email) &&
                        <Pressable onPress={() => Linking.openURL(`mailto:${userDetails.email}`)}>
                            <Text style={styles.email} category="s1" status="primary">{userDetails.email}</Text>
                        </Pressable>
                    }
                    <View style={styles.status}>
                        {userDetails.isActive == "Yes" &&
                            <Badge label="Active" type="success" />
                        }
                        {userDetails.isActive == "No" &&
                            <Badge label="Inactive" type="danger" />
                        }
                    </View>
                </View>
                <Section title="Roles" isFirstItem={true} isLastItem={true} renderBody={renderAttributes(roleAttributes)} />
            </Layout>
        )
    }

    if(isLoading) {
        return (
            <Loader loadingText="Getting User Data" />
        )
    } else {
        return (
            helper.isWeb() ? (
                <View style={styles.webView}>
                    <Header breadcrumbs={breadcrumbs} title="Profile Information" subtitle={'Updated on ' + userDetails.updatedAt} pageActions={getPageActions()} />
                    <UserDetailsView />
                </View>
            ) : (
                <SafeAreaView style={styles.safeAreaView} edges={['top', 'left', 'right']}>
                    <Header activeScreen="UserDetails" title="Profile Information" subtitle={'Updated on ' + userDetails.updatedAt} userDetails={userDetails}/>
                    <ScrollView>
                        <UserDetailsView />
                    </ScrollView>
                </SafeAreaView>
            )
        )
    }
}

const themedStyles = StyleService.create({
    // global styles
    layout: {
        flex: 1,
        paddingBottom: 30,
    },
    personalInformationView: {
        backgroundColor: "color-basic-100",
        alignItems: "center",
        paddingTop: 40,
        paddingBottom: 30,
        marginBottom: 8,
        borderBottomLeftRadius: 10,
        borderBottomRightRadius: 10,
        shadowColor: "shadow-color",
        shadowOffset: {
            width: 0,
            height: 2,
        },
        shadowOpacity: .2,
        shadowRadius: 2,
        marginHorizontal: helper.isWeb() ? 10 : 0,
        marginTop: helper.isWeb() ? 10 : 0,
        borderTopLeftRadius: helper.isWeb() ? 10 : 0,
        borderTopRightRadius: helper.isWeb() ? 10 : 0,
    },
    name: {
        marginTop: 15,
    },
    phone: {
        marginTop: 5,
    },
    email: {
        marginTop: 5
    },
    status: {
        marginTop: 10
    },
    // native specific styles
    safeAreaView: {
        backgroundColor: "safe-area-view-background",
        flex: 1,
    },
    // web specific styles
    webView: {
        flex: 1,
    },
});
