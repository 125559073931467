import React from 'react';
import { Pressable, View } from 'react-native';
import { Divider, StyleService, Text, useStyleSheet } from '@ui-kitten/components';
import { decode } from 'html-entities';
import * as helper from 'utility/Helper';
import * as Linking from 'expo-linking';

export const LoadAttribute = (props) => {

    /*
        props:
        - isFirstItem
        - isLastItem
        - link
        - name
        - nameFlex
        - value
        - valueFlex
        - display
    */

    const styles = useStyleSheet(themedStyles);
    let nameFlex = {
        flex: props.nameFlex !== undefined ? props.nameFlex : 1
    }
    let valueFlex = {
        flex: props.valueFlex !== undefined ? props.valueFlex : 1,
        textAlign: "right",
        fontStyle: helper.hasValue(props.value) ? "normal" : "italic",
    }

    if(!props.display) {
        return (
            null
        )
    } else {
        return (
            <View style={helper.getContainerStyles(props, styles)}>
                <Text style={nameFlex} category="s2">{props.name}</Text>
                {helper.hasValue(props.link) && helper.hasValue(props.value) ? (
                    <Pressable onPress={() => Linking.openURL(props.link)}>
                        <Text style={valueFlex} category="p2" status="primary">{decode(props.value)}</Text>
                    </Pressable>
                ) : (
                    <Text style={valueFlex} category="p2" appearance={helper.hasValue(props.value) ? "default" : "hint"}>{helper.hasValue(props.value) ? decode(props.value) : "Not Provided"}</Text>
                )}
            </View>
        )
    }

}

const themedStyles = StyleService.create({
    container: {
        borderBottomColor: "border-basic-color-3",
        borderBottomWidth: 1,
        flex: 1,
        flexDirection: "row",
        paddingVertical: 10,
    },
    firstItem: {
        paddingTop: 2,
    },
    lastItem: {
        borderBottomWidth: 0,
        paddingBottom: 0,
    },
});
