import React from 'react';
import { View } from 'react-native';
import { Divider, StyleService, Text, useStyleSheet } from '@ui-kitten/components';
import { TextIcon } from 'components/layout';
import * as helper from 'utility/Helper';

export const ShipperMessage = (props) => {

    /*
        props:
        - date
        - dateFull
        - isFirstItem
        - isLastItem
        - message
    */

    const styles = useStyleSheet(themedStyles);

    return (
        <View style={helper.getContainerStyles(props, styles)}>
            <View style={styles.messageView}>
                <Text style={styles.title} category="s2">Message</Text>
                <TextIcon iconName="calendar-alt" iconWeight="far" iconSize={styles.icon.size} iconColor={styles.icon.color} text={'Sent on '+props.date} textCategory="c2" textAppearance="hint" />
                <Text style={styles.message} appearance="hint" category="p1">{props.message}</Text>
            </View>
        </View>
    )

}

const themedStyles = StyleService.create({
    container: {
        borderBottomColor: "border-basic-color-3",
        borderBottomWidth: 1,
        flex: 1,
        paddingVertical: 10,
    },
    firstItem: {
        paddingTop: 2,
    },
    lastItem: {
        borderBottomWidth: 0,
        paddingBottom: 0,
    },
    message: {
        paddingTop: 8,
        paddingBottom: 2
    },
    title: {
        marginBottom: 3,
    },
    icon: {
        size: 14,
        color: "color-basic-600"
    }
});
