import React, { useContext, useState } from 'react';
import { ScrollView, View } from 'react-native';
import { SafeAreaView } from 'react-native-safe-area-context';
import { useFocusEffect, useLinkTo } from "@react-navigation/native";
import { Avatar, Layout, StyleService, Text, useStyleSheet } from '@ui-kitten/components';
import { AppDataContext, AuthContext } from 'components/context';
import { Header, Section } from 'components/layout';
import { CustomLink, EmptyDataSet, Loader } from 'components/utility';
import { LoadSummary, MyActiveLoadSummary, Payment } from 'components/loads';
import { NotificationItem } from 'components/notifications';
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome';
import * as Constants from 'config/Constants';
import * as helper from 'utility/Helper';
import * as api from 'api/SleekTechnologiesApi';
import getEnvVars from 'root/environment';

export default function Dashboard({navigation}) {

    const styles = useStyleSheet(themedStyles);
    const { signOut } = useContext(AuthContext);
    const { appData } = useContext(AppDataContext);
    const { sleekApiUrl } = getEnvVars();
    const [dashboardData, setDashboardData] = useState();
    const [isLoading, setLoading] = useState(true);
    const linkTo = useLinkTo();
    const flexBasis = checkForRoles(['admin', 'manager']) ? "32%" : "49%";
    const profilePic = checkForRoles(['admin', 'manager']) ? sleekApiUrl+appData.carrierData.carrierLogo : sleekApiUrl+appData.carrierUserData.userPhoto;

    const breadcrumbs = [
        {
            label: 'Dashboard',
            path: ''
        }
    ]

    const getDashboardData = () => {
                if(!isLoading) {
            setLoading(true);
        }
        api.getDashboardData()
            .then(function(response){
                helper.logEvent({ category: 'API Response', message: 'getDashboardData()', data: { response: response.data } });
                if(helper.hasValue(response.data.msg) && response.data.msg == "Token Not Found or Expired") {
                    signOut();
                }
                if(response.data.result == "success") {
                    setDashboardData(response.data.dashboardData);
                    setLoading(false);
                } else if(response.data.result == "error") {
                    throw {
                        type: "custom",
                        message: response.data.msg
                    }
                }
            })
            .catch(function(error){
                setLoading(false);
                helper.handleCatchError(error);
            });
    }

    useFocusEffect(
        React.useCallback(() => {
            getDashboardData();
        }, [])
    );

    const renderSubtitle = (type) => {
        
        let subtitle;

        if(type == 'my active load') {
            if(dashboardData.activeLoadCount > 0) {
                subtitle = 'Load # ' + dashboardData.activeLoads[0].sleekFleetLoadId;
            } else {
                subtitle = null;
            }
        } else {
            let loadCount = type == 'active' ? dashboardData.activeLoadCount : dashboardData.upcomingLoadCount;
            let loadRevenue = type == 'active' ? dashboardData.activeLoadRevenue : dashboardData.upcomingLoadRevenue;

            if(checkForRoles(['admin', 'manager'])) {
                subtitle = loadCount + (loadCount == 1 ? ' Load' : ' Loads') + ' \u2022 $' + loadRevenue + ' in Revenue';
            } else {
                subtitle = loadCount + (loadCount == 1 ? ' Load' : ' Loads');
            }
        }

        return subtitle;

    }

    const renderHeaderRight = (type) => {
        
        let resetStack = false;
        let resetStackConfig = {};

        if(helper.hasValue(dashboardData.myActiveLoad)) {
            if(helper.isWeb()) {
                resetStack = true;
            } else {
                const navigationState = navigation.getParent().getParent().getState().routes;
                navigationState.forEach(function(item) {
                    if(item.name == 'LoadDetailsStackNavigator' && helper.hasValue(item.state)) {
                        resetStack = true;
                    }
                });
            }

            if(resetStack) {
                resetStackConfig = {
                    index: 0,
                    routes: [
                        {
                            name: 'LoadDetails_Generic',
                            params: {
                                loadId: dashboardData.activeLoads[0].sleekFleetLoadId,
                            }
                        },
                    ],
                }
            }
        }

        if(type == 'my active load') {
            if(helper.hasValue(dashboardData.myActiveLoad)) {
                return (
                    <CustomLink to={'/load-details/'+dashboardData.activeLoads[0].sleekFleetLoadId} resetStack={resetStack} resetStackConfig={resetStackConfig}>
                        <View style={styles.viewAllView}>
                            <Text style={styles.viewAll} category="s2" status="primary">View Load</Text>
                            <FontAwesomeIcon style={styles.forwardIcon} icon={['fal', 'angle-right']} size={styles.forwardIcon.size} color={styles.forwardIcon.color} />
                        </View>
                    </CustomLink>
                )
            } else {
                return null;
            }
        } else {
            let loads = type == 'active' ? dashboardData.activeLoads : dashboardData.upcomingLoads;

            if(loads.length > 0) {
                return (
                    <CustomLink to={'/booked-loads/'+type}>
                        <View style={styles.viewAllView}>
                            <Text style={styles.viewAll} category="s2" status="primary">View All</Text>
                            <FontAwesomeIcon style={styles.forwardIcon} icon={['fal', 'angle-right']} size={styles.forwardIcon.size} color={styles.forwardIcon.color} />
                        </View>
                    </CustomLink>
                )
            } else {
                return null;
            }
        }

    }

    const renderLoads = (type) => {
        
        let loadsList = type == 'active' ? dashboardData.activeLoads : dashboardData.upcomingLoads;
        let loads;

        if(loadsList.length > 3) {
            loads = loadsList.slice(0,3);
        } else {
            loads = loadsList;
        }

        if(loads.length > 0) {
            return (
                loads.map((item, index) => (
                    <LoadSummary
                        {...item}
                        key={index}
                        loadSummaryType={type}
                        showDivider={index + 1 < loads.length ? true : false}
                    />
                ))
            )
        } else {
            if(checkForRoles(['manager'])) {
                return (
                    <EmptyDataSet primaryMessage={type == 'active' ? "No Active Loads" : "No Upcoming Loads"} secondaryMessage="Find Your Next Load Now" btnText="View Available Loads" btnAppearance="outline" btnSize="small" btnAction={() => navigation.navigate('FindALoadStackNavigator')}/>
                )
            } else {
                return (
                    <EmptyDataSet primaryMessage={type == 'active' ? "No Active Loads" : "No Upcoming Loads"} secondaryMessage={checkForRoles(['driver']) ? 'Waiting on Load Assignment from Carrier' : ''}/>
                )
            }

        }

    }

    const renderMyActiveLoad = () => {
        if(helper.hasValue(dashboardData.myActiveLoad)) {
            let activeLoad = dashboardData.myActiveLoad;
            return (
                <MyActiveLoadSummary {...activeLoad} />
            )
        } else {
            return (
                <EmptyDataSet primaryMessage="No Active Load" />
            )
        }
    }

    const renderPaymentActivityHeaderRight = () => {
        
        let payments = dashboardData.payments;

        if(payments.length > 0) {
            return (
                <CustomLink to={'/reports/payment-history'}>
                    <View style={styles.viewAllView}>
                        <Text style={styles.viewAll} category="s2" status="primary">View Payments</Text>
                        <FontAwesomeIcon style={styles.forwardIcon} icon={['fal', 'angle-right']} size={styles.forwardIcon.size} color={styles.forwardIcon.color} />
                    </View>
                </CustomLink>
            )
        } else {
            return null;
        }
    }

    const renderPaymentActivity = () => {
                let payments = dashboardData.payments;
        if(payments.length > 0) {
            return (
                payments.map((item, index) => (
                    <Payment
                        {...item}
                        key={index}
                        isFirstItem={index == 0 ? true : false}
                        isLastItem={index + 1 == payments.length ? true : false}
                    />
                ))
            )
        } else {
            return (
                <EmptyDataSet primaryMessage="No Payments" />
            )
        }
    }

    const renderNotificationHeaderRight = () => {
                let notifications = dashboardData.notifications.notifications;
        if(notifications.length > 0) {
            return (
                <CustomLink to={'/notifications'}>
                    <View style={styles.viewAllView}>
                        <Text style={styles.viewAll} category="s2" status="primary">View All</Text>
                        <FontAwesomeIcon style={styles.forwardIcon} icon={['fal', 'angle-right']} size={styles.forwardIcon.size} color={styles.forwardIcon.color} />
                    </View>
                </CustomLink>
            )
        } else {
            return null;
        }
    }

    const renderNotifications = () => {
                let notifications = dashboardData.notifications.notifications;
        if(notifications.length > 0) {
            return (
                notifications.map((item, index) => (
                    <NotificationItem
                        {...item}
                        key={index}
                        showDivider={index + 1 < notifications.length ? true : false}
                    />
                ))
            )
        } else {
            return (
                <EmptyDataSet primaryMessage="No Notifications" />
            )
        }
    }

    function checkForRoles(roles) {
                let hasRole = false;
        roles.forEach(role => {
            if(appData.carrierUserData.roles.includes(role)) {
                hasRole = true;
            }
        });

        return hasRole;
    }

    const renderDashboardView = () => {
                return (
            <Layout style={styles.layout} level="3">
                <View style={styles.performanceView}>
                    <View style={styles.performanceHeader}>
                        <View style={styles.avatarView}>
                            <Avatar
                                size="giant"
                                source={{
                                    uri: profilePic,
                                    headers: Constants.RESOURCE_HEADERS
                                }}
                            />
                        </View>
                        <View style={styles.welcomeView}>
                            {checkForRoles(['admin', 'manager']) ? (
                                <Text style={styles.carrierName} category="h6">{appData.carrierData.carrierName}</Text>
                            ) : (
                                <Text style={styles.carrierName} category="h6">{appData.carrierUserData.firstName} {appData.carrierUserData.lastName}</Text>
                            )}
                            <Text category="s2" appearance="hint">Performance Snapshot &bull; Lifetime Stats</Text>
                        </View>
                    </View>
                    <View style={styles.performanceBody}>
                        <View style={[styles.statView, { flexBasis: flexBasis}]}>
                            <Text style={styles.statLabel} category="s2" appearance="hint">Loads</Text>
                            <Text style={styles.statValue} category="h5">{dashboardData.carrierPerformance.loads}</Text>
                        </View>
                        <View style={[styles.statView, { flexBasis: flexBasis}]}>
                            <Text style={styles.statLabel} category="s2" appearance="hint">Miles Driven</Text>
                            <Text style={styles.statValue} category="h5">{dashboardData.carrierPerformance.miles}</Text>
                        </View>
                        {checkForRoles(['admin', 'manager']) &&
                            <View style={[styles.statView, { flexBasis: flexBasis}]}>
                                <Text style={styles.statLabel} category="s2" appearance="hint">Revenue</Text>
                                <Text style={styles.statValue} category="h5">${dashboardData.carrierPerformance.revenue}</Text>
                            </View>
                        }
                    </View>
                </View>
                <View style={helper.isDesktopWeb() ? styles.main : {}}>
                    <View style={helper.isDesktopWeb() ? styles.columnOne : {}}>
                        {checkForRoles(['driver']) &&
                            <Section title="My Active Load" isFirstItem={true} headerRight={renderHeaderRight('my active load')} renderBody={renderMyActiveLoad()}/>
                        }
                        {checkForRoles(['admin', 'manager']) &&
                            <Section title="Active Loads" subtitle={renderSubtitle('active')} isFirstItem={checkForRoles(['driver']) ? false : true} noBodyPadding={dashboardData.activeLoads.length > 0 ? true : false} headerRight={renderHeaderRight('active')} renderBody={renderLoads('active')}/>
                        }
                        <Section title="Upcoming Loads" subtitle={renderSubtitle('upcoming')} noBodyPadding={dashboardData.upcomingLoads.length > 0 ? true : false} headerRight={renderHeaderRight('upcoming')} renderBody={renderLoads('upcoming')}/>
                        {checkForRoles(['admin', 'manager']) &&
                            <Section title="Recent Payments" headerRight={renderPaymentActivityHeaderRight()} headerRightFlex={3} renderBody={renderPaymentActivity()}/>
                        }
                    </View>
                    <View style={helper.isDesktopWeb() ? styles.columnTwo : {}}>
                        <Section title="Recent Notifications" isLastItem={true} noBodyPadding={true} headerRight={renderNotificationHeaderRight()} renderBody={renderNotifications()}/>
                    </View>
                </View>
            </Layout>
        )
    }

    if(isLoading) {
        return (
            <Loader loadingText="Loading Dashboard Data"/>
        )
    } else {
                return (
            helper.isWeb() ? (
                <View style={styles.webView}>
                    <Header breadcrumbs={breadcrumbs} title="Dashboard" subtitle={'Welcome ' + appData.carrierUserData.firstName + '!'} />
                    <ScrollView>
                        {renderDashboardView()}
                    </ScrollView>
                </View>
            ) : (
                <SafeAreaView style={styles.safeAreaView} edges={['top', 'left', 'right']}>
                    <Header activeScreen="Dashboard" />
                    <ScrollView>
                        {renderDashboardView()}
                    </ScrollView>
                </SafeAreaView>
            )
        )
    }
}

const themedStyles = StyleService.create({
    // global styles
    layout: {
        flex: 1,
        paddingBottom: 4
    },
    performanceView: {
        paddingHorizontal: 15,
        paddingVertical: 20,
        backgroundColor: "color-basic-100",
        marginBottom: 6,
        borderBottomLeftRadius: 10,
        borderBottomRightRadius: 10,
        shadowColor: "shadow-color",
        shadowOffset: {
            width: 0,
            height: 2,
        },
        shadowOpacity: .2,
        shadowRadius: 2,
        marginHorizontal: helper.isWeb() ? 10 : 0,
        marginTop: helper.isWeb() ? 10 : 0,
        borderTopLeftRadius: helper.isWeb() ? 10 : 0,
        borderTopRightRadius: helper.isWeb() ? 10 : 0,
    },
    performanceHeader: {
        marginBottom: 5,
        flexDirection: "row"
    },
    avatarView: {
        justifyContent: "center",
        marginRight: 10,
    },
    welcomeView: {
        justifyContent: "center"
    },
    carrierName: {
        marginBottom: 3,
    },
    performanceBody: {
        flexDirection: "row",
        flexWrap: "wrap",
        justifyContent: "space-between",
        marginTop: 10,
    },
    statView: {
        padding: 10,
        backgroundColor: "color-basic-300",
        borderRadius: 4,
    },
    statValue: {
        fontWeight: '400'
    },
    statLabel: {

    },
    viewAllView: {
        flexDirection: "row",
        flexWrap: "wrap",
        justifyContent: "flex-end",
        alignItems: "center"
    },
    forwardIcon: {
        color: "color-primary-500",
        size: 18,
    },
    // native specific styles
    safeAreaView: {
        backgroundColor: "safe-area-view-background",
        flex: 1,
    },
    // web specific styles
    webView: {
        flex: 1,
    },
    main: {
        flexDirection: "row"
    },
    columnOne: {
        flex: 7
    },
    columnTwo: {
        flex: 5
    }
});
