import React from 'react';
import { Image, View } from 'react-native';
import { Layout, StyleService, Text, useStyleSheet } from '@ui-kitten/components';
import { TextIcon } from 'components/layout';
import * as Constants from 'config/Constants';
import * as helper from 'utility/Helper';
import getEnvVars from 'root/environment';

export const ActivityItem = (props) => {

    /*
        props:
        - creator
        - date
        - details
        - imageType
        - picture
        - time
        - type
    */

    const styles = useStyleSheet(themedStyles);
    const { sleekApiUrl } = getEnvVars();
    const profileUri = sleekApiUrl+props.picture;
    const cleanedDetails = props.details.replace(/<span class="subject">|<\/span>/g, '');
    const imageStyle = props.imageType == 'Carrier User' ? styles.avatar : styles.image;

    if(!helper.checkForRoles(['manager'])) {
        if(props.type == 'Book Now' || props.type == 'Payment') {
            return null;
        }
    }

    return (

        <View style={styles.container}>
            <View style={styles.columnOne}>
                <Image
                    source={{
                        uri: profileUri,
                        headers: Constants.RESOURCE_HEADERS
                    }}
                    style={imageStyle}
                    resizeMode="contain"
                />
            </View>
            <View style={styles.columnTwo}>
                <TextIcon iconName="calendar-alt" iconWeight="far" iconSize={styles.icon.size} iconColor={styles.icon.color} text={props.date+' at '+props.time} textCategory="c2" textAppearance="hint" />
                <Text style={styles.details} category="p1">{cleanedDetails}</Text>
            </View>
        </View>
    );
};

const themedStyles = StyleService.create({
    container: {
        flex: 1,
        flexDirection: "row",
        paddingHorizontal: 5,
    },
    columnOne: {
        width: 60,
        justifyContent: "center",
        alignItems: "center",
        paddingRight: 5,
    },
    image: {
        width: 50,
        height: 50,
    },
    avatar: {
        width: 50,
        height: 50,
        borderRadius: 25,
    },
    details: {
        marginTop: 3,
    },
    columnTwo: {
        flex: 10,
        paddingLeft: 5,
    },
    icon: {
        size: 14,
        color: "color-basic-600"
    }
});
