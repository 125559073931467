import React, {useState} from 'react';
import { View } from 'react-native';
import { Button, Layout, StyleService, Text, useStyleSheet } from '@ui-kitten/components';
import { Formik, Field } from 'formik';
import { DatepickerField, InputField, OtherAutocompleteField, SelectField } from 'components/forms';
import { Loader } from 'components/utility';
import * as yup from 'yup';
import * as helper from 'utility/Helper';
import * as api from 'api/SleekTechnologiesApi';
import * as Constants from 'config/Constants';
import moment from 'moment';

export default function LoadHistoryFilters(props) {

    const defaultLoadHistoryFilters = props.defaultLoadHistoryFilters;
    const defaultLoadHistoryFilterDisplayValues = props.defaultLoadHistoryFilterDisplayValues;
    const loadHistoryFilters = props.loadHistoryFilters;
    const loadHistoryFilterDisplayValues = props.loadHistoryFilterDisplayValues;
    const [initialValues, setInitialValues] = useState(loadHistoryFilterDisplayValues);

    const styles = useStyleSheet(themedStyles);
    const [resettingFilters, setResettingFilters] = useState(false);

    const validationSchema = yup.object().shape({
        shipper: yup.lazy(val => (
            Array.isArray(val) ? yup.array().min(1, 'Please select at least 1 Shipper') : yup.string().required('Please select at least 1 Shipper'))
        ),
        loadType: yup.lazy(val => (
            Array.isArray(val) ? yup.array().min(1, 'Please select at least 1 Load Type') : yup.string().required('Please select at least 1 Load Type'))
        ),
        loadStatus: yup.lazy(val => (
            Array.isArray(val) ? yup.array().min(1, 'Please select at least 1 Load Status') : yup.string().required('Please select at least 1 Load Status'))
        )
    });

    const driverOptions = helper.getDriverList();
    const shipperOptions = helper.getShipperLoadList();
    const shipperOptionIds = helper.getShipperLoadList('string');
    const loadTypeOptions = Constants.LOAD_TYPE_OPTIONS;
    const loadStatusOptions = Constants.LOAD_STATUS_OPTIONS;
    const originOptions = helper.getOriginOptions();
    const originRadiusOptions = Constants.MILE_RADIUS_OPTIONS;
    const destinationOptions = helper.getOriginOptions();
    const destinationRadiusOptions = Constants.MILE_RADIUS_OPTIONS;
    const deliveryDateOptions = Constants.DELIVERY_DATE_OPTIONS;
    const minDate = moment().subtract(365, 'days');

    const loadNumberAutocomplete = [];
    const cityStateAutocomplete = [];

    const applyFilters = (data) => {

        let updatedLoadHistoryFilters = {
            loadNumber: data.loadNumber,
            driver: data.driver,
            shipper: data.shipper,
            loadType: data.loadType,
            loadStatus: data.loadStatus,
            origin: (data.origin == 'Search By City & State' && typeof data.originSearch !== 'undefined' ? data.originSearch : data.origin),
            originSearch: data.originSearch,
            originRadius: data.originRadius,
            destination: (data.destination == 'Search By City & State' && typeof data.destinationSearch !== 'undefined' ? data.destinationSearch : data.destination),
            destinationSearch: data.destinationSearch,
            destinationRadius: (typeof data.destinationRadius !== 'undefined' ? data.destinationRadius : ''),
            deliveryDate: data.deliveryDate,
            deliveryDateFrom: data.deliveryDateFrom,
            deliveryDateTo: data.deliveryDateTo,
            sortBy: "date completed",
            offset: 0
        };

        let updatedLoadHistoryFilterDisplayValues = {
            loadNumber: data.loadNumber,
            driver: data.driver,
            shipper: (data.shipper == 'all' ? shipperOptionIds : data.shipper),
            loadType: (data.loadType == 'all' ? Constants.LOAD_TYPE_VALUES : data.loadType),
            loadStatus: (data.loadStatus == 'all' ? Constants.LOAD_STATUS_VALUES : data.loadStatus),
            origin: (data.origin == 'Search By City & State' && typeof data.originSearch !== 'undefined' ? 'Search By City & State' : data.origin),
            originSearch: data.originSearch,
            originRadius: data.originRadius,
            destination: (data.destination == 'Search By City & State' && typeof data.destinationSearch !== 'undefined' ? 'Search By City & State' : data.destination),
            destinationSearch: data.destinationSearch,
            destinationRadius: (typeof data.destinationRadius !== 'undefined' ? data.destinationRadius : ''),
            deliveryDate: data.deliveryDate,
            deliveryDateFrom: data.deliveryDateFrom,
            deliveryDateTo: data.deliveryDateTo,
            sortBy: "date completed",
            offset: 0
        };

        props.setLoading(true);
        props.setLoads([]);
        props.setLoadHistoryFilters(updatedLoadHistoryFilters);
        props.setLoadHistoryFilterDisplayValues(updatedLoadHistoryFilterDisplayValues);
        props.setModalVisible(false);
    };

    const resetFilters = () => {
        setResettingFilters(true);
        setInitialValues(defaultLoadHistoryFilterDisplayValues);
        setTimeout(() => {
            setResettingFilters(false);
        }, 100);
    }

    if(resettingFilters) {
        return (
            <Loader loadingText="Resetting Filters"/>
        )
    } else {
        return (
            <View style={styles.container}>
                <Formik
                    validationSchema={validationSchema}
                    initialValues={initialValues}
                    onSubmit={(values) => applyFilters(values)}
                >
                    {({ handleSubmit, isValid, values }) => (
                        <>
                            <Field
                                component={OtherAutocompleteField}
                                name="loadNumber"
                                label="Search by Load Number"
                                placeholder="Enter Load Number..."
                                options={loadNumberAutocomplete}
                                autoCompleteLookup="loadNumber"
                                defaultValue={loadHistoryFilters.loadNumber}
                            />
                            {helper.checkForRoles(['admin', 'manager']) &&
                                <Field
                                    component={SelectField}
                                    name="driver"
                                    label="Driver"
                                    options={driverOptions}
                                    multiSelect={false}
                                />
                            }
                            <Field
                                component={SelectField}
                                name="deliveryDate"
                                label="Delivery Date"
                                options={deliveryDateOptions}
                                multiSelect={false}
                            />
                            {values.deliveryDate == 'custom date range' &&
                                <View style={styles.deliveryDateView}>
                                    <Field
                                        style={styles.deliveryDateFrom}
                                        component={DatepickerField}
                                        name="deliveryDateFrom"
                                        label="Delivery Date (Start)"
                                        minDate={minDate}
                                    />
                                    <Field
                                        style={styles.deliveryDateTo}
                                        component={DatepickerField}
                                        name="deliveryDateTo"
                                        label="Delivery Date (End)"
                                        minDate={minDate}
                                    />
                                </View>
                            }
                            <Field
                                component={SelectField}
                                name="origin"
                                label="Origin"
                                options={originOptions}
                                multiSelect={false}
                            />
                            {values.origin == 'Search By City & State' &&
                                <Field
                                    component={OtherAutocompleteField}
                                    name="originSearch"
                                    placeholder="Enter City & State..."
                                    options={cityStateAutocomplete}
                                    autoCompleteLookup="location"
                                    defaultValue={loadHistoryFilters.originSearch}
                                />
                            }
                            {values.origin != 'all' &&
                                <Field
                                    component={SelectField}
                                    name="originRadius"
                                    label="Origin Radius"
                                    options={originRadiusOptions}
                                    multiSelect={false}
                                />
                            }
                            <Field
                                component={SelectField}
                                name="destination"
                                label="Destination"
                                options={destinationOptions}
                                multiSelect={false}
                            />
                            {values.destination == 'Search By City & State' &&
                                <Field
                                    component={OtherAutocompleteField}
                                    name="destinationSearch"
                                    placeholder="Enter City & State..."
                                    options={cityStateAutocomplete}
                                    autoCompleteLookup="location"
                                    defaultValue={loadHistoryFilters.destinationSearch}
                                />
                            }
                            {values.destination != 'all' &&
                                <Field
                                    component={SelectField}
                                    name="destinationRadius"
                                    label="Destination Radius"
                                    options={destinationRadiusOptions}
                                    multiSelect={false}
                                />
                            }
                            <Field
                                component={SelectField}
                                name="loadStatus"
                                label="Load Status"
                                options={loadStatusOptions}
                                multiSelect={true}
                                selectAllOverride={true}
                                selectAllSubmittedValue={'all'}
                                selectAllDisplayValue={'All Load Statuses'}
                            />
                            <Field
                                component={SelectField}
                                name="loadType"
                                label="Load Type"
                                options={loadTypeOptions}
                                multiSelect={true}
                                selectAllOverride={true}
                                selectAllSubmittedValue={'all'}
                                selectAllDisplayValue={'All Load Types'}
                            />
                            <Field
                                component={SelectField}
                                name="shipper"
                                label="Shipper"
                                options={shipperOptions}
                                multiSelect={true}
                                selectAllOverride={true}
                                selectAllSubmittedValue={'all'}
                                selectAllDisplayValue={'All Shippers'}
                            />

                            <Button style={styles.ctaButton} onPress={handleSubmit} disabled={!isValid}>Apply Filters</Button>
                            <Button style={styles.secondaryButton} appearance="ghost" onPress={() => resetFilters()}>Reset Filters</Button>
                        </>
                    )}
                </Formik>
            </View>
        )
    }

}

const themedStyles = StyleService.create({
    container: {
        paddingTop: 5,
    },
    deliveryDateView: {
        flexDirection: helper.isDesktopWeb() ? "column" : "row",
        marginBottom: 10,
    },
    deliveryDateFrom: {
        flex: 1,
        marginRight: helper.isDesktopWeb() ? 0 : 5,
        marginBottom: helper.isDesktopWeb() ? 10 : 0
    },
    deliveryDateTo: {
        flex: 1,
        marginLeft: helper.isDesktopWeb() ? 0 : 5
    },
    ctaButton: {
        marginTop: 10,
    },
    secondaryButton: {
        marginVertical: 10,
    },
});
