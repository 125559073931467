import React, { useState, useEffect } from 'react';
import { View } from 'react-native';
import { Datepicker, StyleService, Text, useStyleSheet } from '@ui-kitten/components';
import { MomentDateService } from '@ui-kitten/moment';
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome';
import moment from 'moment';

export const DatepickerField = (props) => {

    const {
        field: { name, onBlur, onChange, value },
        form: { errors, touched, setFieldTouched, setFieldValue },
        ...inputProps
    } = props;

    const dateService = new MomentDateService();
    const hasError = errors[name] && touched[name];
    const [date, setDate] = useState(moment(props.field.value, "MM/DD/YYYY"));
    const styles = useStyleSheet(themedStyles);

    useEffect(() => {
        const dateFormatted = moment(date).format("MM/DD/YYYY");
        setFieldValue(name, dateFormatted);
    }, [date]);

    return (
        <>
            <Datepicker
                style={styles.datepickerInput}
                size="large"
                status="basic"
                date={date}
                onSelect={nextDate => {
                   setDate(nextDate);
                   setFieldTouched(name);
                }}
                dateService={dateService}
                accessoryRight={<FontAwesomeIcon icon={['fal', 'calendar']} />}
                min={props.minDate}
                {...inputProps}
            />
            {hasError &&
                <View style={styles.errorView}>
                    <Text category="p2" status="danger">{errors[name]}</Text>
                </View>
            }
        </>
    );

}

const themedStyles = StyleService.create({
    datepickerInput: {
        marginBottom: 10,
    },
    errorView: {
        marginTop: -3,
        marginLeft: 10,
        marginBottom: 10,
    },
    errorInput: {
        borderColor: "color-danger-500"
    }
});
