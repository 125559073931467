import React from 'react';
import { createNativeStackNavigator } from '@react-navigation/native-stack';
import * as Constants from 'config/Constants';
import moment from 'moment';
import {
    BookedLoads,
    CarrierProfile,
    CarrierProfileEdit,
    Dashboard,
    Drivers,
    DriverDetails,
    EditUser,
    FindALoad,
    LoadDetails,
    ManageUsers,
    MyProfile,
    MyProfileEdit,
    Notifications,
    PreferredLocations,
    Privacy,
    Reports,
    ScreenNotFound,
    SignIn,
    Support,
    UserDetails,
} from 'screens';

// Auth Stack Navigator
const AuthStack = createNativeStackNavigator();
export const AuthStackNavigator = () => {
    return(
        <AuthStack.Navigator screenOptions={{ headerShown: false, contentStyle: { flex: 1 } }}>
            <AuthStack.Screen name="SignIn" component={SignIn} options={{ title: 'Sign In' }}/>
        </AuthStack.Navigator>
    )
};

// Notifications Stack Navigator
const NotificationsStack = createNativeStackNavigator();
export const NotificationsStackNavigator = () => {
    return(
        <NotificationsStack.Navigator screenOptions={{ headerShown: false, contentStyle: { flex: 1 } }}>
            <NotificationsStack.Screen name="Notifications" component={Notifications} options={{ title: 'Notifications' }}/>
            <NotificationsStack.Screen name="LoadDetails_Notifications" component={LoadDetails} options={{ title: 'Load Details' }}/>
        </NotificationsStack.Navigator>
    )
};

// Load Details Stack Navigator
const LoadDetailsStack = createNativeStackNavigator();
export const LoadDetailsStackNavigator = () => {
    return(
        <LoadDetailsStack.Navigator screenOptions={{ headerShown: false, contentStyle: { flex: 1 } }}>
            <LoadDetailsStack.Screen name="LoadDetails_Generic" component={LoadDetails} options={{ title: 'Load Details' }}/>
        </LoadDetailsStack.Navigator>
    )
};

// Dashboard Stack Navigator
const DashboardStack = createNativeStackNavigator();
export const DashboardStackNavigator = () => {
    return(
        <DashboardStack.Navigator screenOptions={{ headerShown: false, contentStyle: { flex: 1 } }}>
            <DashboardStack.Screen name="Dashboard" component={Dashboard} options={{ title: 'Dashboard' }}/>
        </DashboardStack.Navigator>
    )
};

// Find A Load Stack Navigator
const FindALoadStack = createNativeStackNavigator();
export const FindALoadStackNavigator = () => {
    return(
        <FindALoadStack.Navigator screenOptions={{ headerShown: false, contentStyle: { flex: 1 } }}>
            <FindALoadStack.Screen name="FindALoad" component={FindALoad} options={{ title: 'Find A Load' }}/>
            <FindALoadStack.Screen name="LoadDetails_FindALoad" component={LoadDetails} options={{ title: 'Load Details' }}/>
        </FindALoadStack.Navigator>
    )
};

// Booked Loads Stack Navigator
const BookedLoadsStack = createNativeStackNavigator();
export const BookedLoadsStackNavigator = () => {
    return(
        <BookedLoadsStack.Navigator screenOptions={{ headerShown: false, contentStyle: { flex: 1 } }}>
            <BookedLoadsStack.Screen name="BookedLoads" component={BookedLoads} options={{ title: 'Booked Loads' }}/>
            <BookedLoadsStack.Screen name="LoadDetails_BookedLoads" component={LoadDetails} options={{ title: 'Load Details' }}/>
        </BookedLoadsStack.Navigator>
    )
};

// Drivers Stack Navigator
const DriversStack = createNativeStackNavigator();
export const DriversStackNavigator = () => {
    return(
        <DriversStack.Navigator screenOptions={{ headerShown: false, contentStyle: { flex: 1 } }}>
            <DriversStack.Screen name="Drivers" component={Drivers} options={{ title: 'Drivers' }}/>
            <DriversStack.Screen name="DriverDetails" component={DriverDetails} options={{ title: 'Driver Details' }}/>
            <DriversStack.Screen name="LoadDetails_Drivers" component={LoadDetails} options={{ title: 'Load Details' }}/>
        </DriversStack.Navigator>
    )
};

// Reports Stack Navigator
const ReportsStack = createNativeStackNavigator();
export const ReportsStackNavigator = () => {
    return(
        <ReportsStack.Navigator screenOptions={{ headerShown: false, contentStyle: { flex: 1 } }}>
            <ReportsStack.Screen name="Reports" component={Reports} options={{ title: 'Reports' }}/>
            <ReportsStack.Screen name="LoadDetails_Reports" component={LoadDetails} options={{ title: 'Load Details' }}/>
        </ReportsStack.Navigator>
    )
};

// My Profile Stack Navigator
const MyProfileStack = createNativeStackNavigator();
export const MyProfileStackNavigator = () => {
    return(
        <MyProfileStack.Navigator screenOptions={{ headerShown: false, contentStyle: { flex: 1 } }}>
            <MyProfileStack.Screen name="MyProfile" component={MyProfile} options={{ title: 'My Profile' }}/>
            <MyProfileStack.Screen name="MyProfileEdit" component={MyProfileEdit} options={{ title: 'Edit My Profile' }}/>
        </MyProfileStack.Navigator>
    )
};

// Carrier Profile Stack Navigator
const CarrierProfileStack = createNativeStackNavigator();
export const CarrierProfileStackNavigator = () => {
    return(
        <CarrierProfileStack.Navigator screenOptions={{ headerShown: false, contentStyle: { flex: 1 } }}>
            <CarrierProfileStack.Screen name="CarrierProfile" component={CarrierProfile} options={{ title: 'Carrier Profile' }}/>
            <CarrierProfileStack.Screen name="CarrierProfileEdit" component={CarrierProfileEdit} options={{ title: 'Edit Carrier Profile' }}/>
        </CarrierProfileStack.Navigator>
    )
};

// Manage Users Stack Navigator
const ManageUsersStack = createNativeStackNavigator();
export const ManageUsersStackNavigator = () => {
    return(
        <ManageUsersStack.Navigator screenOptions={{ headerShown: false, contentStyle: { flex: 1 } }}>
            <ManageUsersStack.Screen name="ManageUsers" component={ManageUsers} options={{ title: 'Manage Users' }}/>
            <ManageUsersStack.Screen name="UserDetails" component={UserDetails} options={{ title: 'User Details' }}/>
            <ManageUsersStack.Screen name="EditUser" component={EditUser} options={{ title: 'Edit User' }}/>
        </ManageUsersStack.Navigator>
    )
};

// Preferred Locations Stack Navigator
const PreferredLocationsStack = createNativeStackNavigator();
export const PreferredLocationsStackNavigator = () => {
    return(
        <PreferredLocationsStack.Navigator screenOptions={{ headerShown: false, contentStyle: { flex: 1 } }}>
            <PreferredLocationsStack.Screen name="PreferredLocations" component={PreferredLocations} options={{ title: 'Preferred Locations' }}/>
        </PreferredLocationsStack.Navigator>
    )
};

// Support Stack Navigator
const SupportStack = createNativeStackNavigator();
export const SupportStackNavigator = () => {
    return(
        <SupportStack.Navigator screenOptions={{ headerShown: false, contentStyle: { flex: 1 } }}>
            <SupportStack.Screen name="Support" component={Support} options={{ title: 'Support' }}/>
        </SupportStack.Navigator>
    )
};

// Privacy Stack Navigator
const PrivacyStack = createNativeStackNavigator();
export const PrivacyStackNavigator = () => {
    return(
        <PrivacyStack.Navigator screenOptions={{ headerShown: false, contentStyle: { flex: 1 } }}>
            <PrivacyStack.Screen name="Privacy" component={Privacy} options={{ title: 'Privacy' }}/>
        </PrivacyStack.Navigator>
    )
};

// Error Stack Navigator
const ErrorStack = createNativeStackNavigator();
export const ErrorStackNavigator = () => {
    return(
        <ErrorStack.Navigator screenOptions={{ headerShown: false, contentStyle: { flex: 1 } }}>
            <ErrorStack.Screen name="ScreenNotFound" component={ScreenNotFound} options={{ title: 'Page Not Found' }}/>
        </ErrorStack.Navigator>
    )
};
