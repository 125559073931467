import React from 'react';
import { Image, View } from 'react-native';
import { Divider, StyleService, Text, useStyleSheet } from '@ui-kitten/components';
import { Badge } from 'components/utility';
import { TextIcon } from 'components/layout';
import * as Constants from 'config/Constants';
import * as helper from 'utility/Helper';
import getEnvVars from 'root/environment';

export const Payment = (props) => {

    /*
        props:
        - amount
        - authorizedBy
        - createdAt
        - createdAtFull
        - paymentDate
        - paymentDateFull
        - paymentId
        - paymentMethod
        - status
        - transactionId
        - updatedAt
        - updatedAtFull
    */

    const styles = useStyleSheet(themedStyles);
    const { sleekApiUrl } = getEnvVars();

    let logoUri;

    if(props.paymentMethod == 'PayPal') {
        logoUri = sleekApiUrl+'/images/app/logos/paypal-icon.jpg';
    } else if(props.paymentMethod == 'Triumph Pay') {
        logoUri = sleekApiUrl+'/images/app/logos/triumph-pay-icon.jpg';
    } else {
        logoUri = sleekApiUrl+'/images/app/logos/sleek-fleet.png';
    }

    return (
        <View style={helper.getContainerStyles(props, styles)}>
            <View style={styles.header}>
                <View style={styles.headerColumnOne}>
                    <View style={styles.imageView}>
                        <Image
                            source={{
                                uri: logoUri,
                                headers: Constants.RESOURCE_HEADERS
                            }}
                            style={styles.image}
                            resizeMode="contain"
                        />
                    </View>
                </View>
                <View style={styles.headerColumnTwo}>
                    <View style={styles.amountView}>
                        <Text style={styles.amount} category="s2">${props.amount} via {props.paymentMethod}</Text>
                        <Badge label={props.status} type="info"/>
                    </View>
                    <TextIcon style={styles.date} iconName="calendar-alt" iconWeight="far" iconSize={styles.icon.size} iconColor={styles.icon.color} text={props.status == 'Processed' ? 'Paid on ' + props.paymentDate : 'Submitted on ' + props.createdAt} textCategory="c2" textAppearance="hint" />
                </View>
            </View>
            <Text style={styles.transactionId} category="p1" appearance="hint">{props.paymentMethod} Transaction ID: {props.transactionId}</Text>
        </View>
    )

}

const themedStyles = StyleService.create({
    container: {
        borderBottomColor: "border-basic-color-3",
        borderBottomWidth: 1,
        flex: 1,
        paddingVertical: 10,
    },
    firstItem: {
        paddingTop: 2,
    },
    lastItem: {
        borderBottomWidth: 0,
        paddingBottom: 0,
    },
    header: {
        flexDirection: "row",
        flexWrap: "wrap"
    },
    headerColumnOne: {
        marginRight: 5,
        justifyContent: "center",
        alignItems: "center",
    },
    imageView: {
        backgroundColor: "color-basic-100",
        borderWidth: 1,
        borderColor: "color-basic-400",
        borderRadius: 2,
        padding: 5,
        justifyContent: "center",
        alignItems: "center",
        width: 40,
        height: 40,
    },
    image: {
        width: 30,
        height: 30,
    },
    headerColumnTwo: {
        justifyContent: "center",
    },
    amountView: {
        flexDirection: "row",
        flexWrap: "wrap",
        alignItems: "center",
        paddingBottom: 3,
    },
    amount: {

    },
    date: {
        paddingBottom: 2,
        fontSize: 13,
    },
    transactionId: {
        paddingTop: 7,
        paddingBottom: 2
    },
    icon: {
        size: 14,
        color: "color-basic-600"
    }
});
