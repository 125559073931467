export const PHONE_SUPPORT = "910-420-4295";
export const EMAIL_SUPPORT = "drivers@sleek-technologies.com";
export const EMAIL_LEGAL = "legal@sleekfleet.com";
export const GOOGLE_PLAY_APP_LINK = "https://play.google.com/store/apps/details?id=com.sleekfleet.sleek_carrier_portal&hl=en_US&gl=US";
export const APP_STORE_APP_LINK = "https://apps.apple.com/us/app/sleek-carrier-portal/id1609270541";
export const SLEEK_FLEET_HQ_LATITUDE = 42.196969;
export const SLEEK_FLEET_HQ_LONGITUDE = -88.093411;
export const LATITUDE_DELTA = 0.0922;
export const LONGITUDE_DELTA = 0.0421;
export const END_OF_LIST_THRESHOLD = .08;
export const IMAGE_UPLOAD_DEFAULT_WIDTH = 800;
export const IMAGE_UPLOAD_DEFAULT_HEIGHT = 800;
export const ROLLBAR_ACCESS_TOKEN = '61cb192cb1f5451a91b6abc8ff5cd2a1';
export const GOOGLE_API_KEY = 'AIzaSyC0EN9-uHoXdMbF4XWA-_uzDaNO4-V_IXk';
export const MOBILE_WEB_BREAKPOINT = 768;
export const RESOURCE_HEADERS = {
    Referer: 'https://portal.sleekfleet.com'
}

export const LOAD_TYPE_VALUES = ['Chilled', 'Dry', 'Freeze Protect', 'Frozen'];
export const LOAD_TYPE_OPTIONS = [
    {
        label: 'Chilled',
        value: 'Chilled',
    },
    {
        label: 'Dry',
        value: 'Dry',
    },
    {
        label: 'Freeze Protect',
        value: 'Freeze Protect',
    },
    {
        label: 'Frozen',
        value: 'Frozen',
    },
];

export const LOAD_STATUS_VALUES = [3, 2, 10, 6, 8, 12, 11, 7, 13, 14, 5, 4, 9];
export const LOAD_STATUS_OPTIONS = [
    {
        label: 'Awarded',
        value: 3,
    },
    {
        label: 'Cancelled',
        value: 2,
    },
    {
        label: 'Delivered',
        value: 10,
    },
    {
        label: 'Dispatched',
        value: 6,
    },
    {
        label: 'In Transit',
        value: 8,
    },
    {
        label: 'Invoice Issue',
        value: 12,
    },
    {
        label: 'Invoiced',
        value: 11,
    },
    {
        label: 'Loading',
        value: 7,
    },
    {
        label: 'Paid Balance Due',
        value: 13,
    },
    {
        label: 'Paid In Full',
        value: 14,
    },
    {
        label: 'TONU',
        value: 5,
    },
    {
        label: 'Tendered',
        value: 4,
    },
    {
        label: 'Unloading',
        value: 9,
    },
];

export const DELIVERY_DATE_OPTIONS = [
    {
        label: 'Last 7 Days',
        value: 7,
    },
    {
        label: 'Last 30 Days',
        value: 30,
    },
    {
        label: 'Last 60 Days',
        value: 60,
    },
    {
        label: 'Last 90 Days',
        value: 90,
    },
    {
        label: 'Custom Date Range',
        value: 'custom date range',
    },
]

export const EQUIPMENT_TYPE_OPTIONS = [
    {
        label: 'Reefer',
        value: 'Reefer',
    },
    {
        label: 'Van',
        value: 'Van',
    },
    {
        label: 'Flatbed',
        value: 'Flatbed',
    },
];

export const MILE_RADIUS_OPTIONS = [
    {
        label: '50 Miles',
        value: 50,
    },
    {
        label: '100 Miles',
        value: 100,
    },
    {
        label: '250 Miles',
        value: 250,
    },
];

export const DESTINATION_OPTIONS = [
    {
        label: 'All',
        value: 'all',
    },
    {
        label: 'Search By City & State',
        value: 'Search By City & State',
    },
];

export const BOOK_NOW_STATUS_VALUES = ['All', 'Accepted', 'Declined'];
export const BOOK_NOW_STATUS_OPTIONS = [
    {
        label: 'All',
        value: 'all',
    },
    {
        label: 'Accepted',
        value: 'Accepted',
    },
    {
        label: 'Declined',
        value: 'Declined',
    },
];

export const PAYMENT_STATUS_VALUES = ['Cancelled', 'Created', 'Processed'];
export const PAYMENT_STATUS_OPTIONS = [
    {
        label: 'Cancelled',
        value: 'Cancelled',
    },
    {
        label: 'Created',
        value: 'Created',
    },
    {
        label: 'Processed',
        value: 'Processed',
    },
];

export const ACCESSORIAL_TYPE_OPTIONS = [
    {
        label: 'Detention',
        value: 1,
    },
    {
        label: 'Driver Assist',
        value: 6,
    },
    {
        label: 'Driver Count',
        value: 7,
    },
    {
        label: 'Driver Unload',
        value: 5,
    },
    {
        label: 'Layover Charge',
        value: 4,
    },
    {
        label: 'Lumper Charge',
        value: 8,
    },
];

export const PAYMENT_METHOD_OPTIONS = [
    {
        label: 'PayPal',
        value: 'PayPal',
    },
    {
        label: 'Triumph Pay',
        value: 'Triumph Pay',
    },
]

export const TRAILER_TYPE_OPTIONS = [
    {
        label: 'Select Trailer Type',
        value: '',
    },
    {
        label: 'Dry Van',
        value: 'Dry Van',
    },
    {
        label: 'Reefer',
        value: 'Reefer',
    },
    {
        label: 'Flatbed',
        value: 'Flatbed',
    },
    {
        label: 'Tanker',
        value: 'Tanker',
    },
    {
        label: 'Bulk',
        value: 'Bulk',
    },
    {
        label: 'Other',
        value: 'Other',
    },
]

export const TRAILER_LENGTH_VALUES = ['53', '48', '45', '40'];
export const TRAILER_LENGTH_OPTIONS = [
    {
        label: 'Select Trailer Length',
        value: '',
    },
    {
        label: "53'",
        value: "53",
    },
    {
        label: "48'",
        value: "48",
    },
    {
        label: "45'",
        value: "45",
    },
    {
        label: "40'",
        value: "40",
    },
    {
        label: "Other",
        value: "Other",
    },
]

export const TRAILER_WIDTH_VALUES = ['96', '97', '98', '99', '100', '101', '102'];
export const TRAILER_WIDTH_OPTIONS = [
    {
        label: 'Select Trailer Width',
        value: '',
    },
    {
        label: '96"',
        value: '96',
    },
    {
        label: '97"',
        value: '97',
    },
    {
        label: '98"',
        value: '98',
    },
    {
        label: '99"',
        value: '99',
    },
    {
        label: '100"',
        value: '100',
    },
    {
        label: '101"',
        value: '101',
    },
    {
        label: '102"',
        value: '102',
    },
    {
        label: 'Other',
        value: 'Other',
    },
]

export const TRAILER_HEIGHT_VALUES = ['106', '107', '108', '109', '110'];
export const TRAILER_HEIGHT_OPTIONS = [
    {
        label: 'Select Trailer Height',
        value: '',
    },
    {
        label: '106"',
        value: '106',
    },
    {
        label: '107"',
        value: '107',
    },
    {
        label: '108"',
        value: '108',
    },
    {
        label: '109"',
        value: '109',
    },
    {
        label: '110"',
        value: '110',
    },
    {
        label: 'Other',
        value: 'Other',
    },
]

export const TRAILER_DOOR_TYPE_OPTIONS = [
    {
        label: 'Select Door Type',
        value: '',
    },
    {
        label: 'Roll',
        value: 'Roll',
    },
    {
        label: 'Swing',
        value: 'Swing',
    },
]

export const TAX_CLASSIFICATION_OPTIONS = [
    {
        label: 'Please Choose One',
        value: '',
    },
    {
        label: 'Individual / Sole Proprietor',
        value: 'individual',
    },
    {
        label: 'C Corporation',
        value: 'c-corp',
    },
    {
        label: 'S Corporation',
        value: 's-corp',
    },
    {
        label: 'Partnership',
        value: 'partner',
    },
    {
        label: 'Trust / Estate',
        value: 'trust',
    },
    {
        label: 'LLC, C Corporation',
        value: 'llc-c',
    },
    {
        label: 'LLC, S Corporation',
        value: 'llc-s',
    },
    {
        label: 'LLC, Partnership',
        value: 'llc-partner',
    },
    {
        label: 'Other',
        value: 'other',
    },
]

export const TAX_ID_TYPE_OPTIONS = [
    {
        label: 'Please Choose One',
        value: '',
    },
    {
        label: 'Social Security Number (SSN)',
        value: 'ssn',
    },
    {
        label: 'Employer Identification Number (EIN)',
        value: 'ein',
    },
]

export const STATE_PROVICE_OPTIONS = [
    {
        label: 'Alabama',
        value: 'AL',
    },
    {
        label: 'Alaska',
        value: 'AK',
    },
    {
        label: 'Alberta (Canada)',
        value: 'AB',
    },
    {
        label: 'Arkansas',
        value: 'AR',
    },
    {
        label: 'Arizona',
        value: 'AZ',
    },
    {
        label: 'British Columbia (Canada)',
        value: 'BC',
    },
    {
        label: 'California',
        value: 'CA',
    },
    {
        label: 'Colorado',
        value: 'CO',
    },
    {
        label: 'Connecticut',
        value: 'CT',
    },
    {
        label: 'Delaware',
        value: 'DE',
    },
    {
        label: 'Florida',
        value: 'FL',
    },
    {
        label: 'Georgia',
        value: 'GA',
    },
    {
        label: 'Hawaii',
        value: 'HI',
    },
    {
        label: 'Idaho',
        value: 'ID',
    },
    {
        label: 'Illinois',
        value: 'IL',
    },
    {
        label: 'Indiana',
        value: 'IN',
    },
    {
        label: 'Iowa',
        value: 'IA',
    },
    {
        label: 'Kansas',
        value: 'KS',
    },
    {
        label: 'Kentucky',
        value: 'KY',
    },
    {
        label: 'Louisiana',
        value: 'LA',
    },
    {
        label: 'Maine',
        value: 'ME',
    },
    {
        label: 'Manitoba (Canada)',
        value: 'MB',
    },
    {
        label: 'Maryland',
        value: 'MD',
    },
    {
        label: 'Massachusetts',
        value: 'MA',
    },
    {
        label: 'Michigan',
        value: 'MI',
    },
    {
        label: 'Minnesota',
        value: 'MN',
    },
    {
        label: 'Mississippi',
        value: 'MS',
    },
    {
        label: 'Missouri',
        value: 'MO',
    },
    {
        label: 'Montana',
        value: 'MT',
    },
    {
        label: 'Nebraska',
        value: 'NE',
    },
    {
        label: 'Nevada',
        value: 'NV',
    },
    {
        label: 'New Brunswick (Canada)',
        value: 'NB',
    },
    {
        label: 'New Hampshire',
        value: 'NH',
    },
    {
        label: 'New Jersey',
        value: 'NJ',
    },
    {
        label: 'New Mexico',
        value: 'NM',
    },
    {
        label: 'New York',
        value: 'NY',
    },
    {
        label: 'Newfoundland (Canada)',
        value: 'NL',
    },
    {
        label: 'North Carolina',
        value: 'NC',
    },
    {
        label: 'North Dakota',
        value: 'ND',
    },
    {
        label: 'Nova Scotia (Canada)',
        value: 'NS',
    },
    {
        label: 'Ohio',
        value: 'OH',
    },
    {
        label: 'Oklahoma',
        value: 'OK',
    },
    {
        label: 'Ontario (Canada)',
        value: 'ON',
    },
    {
        label: 'Oregon',
        value: 'OR',
    },
    {
        label: 'Pennsylvania',
        value: 'PA',
    },
    {
        label: 'Prince Edward Island (Canada)',
        value: 'PE',
    },
    {
        label: 'Quebec (Canada)',
        value: 'QC',
    },
    {
        label: 'Rhode Island',
        value: 'RI',
    },
    {
        label: 'Saskatchewan (Canada)',
        value: 'SK',
    },
    {
        label: 'South Carolina',
        value: 'SC',
    },
    {
        label: 'South Dakota',
        value: 'SD',
    },
    {
        label: 'Tennessee',
        value: 'TN',
    },
    {
        label: 'Texas',
        value: 'TX',
    },
    {
        label: 'Utah',
        value: 'UT',
    },
    {
        label: 'Vermont',
        value: 'VT',
    },
    {
        label: 'Virginia',
        value: 'VA',
    },
    {
        label: 'Washington',
        value: 'WA',
    },
    {
        label: 'West Virginia',
        value: 'WV',
    },
    {
        label: 'Wisconsin',
        value: 'WI',
    },
    {
        label: 'Wyoming',
        value: 'WY',
    },
]
