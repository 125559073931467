import React from 'react';
import { View } from 'react-native';
import { Button, Layout, StyleService, Text, useStyleSheet } from '@ui-kitten/components';
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome';

export default function LocationTrackingDisclosure(props) {

    const styles = useStyleSheet(themedStyles);

    return (
        <View>
            <View style={styles.locationIconView}>
                <FontAwesomeIcon style={styles.locationIcon} icon={['fal', 'location-circle']} size={styles.locationIcon.size} color={styles.locationIcon.color} />
                <Text style={styles.welcome} category="s1">Location Services Information</Text>
            </View>
            <View style={styles.section}>
                <Text style={styles.sectionText} category="p1" appearance="hint">Sleek Carrier Portal collects location data to enable load execution tracking even when the app is closed or not in use.</Text>
            </View>
            <View style={styles.section}>
                <Text style={styles.sectionTitle} category="s2">Why is location tracking important?</Text>
                <Text style={styles.sectionText} category="p1" appearance="hint">The Sleek Carrier Portal needs to track the locations of drivers during the load execution process so all appropriate parties may stay informed on the delivery progress.</Text>
            </View>
            <View style={styles.section}>
                <Text style={styles.sectionTitle} category="s2">How is my location data used?</Text>
                <Text style={styles.sectionText} category="p1" appearance="hint">Your location data is used to provide automatic updates to critical resources (e.g., shippers, dispatchers) during the execution of a load, alleviating the need for manual check ins.</Text>
            </View>
            <View style={styles.section}>
                <Text style={styles.sectionTitle} category="s2">When do you track my location?</Text>
                <Text style={styles.sectionText} category="p1" appearance="hint">Your location will only be tracked on an active load between the time you have indicated you are “Dispatched” until you are marked as “Delivered”.</Text>
            </View>
            <View style={styles.buttonView}>
                <Button onPress={() => props.setModalVisible(false)}>Got It</Button>
            </View>
        </View>
    )
}

const themedStyles = StyleService.create({
    locationIconView: {
        alignItems: "center",
        justifyContent: "center",
        paddingTop: 20,
        paddingBottom: 20,
        borderBottomWidth: 1,
        borderBottomColor: "border-generic",
        marginBottom: 20
    },
    locationIcon: {
        color: "color-primary-500",
        size: 48,
    },
    welcome: {
        marginTop: 15,
    },
    section: {
        marginBottom: 15,
    },
    sectionTitle: {

    },
    sectionText: {

    },
    buttonView: {
        marginTop: 15,
    }
});
