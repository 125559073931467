import React, { useContext, useState, useEffect } from 'react';
import { KeyboardAvoidingView, Image, Pressable, Platform, ScrollView, View } from 'react-native';
import { SafeAreaView } from 'react-native-safe-area-context';
import { Button, Divider, Layout, StyleService, Text, useStyleSheet } from '@ui-kitten/components';
import { AppDataContext, AuthContext } from 'components/context';
import { Header } from 'components/layout';
import { Loader, UploadPreview } from 'components/utility';
import { InputField, SelectField } from 'components/forms';
import { Formik, Field } from 'formik';
import Collapsible from 'react-native-collapsible';
import * as yup from 'yup';
import * as api from 'api/SleekTechnologiesApi';
import * as helper from 'utility/Helper';
import * as Constants from 'config/Constants';
import getEnvVars from 'root/environment';

export default function CarrierProfileEdit({ navigation }) {

    const styles = useStyleSheet(themedStyles);
    const { sleekApiUrl } = getEnvVars();
    const { appData, setAppData } = React.useContext(AppDataContext);
    const { signOut } = useContext(AuthContext);
    const carrierData = appData.carrierData;
    const [isLoading, setLoading] = useState(false);

    const [carrierLogoCollapsed, setCarrierLogoCollapsed] = useState(true);
    const [uploads, setUploads] = useState([]);

    const stateProvinceOptions = Constants.STATE_PROVICE_OPTIONS;
    let paymentMethodOptions = Constants.PAYMENT_METHOD_OPTIONS;

    if(!helper.hasValue(carrierData.preferredPaymentMethod)) {
        if(paymentMethodOptions[0].label != 'Please Choose One') {
            paymentMethodOptions.unshift({
                label: 'Please Choose One',
                value: '',
            });
        }
    }

    const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/

    yup.addMethod(yup.string, "phoneLength", function (errorMessage) {
        return this.test(`test-phone-length`, errorMessage, function (value) {
            const { path, createError } = this;

            return (
                ((value && value.replace(/-/g, "").length === 10) || typeof value == 'undefined') ||
                createError({ path, message: errorMessage })
            );
        });
    });

    const validationSchema = yup.object().shape({
        carrierName: yup
            .string()
            .required('Carrier Name is Required'),
        phone: yup
            .string()
            .matches(phoneRegExp, 'Phone number is not valid')
            .phoneLength('Phone number must be 10 digits')
            .required('Phone is Required'),
        email: yup
            .string()
            .email()
            .required('Email is Required'),
        paypalEmail: yup
            .string()
            .when('preferredPaymentMethod', {is: value => value && value == 'PayPal', then: yup.string().required('PayPal Email is Required').email()}),
        billingName: yup
            .string()
            .when('preferredPaymentMethod', {is: value => value && value == 'Triumph Pay', then: yup.string().required('Billing Name is Required')}),
        billingAddress: yup
            .string()
            .when('preferredPaymentMethod', {is: value => value && value == 'Triumph Pay', then: yup.string().required('Billing Address is Required')}),
        billingCity: yup
            .string()
            .when('preferredPaymentMethod', {is: value => value && value == 'Triumph Pay', then: yup.string().required('Billing City is Required')}),
        billingState: yup
            .string()
            .when('preferredPaymentMethod', {is: value => value && value == 'Triumph Pay', then: yup.string().required('Billing State is Required')}),
        billingZip: yup
            .string()
            .when('preferredPaymentMethod', {is: value => value && value == 'Triumph Pay', then: yup.string().required('Billing Zip is Required')}),
    });

    const breadcrumbs = [
        {
            label: 'Dashboard',
            path: '/dashboard'
        },
        {
            label: 'Carrier Profile',
            path: '/carrier-profile'
        },
        {
            label: 'Edit',
            path: ''
        }
    ]

    const pickImage = async () => {

        let result = await helper.pickImage();

        if(result) {
            let newUploads = uploads;
            newUploads.push(result);
            setUploads([...newUploads]);
        }

    };

    const takePhoto = async () => {

        let result = await helper.takePhoto();

        if(result) {
            let newUploads = uploads;
            newUploads.push(result);
            setUploads([...newUploads]);
        }
    };

    const UploadsList = () => {
        return (
            uploads.map((item, index) => {
                let uploadNumber = index + 1;
                item.uploadMethod = "imagePicker";
                item.uploadTitle = "Uploaded Carrier Logo";
                item.index = index;

                return (
                    <UploadPreview key={index} removeUpload={removeUpload} {...item} />
                )
            })
        )
    }

    const resetUploads = () => {
        let newUploads = [];
        setUploads([...newUploads]);
    }

    const removeUpload = (index) => {
        let newUploads = uploads;
        newUploads.splice(index, 1);
        setUploads([...newUploads]);
    }

    const carrierSettings = {
        carrierName: carrierData.carrierName,
        address: (carrierData.address == null ? '' : carrierData.address),
        address2: (carrierData.address2 == null ? '' : carrierData.address2),
        city: (carrierData.city == null ? '' : carrierData.city),
        state: (carrierData.state == null ? '' : carrierData.state),
        zip: (carrierData.zip == null ? '' : carrierData.zip),
        phone: carrierData.phone,
        email: carrierData.email,
        preferredPaymentMethod: (carrierData.preferredPaymentMethod != '' && carrierData.preferredPaymentMethod != null ? carrierData.preferredPaymentMethod : ''),
        paypalEmail: (carrierData.paypalEmail == null ? '' : carrierData.paypalEmail),
        billingName: (carrierData.billingName == null ? '' : carrierData.billingName),
        billingAddress: (carrierData.billingAddress == null ? '' : carrierData.billingAddress),
        billingAddress2: (carrierData.billingAddress2 == null ? '' : carrierData.billingAddress2),
        billingCity: (carrierData.billingCity == null ? '' : carrierData.billingCity),
        billingState: (carrierData.billingState == null ? '' : carrierData.billingState),
        billingZip: (carrierData.billingZip == null ? '' : carrierData.billingZip),
        carrierLogoImage: '',   //Possibly set to display the current one -- here and under render section
    };

    const saveCarrierData = (data) => {

        setLoading(true);

        //Add Carrier Logo to Data, if provided
        data.carrierPhotoContent = (typeof uploads[0] !== 'undefined' ? uploads[0].base64 : '');

        //Update Carrier Data
        api.updateCarrierData(data)
            .then(function(response){
                if(helper.hasValue(response.data.msg) && response.data.msg == "Token Not Found or Expired") {
                    signOut();
                }
                if(response.data.result == 'success') {
                    helper.showFlashMessage(response.data.result, response.data.msg);

                    //Refresh Application Data
                    api.getApplicationData()
                        .then(function(response){
                            if(response.data.result == "success") {
                                setAppData({...response.data});
                                navigation.reset({index: 0, routes: [{ name: 'CarrierProfile' }]});
                            } else if(response.data.result == "error") {
                                throw {
                                    type: "custom",
                                    message: response.data.msg
                                }
                            }
                        })
                        .catch(function(error){
                            helper.handleCatchError(error);
                        });
                } else if(response.data.result == "error") {
                    setLoading(false);
                    throw {
                        type: "custom",
                        message: response.data.msg
                    }
                }
            })
            .catch(function(error){
                setLoading(false);
                helper.handleCatchError(error);
            });
    };

    const EditCarrierProfileView = () => {
        return (
            <Layout style={styles.layout} level="3">
                <Formik
                    validationSchema={validationSchema}
                    initialValues={carrierSettings}
                    onSubmit={(values) => saveCarrierData(values)}
                >
                    {({ handleSubmit, isValid, values }) => (
                        <>
                            <View style={styles.formSection}>
                                <View style={styles.formSectionTitle}>
                                    <Text category="s1">Contact Information</Text>
                                </View>
                                <View style={styles.formSectionFields}>
                                    <View style={styles.collapsibleWrapper}>
                                        <View style={styles.collapsibleHeader}>
                                            <View style={styles.collapsibleHeaderRowOne}>
                                                <Image
                                                    source={{
                                                        uri: sleekApiUrl+carrierData.carrierLogo,
                                                        headers: Constants.RESOURCE_HEADERS
                                                    }}
                                                    style={styles.carrierLogo}
                                                    resizeMode="contain"
                                                />
                                            </View>
                                            <View style={styles.collapsibleHeaderRowTwo}>
                                                <Pressable onPress={() => setCarrierLogoCollapsed(!carrierLogoCollapsed)}>
                                                    <Text style={styles.collapsibleHeaderAction} status="primary" category="s2">Update Carrier Logo</Text>
                                                </Pressable>
                                            </View>
                                        </View>
                                        <Collapsible style={styles.collapsibleBody} collapsed={carrierLogoCollapsed}>
                                            {uploads.length == 0 &&
                                                <>
                                                    <Text style={styles.formSectionIntro} category="p1" appearance="hint">Upload a new image to replace your existing logo:</Text>
                                                    <View style={styles.buttonWrapper}>
                                                        <View style={styles.buttonView}>
                                                            <Button style={[styles.button, styles.buttonLeft]} appearance="outline" size="small" onPress={pickImage}>Choose From Library</Button>
                                                        </View>
                                                        {!helper.isWeb() &&
                                                            <View style={styles.buttonView}>
                                                                <Button style={[styles.button, styles.buttonRight]} appearance="outline" size="small" onPress={takePhoto}>Take Photo</Button>
                                                            </View>
                                                        }
                                                    </View>
                                                </>
                                            }
                                            {uploads.length > 0 &&
                                                <>
                                                    <View style={styles.uploadsView}>
                                                        <UploadsList />
                                                    </View>
                                                    <View style={styles.buttonWrapper}>
                                                        <View style={styles.buttonView}>
                                                            <Button style={styles.button} appearance="outline" size="small" onPress={resetUploads}>Cancel</Button>
                                                        </View>
                                                    </View>
                                                </>
                                            }
                                        </Collapsible>
                                    </View>
                                    <Field
                                        component={InputField}
                                        name="carrierName"
                                        label="Name"
                                        defaultValue={carrierData.carrierName}
                                    />
                                    <Field
                                        component={InputField}
                                        name="address"
                                        label="Address"
                                        defaultValue={carrierData.address}
                                    />
                                    <Field
                                        component={InputField}
                                        name="address2"
                                        label="Address 2 (Optional)"
                                        defaultValue={carrierData.address2}
                                    />
                                    <Field
                                        component={InputField}
                                        name="city"
                                        label="City"
                                        defaultValue={carrierData.city}
                                    />
                                    <Field
                                        component={SelectField}
                                        name="state"
                                        label="State / Province"
                                        options={stateProvinceOptions}
                                        multiSelect={false}
                                        defaultValue={carrierData.state}
                                    />
                                    <Field
                                        component={InputField}
                                        name="zip"
                                        label="Zip"
                                        defaultValue={carrierData.zip}
                                    />
                                    <Field
                                        component={InputField}
                                        name="phone"
                                        label="Phone"
                                        defaultValue={carrierData.phone}
                                    />
                                    <Field
                                        component={InputField}
                                        name="email"
                                        label="Email"
                                        defaultValue={carrierData.email}
                                    />
                                </View>
                            </View>
                            <View style={styles.formSection}>
                                <View style={styles.formSectionTitle}>
                                    <Text category="s1">Payment Information</Text>
                                </View>
                                <View style={styles.formSectionFields}>
                                    <Field
                                        component={SelectField}
                                        name="preferredPaymentMethod"
                                        label="Payment Method"
                                        options={paymentMethodOptions}
                                        multiSelect={false}
                                    />
                                    {values.preferredPaymentMethod == 'PayPal' &&
                                        <Field
                                            component={InputField}
                                            name="paypalEmail"
                                            label="PayPal Email"
                                            defaultValue={carrierData.paypalEmail}
                                        />
                                    }
                                    {values.preferredPaymentMethod == 'Triumph Pay' &&
                                        <>
                                            <Field
                                                component={InputField}
                                                name="billingName"
                                                label="Billing Name"
                                                defaultValue={carrierData.billingName}
                                            />
                                            <Field
                                                component={InputField}
                                                name="billingAddress"
                                                label="Billing Address"
                                                defaultValue={carrierData.billingAddress}
                                            />
                                            <Field
                                                component={InputField}
                                                name="billingAddress2"
                                                label="Billing Suite / Unit"
                                                defaultValue={carrierData.billingAddress2}
                                            />
                                            <Field
                                                component={InputField}
                                                name="billingCity"
                                                label="Billing City"
                                                defaultValue={carrierData.billingCity}
                                            />
                                            <Field
                                                component={SelectField}
                                                name="billingState"
                                                label="Billing State / Province"
                                                options={stateProvinceOptions}
                                                multiSelect={false}
                                                defaultValue={carrierData.billingState}
                                            />
                                            <Field
                                                component={InputField}
                                                name="billingZip"
                                                label="Billing Zip"
                                                defaultValue={carrierData.billingZip}
                                            />
                                        </>
                                    }
                                </View>
                            </View>
                            <View style={styles.submitButtonView}>
                                <Button onPress={handleSubmit} disabled={!isValid}>Update Carrier Profile</Button>
                            </View>
                        </>
                    )}
                </Formik>
            </Layout>
        )
    }

    if(isLoading) {
        return (
            <Loader loadingText="Updating Carrier Profile" />
        )
    } else {
        return (
            helper.isWeb() ? (
                <View style={styles.webView}>
                    <Header breadcrumbs={breadcrumbs} title="Edit Carrier Profile" />
                    <ScrollView>
                        <EditCarrierProfileView />
                    </ScrollView>
                </View>
            ) : (
                <SafeAreaView style={styles.safeAreaView} edges={['top', 'left', 'right']}>
                    <KeyboardAvoidingView
                        behavior={Platform.OS === "ios" ? "padding" : "height"}
                        style={{flex: 1}}
                    >
                        <Header activeScreen="CarrierProfileEdit" />
                        <ScrollView>
                            <EditCarrierProfileView />
                        </ScrollView>
                    </KeyboardAvoidingView>
                </SafeAreaView>
            )
        )
    }

}

const themedStyles = StyleService.create({
    // global styles
    layout: {
        flex: 1,
        paddingTop: 4,
    },
    formSection: {
        backgroundColor: "color-basic-100",
        borderRadius: 6,
        borderWidth: 1,
        borderColor: "border-generic",
        marginHorizontal: 8,
        marginVertical: 4,
        shadowColor: "shadow-color",
        shadowOffset: {
            width: 0,
            height: 0,
        },
        shadowOpacity: .1,
        shadowRadius: 2
    },
    formSectionTitle: {
        backgroundColor: "background-basic-color-2",
        borderBottomWidth: 1,
        borderBottomColor: "border-basic-color-4",
        padding: 10,
        alignItems: "center",
        flexDirection: "row",
        minHeight: 50,
        borderTopLeftRadius: 6,
        borderTopRightRadius: 6,
    },
    formSectionFields: {
        paddingVertical: 10,
        paddingHorizontal: 10,
    },
    formSectionFieldsNoVerticalPadding: {
        paddingVertical: 0,
    },
    formSectionIntro: {
        marginBottom: 10,
    },
    toggleRow: {
        flexDirection: "row",
        paddingVertical: 3,
        borderBottomColor: "border-basic-color-3",
        borderBottomWidth: 1,
        minHeight: 54,
    },
    toggleRowLast: {
        borderBottomWidth: 0,
    },
    toggleLabel: {
        flex: 2,
        justifyContent: "center",
    },
    toggleInput: {
        flex: 1,
        justifyContent: "center",
        alignItems: "flex-end",
    },
    collapsibleWrapper: {
        marginTop: 10,
        marginBottom: 15,
        backgroundColor: "color-basic-300",
        borderWidth: 1,
        borderColor: "border-generic",
        borderRadius: 4,
    },
    collapsibleHeader: {
        flexDirection: "row",
        paddingHorizontal: 10,
        paddingVertical: 5,
        alignItems: "center",
    },
    collapsibleHeaderRowOne: {
        flex: 1,
    },
    collapsibleHeaderRowTwo: {
        flex: 1,
    },
    collapsibleHeaderAction: {
        textAlign: "right",
    },
    collapsibleBody: {
        backgroundColor: "color-basic-100",
        borderTopWidth: 1,
        borderTopColor: "border-generic",
        padding: 10,
        paddingBottom: 5,
        borderBottomStartRadius: 4,
        borderBottomEndRadius: 4,
    },
    carrierLogo: {
        width: 50,
        height: 50
    },
    buttonWrapper: {
        flexDirection: "row",
    },
    buttonView: {
        flex: 1,
    },
    button: {
        marginBottom: 10,
    },
    buttonLeft: {
        marginRight: 5,
    },
    buttonRight: {
        marginLeft: 5
    },
    submitButtonView: {
        paddingHorizontal: 10,
        marginBottom: 40,
        marginTop: 10
    },
    // native specific styles
    safeAreaView: {
        backgroundColor: "safe-area-view-background",
        flex: 1,
    },
    // web specific styles
    webView: {
        flex: 1,
    },
});
