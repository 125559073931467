import React from 'react';
import { Dimensions, View } from 'react-native';
import { Button, Divider, Layout, List, ListItem, StyleService, Text, useStyleSheet } from '@ui-kitten/components';
import { useNavigation } from '@react-navigation/native';
import { ActivityItem } from 'components/loads';
import { EmptyDataSet } from 'components/utility';
import { Section } from 'components/layout';
import * as helper from 'utility/Helper';

export default function ActivityView(props) {

    const styles = useStyleSheet(themedStyles);
    const load = props.loadDetails;
    const windowWidth = Dimensions.get('window').width;
    const windowHeight = Dimensions.get('window').height;
    const bodyHeight = windowHeight - 170;
    const navigation = useNavigation();
    const isManager = helper.checkForRoles(['manager']);

    const renderActivity = () => {
        if(load.activity.length > 0) {
            return (
                <List style={styles.listView} data={load.activity} renderItem={renderActivityItem} ItemSeparatorComponent={Divider} />
            )
        } else {
            return (
                <EmptyDataSet secondaryMessage="No Load Activity" />
            )
        }
    }

    const renderActivityItem = ({ item, index }) => {

        if(!isManager) {
            if(item.type == 'Book Now' || item.type == 'Payment') {
                return null;
            }
        }

        return (
            <ListItem children={<ActivityItem {...item} />} />
        )
    }

    React.useLayoutEffect(() => {
        if(helper.isWeb()) {
            navigation.setOptions({
                title: 'Load Details - Activity'
            })
        }
    }, [navigation]);

    return (
        <Layout style={styles.layout} level="3">
            {helper.isDesktopWeb() &&
                <Section title="Activity" noBodyPadding={load.activity.length > 0 ? true : false} bodyHeight={bodyHeight} renderBody={renderActivity()} />
            }
            {!helper.isDesktopWeb() &&
                renderActivity()
            }
        </Layout>
    )
}

const themedStyles = StyleService.create({
    layout: {
        marginTop: helper.isDesktopWeb() ? 5 : 0
    },
    listView: {
        borderBottomLeftRadius: helper.isDesktopWeb() ? 6 : 0,
        borderBottomRightRadius: helper.isDesktopWeb() ? 6 : 0
    },
});
