import React from 'react';
import { View } from 'react-native';
import { StyleService, Text, useStyleSheet } from '@ui-kitten/components';
import { CustomLink } from 'components/utility';
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome';

export const Breadcrumbs = (props) => {

    /*
        props:
        - breadcrumbs
    */

    const styles = useStyleSheet(themedStyles);

    const renderBreadcrumbs = () => {
        if(props.breadcrumbs.length > 0) {
            return (
                props.breadcrumbs.map((item, index) => {

                    const isLast = index + 1 == props.breadcrumbs.length ? true : false;

                    if(item.path != '') {
                        return (
                            <View style={styles.breadcrumbView} key={index}>
                                <CustomLink to={item.path}>
                                    <Text category="s2" status="primary">{item.label}</Text>
                                </CustomLink>
                                {!isLast &&
                                    <FontAwesomeIcon style={styles.icon} icon={['far', 'angle-right']} size={styles.icon.size} color={styles.icon.color} />
                                }
                            </View>
                        )
                    } else {
                        return (
                            <View style={styles.breadcrumbView} key={index}>
                                <Text category="s2">{item.label}</Text>
                            </View>
                        )
                    }
                })
            )
        } else {
            return null;
        }
    }

    return (
        <View style={styles.container}>
            {renderBreadcrumbs()}
        </View>
    )

}

const themedStyles = StyleService.create({
    container: {
        flexDirection: "row",
        alignItems: "center",
    },
    breadcrumbView: {
        flexDirection: "row",
        alignItems: "center"
    },
    icon: {
        size: 16,
        color: "color-basic-600",
    },
});
