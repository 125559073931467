import React, { useState, useContext } from 'react';
import { View } from 'react-native';
import { Button, Layout, StyleService, Text, useStyleSheet } from '@ui-kitten/components';
import { AppDataContext, AuthContext } from 'components/context';
import { Formik, Field } from 'formik';
import { InputField, ToggleField } from 'components/forms';
import { Loader } from 'components/utility';
import * as yup from 'yup';
import * as helper from 'utility/Helper';
import * as api from 'api/SleekTechnologiesApi';

export default function AddUser(props) {

    const styles = useStyleSheet(themedStyles);
    const { appData } = useContext(AppDataContext);
    const { signOut } = useContext(AuthContext);
    const [isLoading, setLoading] = useState(false);
    const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/

    yup.addMethod(yup.string, "phoneLength", function (errorMessage) {
        return this.test(`test-phone-length`, errorMessage, function (value) {
            const { path, createError } = this;

            return (
                ((value && value.replace(/-/g, "").length === 10) || typeof value == 'undefined') ||
                createError({ path, message: errorMessage })
            );
        });
    });

    const validationSchema = yup.object().shape({
        firstName: yup
            .string()
            .required('First Name is Required'),
        lastName: yup
            .string()
            .required('Last Name is Required'),
        email: yup
            .string()
            .email('Email must be a valid email')
            .test('oneOfRequired', 'Email or Phone is Required', function(item){return(this.parent.email || this.parent.phone)}),
        phone: yup
            .string()
            .matches(phoneRegExp, 'Phone number is not valid')
            .phoneLength('Phone number must be 10 digits')
            .test('oneOfRequired', 'Email or Phone is Required', function(item){return(this.parent.email || this.parent.phone)}), 
        admin: yup.boolean().when(['manager', 'driver'], {
            is: (manager, driver) => !manager && !driver,
            then: yup.boolean().oneOf([true], "At least one role is required"),
        }),
        manager: yup.boolean().when(['admin', 'driver'], {
            is: (admin, driver) => !admin && !driver,
            then: yup.boolean().oneOf([true], "At least one role is required"),
        }),
        driver: yup.boolean().when(['admin', 'manager'], {
            is: (admin, manager) => !admin && !manager,
            then: yup.boolean().oneOf([true], "At least one role is required"),
        })
    }, [['admin', 'manager'], ['admin', 'driver'], ['manager','driver']]);

    const newUserSettings = {
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
        admin: false,
        manager: false,
        driver: (props.isDriver ? true : false),
    }

    const createUser = (data) => {

        setLoading(true);

        //Format Phone & Email
        data.email = (typeof data.email == 'undefined' ? '' : data.email);
        data.phone = (typeof data.phone == 'undefined' ? '' : data.phone);
        if(data.phone != '') {
            let submittedPhone = data.phone.replace(/-/g, "");
            data.phone = submittedPhone.substr(0,3)+'-'+submittedPhone.substr(3,3)+'-'+submittedPhone.substr(6,4);
        }

        //Create Carrier User
        api.createCarrierUser(data)
            .then(function(response) {
                if(helper.hasValue(response.data.msg) && response.data.msg == "Token Not Found or Expired") {
                    signOut();
                }
                setLoading(false);
                if(response.data.result == 'success') {
                    let newUserRoles = [];
                    if(data.admin) {
                        newUserRoles.push('admin');
                    }
                    if(data.manager) {
                        newUserRoles.push('manager');
                    }
                    if(data.driver) {
                        newUserRoles.push('driver');
                    }

                    let newCarrierUser = {
                        id: response.data.newCarrierUserId,
                        isActive: 'Yes',
                        name: data.firstName+" "+data.lastName,
                        userType: '',
                        isAdmin: '',
                        isDriver: '',
                        allowBookNow: '',
                        roles: newUserRoles,
                        email: data.email,
                        phone: data.phone,
                        userPhoto: '/images/app/logos/default-driver.png',
                        hasConverted: 'Yes',
                        createdAt: response.data.createdAt,
                        updatedAt: response.data.updatedAt,
                    }

                    if(helper.hasValue(props.isRefresh)) {
                        props.refreshAction(props.isRefresh)
                    } else {
                        props.refreshAction();
                    }

                    props.setModalVisible(false);
                    props.setModalError(null);
                    helper.showFlashMessage(response.data.result, response.data.msg);

                } else if(response.data.result == "error") {
                    throw {
                        type: "custom",
                        message: response.data.msg
                    }
                }

            })
            .catch(function(error){
                setLoading(false);
                helper.handleCatchError(error, true, props.setModalError);
            });
    }

    if(isLoading) {
        return (
            <Loader loadingText="Creating User" />
        )
    } else {
        return (
            <>
                <Formik
                    validationSchema={validationSchema}
                    initialValues={newUserSettings}
                    onSubmit={(values) => createUser(values)}
                >
                    {({ handleSubmit, isValid, values }) => (
                        <>
                            <View style={styles.formSection}>
                                <View style={styles.formSectionTitle}>
                                    <Text category="s1">Contact Information</Text>
                                </View>
                                <View style={styles.formSectionFields}>
                                    <Field
                                        component={InputField}
                                        name="firstName"
                                        label="First Name"
                                        defaultValue={''}
                                    />
                                    <Field
                                        component={InputField}
                                        name="lastName"
                                        label="Last Name"
                                        defaultValue={''}
                                    />
                                    <Field
                                        component={InputField}
                                        name="email"
                                        label="Email"
                                        defaultValue={''}
                                    />
                                    <Field
                                        component={InputField}
                                        name="phone"
                                        label="Phone"
                                        defaultValue={''}
                                    />
                                </View>
                            </View>
                            <View style={styles.formSection}>
                                <View style={styles.formSectionTitle}>
                                    <Text category="s1">Roles</Text>
                                </View>
                                <View style={styles.formSectionFields}>
                                    <View style={styles.toggleRow}>
                                        <View style={styles.toggleLabel}>
                                            <Text category="s2" appearance="hint">Admin</Text>
                                        </View>
                                        <View style={styles.toggleInput}>
                                            <Field
                                                component={ToggleField}
                                                disabled={helper.checkForRoles(['admin']) ? false : true}
                                                name="admin"
                                                defaultValue={false}
                                            />
                                        </View>
                                    </View>
                                    <View style={styles.toggleRow}>
                                        <View style={styles.toggleLabel}>
                                            <Text category="s2" appearance="hint">Manager</Text>
                                        </View>
                                        <View style={styles.toggleInput}>
                                            <Field
                                                component={ToggleField}
                                                disabled={false}
                                                name="manager"
                                                defaultValue={false}
                                            />
                                        </View>
                                    </View>
                                    <View style={[styles.toggleRow, styles.toggleRowLast]}>
                                        <View style={styles.toggleLabel}>
                                            <Text category="s2" appearance="hint">Driver</Text>
                                        </View>
                                        <View style={styles.toggleInput}>
                                            <Field
                                                component={ToggleField}
                                                disabled={false}
                                                name="driver"
                                                defaultValue={(props.isDriver ? true : false)}
                                            />
                                        </View>
                                    </View>
                                </View>
                            </View>
                            <View style={styles.submitButtonView}>
                                <Button onPress={handleSubmit} disabled={!isValid}>{(props.isDriver ? 'Create Driver' : 'Create User')}</Button>
                            </View>
                        </>
                    )}
                </Formik>
            </>
        )
    }
}

const themedStyles = StyleService.create({
    formSection: {
        backgroundColor: "color-basic-100",
        marginTop: 5,
    },
    formSectionTitle: {
        backgroundColor: "background-basic-color-3",
        paddingHorizontal: 10,
        paddingVertical: 10,
        alignItems: "center",
        flexDirection: "row",
        borderBottomColor: "border-generic",
        borderBottomWidth: 1,
    },
    formSectionFields: {
        paddingVertical: 10,
        paddingHorizontal: 10,
    },
    formSectionFieldsNoVerticalPadding: {
        paddingVertical: 0,
    },
    formSectionIntro: {
        marginBottom: 10,
    },
    toggleRow: {
        flexDirection: "row",
        paddingVertical: 3,
        borderBottomColor: "border-basic-color-3",
        borderBottomWidth: 1,
        minHeight: 54,
    },
    toggleRowLast: {
        borderBottomWidth: 0,
    },
    toggleLabel: {
        flex: 1,
        justifyContent: "center",
    },
    toggleInput: {
        flex: 1,
        justifyContent: "center",
        alignItems: "flex-end",
    },
    submitButtonView: {
        marginBottom: 40,
        marginTop: 20
    },
});
