import React, { useState, useEffect } from 'react';
import { View } from 'react-native';
import { RangeCalendar, NativeDateService, StyleService, Text, useStyleSheet } from '@ui-kitten/components';
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome';

export const RangeCalendarField = (props) => {

    const {
        field: { name, onBlur, onChange, value },
        form: { errors, touched, setFieldTouched, setFieldValue },
        ...inputProps
    } = props;

    const formatDateService = new NativeDateService('en', { format: 'MM/DD/YYYY' });
    const hasError = errors[name] && touched[name];
    const [range, setRange] = useState({});
    const styles = useStyleSheet(themedStyles);

    useEffect(() => {
        setFieldValue(name, range);
    }, [range]);

    return (
        <>
            <RangeCalendar
                style={styles.selectInput}
                size="large"
                status="basic"
                range={range}
                onSelect={nextRange => {
                   setRange(nextRange);
                   setFieldTouched(name);
                }}
                dateService={formatDateService}
                accessoryRight={<FontAwesomeIcon icon={['fal', 'calendar']} />}
            />
            {hasError &&
                <View style={styles.errorView}>
                    <Text category="p2" status="danger">{errors[name]}</Text>
                </View>
            }
        </>
    );

}

const themedStyles = StyleService.create({
    textInput: {
        // styles here
    },
    errorView: {
        marginTop: -3,
        marginLeft: 10,
        marginBottom: 10,
    },
    errorInput: {
        borderColor: "color-danger-500"
    }
});
